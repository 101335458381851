import {
  SimpleGrid,
  Stack,
  Title,
  LoadingOverlay,
  Text,
  Skeleton,
  Paper,
} from '@mantine/core';
import { MapEvent } from 'mapbox-gl';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './common-result-maps.module.css';

import { FORM_STATUS } from '@advices/data-access';
import { ProductRel, ZoningFeatureCollection } from '@data-access';
import {
  LegendControl,
  PfMap,
  ZoningLayer,
  ZoningLegend,
  ZoningLegendProps,
  zoomOnFeature,
} from '@map';

const Pending = () => (
  <Skeleton animate={false} h="100%" pos="relative" radius="xs">
    <LoadingOverlay visible />
  </Skeleton>
);

const Incomplete = () => (
  <Stack className={classes.fullContainer}>
    <Title order={3} size="h2">
      Formulaire incomplet
    </Title>
    <Text size="lg">
      Certaines données sont manquantes afin de générer un conseil. Merci de
      vérifier et de compléter les informations manquantes
    </Text>
  </Stack>
);

const Error = () => (
  <Stack className={classes.fullContainer}>
    <Title order={3} size="h2">
      Nous avons rencontré une erreur
    </Title>
    <Text size="lg">
      Une erreur est survenue lors de la génération du conseil. Merci de
      réessayer plus tard.
    </Text>
  </Stack>
);

interface CommonResultMapsProps {
  status: FORM_STATUS;
  nitrogenmap: ZoningFeatureCollection | null;
  yieldmap: ZoningFeatureCollection | null;
  product?: ProductRel | null;
  yieldlegend?: {
      total: number;
      mean: number;
    };
  nitrogenlegend?: {
    total: number;
    mean: number;
    total_N: number;
    mean_N: number;
  };
}

export function CommonResultMaps({
  status,
  nitrogenmap,
  nitrogenlegend,
  yieldmap,
  yieldlegend,
  product,
}: CommonResultMapsProps) {
  const { t } = useTranslation();

  const yieldmapLegend: ZoningLegendProps = useMemo(() => {
    if (yieldmap) {
      const l = yieldmap.features.reduce((acc, feature) => {
        return {
          ...acc,
          [feature.properties.zone]: {
            zone: feature.properties.zone,
            color: feature.properties.color,
            value: feature.properties.value.toFixed(2),
            percent: feature.properties.percent,
            area: feature.properties.area,
          },
        };
      }, {});

      return {
        title: t('advices.yield-map-legend-title'),
        legend: Object.values(l),
        unit: 'q/ha',
        mean : yieldlegend?.mean,
      };
    }
    return {
      legend: [],
    };
  }, [t, yieldmap,yieldlegend]);

  const nitrogenmapLegend: ZoningLegendProps = useMemo(() => {
    if (nitrogenmap) {
      const l = nitrogenmap.features.reduce((acc, feature) => {
        return {
          ...acc,
          [feature.properties.zone]: {
            zone: feature.properties.zone,
            color: feature.properties.color,
            value: feature.properties.value.toFixed(2),
            percent: feature.properties.percent,
            area: feature.properties.area,
            value_N: feature.properties.value_N?.toFixed(2),
          },
        };
      }, {});

      return {
        legend: Object.values(l),
        unit: product?.unit,
        mean : nitrogenlegend?.mean,
        total : nitrogenlegend?.total,
        mean_N : nitrogenlegend?.mean_N,
        total_N : nitrogenlegend?.total_N,
      };
    }
    return {
      legend: [],
    };
  }, [nitrogenmap, product?.unit,nitrogenlegend]);

  const handleMapLoad = (e: MapEvent, map: ZoningFeatureCollection) => {
    if (yieldmap) {
      zoomOnFeature({
        map: e.target,
        geojson: map,
      });
    }
  };

  if (status === FORM_STATUS.INCOMPLETE) {
    return <Incomplete />;
  }

  if (status === FORM_STATUS.ERROR) {
    return <Error />;
  }

  return (
    <SimpleGrid className={classes.grid} cols={2}>
      <Stack className={classes.col}>
        <Title order={3}>{t('common.yield_potential')}</Title>
        {status !== FORM_STATUS.PENDING && yieldmap ? (
          <PfMap
            classnames={{
              container: classes.mapContainer,
            }}
            onLoad={(e) => handleMapLoad(e, yieldmap)}
          >
            <ZoningLayer data={yieldmap} />
            <LegendControl>
              <ZoningLegend {...yieldmapLegend} />
            </LegendControl>
          </PfMap>
        ) : (
          <Pending />
        )}
      </Stack>
      <Stack className={classes.col}>
        <Title order={3}>{t('common.prescription_map')}</Title>
        {status !== FORM_STATUS.PENDING && nitrogenmap ? (
          <PfMap
            classnames={{
              container: classes.mapContainer,
            }}
            onLoad={(e) => handleMapLoad(e, nitrogenmap)}
          >
            <ZoningLayer data={nitrogenmap} />
            <LegendControl>
              <ZoningLegend
                {...nitrogenmapLegend}
                product={product}
                switchable
              />
            </LegendControl>
          </PfMap>
        ) : (
          <Pending />
        )}
      </Stack>
    </SimpleGrid>
  );
}

export default CommonResultMaps;
