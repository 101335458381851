const StaticMode: MapboxDraw.DrawCustomMode = {
  onSetup: function () {
    this.setActionableState({
      combineFeatures: false,
      uncombineFeatures: false,
      trash: false,
    }); // default actionable state is false for all actions
    return {};
  },
  toDisplayFeatures(state, geojson, display) {
    display(geojson);
  },
};

// StaticMode.onSetup = function () {
//   this.setActionableState(); // default actionable state is false for all actions
//   return {};
// };

// StaticMode.toDisplayFeatures = function (state, geojson, display) {
//   display(geojson);
// };

export { StaticMode };
