import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { fieldsQueries } from '../../queries/fields-queries';

import { useFiltersStore, useQueriesWithGlobal } from '@data-access';

export function useFields() {
  const page = useFiltersStore.use['plots']().pagination.pageIndex + 1;
  const sorting = useFiltersStore.use['plots']().sorting;
  const filters = useFiltersStore.use['plots']().filters;
  const queries = useQueriesWithGlobal(fieldsQueries);

  const query = useQuery({
    ...queries.list({ page, sorting, filters }),
    placeholderData: keepPreviousData,
  });

  return query;
}

export default useFields;
