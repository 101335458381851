import { useMutation } from '@tanstack/react-query';

import { fieldsApi } from '../../apis/fields';

export function useUpdatePkAdvice() {
  const mutation = useMutation({
    mutationFn: fieldsApi.updatePkAdvice,
  });

  return mutation;
}

export default useUpdatePkAdvice;
