export const asCbData = (data?: Record<string, any>[]) => {
  return data?.map(({ label, value }) => {
    if (!label && typeof label !== 'string') {
      throw new Error('Data should have a label');
    }

    if (!value && typeof value !== 'string') {
      throw new Error('Data should have a value');
    }

    return {
      label,
      value,
    };
  });
};
