import { queryOptions, useQuery } from '@tanstack/react-query';

import { sharedApi } from '../../apis/shared.api';

interface MeQueryOptions {
  enabled?: boolean;
}

export function campaignsQueryOptions(params: MeQueryOptions = {}) {
  return queryOptions({
    queryKey: ['campaigns'],
    queryFn: () => sharedApi.campaigns(),
    enabled: params.enabled,
  });
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseCampaigns {}

export function useCampaigns() {
  const query = useQuery(campaignsQueryOptions());

  return query;
}

export default useCampaigns;
