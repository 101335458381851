import { Stack } from '@mantine/core';

import { PlotsDataGrid } from '../../components/plots-data-grid/plots-data-grid';
import { SynthesisHeader } from '../../components/synthesis-header/synthesis-header';

import styles from './page.module.css';

export function SynthesisPage() {
  return (
    <Stack>
      <SynthesisHeader />
      <PlotsDataGrid />
    </Stack>
  );
}

export default SynthesisPage;
