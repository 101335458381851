import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { accountsQueries } from '../../queries/accounts';

import { useFiltersStore, useQueriesWithGlobal } from '@data-access';

export function useAccounts() {
  const page = useFiltersStore.use['accounts']().pagination.pageIndex + 1;
  const sorting = useFiltersStore.use['accounts']().sorting;
  const filters = useFiltersStore.use['accounts']().filters;
  const queries = useQueriesWithGlobal(accountsQueries);

  const query = useQuery({
    ...queries.list({ page, sorting, filters }),
    placeholderData: keepPreviousData,
  });

  return query;
}

export default useAccounts;
