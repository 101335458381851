import { Button, Grid, Group, Paper, Title } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { dashboardApi } from '../../data-access/api/dashboard.api';

import styles from './statistics-header.module.css';

export function StatisticsHeader() {
  const { t } = useTranslation();

  const exportStats = useMutation({
    mutationFn: dashboardApi.exportStats,
  });

  return (
    <Paper>
      <Group justify="space-between">
        <Title order={2}>{t('statistics.title')}</Title>
        <Button
          loading={exportStats.isPending}
          onClick={() => {
            exportStats.mutate();
          }}
        >
          {t('statistics.export')}
        </Button>
      </Group>
    </Paper>
  );
}

export default StatisticsHeader;
