import { Group, ActionIcon, Image, Box } from '@mantine/core';
import { IconHelp, IconLogout, IconUserCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import classes from './app-header.module.css';

import { myPrecifieldLogo2, precifieldLogoIcon } from '@assets';
import { AccountsSelector, CampaignSelect, LangSelect, TsrAILink } from '@ui';

interface AppHeaderProps {
  logout: () => void;
}

export function AppHeader({ logout }: AppHeaderProps) {
  const { t } = useTranslation();

  return (
    <Group className={classes.container} h="100%" justify="space-between">
      <Group h="100%">
        <Box className={classes.logoContainer}>
          <Image
            alt="MyPrecifield"
            fit="contain"
            src={myPrecifieldLogo2}
            visibleFrom="md"
          />
          <Image
            alt="MyPrecifield"
            fit="contain"
            hiddenFrom="md"
            src={precifieldLogoIcon}
          />
        </Box>
      </Group>
      <Group>
        <AccountsSelector />
        <CampaignSelect />
        <TsrAILink
          aria-label={t('app.nav.help')}
          color="white"
          size={40}
          to="/support"
          variant="subtle"
          visibleFrom="xs"
        >
          <IconHelp stroke={1.5} style={{ width: '100%', height: '100%' }} />
        </TsrAILink>
        <Group visibleFrom="md">
          <LangSelect />
        </Group>
        <TsrAILink
          aria-label={t('app.nav.profile')}
          color="white"
          size={40}
          to="/profile"
          variant="subtle"
          visibleFrom="xs"
        >
          <IconUserCircle
            stroke={1.5}
            style={{ width: '100%', height: '100%' }}
          />
        </TsrAILink>
        <Group visibleFrom="md">
          <ActionIcon
            aria-label={t('auth.logout')}
            color="white"
            onClick={logout}
            size={40}
            variant="subtle"
          >
            <IconLogout
              stroke={1.5}
              style={{ width: '100%', height: '100%' }}
            />
          </ActionIcon>
        </Group>
      </Group>
    </Group>
  );
}

export default AppHeader;
