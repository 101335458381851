import { constants } from '@mapbox/mapbox-gl-draw';

export const constantsModes: MapboxDraw.DrawModes = {
  ...constants.modes,
  PASSING_DRAW_MODE: 'passing_draw_polygon',
  PASSING_DRAW_LINE_STRING_MODE: 'passing_draw_line_string',
  CUT_POLYGON_MODE: 'cut_polygon',
  SPLIT_POLYGON_MODE: 'split_polygon',
};

export const highlightPropertyName = `highlight`;
