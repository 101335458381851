import { Text, deepMerge } from '@mantine/core';
import { modals } from '@mantine/modals';
import { LinkProps } from '@tanstack/react-router';
import { OnChangeFn, RowSelectionState } from '@tanstack/table-core';
import {
  MRT_Column,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableOptions,
} from 'mantine-react-table';
import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useFieldsTable } from './use-fields-table';

import { useFiltersTableOptions } from '@data-access';
import { Field, useDeleteField, useUpdateFields } from '@fields/data-access';
import { PfTable, useRowSelection } from '@ui';

/* eslint-disable-next-line */
export interface FieldsTableProps {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: OnChangeFn<RowSelectionState>;
}

export function FieldsTable({
  rowSelection,
  setRowSelection,
}: FieldsTableProps) {
  const { t } = useTranslation();
  const [currentEdits, setCurrentEdits] = useState<Record<string, string>>({});
  const filtersTableOptions = useFiltersTableOptions<Field>('plots');

  const { fields, data, columns } = useFieldsTable();
  const updateManyFields = useUpdateFields();
  const deleteField = useDeleteField();

  const rowSelectionProps = useRowSelection<Field>({
    data,
    rowSelection,
    setRowSelection,
  });

  const handleEditInputChange = (
    row: MRT_Row<Field>,
    column: MRT_Column<Field>,
    value: FormEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement> | string,
  ) => {
    setCurrentEdits((prev) => ({
      ...prev,
      [column.id]: value as string,
    }));
  };

  const handleMultiEditSave = () => {
    const ids = Object.keys(rowSelection);
    updateManyFields.mutate({
      ids: ids.map((id) => parseInt(id)),
      ...currentEdits,
    });
  };

  const tableOptions: MRT_TableOptions<Field> = deepMerge<
    MRT_TableOptions<Field>
  >(
    {
      data,
      columns,
      rowCount: fields.data?.count || 0,

      initialState: {
        columnVisibility: {
          'mrt-row-pin': false,
        },
        showColumnFilters: true,
      },
      state: {
        isLoading: fields.isLoading,
        rowSelection,
        showProgressBars:
          updateManyFields.isPending ||
          fields.isRefetching ||
          deleteField.isPending,
      },

      selectAllMode: 'page',
      onRowSelectionChange: (c) => {
        setRowSelection(c);
      },

      enableEditing: true,
      editDisplayMode: 'table',

      // mantineSelectAllCheckboxProps: ({ table }) => ({
      //   checked: rowSelection.all || table.getIsAllRowsSelected(),
      //   onChange: () => {
      //     setRowSelection((p) => {
      //       if (p.all) {
      //         return {};
      //       } else {
      //         const idsMap = data.reduce(
      //           (acc, row) => {
      //             acc[row.id] = true;
      //             return acc;
      //           },
      //           {} as Record<string, boolean>,
      //         );
      //         return { all: true, ...idsMap };
      //       }
      //     });
      //   },
      // }),
      // mantineSelectCheckboxProps: ({ row, table }) => {
      //   return {
      //     checked: row.getIsSelected(),
      //     onChange: (e) => {
      //       setRowSelection((p) => {
      //         if (p.all) {
      //           delete p.all;
      //         }

      //         if (p[row.id]) {
      //           const { [row.id]: _, ...rest } = p;
      //           return rest;
      //         } else {
      //           return { ...p, [row.id]: true };
      //         }
      //       });
      //     },
      //   };
      // },

      ...rowSelectionProps,
      mantineEditSelectProps: ({ row, column }) => ({
        onChange: (value) => {
          handleEditInputChange(row, column, value!);
        },
      }),
      mantinePaperProps: {
        h: '100%',
      },
    },
    filtersTableOptions,
  );

  const handleDelete = useCallback(
    (row: MRT_Row<Field>) => {
      modals.openConfirmModal({
        title: t('plots.delete.title'),

        children: (
          <Text>
            {t('plots.delete.content1')}{' '}
            <Text fw={700} size="lg" span>
              {row.original.name}
            </Text>{' '}
            ? {t('plots.delete.content2')}
          </Text>
        ),

        labels: { confirm: t('common.delete'), cancel: t('common.cancel') },

        confirmProps: { color: 'red' },

        onConfirm: () => deleteField.mutate(row.original.id),
      });
    },
    [deleteField, t],
  );

  const editActionLink = useCallback((row: MRT_Row<Field>): LinkProps => {
    return {
      to: '/fields/$fieldId/edit',
      params: { fieldId: row.id },
    };
  }, []);

  useEffect(() => {
    // on success untoggle row selection
    if (updateManyFields.isSuccess) {
      setRowSelection({});
      setCurrentEdits({});
      updateManyFields.reset();
    }
  }, [setRowSelection, updateManyFields]);

  // useEffect(() => {
  //   // On data change toggle row selection for newly fetched data
  //   if (data && rowSelection.all) {
  //     const idsMap = data.reduce(
  //       (acc, row) => {
  //         acc[row.id] = true;
  //         return acc;
  //       },
  //       {} as Record<string, boolean>,
  //     );
  //     setRowSelection({ all: true, ...idsMap });
  //   }
  // }, [data, rowSelection.all, setRowSelection]);

  return (
    <PfTable<Field>
      editActionLink={editActionLink}
      enableMultiedit
      multieditRowColName="name"
      onDeleteActionClick={handleDelete}
      onMultiEditRowSave={handleMultiEditSave}
      options={tableOptions}
    />
  );
}

export default FieldsTable;
