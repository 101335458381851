import { Box } from '@mantine/core';
import { FallbackRender } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { CommonError } from '@ui';

export const CropStatisticsCardError: FallbackRender = ({
  error,
  resetError,
}) => {
  const { t } = useTranslation();

  return (
    <Box h={372}>
      <CommonError
        description={t('common.error.componentError')}
        error={error}
        // Component should be used in Sentry error boundary,
        // so we don't need to report errors to prevent duplicates
        report={false}
        reset={resetError}
      />
    </Box>
  );
};

export default CropStatisticsCardError;
