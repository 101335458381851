import { Center, Skeleton, Text } from '@mantine/core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { MapEvent } from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef } from 'react-map-gl';

import { advicesQueries, FORM_STATUS } from '@advices/data-access';
import { useQueriesWithGlobal } from '@data-access';
import {
  formatBiomassLegend,
  LegendControl,
  PfMap,
  ZoningLayer,
  ZoningLegend,
  zoomOnFeature,
} from '@map';

interface BiomassMapProps {
  mapId: number;
}

const Pending = () => <Skeleton h="100%" w="100%" />;

const Error = () => {
  const { t } = useTranslation();
  return (
    <Center bg="gray.1" h="100%" p="xl">
      <Text fw="bold" size="xl" ta="center">
        {t('advices.biomass_map_unavailable')}
      </Text>
    </Center>
  );
};

export const BiomassMap = ({ mapId }: BiomassMapProps) => {
  const mapRef = useRef<MapRef>(null);
  const queries = useQueriesWithGlobal(advicesQueries);

  const biomassMap = useSuspenseQuery({
    ...queries.biomassMap(mapId),
  });

  const handleLoad = (e: MapEvent) => {
    if (biomassMap.data.status === FORM_STATUS.FINISHED) {
      zoomOnFeature({
        map: e.target,
        geojson: biomassMap.data.map,
      });
    }
  };

  useEffect(() => {
    if (biomassMap.data.status === FORM_STATUS.FINISHED) {
      zoomOnFeature({
        map: mapRef?.current?.getMap(),
        geojson: biomassMap.data.map,
      });
    }
  }, [biomassMap.data]);

  if (biomassMap.data.status === FORM_STATUS.PENDING) {
    return <Error />;
  }

  return (
    <PfMap onLoad={handleLoad} ref={mapRef}>
      <ZoningLayer data={biomassMap.data.map} filled outline={false} />
      <LegendControl>
        <ZoningLegend 
          {...formatBiomassLegend(biomassMap.data.map)}
          mean={biomassMap.data.mean}
        />
      </LegendControl>
    </PfMap>
  );
};

BiomassMap.Pending = Pending;
BiomassMap.Error = Error;
