import { QueryClient } from '@tanstack/react-query';
import { createFileRoute, notFound } from '@tanstack/react-router';

import { getCampaign, is404Error } from '@data-access';
import { fieldsQueries } from '@fields/data-access';
import { FieldDetailsPage } from '@fields/feature';

export const fieldLoader = async ({
  queryClient,
  fieldId,
}: {
  queryClient: QueryClient;
  fieldId: string;
}) => {
  const campaign = getCampaign();

  if (!campaign) {
    throw notFound();
  }

  try {
    const plot = await queryClient.ensureQueryData(
      fieldsQueries(campaign).detail(Number(fieldId)),
    );
    if (!plot) {
      throw notFound();
    }
  } catch (error: any) {
    throw notFound();
  }
};

export const Route = createFileRoute('/_app/fields_/$fieldId')({
  loader: async ({ context, params }) => {
    return fieldLoader({
      queryClient: context.queryClient,
      fieldId: params.fieldId,
    });
  },
  component: FieldDetailsPage,
  pendingComponent: FieldDetailsPage.Pending,
  errorComponent: FieldDetailsPage.Error,
  notFoundComponent: FieldDetailsPage.NotFound,
});
