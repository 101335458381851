import { rem } from '@mantine/core';

interface CanolaIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export function CanolaIcon({ size, style, ...others }: CanolaIconProps) {
  return (
    <svg
      fill="none"
      style={{ width: rem(size), height: rem(size), ...style }}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <image
        height={512}
        href="https://img.icons8.com/ios/50/canola.png"
        width={512}
      />
    </svg>
  );
}
