import { Text, TextProps } from '@mantine/core';
import { createLink } from '@tanstack/react-router';
import { forwardRef } from 'react';

const LinkText = forwardRef<HTMLAnchorElement, TextProps>((props, ref) => (
  <Text component="a" ref={ref} {...props} />
));

export const TsrTextLink = createLink(LinkText);

export default TsrTextLink;
