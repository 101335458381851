import { NavLink, NavLinkProps } from '@mantine/core';
import { createLink } from '@tanstack/react-router';
import { forwardRef } from 'react';

import styles from './tsr-navlink.module.css';

const NavLinkSub = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLink component="a" ref={ref} {...props} />
));

export const TsrNavLink = createLink(NavLinkSub);

export default TsrNavLink;
