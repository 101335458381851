import saveAs from 'file-saver';

import { NewsResponse } from '../types';
import { AdminStatsResponse } from '../types/stats';

import {
  Filters,
  formatFilters,
  handleRequest,
  privateClient,
} from '@data-access';

export const dashboardApi = {
  news: async () => {
    return handleRequest<NewsResponse>(privateClient.get('news/').json());
  },
  getAdminStats: async (filters: Filters) => {
    return handleRequest<AdminStatsResponse>(
      privateClient
        .get('dashboard/admin/', {
          searchParams: {
            ...formatFilters(filters),
          },
        })
        .json(),
    );
  },
  exportStats: async () => {
    const res = await handleRequest(
      privateClient.get('dashboard/admin/export/'),
    );

    // Extract filename from header
    const filename = res.headers
      .get('content-disposition')
      ?.split(';')
      .find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim();

    const blob = await res.blob();

    saveAs(blob, filename);

    return res;
  },
};
