import { Loader, Stack, ThemeIcon } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';

import { FORM_STATUS, Yield } from '@advices/data-access';

interface YieldCellProps {
  mean_yield?: number | false;
  mean_dose?: number | false;
  status: FORM_STATUS;
}
export const YieldCell = ({
  mean_dose,
  mean_yield,
  status,
}: YieldCellProps) => {
  if (status === FORM_STATUS.INCOMPLETE || status === FORM_STATUS.WAITING) {
    return (
      <Stack gap={0}>
        {mean_yield !== false ? <div>-</div> : null}
        {mean_dose !== false ? <div>-</div> : null}
      </Stack>
    );
  }

  if (status === FORM_STATUS.FINISHED) {
    return (
      <Stack gap={0}>
        {mean_yield !== false ? (
          <div>{typeof mean_yield === 'number' ? mean_yield.toFixed(2) : mean_yield}</div>
        ) : null}
        {mean_dose !== false ? (
          <div>{typeof mean_dose === 'number' ? mean_dose.toFixed(2) : mean_dose}</div>
        ) : null}
      </Stack>
    );
  }
  

  if (status === FORM_STATUS.PENDING) {
    return (
      <Stack gap={0}>
        <Loader size={36} type="dots" />
      </Stack>
    );
  }

  if (status === FORM_STATUS.ERROR) {
    return (
      <Stack gap={0}>
        <ThemeIcon color="red" size="lg" variant="transparent">
          <IconAlertTriangle />
        </ThemeIcon>
      </Stack>
    );
  }

  return null;
};
