import dayjs from 'dayjs';
import { SearchParamsOption } from 'ky';
import { MRT_SortingState, MRT_ColumnFiltersState } from 'mantine-react-table';

export type Filters = {
  page: number;
  sorting: MRT_SortingState;
  filters: MRT_ColumnFiltersState;
};

export const formatFilters = (filters: Filters) => {
  const formattedFilters: SearchParamsOption = {};

  if (filters.sorting.length > 0) {
    // replace . in _ to match the api
    const id = filters.sorting[0].id.replace(/\./g, '__');
    // prepend '-' to the id if desc is true
    const sort = filters.sorting[0].desc ? `-${id}` : id;

    formattedFilters['sort'] = sort;
  }

  if (filters.filters.length > 0) {
    filters.filters.forEach((filter) => {
      let value = filter.value as any;

      // if value is a date
      if (value instanceof Date) {
        value = dayjs(value).toISOString();
      }

      // if value is an array
      if (Array.isArray(value)) {
        const [start, end] = value;
        if (!start && !end) return;

        // if value is an arry of dates
        if (value[0] instanceof Date || value[1] instanceof Date) {
          value = [
            start ? dayjs(start).toISOString() : '',
            end ? dayjs(end).toISOString() : '',
          ];
        }
      }
      // replace . in _ to match the api
      const id = filter.id.replace(/\./g, '__');
      formattedFilters[id] = value;
    });
  }
  formattedFilters.page = filters.page;

  return formattedFilters;
};
