import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

const mapSearchSchema = z.object({
  mapType: z.string().optional(),
  mapId: z.number().optional(),
  heatmapId: z.number().optional(),
  heatmapLayer: z.string().optional(),
});

export const Route = createFileRoute(
  '/_app/fields_/$fieldId_/maps/prescriptions_/create',
)({
  validateSearch: zodSearchValidator(mapSearchSchema),
});
