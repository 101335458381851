import * as Sentry from '@sentry/react';

import { router } from './router';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
    // eslint-disable-next-line import/namespace
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1,

  // Capture Replay for 0% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,

  // disable on test environment
  enabled:
    process.env.NODE_ENV !== 'test' && import.meta.env.MODE !== 'development',
});
