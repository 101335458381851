import { deepMerge, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { VisibilityState } from '@tanstack/table-core';
import {
  MRT_Row,
  MRT_TableOptions,
  MRT_RowSelectionState,
} from 'mantine-react-table';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ResultsDataColumnControl } from '../results-data-column-control/results-data-column-control';

import { resultsColState, dataColState, useTable } from './use-table';

import {
  CommonAdvice,
  useDeleteAdvice,
  useCommonAdvices,
} from '@advices/data-access';
import { useFiltersTableOptions } from '@data-access';
import { PfTable, useRowSelection } from '@ui';

export interface CommonAdviceTableProps {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: any;
  selectedType: string;
  onEdit: (id: number) => void;
  needValidation?: boolean;
}

export function CommonAdviceTable({
  rowSelection,
  setRowSelection,
  selectedType,
  onEdit,
  needValidation,
}: CommonAdviceTableProps) {
  const { t } = useTranslation();
  const filtersTableOptions = useFiltersTableOptions<CommonAdvice>('advices');

  const commonAdvices = useCommonAdvices({
    type: selectedType,
  });
  const deleteAdvice = useDeleteAdvice();
  const { columns } = useTable({ type: selectedType });

  const rowSelectionProps = useRowSelection<CommonAdvice>({
    data: commonAdvices.data?.results || [],
    rowSelection,
    setRowSelection,
  });

  const tableOptions: MRT_TableOptions<CommonAdvice> = deepMerge<
    MRT_TableOptions<CommonAdvice>
  >(
    {
      data: commonAdvices.data?.results || [],
      columns,
      rowCount: commonAdvices.data?.count || 0,

      enableFilters: !needValidation,
      enableColumnFilters: !needValidation,
      initialState: {
        columnVisibility: dataColState as VisibilityState,
        showColumnFilters: true,
      },
      state: {
        isLoading: commonAdvices.isLoading,
        rowSelection,
        showProgressBars: commonAdvices.isRefetching,
      },
      onRowSelectionChange: setRowSelection,

      mantinePaperProps: {
        h: '100%',
      },

      ...rowSelectionProps,
      renderTopToolbarCustomActions: ({ table }) => (
        <ResultsDataColumnControl
          dataColState={dataColState as VisibilityState}
          needValidation={needValidation}
          resultsColState={resultsColState as VisibilityState}
          table={table as any}
        />
      ),
    },
    filtersTableOptions,
  );

  const handleDelete = useCallback(
    (row: MRT_Row<CommonAdvice>) => {
      modals.openConfirmModal({
        title: t('advices.delete.title'),

        children: (
          <Text>
            {t('advices.delete.content1')}{' '}
            <Text fw={700} size="lg" span>
              {row.original.field.name}
            </Text>{' '}
            ? {t('plots.delete.content2')}
          </Text>
        ),

        labels: { confirm: t('common.delete'), cancel: t('common.cancel') },

        confirmProps: { color: 'red' },

        onConfirm: () =>
          deleteAdvice.mutate({
            id: row.original.id,
            crop: selectedType,
          }),
      });
    },
    [deleteAdvice, selectedType, t],
  );

  const handleEdit = (row: MRT_Row<CommonAdvice>) => {
    onEdit(row.original.id);
  };

  return (
    <PfTable
      onDeleteActionClick={handleDelete}
      onEditActionClick={handleEdit}
      options={tableOptions}
    />
  );
}

export default CommonAdviceTable;
