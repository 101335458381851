import { Group, SegmentedControl } from '@mantine/core';
import { VisibilityState } from '@tanstack/table-core';
import { MRT_RowData, MRT_TableInstance } from 'mantine-react-table';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './results-data-column-control.module.css';

import { CommonAdvice } from '@advices/data-access';

interface ResultsDataColumnControlProps {
  table: MRT_TableInstance<MRT_RowData>;
  resultsColState: VisibilityState;
  dataColState: VisibilityState;
  needValidation?: boolean;
}

export const ResultsDataColumnControl = ({
  table,
  resultsColState,
  dataColState,
  needValidation,
}: ResultsDataColumnControlProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<'results' | 'data'>('data');

  const handleChange = useCallback(
    (v: string) => {
      setValue(v as 'results' | 'data');
      if (v === 'results') {
        table.setColumnVisibility(resultsColState);
      } else if (v === 'data') {
        table.setColumnVisibility(dataColState);
      }
    },
    [dataColState, resultsColState, table],
  );

  useEffect(() => {
    if (needValidation) {
      handleChange('data');
    }
  }, [handleChange, needValidation]);

  return (
    <Group gap="md">
      <SegmentedControl
        color="pf-green"
        data={[
          { value: 'data', label: t('advices.list.data') },
          { value: 'results', label: t('advices.list.results') },
        ]}
        disabled={needValidation}
        onChange={handleChange}
        radius="xs"
        value={value}
      />
    </Group>
  );
};

export default ResultsDataColumnControl;
