import {
  useCombobox,
  Combobox,
  Group,
  Button,
  CloseButton,
  Text,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { AdviceStatusCell } from '../advice-status-cell/advice-status-cell';

import classes from './status-column-filter.module.css';

import { FORM_STATUS } from '@advices/data-access';

const validationOptions = [
  {
    value: FORM_STATUS.INCOMPLETE,
    component: <AdviceStatusCell status={FORM_STATUS.INCOMPLETE} />,
  },
  {
    value: FORM_STATUS.FINISHED,
    component: <AdviceStatusCell status={FORM_STATUS.FINISHED} />,
  },
  {
    value: FORM_STATUS.WAITING,
    component: <AdviceStatusCell status={FORM_STATUS.WAITING} />,
  },
  {
    value: FORM_STATUS.NO_MODULATION,
    component: <AdviceStatusCell status={FORM_STATUS.NO_MODULATION} />,
  },
];

interface StatusColumnFilterProps {
  onChange: (value: FORM_STATUS) => void;
  onClear: () => void;
  value: FORM_STATUS;
}

export function StatusColumnFilter(props: StatusColumnFilterProps) {
  const { t } = useTranslation();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = validationOptions.map((item) => (
    <Combobox.Option key={item.value} value={item.value}>
      <Group justify="center">{item.component}</Group>
    </Combobox.Option>
  ));

  return (
    <Combobox
      onOptionSubmit={(val) => {
        props.onChange(val as FORM_STATUS);
        combobox.closeDropdown();
      }}
      position="bottom-start"
      store={combobox}
    >
      <Combobox.Target>
        <Button
          className={classes.button}
          color="gray"
          fullWidth
          justify="space-between"
          onClick={() => combobox.toggleDropdown()}
          px="sm"
          rightSection={
            props.value ? (
              <CloseButton
                aria-label={t('common.clear')}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onClear();
                }}
                onMouseDown={(event) => event.preventDefault()}
                size="sm"
              />
            ) : (
              <Combobox.Chevron />
            )
          }
          tt="initial"
          variant="light"
        >
          {props.value ? (
            <AdviceStatusCell status={props.value} />
          ) : (
            <Text c="var(--mantine-color-placeholder)">
              {t('table.columns.status-filter-placeholder')}
            </Text>
          )}
        </Button>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}

export default StatusColumnFilter;
