import { Group, Text, ThemeIcon } from '@mantine/core';
import { IconCloudFilled } from '@tabler/icons-react';
import { memo } from 'react';

import { TsrButtonLink } from '@ui';

interface FieldNameCellProps {
  rowId: string;
  value: string;
  type: string;
}

export const FieldNameCell = memo(
  ({ rowId, value, type }: FieldNameCellProps) => {
    return (
      <Group gap={0}>
        <TsrButtonLink
          fullWidth
          justify="start"
          maw={250}
          ml="calc(var(--button-padding-x-compact-md) * -1)"
          params={{ resultId: rowId.toString(), type: type }}
          size="compact-md"
          td="underline"
          to="/precifert-n/$type/results/$resultId"
          tt="capitalize"
          variant="subtle"
        >
          <Text span truncate="end">
            {value}
          </Text>
        </TsrButtonLink>
      </Group>
    );
  },
);
