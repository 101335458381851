import { Button, Group, Select, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';

import classes from './contact-form.module.css';

import { Drawer } from '@ui';

interface ContactFormValues {
  title: string;
  description: string;
}

interface ContactFormProps {
  onClose: () => void;
  opened: boolean;
  loading?: boolean;
  success?: boolean;
  onSubmit: (values: ContactFormValues) => void;
}

export function ContactForm({
  onClose,
  opened,
  onSubmit,
  loading,
  success,
}: ContactFormProps) {
  const form = useForm<ContactFormValues>({
    initialValues: {
      title: '',
      description: '',
    },
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    if (success) {
      form.reset();
    }
  }, [success]);

  return (
    <Drawer
      onClose={handleClose}
      opened={opened}
      position="right"
      size="auto"
      title={'Nous contacter'}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Select
          data={['Demande de renseignements', 'Signaler un bug', 'Autre']}
          label="Objet de la demande"
          required
          {...form.getInputProps('title')}
        />

        <Textarea
          autosize
          label="Description"
          minRows={3}
          mt="sm"
          required
          resize="vertical"
          {...form.getInputProps('description')}
        />

        <Group mt="xl">
          <Button loading={loading} type="submit">
            Enregistrer
          </Button>
          <Button color="red" onClick={handleClose} type="button">
            Annuler
          </Button>
        </Group>
      </form>
    </Drawer>
  );
}

export default ContactForm;
