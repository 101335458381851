import { Portal, Switch } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import styles from './style-control.module.css';

type SwitchOnChange = React.ComponentProps<typeof Switch>['onChange'];

/* eslint-disable-next-line */
export interface StyleControlProps {}

export function StyleControl(props: StyleControlProps) {
  const { t } = useTranslation();
  const { current: map } = useMap();
  const [checked, setChecked] = useState(false);

  const handleChange: SwitchOnChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const m = map?.getMap();
    if (checked) {
      m?.setStyle('mapbox://styles/mapbox/outdoors-v12');
    } else {
      m?.setStyle('mapbox://styles/mapbox/satellite-streets-v12');
    }
  }, [checked]);

  return (
    <Portal
      target={
        document
          ? (document.getElementsByClassName(
              'mapboxgl-ctrl-top-left',
            )[0] as HTMLElement)
          : undefined
      }
    >
      <div className={`${styles['container']} mapboxgl-ctrl`}>
        <Switch
          checked={checked}
          label={t('map.style-control.label')}
          onChange={handleChange}
        />
      </div>
    </Portal>
  );
}

export default StyleControl;
