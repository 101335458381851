import { deepMerge } from '@mantine/core';
import { MRT_ColumnDef, MRT_TableOptions } from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAdminStats from '../../data-access/hooks/use-admin-stats/admin-stats';
import { AdminStatsFarm } from '../../data-access/types/stats';

import { useFiltersTableOptions } from '@data-access';
import { PfTable } from '@ui';

export function FarmsDataGrid() {
  const { t } = useTranslation();
  const filtersTableOptions =
    useFiltersTableOptions<AdminStatsFarm>('adminStats');
  const stats = useAdminStats();

  const tableColumns = useMemo<MRT_ColumnDef<AdminStatsFarm>[]>(
    () => [
      {
        accessorKey: 'last_name',
        accessorFn: (row) => `${row.last_name} ${row.first_name}`,
        header: 'Nom',
      },
      {
        accessorKey: 'name',
        header: 'Exploitation',
      },
      {
        accessorKey: 'organization',
        header: 'Organisation',
        filterVariant: 'select',

        mantineFilterSelectProps: {
          data: [],
        },
      },
      {
        accessorKey: 'total_area',
        Cell: ({ cell }) => `${cell.getValue<number>()?.toFixed(2)} ha`,
        header: 'Surface totale',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'wheat_forms',
        Cell: ({ cell }) => `${cell.getValue<number>()} %`,
        header: 'Formulaires blé',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'wheat_area',
        Cell: ({ cell }) => `${cell.getValue<number>()?.toFixed(2)} ha`,
        header: 'Surface blé',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'barley_forms',
        Cell: ({ cell }) => `${cell.getValue<number>()} %`,
        header: 'Formulaires orge',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'barley_area',
        Cell: ({ cell }) => `${cell.getValue<number>()?.toFixed(2)} ha`,
        header: 'Surface orge',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'rapeseed_forms',
        Cell: ({ cell }) => `${cell.getValue<number>()} %`,
        header: 'Formulaires colza',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'rapeseed_area',
        Cell: ({ cell }) => `${cell.getValue<number>()?.toFixed(2)} ha`,
        header: 'Surface colza',
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
    ],
    [t],
  );

  const tableOptions: MRT_TableOptions<AdminStatsFarm> = deepMerge<
    MRT_TableOptions<AdminStatsFarm>
  >(
    {
      columns: tableColumns,
      data: stats.data?.farms.results ?? [],
      rowCount: stats.data?.farms.count ?? 0,

      state: {
        isLoading: stats.isLoading,
      },
      initialState: {
        showColumnFilters: true,

        columnVisibility: {
          organization: false,
        },
      },

      enableRowActions: false,
    },
    filtersTableOptions,
  );

  return <PfTable options={tableOptions} />;
}

export default FarmsDataGrid;
