import { createFormContext } from '@mantine/form';
import { useTranslation } from 'react-i18next';

interface PkPlanFormValues {
  rows: {
    group: string;
    input: string;
    p205: string;
    k2o: string;
    average_utilization: string;
    input_price: number;
  }[];
}

interface TransformedValues {
  rows: {
    group: string;
    input: string;
    p205: string;
    k2o: string;
    average_utilization: string;
    input_price: number;
    total_utilization: number;
    total_price: number;
  }[];
}

export type TransformValues = (values: PkPlanFormValues) => TransformedValues;

const [PkPlanFormProvider, usePkPlanFormContext, useForm] = createFormContext<
  PkPlanFormValues,
  TransformValues
>();

export function usePkPlanForm() {
  const { t } = useTranslation();
  return useForm({
    initialValues: {
      rows: [
        {
          group: '',
          input: '',
          p205: '',
          k2o: '',
          average_utilization: '',
          input_price: 0,
        },
      ],
    },
    transformValues: (values) => {
      return {
        rows: values.rows.map((row) => ({
          ...row,
          total_utilization: 0,
          total_price: 0,
        })),
      };
    },
  });
}

export { PkPlanFormProvider, usePkPlanFormContext };
