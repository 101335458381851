import React from 'react';

import CommonAdviceForm from '../../../components/common-advice-form/common-advice-form';
import RapeseedAdviceForm from '../../../components/rapeseed-advice-form/rapeseed-advice-form';
import Wheat2AdviceForm from '../../../components/wheat2-advice-form/wheat2-advice-form';
import { CommonAdviceFormTransformedValues } from '../../../hooks/use-common-advice-form/common-advice-form';

import {
  CreateRapeseedAdviceDto,
  CreateWheat2AdviceDto,
} from '@advices/data-access';
import { CropEnum } from '@data-access';

interface AdviceFormProps {
  opened: boolean;
  onClose: () => void;
  onSubmitRapeseed: (values: CreateRapeseedAdviceDto) => void;
  onSubmitCommon: (values: CommonAdviceFormTransformedValues) => void;
  onSubmitWheat2: (values: CreateWheat2AdviceDto) => void;
  initialIds?: number[];
  mode: 'create' | 'edit';
  selectedType: string;
}

const AdviceForm: React.FC<AdviceFormProps> = ({
  opened,
  onClose,
  onSubmitRapeseed,
  onSubmitCommon,
  onSubmitWheat2,
  initialIds,
  mode,
  selectedType,
}) => {
  switch (selectedType) {
    case CropEnum.RAPESEED: {
      return (
        <RapeseedAdviceForm
          initialIds={initialIds}
          mode={mode}
          onClose={onClose}
          onSubmit={onSubmitRapeseed}
          opened={opened}
        />
      );
    }

    case CropEnum.WHEAT2: {
      return (
        <Wheat2AdviceForm
          initialIds={initialIds}
          mode={mode}
          onClose={onClose}
          onSubmit={onSubmitWheat2}
          opened={opened}
        />
      );
    }

    default: {
      return (
        <CommonAdviceForm
          initialIds={initialIds}
          mode={mode}
          onClose={onClose}
          onSubmit={onSubmitCommon}
          opened={opened}
          selectedType={selectedType}
        />
      );
    }
  }
};

export default AdviceForm;
