import {
  ButtonProps,
  Combobox,
  Input,
  InputBase,
  InputBaseProps,
  Loader,
  Popover,
  ScrollArea,
  Text,
  useCombobox,
} from '@mantine/core';

import classes from './extended-combobox.module.css';

interface ExtendedComboboxProps {
  onOptionSubmit: (val: string) => void;
  options: {
    value: string;
    label: string;
    description: string[];
  }[];
  selectedOption: string;
  isPending: boolean;
  inputProps?: InputBaseProps;
}

export function ExtendedCombobox({
  options,
  isPending,
  selectedOption,
  onOptionSubmit,
  inputProps = {},
}: ExtendedComboboxProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const selectedOptionData = options.find(
    (item) => item.value === selectedOption,
  );

  const o = options.map(({ value, label, description }) => (
    <Combobox.Option
      active={value === selectedOption}
      className={classes.option}
      key={value}
      value={value}
    >
      <Text>{label}</Text>
      {description.map((d) => (
        <Text key={d} size="xs">
          {d}
        </Text>
      ))}
    </Combobox.Option>
  ));

  return (
    <Popover
      disabled={selectedOptionData?.description.length === 0}
      opened={selectedOptionData && selectedOptionData.description.length > 0}
      position="right"
      withArrow
    >
      <Popover.Target>
        <div>
          <Combobox
            onOptionSubmit={(val) => {
              onOptionSubmit(val);
              combobox.closeDropdown();
            }}
            position="bottom-start"
            store={combobox}
            width={250}
            withArrow
            withinPortal={false}
          >
            <Combobox.Target>
              <InputBase
                component="button"
                onClick={() => combobox.toggleDropdown()}
                pointer
                rightSection={
                  isPending ? <Loader size="xs" /> : <Combobox.Chevron />
                }
                rightSectionPointerEvents="none"
                type="button"
                {...inputProps}
              >
                {selectedOptionData?.label || (
                  <Input.Placeholder>Pick value</Input.Placeholder>
                )}
              </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Options>
                <ScrollArea.Autosize mah={300} type="scroll">
                  {o}
                </ScrollArea.Autosize>
              </Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        {selectedOptionData?.description.map((d) => (
          <Text key={d} lh={0.9}>
            {d}
          </Text>
        ))}
      </Popover.Dropdown>
    </Popover>
  );
}

export default ExtendedCombobox;
