import { FullMainHeightContent } from './full-main-height-content';
import { FullMainHeightFlexContent } from './full-main-height-flex-content';
import { FullMainHeightHeader } from './full-main-height-header';
import { FullMainHeightRoot } from './full-main-height-root';
import { FullMainHeightSidebar } from './full-main-height-sidebar';

interface FullMainHeightProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

export function FullMainHeight({ children, header }: FullMainHeightProps) {
  const hasHeader = !!header;
  return (
    <FullMainHeightRoot>
      {hasHeader ? <FullMainHeightHeader>{header}</FullMainHeightHeader> : null}

      <FullMainHeightContent>{children}</FullMainHeightContent>
    </FullMainHeightRoot>
  );
}

FullMainHeight.Root = FullMainHeightRoot;
FullMainHeight.Header = FullMainHeightHeader;
FullMainHeight.Sidebar = FullMainHeightSidebar;
FullMainHeight.Content = FullMainHeightContent;
FullMainHeight.FlexContent = FullMainHeightFlexContent;

export default FullMainHeight;
