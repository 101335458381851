import { createFormContext } from '@mantine/form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { CreateWheat2AdviceDto } from '@advices/data-access';

export interface Wheat2AdviceFormValues {
  field: number[];
  product?: string | null;
  nitrogen_to_apply?: number;
  repartition_method?: string | null;
  date?: Date;

  is_hidden?: boolean;
  disable_mail?: boolean;
  restart?: boolean;
}

type TransformValues = (
  values: Wheat2AdviceFormValues,
) => CreateWheat2AdviceDto;

const [Wheat2AdviceFormProvider, useWheat2AdviceFormContext, useForm] =
  createFormContext<Wheat2AdviceFormValues, TransformValues>();

export function useWheat2AdviceForm() {
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      field: [],
    },
    validate: {
      field: (value) => {
        if (value.length === 0) {
          return t('form.required');
        }
      },
    },
    transformValues: (values) => {
      const { field, product, date, ...rest } = values;

      const transformedValues: CreateWheat2AdviceDto = {
        field: field.length === 1 ? field[0] : field,
        ...rest,
      };
      if (date) {
        transformedValues.date = dayjs(date).format('YYYY-MM-DD');
      }

      if (product) {
        transformedValues.product = Number(product);
      }

      return transformedValues;
    },
  });

  return form;
}

export { Wheat2AdviceFormProvider, useWheat2AdviceFormContext };
