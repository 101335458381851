import { Paper, Group, PasswordInput, Title, Button } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { IconKey } from '@tabler/icons-react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './reset-password-form.module.css';

import { LangSelect } from '@ui';

interface ResetPasswordFormProps {
  onSubmit: (password: string) => void;
  isPending?: boolean;
}

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },

    validate: {
      password: isNotEmpty(t('forms.required')),
      confirmPassword: (value, values) =>
        value !== values.password ? t('forms.passwordsDoNotMatch') : null,
    },
  });

  const passwordFormInputs = [
    {
      name: 'password',
      label: t('auth.newPassword'),
    },
    {
      name: 'confirmPassword',
      label: t('auth.confirmNewPassword'),
    },
  ];

  return (
    <Paper
      className={clsx(styles.container, 'border-light')}
      p="md"
      shadow="sm"
      withBorder
    >
      <Group justify="end">
        <LangSelect />
      </Group>
      <form
        onSubmit={form.onSubmit((values) => props.onSubmit(values.password))}
      >
        <Title order={2}>{t('auth.resetPassword')}</Title>
        {passwordFormInputs.map((input) => (
          <PasswordInput
            className={input.name}
            height="small"
            key={input.name}
            label={input.label}
            leftSection={<IconKey />}
            mt="md"
            placeholder="********"
            {...form.getInputProps(input.name)}
            error={form.errors[input.name] as string}
          />
        ))}
        <Button
          disabled={props.isPending}
          fullWidth
          mt="lg"
          size="large"
          type="submit"
        >
          {t('common.continue')}
        </Button>
      </form>
    </Paper>
  );
}

export default ResetPasswordForm;
