// firstCol is a key of the row object it can be a flat key
// or a nested key with dot notation
// example: 'name', 'address.street'
const createObjectFromFlatKey = (key: string, value: any): any => {
  const [first, ...rest] = key.split('.');
  if (rest.length === 0) {
    return { [key]: value };
  } else {
    return { [first]: createObjectFromFlatKey(rest.join('.'), value) };
  }
};

export const MULTI_EDIT_ROW_ID = 'multi-edit';

export const isMultieditRow = (rowId: string) => rowId === MULTI_EDIT_ROW_ID;

export const multiEditRow = (firstCol: string, label: string) =>
  ({
    id: MULTI_EDIT_ROW_ID,
    ...createObjectFromFlatKey(firstCol, label),
  } as any);
