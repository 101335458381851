import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { useMutation } from '@tanstack/react-query';
import { RouteComponent } from '@tanstack/react-router';
import { MRT_RowSelectionState } from 'mantine-react-table';
import { useState } from 'react';

import ExportPrescriptionModal from '../../components/export-prescription-modal/export-prescription-modal';
import PrescriptionsListHeader from '../../components/prescriptions/prescriptions-list-header/prescriptions-list-header';
import PrescriptionsTable from '../../components/prescriptions/prescriptions-table/prescriptions-table';

import { useColFilters } from '@data-access';
import { fieldsApi } from '@fields/data-access';
import { Drawer, FullMainHeight } from '@ui';

export const PrescriptionsListPage: RouteComponent = () => {
  const [exportOpened, exportActions] = useDisclosure();
  const [filters, setFilters] = useColFilters('prescriptions');

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const downloadSynthesis = useMutation({
    mutationFn: fieldsApi.prescriptions.getSynthesis,
  });

  const deleteMany = useMutation({
    mutationFn: fieldsApi.prescriptions.deleteMany,
    onSuccess: () => {
      setRowSelection({});
    },
  });

  const handleDownloadSynthesis = () => {
    if (rowSelection.all) {
      downloadSynthesis.mutate({
        ids: [],
        all: true,
        selectedFilters: filters,
      });
    } else {
      const ids = Object.keys(rowSelection).map((id) => Number(id));
      downloadSynthesis.mutate({
        ids,
      });
    }
  };

  const handleDelete = () => {
    modals.openConfirmModal({
      title: 'Suppression des cartes',
      children: 'Êtes-vous sûr de vouloir supprimer ces cartes ?',
      labels: {
        confirm: 'Supprimer',
        cancel: 'Annuler',
      },
      confirmProps: {
        color: 'red',
      },
      onConfirm: () => {
        if (rowSelection.all) {
          deleteMany.mutate({
            ids: [],
            all: true,
            selectedFilters: filters,
          });
        } else {
          const ids = Object.keys(rowSelection).map((id) => Number(id));
          deleteMany.mutate({
            ids,
          });
        }
      },
    });
  };

  return (
    <FullMainHeight.Root>
      <FullMainHeight.Header>
        <PrescriptionsListHeader
          isLoading={downloadSynthesis.isPending || deleteMany.isPending}
          onDelete={handleDelete}
          onDownloadSynthesis={handleDownloadSynthesis}
          onExport={() => exportActions.open()}
          rowSelection={rowSelection}
        />
      </FullMainHeight.Header>
      <FullMainHeight.Content>
        <PrescriptionsTable
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
        {Object.keys(rowSelection) ? (
          <Drawer
            onClose={exportActions.close}
            opened={exportOpened}
            position="right"
            title="Exportation"
          >
            <ExportPrescriptionModal
              onClose={exportActions.close}
              selection={rowSelection}
            />
          </Drawer>
        ) : null}
      </FullMainHeight.Content>
    </FullMainHeight.Root>
  );
};

export default PrescriptionsListPage;
