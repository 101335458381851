import { queryOptions } from '@tanstack/react-query';

import { dashboardApi } from '../api/dashboard.api';

import { Filters, Globals } from '@data-access';

export const dashboardQueries = (global: Globals) => ({
  all: () => [global, 'dashboard'],
  news: () =>
    queryOptions({
      queryKey: ['news'],
      queryFn: () => dashboardApi.news(),
    }),
  adminStats: (filters: Filters) =>
    queryOptions({
      queryKey: [...dashboardQueries(global).all(), 'admin', filters],
      queryFn: () => dashboardApi.getAdminStats(filters),
    }),
});
