import { createFileRoute } from '@tanstack/react-router';
import * as React from 'react';

import { RapeseedAdviceAdminPage } from '@advices/feature';

export const Route = createFileRoute(
  '/_app/precifert-n_/rapeseed/results/$resultId_/admin',
)({
  component: RapeseedAdviceAdminPage,
});
