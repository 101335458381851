import { Fieldset, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useRapeseedAdviceFormContext } from '../../../hooks/use-rapeseed-advice-form/rapeseed-advice-form';
import InfoLabel from '../../info-label/info-label';

import { useValues } from '@advices/data-access';
import { CropEnum } from '@data-access';

export const BiomassFieldset = () => {
  const { t } = useTranslation();
  const form = useRapeseedAdviceFormContext();
  const selectedFields = form.getValues().field;

  const values = useValues({
    type: CropEnum.RAPESEED,
    fieldId: selectedFields.length === 1 ? selectedFields[0] : null,
  });

  return (
    <Fieldset
      legend={t('advices.properties.biomass_legend')}
      mt="md"
      variant="filled"
    >
      <Select
        {...form.getInputProps('biomass_start')}
        data={values.data?.biomass_start}
        label={
          <InfoLabel
            info={t('advices.properties.biomass_start_info')}
            label={t('advices.properties.biomass_start')}
          />
        }
      />

      <Select
        {...form.getInputProps('biomass_end')}
        data={values.data?.biomass_end}
        label={
          <InfoLabel
            info={t('advices.properties.biomass_end_info')}
            label={t('advices.properties.biomass_end')}
          />
        }
        mt="sm"
      />
    </Fieldset>
  );
};
