import { queryOptions } from '@tanstack/react-query';

import { prescriptionsApi } from '../apis/prescriptions';
import { GetPrescriptionCreateValuesDto } from '../types/prescriptions';

import { Filters, Globals } from '@data-access';

export const prescriptionsQueries = (globals: Globals) => ({
  all: () => [globals, 'prescriptions'],
  lists: () => [...prescriptionsQueries(globals).all(), 'list'],
  list: (filters: Filters) =>
    queryOptions({
      queryKey: [...prescriptionsQueries(globals).lists(), filters],
      queryFn: () => prescriptionsApi.getAll(filters),
    }),

  summary: (fieldId: number) =>
    queryOptions({
      queryKey: [
        ...prescriptionsQueries(globals).all(),
        'summary',
        { fieldId },
      ],
      queryFn: () => prescriptionsApi.getFieldSummary(fieldId),
    }),

  details: () => [...prescriptionsQueries(globals).all(), 'details'],
  detail: (mapId: number) =>
    queryOptions({
      queryKey: [...prescriptionsQueries(globals).details(), mapId],
      queryFn: () => prescriptionsApi.getOne(mapId),
    }),

  values: () => [...prescriptionsQueries(globals).all(), 'values'],
  listValues: () =>
    queryOptions({
      queryKey: [...prescriptionsQueries(globals).values(), 'list'],
      queryFn: () => prescriptionsApi.getListValues(),
    }),
  createValues: (args: GetPrescriptionCreateValuesDto) =>
    queryOptions({
      queryKey: [...prescriptionsQueries(globals).values(), 'create', args],
      queryFn: () => prescriptionsApi.getCreateValues(args),
    }),
  exportValues: (maps: number[]) =>
    queryOptions({
      queryKey: [...prescriptionsQueries(globals).values(), 'export', maps],
      queryFn: () => prescriptionsApi.getExportValues(maps),
    }),
});
