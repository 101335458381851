import { Indicator, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconCloud, IconEyeOff, IconAlertTriangle } from '@tabler/icons-react';
import { DeepKeys } from '@tanstack/table-core';
import dayjs from 'dayjs';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdviceStatusCell } from '../advice-status-cell/advice-status-cell';
import { StatusColumnFilter } from '../status-column-filter/status-column-filter';

import { FieldNameCell } from './field-name-cell';
import { YieldCell } from './yield-cell';

import {
  CommonAdvice,
  FORM_STATUS,
  useValues,
  Yield,
} from '@advices/data-access';
import { useAuth } from '@auth/data-access';
import { ROLES, fromBacki18n } from '@data-access';

// eslint-disable-next-line @typescript-eslint/ban-types
type accessorKey = DeepKeys<CommonAdvice> | (string & {});
type ColVisibilityState = Partial<Record<accessorKey, boolean>>;

export const initialColState: ColVisibilityState = {
  'field.thumbnail': true,
  'field.name': true,
  'field.farm.name': true,
  olympic_yield: true,
  X_dose: true,
  status: true,

  inexistant: false,
  april1: false,
  april15: false,
  may1: false,
  may15: false,
  variety:false,
  'variety.name': false,
  seed_date: false,
  yield_potential: false,
  nitrogen_already_applied: false,
  product: false,
};

export const resultsColState: ColVisibilityState = {
  ...initialColState,
  olympic_yield: true,
  X_dose: true,
  variety:false,
  inexistant: true,
  april1: true,
  april15: true,
  may1: true,
  may15: true,
};

export const dataColState: ColVisibilityState = {
  ...initialColState,
  'variety.name': true,
  seed_date: true,
  yield_potential: true,
  olympic_yield: true,
  X_dose: true,
  nitrogen_already_applied: true,
  product: true,
};

interface UseTableParams {
  type: string;
}

export const useTable = ({ type }: UseTableParams) => {
  const { t, i18n } = useTranslation();
  const values = useValues({ type });
  const { user } = useAuth();

  const hasAccess = user?.user_access === ROLES.ADMIN;

  const columns = useMemo<MRT_ColumnDef<CommonAdvice>[]>(
    () => [
      {
        accessorKey: 'field.thumbnail',
        enableColumnFilter: false,
        header: '',
        enableSorting: false,
        enableColumnActions: false,
        size: 32,
        Cell: ({ cell, row }) => {
          const thumb = cell.getValue<string>();
          if (thumb) {
            return <img alt="thumbnail" src={thumb} width="32" />;
          }
        },
      },
      {
        accessorKey: 'field.name',
        header: t('table.columns.field-name'),
        Cell: ({ cell, row }) => {
          const value = cell.getValue<string>();

          return <FieldNameCell rowId={row.id} type={type} value={value} />;
        },
      },
      {
        accessorKey: 'field.farm.name',
        header: t('table.columns.farm-name'),
        minSize: 250,
      },
      {
        accessorKey: 'variety',
        accessorFn: (row) => row.variety?.name,
        header: t('table.columns.variety'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: values.data?.variety,
        },
      },
      {
        accessorKey: 'seed_date',
        header: t('table.columns.seed_date'),
        Cell: ({ cell }) => {
          const date = cell.getValue<string>();
          return <Text>{date ? dayjs(date).format('DD/MM/YYYY') : ''}</Text>;
        },
        filterVariant: 'date-range',
      },
      {
        accessorKey: 'yield_potential',
        Cell: ({ cell }) => t(`common.properties.yield_potential.${cell.getValue()}`),
        header: t('table.columns.yield_potential'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: values.data?.yield_potential?.map((item) => ({
            value: item,
            label: t(`common.properties.yield_potential.${item}`),
          })),
        },
      },
      {
        accessorKey: 'olympic_yield',
        Cell: ({ cell }) => `${cell.getValue() || '-'} q/ha`,
        header: t('table.columns.olympic_yield'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'X_dose',
        Cell: ({ cell }) => `${cell.getValue() || '-'} u/ha`,
        header: t('table.columns.X_dose'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'status',
        header: t('table.columns.status'),
        maxSize: 100,
        Cell: ({ cell }) => {
          const status = cell.getValue<FORM_STATUS>();

          return <AdviceStatusCell context='common' status={status} />;
        },
        Filter: ({ column, table }) => {
          return (
            <StatusColumnFilter
              onChange={(value) => column.setFilterValue(value)}
              onClear={() => column.setFilterValue(null)}
              value={column.getFilterValue() as FORM_STATUS}
            />
          );
        },
      },
      {
        accessorKey: 'nitrogen_already_applied',
        Cell: ({ cell }) => `${cell.getValue() || '-'} u/ha`,
        header: t('table.columns.nitrogen_already_applied'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'product',
        accessorFn: (row) => fromBacki18n(row.product, i18n.language),
        header: t('table.columns.product'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: values.data?.product?.map(({ value, label }) => ({
            value,
            label,
          })),
        },
      },
      {
        accessorKey: 'inexistant',
        header: '',
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        minSize: 200,
        Cell: ({ cell }) => (
          <Stack gap={0}>
            <div>{t('precifert.yieldPotential')}</div>
            <div>{t('precifert.suggestedNitrogenQuantity')}</div>
          </Stack>
        ),
      },
      {
        accessorKey: 'april1',
        size: 50,
        header: dayjs('2022-04-01').format('Do MMMM'),
        accessorFn: (row) => row.yields?.[0],
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          const yieldValue = cell.getValue<Yield>();
          return (
            <Indicator
              color="transparent"
              disabled={!yieldValue?.cloud && !(yieldValue?.is_hidden && hasAccess) && !(yieldValue?.is_blocked && hasAccess)}
              label={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                  {yieldValue?.cloud && (
                    <ThemeIcon color="orange" size="xs" style={{ marginBottom: 'auto' }} variant="transparent" >
                      <IconCloud />
                    </ThemeIcon>
                  )}
                  {yieldValue?.is_hidden && hasAccess && (
                    <ThemeIcon color="grey" size="xs" style={{ marginTop: 'auto' }} variant="transparent">
                      <IconEyeOff /> 
                    </ThemeIcon>
                  )}
                  {yieldValue?.is_blocked && hasAccess && (
                    <ThemeIcon color="red" size="xs" style={{ marginTop: '3px' }} variant="transparent">
                      <IconAlertTriangle /> 
                    </ThemeIcon>
                  )}
                </div>
              }
            > 
            <YieldCell {...yieldValue} />
          </Indicator>
        );
        },
      },
      {
        accessorKey: 'april15',
        size: 50,
        header: dayjs('2022-04-15').format('Do MMMM'),
        accessorFn: (row) => row.yields?.[1],
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          const yieldValue = cell.getValue<Yield>();
          return (
            <Indicator
              color="transparent"
              disabled={!yieldValue?.cloud && !(yieldValue?.is_hidden && hasAccess) && !(yieldValue?.is_blocked && hasAccess)}
              label={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                  {yieldValue?.cloud && (
                    <ThemeIcon color="orange" size="xs" style={{ marginBottom: 'auto' }} variant="transparent" >
                      <IconCloud />
                    </ThemeIcon>
                  )}
                  {yieldValue?.is_hidden && hasAccess && (
                    <ThemeIcon color="grey" size="xs" style={{ marginTop: 'auto' }} variant="transparent">
                      <IconEyeOff /> 
                    </ThemeIcon>
                  )}
                  {yieldValue?.is_blocked && hasAccess && (
                    <ThemeIcon color="red" size="xs" style={{ marginTop: '3px' }} variant="transparent">
                      <IconAlertTriangle /> 
                    </ThemeIcon>
                  )}
                </div>
              }
            > 
            <YieldCell {...yieldValue} />
          </Indicator>
        );
        },
      },
      {
        accessorKey: 'may1',
        size: 50,
        header: dayjs('2022-05-01').format('Do MMMM'),
        accessorFn: (row) => row.yields?.[2],
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          const yieldValue = cell.getValue<Yield>();
          return (
            <Indicator
              color="transparent"
              disabled={!yieldValue?.cloud && !(yieldValue?.is_hidden && hasAccess) && !(yieldValue?.is_blocked && hasAccess)}
              label={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                  {yieldValue?.cloud && (
                    <ThemeIcon color="orange" size="xs" style={{ marginBottom: 'auto' }} variant="transparent" >
                      <IconCloud />
                    </ThemeIcon>
                  )}
                  {yieldValue?.is_hidden && hasAccess && (
                    <ThemeIcon color="grey" size="xs" style={{ marginTop: 'auto' }} variant="transparent">
                      <IconEyeOff /> 
                    </ThemeIcon>
                  )}
                  {yieldValue?.is_blocked && hasAccess && (
                    <ThemeIcon color="red" size="xs" style={{ marginTop: '3px' }} variant="transparent">
                      <IconAlertTriangle /> 
                    </ThemeIcon>
                  )}
                </div>
              }
            > 
            <YieldCell {...yieldValue} />
          </Indicator>
        );
        },
      },
      {
        accessorKey: 'may15',
        size: 50,
        header: dayjs('2022-05-15').format('Do MMMM'),
        accessorFn: (row) => row.yields?.[3],
        enableColumnFilter: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell }) => {
          const yieldValue = cell.getValue<Yield>();
          return (
            <Indicator
              color="transparent"
              disabled={!yieldValue?.cloud && !(yieldValue?.is_hidden && hasAccess) && !(yieldValue?.is_blocked && hasAccess)}
              label={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
                  {yieldValue?.cloud && (
                    <ThemeIcon color="orange" size="xs" style={{ marginBottom: 'auto' }} variant="transparent" >
                      <IconCloud />
                    </ThemeIcon>
                  )}
                  {yieldValue?.is_hidden && hasAccess && (
                    <ThemeIcon color="grey" size="xs" style={{ marginTop: 'auto' }} variant="transparent">
                      <IconEyeOff /> 
                    </ThemeIcon>
                  )}
                  {yieldValue?.is_blocked && hasAccess && (
                    <ThemeIcon color="red" size="xs" style={{ marginTop: '3px' }} variant="transparent">
                      <IconAlertTriangle /> 
                    </ThemeIcon>
                  )}
                </div>
              }
            > 
            <YieldCell {...yieldValue} />
          </Indicator>
        );
        },
      },
    ],
    [t, values.data, i18n.language, type, hasAccess],
  );

  return { columns };
};
