import {
  Checkbox,
  CheckboxProps,
  Group,
  NumberInput,
  NumberInputProps,
  Switch,
} from '@mantine/core';
import {
  CalendarLevel,
  DateInput,
  DateInputProps,
  DateValue,
} from '@mantine/dates';
import { useDebouncedValue } from '@mantine/hooks';
import { IconCalendarDue } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { MRT_Cell, MRT_TableInstance } from 'mantine-react-table';
import { FocusEvent, KeyboardEvent, useState } from 'react';

type InputTypes = 'date' | 'number' | 'checkbox' | 'switch' | 'date-range';

/* eslint-disable-next-line */
export interface MrtEditCellCustomInputProps<
  TData extends Record<string, any>
> {
  cell: MRT_Cell<TData>;
  table: MRT_TableInstance<TData>;
  type: InputTypes;
  rightSection?: React.ReactNode;
  maxLevel?: CalendarLevel;
  inputValueFormat?: string;
  returnValueFormat?: string;
  monthLabelFormat?: string;
  mantineEditCheckboxProps?: CheckboxProps;
  mantineEditNumberInputProps?: NumberInputProps;
  mantineEditDateInputProps?: DateInputProps;
}

function parseFromValuesOrFunc<T, U>(
  fn: T | ((arg: U) => T) | undefined,
  arg: U
): T | undefined {
  return fn instanceof Function ? fn(arg) : fn;
}

function formatValue(type: InputTypes, value: any) {
  if (!value) {
    return null;
  }
  switch (type) {
    case 'date-range':
      return {
        start: dayjs(value.start, 'YYYY-MM-DD').toDate(),
        end: dayjs(value.end, 'YYYY-MM-DD').toDate(),
      };
    case 'date':
      return dayjs(value, 'YYYY-MM-DD').toDate();
    case 'number':
      return value;
    default:
      return value;
  }
}

export const MrtEditCellCustomInput = <TData extends Record<string, any>>({
  cell,
  table,
  type,
  rightSection,
  inputValueFormat,
  returnValueFormat,
  maxLevel,
  monthLabelFormat,
}: MrtEditCellCustomInputProps<TData>) => {
  const {
    getState,
    options: { mantineEditTextInputProps, mantineEditSelectProps },
    refs: { editInputRefs },
    setEditingCell,
    setEditingRow,
    setCreatingRow,
  } = table;
  const { column, row } = cell;
  const { columnDef } = column;
  const { creatingRow, editingRow } = getState();
  const isCreating = creatingRow?.id === row.id;
  const isEditing = editingRow?.id === row.id;
  const [value, setValue] = useState(() =>
    formatValue(type, cell.getValue<any>())
  );
  const [debouncedValue] = useDebouncedValue(value, 200);

  const arg = { cell, column, row, table };
  const textInputProps = {
    ...parseFromValuesOrFunc(mantineEditTextInputProps, arg),
    ...parseFromValuesOrFunc(columnDef.mantineEditTextInputProps, arg),
  };

  const selectProps = {
    ...parseFromValuesOrFunc(mantineEditSelectProps, arg),
    ...parseFromValuesOrFunc(columnDef.mantineEditSelectProps, arg),
  };

  const saveInputValueToRowCache = (newValue: string | null) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    row._valuesCache[column.id] = newValue;
    if (isCreating) {
      setCreatingRow(row);
    } else if (isEditing) {
      setEditingRow(row);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    textInputProps.onBlur?.(event);
    if (cell.getValue() !== value) {
      textInputProps.onChange?.(value);
    }
    saveInputValueToRowCache(
      type === 'date'
        ? dayjs(value).format(returnValueFormat || 'YYYY-MM-DD')
        : value
    );
    setEditingCell(null);
  };

  const handleEnterKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    textInputProps.onKeyDown?.(event);
    if (event.key === 'Enter') {
      editInputRefs.current[cell.id]?.blur();
    }
  };

  const handleDateChange = (date: DateValue) => {
    selectProps.onChange?.(
      dayjs(date).format(returnValueFormat || 'YYYY-MM-DD') as any
    );
    setValue(date);
  };

  const handleDateRangeChange = (date: DateValue, range: 'start' | 'end') => {
    if (!date) {
      setValue({ start: null, end: null });
      return;
    }
    const newValue = {
      start: value?.start,
      end: value?.end,
    };
    newValue[range] = date;

    const formattedValue = {
      start:
        newValue.start &&
        dayjs(newValue.start).format(returnValueFormat || 'YYYY-MM-DD'),
      end:
        newValue.end &&
        dayjs(newValue.end).format(returnValueFormat || 'YYYY-MM-DD'),
    };
    selectProps.onChange?.(formattedValue as any);

    setValue(newValue);
  };

  const handleNumberChange = (v: string | number) => {
    setValue(v);
  };

  const handleCheckboxChange = (v: boolean) => {
    selectProps.onChange?.(v as any);
    setValue(v);
  };

  switch (type) {
    case 'date':
      return (
        <DateInput
          maxLevel={maxLevel}
          monthLabelFormat={monthLabelFormat}
          onChange={handleDateChange}
          placeholder={cell.column.columnDef.header}
          popoverProps={{
            position: 'bottom',
          }}
          rightSection={<IconCalendarDue />}
          value={formatValue('date', cell.getValue()) || value}
          valueFormat={inputValueFormat || 'DD/MM/YYYY'}
        />
      );
    case 'date-range':
      return (
        <Group>
          <DateInput
            clearable
            maxLevel={maxLevel}
            monthLabelFormat={monthLabelFormat}
            onChange={(date) => handleDateRangeChange(date, 'start')}
            placeholder="Début"
            popoverProps={{
              position: 'bottom',
            }}
            rightSection={<IconCalendarDue />}
            value={formatValue('date-range', cell.getValue())?.start}
            valueFormat={inputValueFormat || 'DD/MM/YYYY'}
            w={125}
          />
          <DateInput
            clearable
            maxLevel={maxLevel}
            monthLabelFormat={monthLabelFormat}
            onChange={(date) => handleDateRangeChange(date, 'end')}
            placeholder="Fin"
            popoverProps={{
              position: 'bottom',
            }}
            rightSection={<IconCalendarDue />}
            value={formatValue('date-range', cell.getValue())?.end}
            valueFormat={inputValueFormat || 'DD/MM/YYYY'}
            w={125}
          />
        </Group>
      );
    case 'number':
      return (
        <NumberInput
          hideControls
          onBlur={handleBlur}
          onChange={handleNumberChange as any}
          onKeyDown={handleEnterKeyDown}
          placeholder={cell.column.columnDef.header}
          ref={(node) => {
            if (node) {
              editInputRefs.current[cell.id] = node as any;
              if (textInputProps.ref) {
                textInputProps.ref.current = node as any;
              }
            }
          }}
          rightSection={rightSection}
          rightSectionPointerEvents="none"
          value={cell.getValue() || value}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          onChange={(e) => handleCheckboxChange(e.target.checked)}
          value={cell.getValue() || value}
        />
      );
    case 'switch':
      return (
        <Switch
          checked={cell.getValue() || value}
          offLabel="Non"
          onChange={(e) => handleCheckboxChange(e.target.checked)}
          onLabel="Oui"
          size="lg"
        />
      );
    default:
      return <div>Type not implemented</div>;
  }
};

export default MrtEditCellCustomInput;
