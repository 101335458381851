import { createFileRoute } from '@tanstack/react-router';
import * as React from 'react';

import { RapeseedAdviceAdminPage } from '@advices/feature';

export const Route = createFileRoute(
  '/_app/fields_/$fieldId_/maps/biomass_/settings',
)({
  component: RapeseedAdviceAdminPage,
});
