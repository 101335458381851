import {
  ActionIcon,
  Box,
  Grid,
  Group,
  Image,
  Paper,
  rem,
  Stack,
} from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { ErrorRouteComponent } from '@tanstack/react-router';

import { FieldNavbar } from '../../components/field-navbar/field-navbar';

import classes from './page.module.css';

import { CommonError, FullMainHeight } from '@ui';

export const ErrorPage: ErrorRouteComponent = ({ error, reset }) => {
  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar>
          <Stack className={classes.navCol}>
            <Paper className={classes.navHeader}>
              <Group>
                <Box>
                  <Image
                    alt="plot thumbnail"
                    h={36}
                    src={'https://placehold.co/50x50'}
                  />
                </Box>
                <Stack flex="1" gap={0} h={rem(36)} justify="center"></Stack>
                <ActionIcon disabled variant="outline">
                  <IconDotsVertical />
                </ActionIcon>
              </Group>
            </Paper>
            <Paper className={classes.navBar} p={0}>
              <FieldNavbar disabled fieldId="" links={[]} />
            </Paper>
          </Stack>
        </FullMainHeight.Sidebar>

        <CommonError error={error} reset={reset} />
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
};

export default ErrorPage;
