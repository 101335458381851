import { Login } from '../types/auth.types';
import { Profile } from '../types/profile.types';

import { handleRequest, privateClient, publicClient } from '@data-access';

export interface Credentials {
  email: string;
  password: string;
}

interface lostPasswordDto {
  email: string;
  lang: string;
}

interface ResetPasswordDto {
  password: string;
  token: string;
}

export const authenticationAPI = {
  login: async (credentials: Credentials) => {
    return handleRequest<Login>(
      publicClient.post('token/', { json: credentials }).json(),
    );
  },
  getLoggedUser: async () => {
    return handleRequest<Profile>(privateClient.get(`me/`).json());
  },
  lostPassword: async (params: lostPasswordDto) => {
    return handleRequest(
      publicClient.post(`password_reset/`, { json: params }).json(),
    );
  },
  resetPassword: async (params: ResetPasswordDto) => {
    return handleRequest(
      publicClient.post(`password_reset/confirm/`, { json: params }).json(),
    );
  },
  updatePassword: async (params: {
    old_password: string;
    new_password: string;
  }) => {
    return handleRequest(
      privateClient.post(`change_password/`, { json: params }).json(),
    );
  },
  updateEmail: async (params: { old_email: string; new_email: string }) => {
    return handleRequest(
      privateClient.post(`change_email/`, { json: params }).json(),
    );
  },
};
