import { Box, Flex, Grid, Skeleton } from '@mantine/core';

import { StatisticsHeader } from '../../components/statistics-header/statistics-header';

import classes from './page.module.css';

export function PendingPage() {
  return (
    <>
      <Box mb="x2s">
        <StatisticsHeader />
      </Box>
      <Flex className={classes.container}>
        <Grid miw={1400} my="x2s">
          {['', '', ''].map((_, index) => (
            <Grid.Col span={index < 2 ? 4.25 : 'auto'}>
              <Skeleton h={372} />
            </Grid.Col>
          ))}
        </Grid>
      </Flex>
      <Box mt="x2s">
        <Skeleton h={400} />
      </Box>
    </>
  );
}

export default PendingPage;
