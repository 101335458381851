import { QueryClient } from '@tanstack/react-query';
import { createRouter } from '@tanstack/react-router';

import { routeTree } from './routeTree.gen';

import { IAuthContext } from '@auth/data-access';
import { DefaultPendingComponent, NotFound } from '@ui';

export const router = createRouter({
  routeTree,
  // defaultPreload: 'intent',
  context: {
    auth: undefined! as IAuthContext, // This will be set after we wrap the app in an AuthProvider
    queryClient: undefined! as QueryClient, // This will be set after we wrap the app in a QueryClientProvider
  },
  defaultNotFoundComponent: NotFound,
  defaultPreloadStaleTime: 0,
  defaultPendingComponent: DefaultPendingComponent,
});
