import { useMutation } from '@tanstack/react-query';

import { fieldMapsApi } from '../../apis/field-maps';

export function useUploadYieldMap() {
  return useMutation({
    mutationFn: fieldMapsApi.uploadYieldMap,
  });
}

export default useUploadYieldMap;
