import { createFileRoute } from '@tanstack/react-router';
import * as React from 'react';

import { CommonAdviceAdminPage } from '@advices/feature';

export const Route = createFileRoute(
  '/_app/precifert-n_/$type/results/$resultId_/settings/$yieldId',
)({
  component: CommonAdviceAdminPage,
});
