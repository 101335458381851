import { rem } from '@mantine/core';

import { skipImage } from '@assets';

interface SkipIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export function SkipIcon({ size, style, ...others }: SkipIconProps) {
  return (
    <svg
      fill="none"
      style={{ width: rem(size), height: rem(size), ...style }}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <image height={512} href={skipImage} width={512} />
    </svg>
  );
}
