import { Paper, Group, Title, Button } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconFileDownload, IconTrash } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { RowSelectionState } from '@tanstack/table-core';
import { MRT_RowSelectionState } from 'mantine-react-table';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useFiltersStore } from '@data-access';
import { fieldsApi } from '@fields/data-access';
import { ActionsMenu, ActionsMenuItem, TsrButtonLink } from '@ui';

interface FieldsListHeaderProps {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
}

export function FieldsListHeader({
  rowSelection,
  setRowSelection,
}: FieldsListHeaderProps) {
  const { t } = useTranslation();
  const filters = useFiltersStore.use['plots']().filters;

  const exportFields = useMutation({
    mutationFn: fieldsApi.export,
  });
  const deleteFields = useMutation({
    mutationFn: fieldsApi.deleteMany,
    onSuccess: () => {
      setRowSelection({});
    },
  });

  const handleDelete = () => {
    modals.openConfirmModal({
      title: t('common.delete'),
      children: t('fields.delete_confirmation'),
      labels: { confirm: t('common.delete'), cancel: t('common.cancel') },

      confirmProps: { color: 'red' },
      onConfirm: () => {
        if (rowSelection.all) {
          deleteFields.mutate({
            ids: [],
            all: true,
            selectedFilters: filters,
          });
        } else {
          const ids = Object.keys(rowSelection).map((id) => Number(id));
          deleteFields.mutate({
            ids,
          });
        }
      },
    });
  };

  const handleExport = () => {
    if (rowSelection.all) {
      fieldsApi.export({
        ids: [],
        all: true,
        selectedFilters: filters,
      });
    } else {
      const ids = Object.keys(rowSelection).map((id) => Number(id));
      fieldsApi.export({
        ids,
      });
    }
  };

  const actions: ActionsMenuItem[] = [
    {
      type: 'action',
      icon: IconFileDownload,
      label: t('common.export'),
      onClick: () => {
        handleExport();
      },
    },
    {
      type: 'action',
      icon: IconTrash,
      label: t('common.delete'),
      color: 'red',
      onClick: () => handleDelete(),
    },
  ];

  return (
    <Paper>
      <Group justify="space-between">
        <Title order={2}>Liste des parcelles</Title>
        <Group>
          <TsrButtonLink to="/fields/create">
            Ajouter une parcelle
          </TsrButtonLink>
          <ActionsMenu items={actions}>
            <ActionsMenu.Button
              disabled={Object.keys(rowSelection).length === 0}
              loading={exportFields.isPending || deleteFields.isPending}
            />
          </ActionsMenu>
        </Group>
      </Group>
    </Paper>
  );
}

export default FieldsListHeader;
