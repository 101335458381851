import { LoadingOverlay, Overlay, Stack } from '@mantine/core';

import classes from './full-main-height.module.css';

interface FullMainHeightRootProps {
  children: React.ReactNode;
  loading?: boolean;
}

export const FullMainHeightRoot = ({
  children,
  loading,
}: FullMainHeightRootProps) => {
  return (
    <div className={classes.root}>
      <LoadingOverlay visible={loading} />
      <Stack h="100%">{children}</Stack>
    </div>
  );
};
