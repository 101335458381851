import saveAs from 'file-saver';
import { SearchParamsOption } from 'ky';
import { LngLatBounds } from 'mapbox-gl';

import {
  CreateFieldDto,
  CutOneDto,
  GetMapDto,
  UpdateManyDto,
  UpdateOneDto,
  UpdatePkAdviceDto,
} from '../types/dto';
import {
  FieldFeature,
  FieldMapResponse,
  FieldsFeatureCollection,
  FieldsResponse,
  UploadeFileResponse,
} from '../types/fields';
import { HeatmapsInBoundsResponse } from '../types/heatmaps';
import {
  PkAdviceResponse,
  PkResultResponse,
  PkValuesResponse,
} from '../types/pk';
import { FieldsValuesResponse } from '../types/values';
import { MAP_TYPES } from '../utils/map-types.const';

import { prescriptionsApi } from './prescriptions';

import {
  Filters,
  formatFilters,
  fromBacki18n,
  handleRequest,
  MultisectActionDto,
  privateClient,
} from '@data-access';

export interface GetPkResultsArgs {
  fieldId: number;
  product_ferti: number;
  price?: number;
}

export const fieldsApi = {
  getMaxBounds: () => {
    return privateClient.get('plotmaxbounds/').json<{ bbox: LngLatBounds }>();
  },
  getAll: async (filters: Filters) => {
    const res = await handleRequest<FieldsResponse>(
      privateClient
        .get('plots/', {
          searchParams: {
            ...formatFilters(filters),
          },
        })
        .json(),
    );

    const options = res.results.features.map((plot) => ({
      value: plot.id!.toString(),
      label: `${plot.properties.name} (${plot.properties.farm.name})`,
    }));

    return { ...res, options };
  },
  getOne: async (id: number) => {
    const res = await handleRequest<FieldFeature>(
      privateClient.get(`plots/${id}/`).json(),
    );
    const maps: { type: MAP_TYPES; numbers: number | null }[] = [
      { type: MAP_TYPES.SOIL, numbers: res.properties.soilMaps_count },
      { type: MAP_TYPES.SATELLITE, numbers: null },
      {
        type: MAP_TYPES.YIELD,
        numbers: res.properties.yieldMaps_count,
      },
      {
        type: MAP_TYPES.PRESCRIPTIONS,
        numbers: res.properties.prescriptionMaps_count,
      },
      {
        type: MAP_TYPES.SAMPLES,
        numbers: res.properties.sampling_pointsMaps_count,
      },
      {
        type: MAP_TYPES.POTENTIAL,
        numbers: res.properties.potentialMaps_count,
      },
      {
        type: MAP_TYPES.BIOMASS,
        numbers: res.properties.biomassMaps_count,
      },
      {
        type: MAP_TYPES.OTHER,
        numbers: res.properties.otherMaps_count,
      },
    ];

    return {
      ...res,
      properties: {
        ...res.properties,
        maps,
      },
    };
  },
  getInBounds: (bounds: LngLatBounds, cluster: boolean, zoom: number) => {
    // format bounds as xmin,ymin,xmax,ymax string
    const formattedBounds = bounds.toArray().flat();

    return privateClient
      .get('plotmap/', {
        searchParams: {
          bbox: formattedBounds.join(','),
          cluster,
          zoom,
        },
      })
      .json<
        FieldsFeatureCollection & {
          datelist?: string[];
        }
      >();
  },
  getHeatmapsInBounds: (args: {
    bounds: LngLatBounds;
    layer: string;
    date: string;
  }) => {
    const formattedBounds = args.bounds.toArray().flat();

    return privateClient
      .get('heatmaps/', {
        searchParams: {
          bbox: formattedBounds.join(','),
          layer: args.layer,
          date: args.date,
        },
      })
      .json<HeatmapsInBoundsResponse>();
  },
  export: async (data: MultisectActionDto) => {
    const res = await handleRequest(
      privateClient.post(`plots/export/`, {
        json: data,
      }),
    );

    // Extract filename from header
    const filename = res.headers
      .get('content-disposition')
      ?.split(';')
      .find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim();

    const blob = await res.blob();

    saveAs(blob, filename);

    return res;
  },

  createOne: (data: CreateFieldDto) => {
    const { is_multiple } = data;
    if (is_multiple) {
      data.name = 'undefined';
    }
    return privateClient
      .post('plots/', { json: data })
      .json<FieldFeature | FieldFeature[]>();
  },
  updateOne: (updateDto: UpdateOneDto) => {
    const { id, ...data } = updateDto;
    if (data.boundaries?.features.length === 0) {
      delete data.boundaries;
    }
    return privateClient
      .patch(`plots/${id}/`, { json: data })
      .json<FieldFeature>();
  },
  updateMany: (updateDto: UpdateManyDto) => {
    return privateClient
      .patch('plots/many/', { json: updateDto })
      .json<FieldsResponse>();
  },
  deleteOne: (id: number) => {
    return privateClient.delete(`plots/${id}/`);
  },
  deleteMany: (data: MultisectActionDto) => {
    return privateClient
      .delete('plots/bulk_delete/', { json: data })
      .json<unknown>();
  },
  cutOne: (params: CutOneDto) => {
    return privateClient
      .post(`plots/${params.id}/cut/`, { json: params.data })
      .json<{
        status: string;
      }>();
  },

  uploadFile: async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const res = await handleRequest<UploadeFileResponse>(
      privateClient
        .post('plots/upload/', {
          body: formData,
        })
        .json(),
    );
    const features = res.data.features.map((f) => {
      const { id, properties } = f;
      return {
        ...f,
        properties: {
          ...properties,
          'unique-precifield-upload-id': id as string,
        },
      };
    });

    return { ...res, data: { ...res.data, features } };
  },
  getValues: async () => {
    const res = await handleRequest<FieldsValuesResponse>(
      privateClient.get('plots/values/').json(),
    );

    const species = (lang: string) =>
      res.species.map((s) => ({
        value: s.id.toString(),
        label: fromBacki18n(s, lang),
      }));

    const farm = res.farm.map((f) => ({
      value: f.id.toString(),
      label: f.name,
    }));

    const variety = res.variety.map((v) => ({
      parent: v.species,
      value: v.id.toString(),
      label: v.name,
    }));

    const soil_types = res.soil_types.map((s) => ({
      value: s.id.toString(),
      label: s.name,
    }));

    return { ...res, species, farm, variety, soil_types };
  },
  getMap: async (args: GetMapDto) => {
    let url = `${args.type}/${args.id}/`;

    if (args.type === 'heatmaps') {
      url = `plots/${args.fieldId}/${args.type}/${args.id}/`;
    }
    const res = await handleRequest<FieldMapResponse>(
      privateClient.get(url).json(),
    );

    return res;
  },
  computeHeatmap: async (params: {
    fieldId: number;
    heatmapId: number;
    heatmapLayer: string;
  }) => {
    return privateClient
      .post(`plots/${params.fieldId}/heatmaps/`, {
        json: {
          id: params.heatmapId,
        },
        searchParams: {
          type: params.heatmapLayer,
        },
      })
      .json<{
        status: string;
        async_computation_request_id: number;
      }>();
  },
  getHeatmapComputationStatus: async (requestId: number) => {
    return privateClient.get(`computation_requests/${requestId}/`).json<{
      id: number;
      status: string;
      instance_id: number;
    }>();
  },

  // PK
  getPkTuto: async (fieldId: number) => {
    // api/plots/id/pk_advice/tutorial
    const res = await handleRequest<{ file: string }>(
      privateClient.get(`plots/${fieldId}/pk_advice/tutorial/`).json(),
    );

    return res;
  },
  getPkAdvice: async (fieldId: number) => {
    const res = await handleRequest<PkAdviceResponse>(
      privateClient.get(`plots/${fieldId}/pk_advice/`).json(),
    );

    return res;
  },
  getPkValues: async (fieldId: number) => {
    const res = await handleRequest<PkValuesResponse>(
      privateClient
        .get(`pk_advice/values/`, {
          searchParams: {
            field_id: fieldId,
          },
        })
        .json(),
    );

    const soil_type = res.soil_type.map((s) => ({
      value: s.id.toString(),
      label: s.name,
    }));

    const crop = (lang: string) =>
      res.crop.map((c) => ({
        value: c.id.toString(),
        label: fromBacki18n(c, lang),
      }));

    const P2O5_impasse = res.P2O5_impasse.map((p) => ({
      value: p[0].toString(),
      label: p[1],
    }));

    const K2O_impasse = res.K2O_impasse.map((k) => ({
      value: k[0].toString(),
      label: k[1],
    }));

    const product_group = (lang: string) =>
      res.product_group.map((pg) => ({
        value: pg.id.toString(),
        label: fromBacki18n(pg, lang),
      }));

    const product = (lang: string) =>
      res.product.map((p) => ({
        value: p.id.toString(),
        label: fromBacki18n(p, lang),
        parent: p.group,
      }));

    const product_ferti = (lang: string) =>
      res.product_ferti.map((p) => ({
        value: p.id.toString(),
        label: fromBacki18n(p, lang),
        parent: p.group,
      }));

    return {
      res,
      soil_type,
      crop,
      P2O5_impasse,
      K2O_impasse,
      product_group,
      product,
      product_ferti,
    };
  },
  updatePkAdvice: async (data: UpdatePkAdviceDto) => {
    const res = await handleRequest<unknown>(
      privateClient
        .patch(`plots/${data.field}/pk_advice/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },

  getPkResults: async (args: GetPkResultsArgs) => {
    const res = await handleRequest<PkResultResponse>(
      privateClient
        .post(`plots/${args.fieldId}/pk_advice/`, {
          json: {
            product_ferti: args.product_ferti,
            price: args.price,
          },
        })
        .json(),
    );

    return res;
  },
  createPkMap: async (args: { fieldId: number; product: number[] }) => {
    const res = await handleRequest<{
      status: string;
      map_instances: number[];
    }>(
      privateClient
        .post(`plots/${args.fieldId}/pk_map/`, {
          json: {
            product: args.product,
          },
        })
        .json(),
    );

    return res;
  },
  prescriptions: prescriptionsApi,
};
