import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import saveAs from 'file-saver';
import { SearchParamsOption } from 'ky';

import { BiomassMapResult } from '../types/biomassmap';
import {
  CommonAdvice,
  CommonAdvicesResponse,
  FORM_STATUS,
} from '../types/common';
import {
  CommonResultResponse,
  GetCommonAdminResponse,
} from '../types/common-result';
import {
  CreateCommonAdviceDto,
  CreateRapeseedAdviceDto,
  CreateWheat2AdviceDto,
  GetCommonAdminDto,
  GetRapeseedAdminDto,
  UpdateCommonAdviceDto,
  UpdateCommonSettings,
  UpdateManyCommonAdviceDto,
  UpdateManyRapeseedAdviceDto,
  UpdateManyWheat2AdviceDto,
  UpdateRapeseedAdviceDto,
  UpdateRapeseedSettings,
  UpdateWheat2AdviceDto,
} from '../types/dto';
import {
  RapeseedAdminResponse,
  RapeseedListAdvice,
  RapeseedListResult,
} from '../types/rapeseed';
import { RapeseedResultResponse } from '../types/rapeseed-result';
import { ValuesResponse } from '../types/values';
import { Wheat2Advice, Wheat2AdvicesResponse } from '../types/wheat2';

import {
  formatFilters,
  Filters,
  privateClient,
  handleRequest,
  CropEnum,
  fromBacki18n,
  I18n,
  MultisectActionDto,
} from '@data-access';

dayjs.extend(advancedFormat);

export const advicesApi = {
  getAllCommon: async (crop: string, filters: Filters) => {
    const res = await handleRequest<CommonAdvicesResponse>(
      privateClient
        .get(`nitrogen/${crop}/`, {
          searchParams: {
            ...formatFilters(filters),
          },
        })
        .json(),
    );

    const hasPending = res.results.some((r) => {
      return r.yields?.some((y) => y.status === FORM_STATUS.PENDING);
    });

    return { ...res, hasPending };
  },
  getAllWheat2: async (filters: Filters) => {
    const res = await privateClient
      .get(`nitrogen/${CropEnum.WHEAT2}/`, {
        searchParams: {
          ...formatFilters(filters),
        },
      })
      .json<Wheat2AdvicesResponse>();

      const hasPending = res.results.some((r) => {
        return r.computation_status === FORM_STATUS.PENDING;
      });

    return { ...res, hasPending };
  },
  getTuto: async (crop: string) => {
    // api/nitrogen/wheat/tutorial
    const res = await handleRequest<{
      id: number;
      page: string;
      file: string;
    }>(privateClient.get(`nitrogen/${crop}/tutorial/`).json());

    return res;
  },

  getOne: async (args: { id: number; crop: string }) => {
    const res = await handleRequest<CommonAdvice>(
      privateClient.get(`nitrogen/${args.crop}/${args.id}/`).json(),
    );

    return res;
  },
  getOneWheat2: async (args: { id: number }) => {
    const res = await handleRequest<Wheat2Advice>(
      privateClient.get(`nitrogen/${CropEnum.WHEAT2}/${args.id}/`).json(),
    );

    return res;
  },
  getOneRapeseed: async (args: { id: number }) => {
    const res = await handleRequest<RapeseedListAdvice>(
      privateClient.get(`nitrogen/${CropEnum.RAPESEED}/${args.id}/`).json(),
    );

    return res;
  },

  getCommonResults: async (args: { id: number; crop: string }) => {
    const res = await handleRequest<CommonResultResponse>(
      privateClient.get(`nitrogen/${args.crop}/${args.id}/result/`).json(),
    );

    return res;
  },
  getRapeseedResult: async (args: { id: number }) => {
    const res = await handleRequest<RapeseedResultResponse>(
      privateClient
        .get(`nitrogen/${CropEnum.RAPESEED}/${args.id}/result/`)
        .json(),
    );

    return res;
  },

  getResultRecap: async (args: {
    formId: number;
    crop: string;
    yieldId?: number;
  }) => {
    const res = await handleRequest<{
      id: number;
      file: string;
    }>(
      privateClient
      .get(`nitrogen/${args.crop}/${args.formId}/recap/${args.yieldId ? `${args.yieldId}/` : ''}`)
      .json(),
    );

    return res;
  },
  downloadMany: async (data: MultisectActionDto & { crop: string }) => {
    // POST nitrogen/wheat/synthesis
    const res = await handleRequest(
      privateClient.post(`nitrogen/${data.crop}/synthesis/`, {
        json: data,
      }),
    );

    // Extract filename from header
    const filename = res.headers
      .get('content-disposition')
      ?.split(';')
      .find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim();

    const blob = await res.blob();

    saveAs(blob, filename);

    return res;
  },

  getAllRapeseed: async (filters: Filters) => {
    const res = await privateClient
      .get(`nitrogen/${CropEnum.RAPESEED}/`, {
        searchParams: {
          ...formatFilters(filters),
        },
      })
      .json<RapeseedListResult>();

    const hasPending = res.results.some((r) => {
      return r.computation_status === FORM_STATUS.PENDING;
    });

    return { ...res, hasPending };
  },
  getBiomassMap: async (mapId: number) => {
    const res = await privateClient
      .get(`biomassmaps/${mapId}/`, {})
      .json<BiomassMapResult>();

    return res;
  },

  createCommon: async (createDto: CreateCommonAdviceDto) => {
    const { crop, ...data } = createDto;
    const res = await handleRequest<CommonAdvice[]>(
      privateClient
        .post(`nitrogen/${crop}/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },
  updateCommon: async (updateDto: UpdateCommonAdviceDto) => {
    const { crop, id, ...data } = updateDto;
    const res = await handleRequest(
      privateClient
        .patch(`nitrogen/${crop}/${id}/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },
  updateManyCommon: async (updateManyDto: UpdateManyCommonAdviceDto) => {
    const { crop, ...data } = updateManyDto;
    const res = await handleRequest(
      privateClient
        .patch(`nitrogen/${crop}/bulk_update/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },
  createWheat2: async (createDto: CreateWheat2AdviceDto) => {
    const res = await handleRequest<Wheat2Advice[]>(
      privateClient
        .post(`nitrogen/${CropEnum.WHEAT2}/`, {
          json: createDto,
        })
        .json(),
    );

    return res;
  },
  updateWheat2: async (updateDto: UpdateWheat2AdviceDto) => {
    const { id, ...data } = updateDto;
    const res = await handleRequest(
      privateClient
        .patch(`nitrogen/${CropEnum.WHEAT2}/${id}/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },
  updateManyWheat2: async (data: UpdateManyWheat2AdviceDto) => {
    const res = await handleRequest(
      privateClient
        .patch(`nitrogen/${CropEnum.WHEAT2}/bulk_update/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },
  createRapeseed: async (createDto: CreateRapeseedAdviceDto) => {
    const res = await handleRequest<RapeseedListAdvice[]>(
      privateClient
        .post(`nitrogen/${CropEnum.RAPESEED}/`, {
          json: createDto,
        })
        .json(),
    );

    return res;
  },
  updateRapeseed: async (updateDto: UpdateRapeseedAdviceDto) => {
    const { id, ...data } = updateDto;
    const res = await handleRequest(
      privateClient
        .patch(`nitrogen/${CropEnum.RAPESEED}/${id}/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },
  updateManyRapeseed: async (data: UpdateManyRapeseedAdviceDto) => {
    const res = await handleRequest(
      privateClient
        .patch(`nitrogen/${CropEnum.RAPESEED}/bulk_update/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },

  getTypes: async () => {
    const res = await handleRequest<
      {
        /**
         * @deprecated Use i18n instead
         */
        name: string;
        i18n: I18n;
        parent: {
          id: number;
          /**
           * @deprecated Use i18n instead
           */
          name: CropEnum.RAPESEED;
          i18n: I18n;
          code: string;
        };
      }[]
    >(privateClient.get(`nitrogen/types/`).json());

    return res;
  },
  getValues: async ({
    crop,
    fieldId,
  }: {
    crop: string;
    fieldId: number | null;
  }) => {
    let sP: SearchParamsOption = {};

    if (fieldId) {
      sP = {
        field_id: fieldId,
      };
    }
    // return true;
    const res = await handleRequest<ValuesResponse>(
      privateClient
        .get(`nitrogen/${crop}/values/`, {
          searchParams: sP,
        })
        .json(),
    );

    const varieties = res.variety?.map((variety) => ({
      ...variety,
      value: variety.id.toString(),
      label: variety.name,
    }));

    const products = res.product?.map((product) => ({
      // ...product,
      value: product.id.toString(),
      label: product.name,
    }));

    const yieldPotentials = res.yield_potential?.map(
      ([yieldPotential]) => yieldPotential,
    );

    const sol = res.sol?.map(([s]) => s);

    const biomass_start = res.biomass_start?.map(([date]) => date);
    const biomass_end = res.biomass_end?.map(([date]) => date);

    const biomass_map_start = res.biomass_map_start?.map((map) => ({
      ...map,
      value: map.id.toString(),
      label: dayjs(map.date).format('Do MMMM YYYY'),
    }));
    const biomass_map_end = res.biomass_map_end?.map((map) => ({
      ...map,
      value: map.id.toString(),
      label: dayjs(map.date).format('Do MMMM YYYY'),
    }));

    const biomass_merge_start = [
      ...(biomass_map_start || []),
      ...(biomass_start || []),
    ];
    const biomass_merge_end = [
      ...(biomass_map_end || []),
      ...(biomass_end || []),
    ];

    const pdt_org_hist = (lang: string) =>
      res.pdt_org_hist?.map((pdt) => ({
        ...pdt,
        value: pdt.id.toString(),
        label: fromBacki18n(pdt, lang),
      }));
    const pdt_org = (lang: string) =>
      res.pdt_org?.map((pdt) => ({
        ...pdt,
        value: pdt.id.toString(),
        label: fromBacki18n(pdt, lang),
      }));

    const frq_org = res.frq_org?.map(([value, label]) => ({ value, label }));

    // const dataSources = res.data_source.map(([value, label]) => ({
    //   value,
    //   label,
    // }));${crop}

    return {
      ...res,
      variety: varieties,
      product: products,
      yield_potential: yieldPotentials,
      // data_source: dataSources,
      sol,
      biomass_start,
      biomass_end,
      biomass_map_start,
      biomass_map_end,
      biomass_merge_start,
      biomass_merge_end,
      pdt_org_hist,
      pdt_org,
      frq_org,
    };
  },
  deleteOne: async (args: { id: number; crop: string }) => {
    const res = await handleRequest(
      privateClient.delete(`nitrogen/${args.crop}/${args.id}/`).json(),
    );

    return res;
  },
  deleteMany: async (data: MultisectActionDto & { crop: string }) => {
    const res = await handleRequest(
      privateClient
        .delete(`nitrogen/${data.crop}/bulk_delete/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },

  getCommonAdmin: async (params: GetCommonAdminDto) => {
    // api debug_panel/common/154/yield_instance/125/
    const res = await handleRequest<GetCommonAdminResponse>(
      privateClient
        .get(
          `debug_panel/common/${params.fieldId}/yield_instance/${params.yieldId}/`,
          {
            searchParams: {
              layer: params.layer,
            },
          },
        )
        .json(),
    );

    return res;
  },
  getCommonAdminValues: async () => {
    //debug_panel/values
    const res = await handleRequest<{
      data_collection: [string, string][];
    }>(privateClient.get(`debug_panel/values/`).json());

    return {
      data_collection: res.data_collection.map(([value, label]) => ({
        value,
        label,
      })),
    };
  },
  updateCommonSettings: async ({
    fieldId,
    yieldId,
    ...data
  }: UpdateCommonSettings) => {
    const res = await handleRequest(
      privateClient
        .patch(`debug_panel/common/${fieldId}/yield_instance/${yieldId}/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },

  getRapeseedAdmin: async (params: GetRapeseedAdminDto) => {
    const res = await handleRequest<RapeseedAdminResponse>(
      privateClient
        .get(`debug_panel/rapeseed/${params.fieldId}/`, {
          searchParams: {
            layer: params.layer,
          },
        })
        .json(),
    );

    return res;
  },
  updateRapeseedSettings: async ({
    fieldId,
    ...data
  }: UpdateRapeseedSettings) => {
    const res = await handleRequest(
      privateClient
        .patch(`debug_panel/rapeseed/${fieldId}/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },
};
