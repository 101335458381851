import { Paper } from '@mantine/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { Map, MapEvent } from 'mapbox-gl';
import { useCallback, useEffect, useRef } from 'react';
import { MapRef } from 'react-map-gl';

import FieldsInBounds, {
  INTERACTIVE_LAYER_IDS,
  useFieldsInBoundsClick,
} from '../../components/fields-in-bounds/fields-in-bounds';
import { FieldsMapHeader } from '../../components/fields-map-header/fields-map-header';

import { ErrorPage } from './page.error';
import styles from './page.module.css';

import { useColFilters, useQueriesWithGlobal } from '@data-access';
import { fieldsQueries, useFields } from '@fields/data-access';
import { GeocoderControl, PfMap, StyleControl, zoomOnFeature } from '@map';
import { FullMainHeight } from '@ui';

export function FieldsMap() {
  const mapRef = useRef<MapRef>(null);
  const [, setPlotsFilters] = useColFilters('plots');
  const queryClient = useQueryClient();

  const queries = useQueriesWithGlobal(fieldsQueries);
  const fields = useFields();
  const maxBounds = useQuery(queries.maxBounds());

  const handleMapClick = useFieldsInBoundsClick();

  const handleSearchSubmit = async (fieldId: string) => {
    try {
      const field = await queryClient.fetchQuery(
        queries.detail(Number(fieldId)),
      );

      if (field && mapRef.current) {
        zoomOnFeature({
          geojson: field,
          map: mapRef.current.getMap(),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (value: string) => {
    setPlotsFilters([
      {
        id: 'name',
        value: value.toLowerCase().trim(),
      },
    ]);
  };

  const fitMaxBounds = useCallback(
    (map: Map) => {
      if (maxBounds.data?.bbox && map) {
        map.fitBounds(maxBounds.data.bbox, {
          padding: 20,
        });
      }
    },
    [maxBounds.data],
  );

  const handleLoad = (e: MapEvent) => {
    fitMaxBounds(e.target);
  };

  useEffect(() => {
    if (maxBounds.isSuccess && mapRef.current) {
      fitMaxBounds(mapRef.current.getMap());
    }
  }, [fitMaxBounds, maxBounds.isSuccess]);

  return (
    <FullMainHeight.Root>
      <FullMainHeight.Header>
        <FieldsMapHeader
          isLoading={fields.isFetching}
          onSearchChange={handleChange}
          onSearchSubmit={handleSearchSubmit}
          searchOptions={fields.data?.options || []}
        />
      </FullMainHeight.Header>
      <FullMainHeight.Content>
        <Paper
          className={clsx(styles['map-container'], 'border-light')}
          p={0}
          pos="relative"
          shadow="sm"
        >
          <PfMap
            interactiveLayerIds={INTERACTIVE_LAYER_IDS}
            onClick={handleMapClick}
            onLoad={handleLoad}
            ref={mapRef}
          >
            <StyleControl />
            <GeocoderControl />

            <FieldsInBounds />
          </PfMap>
        </Paper>
      </FullMainHeight.Content>
    </FullMainHeight.Root>
  );
}

FieldsMap.Error = ErrorPage;

export default FieldsMap;
