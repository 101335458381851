import { Fieldset, NumberInput, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useRapeseedAdviceFormContext } from '../../../hooks/use-rapeseed-advice-form/rapeseed-advice-form';
import InfoLabel from '../../info-label/info-label';

import { useValues } from '@advices/data-access';
import { asCbData, CropEnum } from '@data-access';

export const FertilizerFieldset = () => {
  const { t, i18n } = useTranslation();
  const form = useRapeseedAdviceFormContext();
  const selectedFields = form.getValues().field;

  const values = useValues({
    type: CropEnum.RAPESEED,
    fieldId: selectedFields.length === 1 ? selectedFields[0] : null,
  });

  return (
    <Fieldset legend={t('advices.fertilizing')} mt="md" variant="filled">
      <NumberInput
        {...form.getInputProps('nitrogen_already_applied')}
        hideControls
        label={
          <InfoLabel
            info={t('advices.properties.nitrogen_already_applied_info')}
            label={t('advices.properties.nitrogen_already_applied')}
          />
        }
      />

      <Select
        {...form.getInputProps('product')}
        data={asCbData(values.data?.product)}
        label={
          <InfoLabel
            info={t('advices.properties.product_info')}
            label={t('advices.properties.product')}
          />
        }
        mt="sm"
      />
    </Fieldset>
  );
};
