import { useQuery } from '@tanstack/react-query';

import { advicesQueries } from '../../queries/advices';

import { useFiltersStore, useQueriesWithGlobal } from '@data-access';

export function useWheat2Advices() {
  const page = useFiltersStore.use['advices']().pagination.pageIndex + 1;
  const sorting = useFiltersStore.use['advices']().sorting;
  const filters = useFiltersStore.use['advices']().filters;

  const queries = useQueriesWithGlobal(advicesQueries);

  const query = useQuery(
    queries.wheat2List({
      filters,
      page,
      sorting,
    }),
  );

  return query;
}

export default useWheat2Advices;
