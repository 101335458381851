import dayjs from 'dayjs';

type FormatDateReturnType<T> = T extends { date: string }
  ? string
  : string | null;

export const formatDate = <
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends { date?: string | null; format: 'display' | 'db' | (string & {}) },
>({
  date,
  format,
}: T): FormatDateReturnType<T> => {
  if (!date) {
    return null as FormatDateReturnType<T>;
  }

  if (format === 'display') {
    return dayjs(date).format('DD/MM/YYYY') as FormatDateReturnType<T>;
  }

  if (format === 'db') {
    return dayjs(date).format('YYYY-MM-DD') as FormatDateReturnType<T>;
  }

  return dayjs(date).format(format) as FormatDateReturnType<T>;
};
