import { Progress } from '@mantine/core';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { bbox, featureCollection } from '@turf/turf';
import dayjs from 'dayjs';
import { LngLatBounds, MapEvent } from 'mapbox-gl';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { MapMouseEvent, useMap } from 'react-map-gl';

import { useQueriesWithGlobal } from '@data-access';
import { FieldsFeatureCollection, fieldsQueries } from '@fields/data-access';
import {
  PlotsLayer,
  ClusterLayer,
  SatelliteImageryControl,
  ImageLayer,
} from '@map';

export const FIELDS_LAYER_ID = 'fields-layer';
export const CLUSTER_LAYER_ID = 'cluster-layer';

export const INTERACTIVE_LAYER_IDS = [
  FIELDS_LAYER_ID,
  CLUSTER_LAYER_ID,
  `${CLUSTER_LAYER_ID}_points`,
];

// The combination of the FieldsInBounds component and useFieldsInBounds hook
// is needed because listening to onClick event through useMap map.on('click') does not work

interface FieldsInBoundsProps {
  fieldId?: string;
  withSatelliteControl?: boolean;
  hideFieldsLayer?: boolean;
  hideClusters?: boolean;
}

export function FieldsInBounds({
  fieldId,
  withSatelliteControl = true,
  hideFieldsLayer = false,
  hideClusters = false,
}: FieldsInBoundsProps) {
  const { data, query, heatmaps, heatmapsLayerState, setHeatmapsLayerState } =
    useFieldsInBounds({
      filterIds: fieldId ? [fieldId] : [],
    });

  return (
    <>
      {query.data?.datelist && withSatelliteControl ? (
        <SatelliteImageryControl
          date={
            heatmapsLayerState.date
              ? dayjs(heatmapsLayerState.date).toDate()
              : null
          }
          dates={query.data.datelist}
          imageType={heatmapsLayerState.imageType}
          loading={heatmaps.isFetching}
          onDateChange={(date) =>
            setHeatmapsLayerState({
              ...heatmapsLayerState,
              date: dayjs(date).format('YYYY-MM-DD'),
            })
          }
          onEnableChange={(enabled) =>
            setHeatmapsLayerState({ ...heatmapsLayerState, enabled })
          }
          onImageTypeChange={(imageType) =>
            setHeatmapsLayerState({ ...heatmapsLayerState, imageType })
          }
        />
      ) : null}

      {query.isRefetching ? (
        <Progress
          animated
          opacity={0.9}
          radius={0}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            zIndex: 1,
          }}
          value={100}
        />
      ) : null}

      {heatmapsLayerState.enabled
        ? heatmaps.data?.map(({ heatmaps: heatmap }) => (
            <Fragment key={heatmap.data[0].url}>
              <ImageLayer
                beforeId="fields-outline-heatmap_outline"
                coordinates={heatmap.corners_coords}
                imageUrl={heatmap.data[0].url}
              />
              <PlotsLayer
                data={data}
                id="fields-outline-heatmap"
                minZoom={12}
                outline
              />
            </Fragment>
          ))
        : null}

      {!heatmapsLayerState.enabled && data && !hideFieldsLayer ? (
        <PlotsLayer data={data} id={FIELDS_LAYER_ID} minZoom={12} />
      ) : null}

      {data && !hideClusters ? (
        <ClusterLayer
          clusterColor="#ED7D31"
          data={data as any}
          layerId={CLUSTER_LAYER_ID}
          pointsMaxZoom={12}
        />
      ) : null}
    </>
  );
}

interface UseFieldsInBoundsParams {
  filterIds?: string[];
}

export function useFieldsInBounds({ filterIds }: UseFieldsInBoundsParams = {}) {
  const { current: map } = useMap();
  const [mapBounds, setMapBounds] = useState<LngLatBounds | null>(null);
  const [mapZoom, setMapZoom] = useState(0);
  const [heatmapsLayerState, setHeatmapsLayerState] = useState({
    enabled: false,
    date: '',
    imageType: 'NDVI',
  });

  const cluster = mapZoom < 12 ? true : false;

  const queries = useQueriesWithGlobal(fieldsQueries);
  const query = useQuery({
    ...queries.boundedList({
      bounds: mapBounds!,
      cluster: cluster,
      zoom: mapZoom,
    }),
    enabled: !!mapBounds,
    placeholderData: keepPreviousData,
  });
  const heatmaps = useQuery({
    ...queries.boundedHeatmaps({
      bounds: mapBounds!,
      layer: heatmapsLayerState.imageType,
      date: heatmapsLayerState.date,
    }),
    enabled:
      !cluster &&
      !!query.data?.datelist?.length &&
      !!heatmapsLayerState.date &&
      heatmapsLayerState.enabled,
    placeholderData: keepPreviousData,
  });

  const fieldsInBounds = useMemo(() => {
    if (!query.data) {
      return featureCollection([]) as FieldsFeatureCollection;
    }

    if (filterIds && filterIds.length) {
      return featureCollection(
        query.data.features.filter((f) => {
          if (f.id && !heatmapsLayerState.enabled) {
            return !filterIds.includes(f.id.toString());
          }
          return true;
        }),
      );
    }

    return query.data;
  }, [filterIds, heatmapsLayerState.enabled, query.data]);

  const handleMapIdle = useCallback((e: MapEvent) => {
    setMapBounds(e.target.getBounds());
    setMapZoom(e.target.getZoom());
  }, []);

  useEffect(() => {
    if (map) {
      // Listen to the map idle event
      map.on('idle', handleMapIdle);

      // Cleanup the listener on component unmount
      return () => {
        map.off('idle', handleMapIdle);
      };
    }
  }, [map, handleMapIdle]);

  // set initial date from last heatmaps date
  useEffect(() => {
    if (query.data?.datelist?.length) {
      const lastDate = query.data.datelist[query.data.datelist.length - 1];
      setHeatmapsLayerState((state) => ({
        ...state,
        date: lastDate,
      }));
    }
  }, [query.data?.datelist]);

  return {
    handleMapIdle,
    query,
    heatmaps,
    heatmapsLayerState,
    setHeatmapsLayerState,
    data: fieldsInBounds,
    interactiveLayerIds: INTERACTIVE_LAYER_IDS,
  };
}

export const useFieldsInBoundsClick = () => {
  const navigate = useNavigate();

  return (e: MapMouseEvent) => {
    const feature = e.features?.[0];

    if (!feature) {
      return;
    }

    switch (feature.layer?.id) {
      case FIELDS_LAYER_ID: {
        if (feature.id) {
          navigate({
            to: '/fields/$fieldId',
            params: { fieldId: feature.id.toString() },
          });
        }
        break;
      }
      case CLUSTER_LAYER_ID:
      case `${CLUSTER_LAYER_ID}_points`: {
        const [x1, y1, x2, y2] = bbox(feature.geometry);
        e.target.fitBounds([x1, y1, x2, y2], {
          padding: 50,
          animate: false,
        });
        break;
      }
    }
  };
};

export default FieldsInBounds;
