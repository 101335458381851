import { Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface BiomassCellProps {
  mean?: number;
  date?: string;
}

export const BiomassCell = ({ mean, date }: BiomassCellProps) => {
  const { t } = useTranslation();

  return (
    <Stack gap={0}>
      <Text>
        {t('advices.biomass')} {mean?.toFixed(2) ?? '-'} kg/m²
      </Text>
      <Text>
        {t('advices.date')} {date ? dayjs(date).format('DD/MM/YYYY') : '-'}
      </Text>
    </Stack>
  );
};
