import { Box, Flex } from '@mantine/core';
import { ErrorRouteComponent } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { StatisticsHeader } from '../../components/statistics-header/statistics-header';

import classes from './page.module.css';

import { CommonError } from '@ui';

export const ErrorPage: ErrorRouteComponent = ({ error, reset }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box mb="md">
        <StatisticsHeader />
      </Box>
      <Flex className={classes.container}>
        <CommonError
          description={t('common.error.pageError')}
          error={error}
          reset={reset}
        />
      </Flex>
    </>
  );
};

export default ErrorPage;
