export enum CropEnum {
  WHEAT = 'wheat',
  RAPESEED = 'rapeseed',
  BARLEY = 'barley',
  WHEAT2 = 'wheat2',
}
export type Crop = CropEnum.BARLEY | CropEnum.RAPESEED | CropEnum.WHEAT;

export const CROPS = [CropEnum.WHEAT, CropEnum.RAPESEED, CropEnum.BARLEY];

export const CROPS_OPTIONS = [
  { label: 'Blé', value: CropEnum.WHEAT },
  {
    label: 'Colza',
    value: CropEnum.RAPESEED,
  },
  { label: 'Orge', value: CropEnum.BARLEY },
];
