import { getRouteApi, useNavigate } from '@tanstack/react-router';

import { ResetPasswordForm } from '../../ui/reset-password-form/reset-password-form';

import styles from './reset-password.module.css';

import { useResetPassword } from '@auth/data-access';

const routeApi = getRouteApi('/_auth/reset-password');

export function ResetPassword() {
  const { token } = routeApi.useSearch();
  const navigate = useNavigate();

  const resetPassword = useResetPassword();

  const handleSubmit = (password: string) => {
    if (token) {
      resetPassword.mutate(
        { password, token },
        {
          onSuccess: () => {
            navigate({
              to: '/login',
            });
          },
        },
      );
    }
  };

  return (
    <ResetPasswordForm
      isPending={resetPassword.isPending}
      onSubmit={handleSubmit}
    />
  );
}

export default ResetPassword;
