/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/_auth'
import { Route as AppImport } from './routes/_app'
import { Route as IndexImport } from './routes/index'
import { Route as AuthResetPasswordImport } from './routes/_auth/reset-password'
import { Route as AuthLoginImport } from './routes/_auth/login'
import { Route as AppSynthesisImport } from './routes/_app/synthesis'
import { Route as AppSupportImport } from './routes/_app/support'
import { Route as AppPrescriptionsImport } from './routes/_app/prescriptions'
import { Route as AppPrecifertNImport } from './routes/_app/precifert-n'
import { Route as AppMapImport } from './routes/_app/map'
import { Route as AppFieldsImport } from './routes/_app/fields'
import { Route as AppDashboardImport } from './routes/_app/dashboard'
import { Route as AppConnectivityImport } from './routes/_app/connectivity'
import { Route as AppAdminImport } from './routes/_app/_admin'
import { Route as AppFieldsFieldIdImport } from './routes/_app/fields_/$fieldId'
import { Route as AppAdminStatisticsImport } from './routes/_app/_admin/statistics'
import { Route as AppFieldsFieldIdIndexImport } from './routes/_app/fields_/$fieldId/index'
import { Route as AppFieldsFieldIdEditImport } from './routes/_app/fields_/$fieldId_/edit'
import { Route as AppFieldsFieldIdCutImport } from './routes/_app/fields_/$fieldId_/cut'
import { Route as AppFieldsFieldIdPkImport } from './routes/_app/fields_/$fieldId/pk'
import { Route as AppPrecifertNRapeseedResultsResultIdImport } from './routes/_app/precifert-n_/rapeseed.results.$resultId'
import { Route as AppPrecifertNTypeResultsResultIdImport } from './routes/_app/precifert-n_/$type.results.$resultId'
import { Route as AppFieldsFieldIdMapsMapTypeImport } from './routes/_app/fields_/$fieldId/maps/$mapType'
import { Route as AppPrecifertNRapeseedResultsResultIdAdminImport } from './routes/_app/precifert-n_/rapeseed.results.$resultId_.admin'
import { Route as AppFieldsFieldIdMapsPrescriptionsCreateImport } from './routes/_app/fields_/$fieldId_/maps/prescriptions_/create'
import { Route as AppFieldsFieldIdMapsBiomassSettingsImport } from './routes/_app/fields_/$fieldId_/maps/biomass_.settings'
import { Route as AppFieldsFieldIdMapsEditableMapTypeCreateImport } from './routes/_app/fields_/$fieldId_/maps/$editableMapType/create'
import { Route as AppPrecifertNTypeResultsResultIdSettingsYieldIdImport } from './routes/_app/precifert-n_/$type.results.$resultId_.settings.$yieldId'

// Create Virtual Routes

const AboutLazyImport = createFileRoute('/about')()
const AuthLostPasswordLazyImport = createFileRoute('/_auth/lost-password')()
const AppSettingsLazyImport = createFileRoute('/_app/settings')()
const AppProfileLazyImport = createFileRoute('/_app/profile')()
const AppFieldsCreateLazyImport = createFileRoute('/_app/fields_/create')()
const AppAdminUsersLazyImport = createFileRoute('/_app/_admin/users')()
const AppAdminAccountsLazyImport = createFileRoute('/_app/_admin/accounts')()
const AppAdminUsersCreateLazyImport = createFileRoute(
  '/_app/_admin/users_/create',
)()
const AppAdminAccountsCreateLazyImport = createFileRoute(
  '/_app/_admin/accounts_/create',
)()
const AppAdminUsersUserIdEditLazyImport = createFileRoute(
  '/_app/_admin/users_/$userId/edit',
)()
const AppAdminAccountsAccountIdEditLazyImport = createFileRoute(
  '/_app/_admin/accounts_/$accountId/edit',
)()

// Create/Update Routes

const AboutLazyRoute = AboutLazyImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/about.lazy').then((d) => d.Route))

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AppRoute = AppImport.update({
  id: '/_app',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthLostPasswordLazyRoute = AuthLostPasswordLazyImport.update({
  id: '/lost-password',
  path: '/lost-password',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/lost-password.lazy').then((d) => d.Route),
)

const AppSettingsLazyRoute = AppSettingsLazyImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AppRoute,
} as any).lazy(() => import('./routes/_app/settings.lazy').then((d) => d.Route))

const AppProfileLazyRoute = AppProfileLazyImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AppRoute,
} as any).lazy(() => import('./routes/_app/profile.lazy').then((d) => d.Route))

const AuthResetPasswordRoute = AuthResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AppSynthesisRoute = AppSynthesisImport.update({
  id: '/synthesis',
  path: '/synthesis',
  getParentRoute: () => AppRoute,
} as any)

const AppSupportRoute = AppSupportImport.update({
  id: '/support',
  path: '/support',
  getParentRoute: () => AppRoute,
} as any)

const AppPrescriptionsRoute = AppPrescriptionsImport.update({
  id: '/prescriptions',
  path: '/prescriptions',
  getParentRoute: () => AppRoute,
} as any)

const AppPrecifertNRoute = AppPrecifertNImport.update({
  id: '/precifert-n',
  path: '/precifert-n',
  getParentRoute: () => AppRoute,
} as any)

const AppMapRoute = AppMapImport.update({
  id: '/map',
  path: '/map',
  getParentRoute: () => AppRoute,
} as any)

const AppFieldsRoute = AppFieldsImport.update({
  id: '/fields',
  path: '/fields',
  getParentRoute: () => AppRoute,
} as any)

const AppDashboardRoute = AppDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => AppRoute,
} as any)

const AppConnectivityRoute = AppConnectivityImport.update({
  id: '/connectivity',
  path: '/connectivity',
  getParentRoute: () => AppRoute,
} as any).lazy(() =>
  import('./routes/_app/connectivity.lazy').then((d) => d.Route),
)

const AppAdminRoute = AppAdminImport.update({
  id: '/_admin',
  getParentRoute: () => AppRoute,
} as any)

const AppFieldsCreateLazyRoute = AppFieldsCreateLazyImport.update({
  id: '/fields_/create',
  path: '/fields/create',
  getParentRoute: () => AppRoute,
} as any).lazy(() =>
  import('./routes/_app/fields_/create.lazy').then((d) => d.Route),
)

const AppAdminUsersLazyRoute = AppAdminUsersLazyImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => AppAdminRoute,
} as any).lazy(() =>
  import('./routes/_app/_admin/users.lazy').then((d) => d.Route),
)

const AppAdminAccountsLazyRoute = AppAdminAccountsLazyImport.update({
  id: '/accounts',
  path: '/accounts',
  getParentRoute: () => AppAdminRoute,
} as any).lazy(() =>
  import('./routes/_app/_admin/accounts.lazy').then((d) => d.Route),
)

const AppFieldsFieldIdRoute = AppFieldsFieldIdImport.update({
  id: '/fields_/$fieldId',
  path: '/fields/$fieldId',
  getParentRoute: () => AppRoute,
} as any)

const AppAdminStatisticsRoute = AppAdminStatisticsImport.update({
  id: '/statistics',
  path: '/statistics',
  getParentRoute: () => AppAdminRoute,
} as any).lazy(() =>
  import('./routes/_app/_admin/statistics.lazy').then((d) => d.Route),
)

const AppFieldsFieldIdIndexRoute = AppFieldsFieldIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppFieldsFieldIdRoute,
} as any)

const AppAdminUsersCreateLazyRoute = AppAdminUsersCreateLazyImport.update({
  id: '/users_/create',
  path: '/users/create',
  getParentRoute: () => AppAdminRoute,
} as any).lazy(() =>
  import('./routes/_app/_admin/users_/create.lazy').then((d) => d.Route),
)

const AppAdminAccountsCreateLazyRoute = AppAdminAccountsCreateLazyImport.update(
  {
    id: '/accounts_/create',
    path: '/accounts/create',
    getParentRoute: () => AppAdminRoute,
  } as any,
).lazy(() =>
  import('./routes/_app/_admin/accounts_/create.lazy').then((d) => d.Route),
)

const AppFieldsFieldIdEditRoute = AppFieldsFieldIdEditImport.update({
  id: '/fields_/$fieldId_/edit',
  path: '/fields/$fieldId/edit',
  getParentRoute: () => AppRoute,
} as any).lazy(() =>
  import('./routes/_app/fields_/$fieldId_/edit.lazy').then((d) => d.Route),
)

const AppFieldsFieldIdCutRoute = AppFieldsFieldIdCutImport.update({
  id: '/fields_/$fieldId_/cut',
  path: '/fields/$fieldId/cut',
  getParentRoute: () => AppRoute,
} as any).lazy(() =>
  import('./routes/_app/fields_/$fieldId_/cut.lazy').then((d) => d.Route),
)

const AppFieldsFieldIdPkRoute = AppFieldsFieldIdPkImport.update({
  id: '/pk',
  path: '/pk',
  getParentRoute: () => AppFieldsFieldIdRoute,
} as any)

const AppAdminUsersUserIdEditLazyRoute =
  AppAdminUsersUserIdEditLazyImport.update({
    id: '/users_/$userId/edit',
    path: '/users/$userId/edit',
    getParentRoute: () => AppAdminRoute,
  } as any).lazy(() =>
    import('./routes/_app/_admin/users_/$userId.edit.lazy').then(
      (d) => d.Route,
    ),
  )

const AppAdminAccountsAccountIdEditLazyRoute =
  AppAdminAccountsAccountIdEditLazyImport.update({
    id: '/accounts_/$accountId/edit',
    path: '/accounts/$accountId/edit',
    getParentRoute: () => AppAdminRoute,
  } as any).lazy(() =>
    import('./routes/_app/_admin/accounts_/$accountId.edit.lazy').then(
      (d) => d.Route,
    ),
  )

const AppPrecifertNRapeseedResultsResultIdRoute =
  AppPrecifertNRapeseedResultsResultIdImport.update({
    id: '/precifert-n_/rapeseed/results/$resultId',
    path: '/precifert-n/rapeseed/results/$resultId',
    getParentRoute: () => AppRoute,
  } as any)

const AppPrecifertNTypeResultsResultIdRoute =
  AppPrecifertNTypeResultsResultIdImport.update({
    id: '/precifert-n_/$type/results/$resultId',
    path: '/precifert-n/$type/results/$resultId',
    getParentRoute: () => AppRoute,
  } as any)

const AppFieldsFieldIdMapsMapTypeRoute =
  AppFieldsFieldIdMapsMapTypeImport.update({
    id: '/maps/$mapType',
    path: '/maps/$mapType',
    getParentRoute: () => AppFieldsFieldIdRoute,
  } as any)

const AppPrecifertNRapeseedResultsResultIdAdminRoute =
  AppPrecifertNRapeseedResultsResultIdAdminImport.update({
    id: '/precifert-n_/rapeseed/results/$resultId_/admin',
    path: '/precifert-n/rapeseed/results/$resultId/admin',
    getParentRoute: () => AppRoute,
  } as any)

const AppFieldsFieldIdMapsPrescriptionsCreateRoute =
  AppFieldsFieldIdMapsPrescriptionsCreateImport.update({
    id: '/fields_/$fieldId_/maps/prescriptions_/create',
    path: '/fields/$fieldId/maps/prescriptions/create',
    getParentRoute: () => AppRoute,
  } as any).lazy(() =>
    import(
      './routes/_app/fields_/$fieldId_/maps/prescriptions_/create.lazy'
    ).then((d) => d.Route),
  )

const AppFieldsFieldIdMapsBiomassSettingsRoute =
  AppFieldsFieldIdMapsBiomassSettingsImport.update({
    id: '/fields_/$fieldId_/maps/biomass_/settings',
    path: '/fields/$fieldId/maps/biomass/settings',
    getParentRoute: () => AppRoute,
  } as any)

const AppFieldsFieldIdMapsEditableMapTypeCreateRoute =
  AppFieldsFieldIdMapsEditableMapTypeCreateImport.update({
    id: '/fields_/$fieldId_/maps/$editableMapType/create',
    path: '/fields/$fieldId/maps/$editableMapType/create',
    getParentRoute: () => AppRoute,
  } as any).lazy(() =>
    import(
      './routes/_app/fields_/$fieldId_/maps/$editableMapType/create.lazy'
    ).then((d) => d.Route),
  )

const AppPrecifertNTypeResultsResultIdSettingsYieldIdRoute =
  AppPrecifertNTypeResultsResultIdSettingsYieldIdImport.update({
    id: '/precifert-n_/$type/results/$resultId_/settings/$yieldId',
    path: '/precifert-n/$type/results/$resultId/settings/$yieldId',
    getParentRoute: () => AppRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_app': {
      id: '/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutLazyImport
      parentRoute: typeof rootRoute
    }
    '/_app/_admin': {
      id: '/_app/_admin'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppAdminImport
      parentRoute: typeof AppImport
    }
    '/_app/connectivity': {
      id: '/_app/connectivity'
      path: '/connectivity'
      fullPath: '/connectivity'
      preLoaderRoute: typeof AppConnectivityImport
      parentRoute: typeof AppImport
    }
    '/_app/dashboard': {
      id: '/_app/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof AppDashboardImport
      parentRoute: typeof AppImport
    }
    '/_app/fields': {
      id: '/_app/fields'
      path: '/fields'
      fullPath: '/fields'
      preLoaderRoute: typeof AppFieldsImport
      parentRoute: typeof AppImport
    }
    '/_app/map': {
      id: '/_app/map'
      path: '/map'
      fullPath: '/map'
      preLoaderRoute: typeof AppMapImport
      parentRoute: typeof AppImport
    }
    '/_app/precifert-n': {
      id: '/_app/precifert-n'
      path: '/precifert-n'
      fullPath: '/precifert-n'
      preLoaderRoute: typeof AppPrecifertNImport
      parentRoute: typeof AppImport
    }
    '/_app/prescriptions': {
      id: '/_app/prescriptions'
      path: '/prescriptions'
      fullPath: '/prescriptions'
      preLoaderRoute: typeof AppPrescriptionsImport
      parentRoute: typeof AppImport
    }
    '/_app/support': {
      id: '/_app/support'
      path: '/support'
      fullPath: '/support'
      preLoaderRoute: typeof AppSupportImport
      parentRoute: typeof AppImport
    }
    '/_app/synthesis': {
      id: '/_app/synthesis'
      path: '/synthesis'
      fullPath: '/synthesis'
      preLoaderRoute: typeof AppSynthesisImport
      parentRoute: typeof AppImport
    }
    '/_auth/login': {
      id: '/_auth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reset-password': {
      id: '/_auth/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof AuthResetPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_app/profile': {
      id: '/_app/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AppProfileLazyImport
      parentRoute: typeof AppImport
    }
    '/_app/settings': {
      id: '/_app/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AppSettingsLazyImport
      parentRoute: typeof AppImport
    }
    '/_auth/lost-password': {
      id: '/_auth/lost-password'
      path: '/lost-password'
      fullPath: '/lost-password'
      preLoaderRoute: typeof AuthLostPasswordLazyImport
      parentRoute: typeof AuthImport
    }
    '/_app/_admin/statistics': {
      id: '/_app/_admin/statistics'
      path: '/statistics'
      fullPath: '/statistics'
      preLoaderRoute: typeof AppAdminStatisticsImport
      parentRoute: typeof AppAdminImport
    }
    '/_app/fields_/$fieldId': {
      id: '/_app/fields_/$fieldId'
      path: '/fields/$fieldId'
      fullPath: '/fields/$fieldId'
      preLoaderRoute: typeof AppFieldsFieldIdImport
      parentRoute: typeof AppImport
    }
    '/_app/_admin/accounts': {
      id: '/_app/_admin/accounts'
      path: '/accounts'
      fullPath: '/accounts'
      preLoaderRoute: typeof AppAdminAccountsLazyImport
      parentRoute: typeof AppAdminImport
    }
    '/_app/_admin/users': {
      id: '/_app/_admin/users'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof AppAdminUsersLazyImport
      parentRoute: typeof AppAdminImport
    }
    '/_app/fields_/create': {
      id: '/_app/fields_/create'
      path: '/fields/create'
      fullPath: '/fields/create'
      preLoaderRoute: typeof AppFieldsCreateLazyImport
      parentRoute: typeof AppImport
    }
    '/_app/fields_/$fieldId/pk': {
      id: '/_app/fields_/$fieldId/pk'
      path: '/pk'
      fullPath: '/fields/$fieldId/pk'
      preLoaderRoute: typeof AppFieldsFieldIdPkImport
      parentRoute: typeof AppFieldsFieldIdImport
    }
    '/_app/fields_/$fieldId_/cut': {
      id: '/_app/fields_/$fieldId_/cut'
      path: '/fields/$fieldId/cut'
      fullPath: '/fields/$fieldId/cut'
      preLoaderRoute: typeof AppFieldsFieldIdCutImport
      parentRoute: typeof AppImport
    }
    '/_app/fields_/$fieldId_/edit': {
      id: '/_app/fields_/$fieldId_/edit'
      path: '/fields/$fieldId/edit'
      fullPath: '/fields/$fieldId/edit'
      preLoaderRoute: typeof AppFieldsFieldIdEditImport
      parentRoute: typeof AppImport
    }
    '/_app/_admin/accounts_/create': {
      id: '/_app/_admin/accounts_/create'
      path: '/accounts/create'
      fullPath: '/accounts/create'
      preLoaderRoute: typeof AppAdminAccountsCreateLazyImport
      parentRoute: typeof AppAdminImport
    }
    '/_app/_admin/users_/create': {
      id: '/_app/_admin/users_/create'
      path: '/users/create'
      fullPath: '/users/create'
      preLoaderRoute: typeof AppAdminUsersCreateLazyImport
      parentRoute: typeof AppAdminImport
    }
    '/_app/fields_/$fieldId/': {
      id: '/_app/fields_/$fieldId/'
      path: '/'
      fullPath: '/fields/$fieldId/'
      preLoaderRoute: typeof AppFieldsFieldIdIndexImport
      parentRoute: typeof AppFieldsFieldIdImport
    }
    '/_app/fields_/$fieldId/maps/$mapType': {
      id: '/_app/fields_/$fieldId/maps/$mapType'
      path: '/maps/$mapType'
      fullPath: '/fields/$fieldId/maps/$mapType'
      preLoaderRoute: typeof AppFieldsFieldIdMapsMapTypeImport
      parentRoute: typeof AppFieldsFieldIdImport
    }
    '/_app/precifert-n_/$type/results/$resultId': {
      id: '/_app/precifert-n_/$type/results/$resultId'
      path: '/precifert-n/$type/results/$resultId'
      fullPath: '/precifert-n/$type/results/$resultId'
      preLoaderRoute: typeof AppPrecifertNTypeResultsResultIdImport
      parentRoute: typeof AppImport
    }
    '/_app/precifert-n_/rapeseed/results/$resultId': {
      id: '/_app/precifert-n_/rapeseed/results/$resultId'
      path: '/precifert-n/rapeseed/results/$resultId'
      fullPath: '/precifert-n/rapeseed/results/$resultId'
      preLoaderRoute: typeof AppPrecifertNRapeseedResultsResultIdImport
      parentRoute: typeof AppImport
    }
    '/_app/_admin/accounts_/$accountId/edit': {
      id: '/_app/_admin/accounts_/$accountId/edit'
      path: '/accounts/$accountId/edit'
      fullPath: '/accounts/$accountId/edit'
      preLoaderRoute: typeof AppAdminAccountsAccountIdEditLazyImport
      parentRoute: typeof AppAdminImport
    }
    '/_app/_admin/users_/$userId/edit': {
      id: '/_app/_admin/users_/$userId/edit'
      path: '/users/$userId/edit'
      fullPath: '/users/$userId/edit'
      preLoaderRoute: typeof AppAdminUsersUserIdEditLazyImport
      parentRoute: typeof AppAdminImport
    }
    '/_app/fields_/$fieldId_/maps/$editableMapType/create': {
      id: '/_app/fields_/$fieldId_/maps/$editableMapType/create'
      path: '/fields/$fieldId/maps/$editableMapType/create'
      fullPath: '/fields/$fieldId/maps/$editableMapType/create'
      preLoaderRoute: typeof AppFieldsFieldIdMapsEditableMapTypeCreateImport
      parentRoute: typeof AppImport
    }
    '/_app/fields_/$fieldId_/maps/biomass_/settings': {
      id: '/_app/fields_/$fieldId_/maps/biomass_/settings'
      path: '/fields/$fieldId/maps/biomass/settings'
      fullPath: '/fields/$fieldId/maps/biomass/settings'
      preLoaderRoute: typeof AppFieldsFieldIdMapsBiomassSettingsImport
      parentRoute: typeof AppImport
    }
    '/_app/fields_/$fieldId_/maps/prescriptions_/create': {
      id: '/_app/fields_/$fieldId_/maps/prescriptions_/create'
      path: '/fields/$fieldId/maps/prescriptions/create'
      fullPath: '/fields/$fieldId/maps/prescriptions/create'
      preLoaderRoute: typeof AppFieldsFieldIdMapsPrescriptionsCreateImport
      parentRoute: typeof AppImport
    }
    '/_app/precifert-n_/rapeseed/results/$resultId_/admin': {
      id: '/_app/precifert-n_/rapeseed/results/$resultId_/admin'
      path: '/precifert-n/rapeseed/results/$resultId/admin'
      fullPath: '/precifert-n/rapeseed/results/$resultId/admin'
      preLoaderRoute: typeof AppPrecifertNRapeseedResultsResultIdAdminImport
      parentRoute: typeof AppImport
    }
    '/_app/precifert-n_/$type/results/$resultId_/settings/$yieldId': {
      id: '/_app/precifert-n_/$type/results/$resultId_/settings/$yieldId'
      path: '/precifert-n/$type/results/$resultId/settings/$yieldId'
      fullPath: '/precifert-n/$type/results/$resultId/settings/$yieldId'
      preLoaderRoute: typeof AppPrecifertNTypeResultsResultIdSettingsYieldIdImport
      parentRoute: typeof AppImport
    }
  }
}

// Create and export the route tree

interface AppAdminRouteChildren {
  AppAdminStatisticsRoute: typeof AppAdminStatisticsRoute
  AppAdminAccountsLazyRoute: typeof AppAdminAccountsLazyRoute
  AppAdminUsersLazyRoute: typeof AppAdminUsersLazyRoute
  AppAdminAccountsCreateLazyRoute: typeof AppAdminAccountsCreateLazyRoute
  AppAdminUsersCreateLazyRoute: typeof AppAdminUsersCreateLazyRoute
  AppAdminAccountsAccountIdEditLazyRoute: typeof AppAdminAccountsAccountIdEditLazyRoute
  AppAdminUsersUserIdEditLazyRoute: typeof AppAdminUsersUserIdEditLazyRoute
}

const AppAdminRouteChildren: AppAdminRouteChildren = {
  AppAdminStatisticsRoute: AppAdminStatisticsRoute,
  AppAdminAccountsLazyRoute: AppAdminAccountsLazyRoute,
  AppAdminUsersLazyRoute: AppAdminUsersLazyRoute,
  AppAdminAccountsCreateLazyRoute: AppAdminAccountsCreateLazyRoute,
  AppAdminUsersCreateLazyRoute: AppAdminUsersCreateLazyRoute,
  AppAdminAccountsAccountIdEditLazyRoute:
    AppAdminAccountsAccountIdEditLazyRoute,
  AppAdminUsersUserIdEditLazyRoute: AppAdminUsersUserIdEditLazyRoute,
}

const AppAdminRouteWithChildren = AppAdminRoute._addFileChildren(
  AppAdminRouteChildren,
)

interface AppFieldsFieldIdRouteChildren {
  AppFieldsFieldIdPkRoute: typeof AppFieldsFieldIdPkRoute
  AppFieldsFieldIdIndexRoute: typeof AppFieldsFieldIdIndexRoute
  AppFieldsFieldIdMapsMapTypeRoute: typeof AppFieldsFieldIdMapsMapTypeRoute
}

const AppFieldsFieldIdRouteChildren: AppFieldsFieldIdRouteChildren = {
  AppFieldsFieldIdPkRoute: AppFieldsFieldIdPkRoute,
  AppFieldsFieldIdIndexRoute: AppFieldsFieldIdIndexRoute,
  AppFieldsFieldIdMapsMapTypeRoute: AppFieldsFieldIdMapsMapTypeRoute,
}

const AppFieldsFieldIdRouteWithChildren =
  AppFieldsFieldIdRoute._addFileChildren(AppFieldsFieldIdRouteChildren)

interface AppRouteChildren {
  AppAdminRoute: typeof AppAdminRouteWithChildren
  AppConnectivityRoute: typeof AppConnectivityRoute
  AppDashboardRoute: typeof AppDashboardRoute
  AppFieldsRoute: typeof AppFieldsRoute
  AppMapRoute: typeof AppMapRoute
  AppPrecifertNRoute: typeof AppPrecifertNRoute
  AppPrescriptionsRoute: typeof AppPrescriptionsRoute
  AppSupportRoute: typeof AppSupportRoute
  AppSynthesisRoute: typeof AppSynthesisRoute
  AppProfileLazyRoute: typeof AppProfileLazyRoute
  AppSettingsLazyRoute: typeof AppSettingsLazyRoute
  AppFieldsFieldIdRoute: typeof AppFieldsFieldIdRouteWithChildren
  AppFieldsCreateLazyRoute: typeof AppFieldsCreateLazyRoute
  AppFieldsFieldIdCutRoute: typeof AppFieldsFieldIdCutRoute
  AppFieldsFieldIdEditRoute: typeof AppFieldsFieldIdEditRoute
  AppPrecifertNTypeResultsResultIdRoute: typeof AppPrecifertNTypeResultsResultIdRoute
  AppPrecifertNRapeseedResultsResultIdRoute: typeof AppPrecifertNRapeseedResultsResultIdRoute
  AppFieldsFieldIdMapsEditableMapTypeCreateRoute: typeof AppFieldsFieldIdMapsEditableMapTypeCreateRoute
  AppFieldsFieldIdMapsBiomassSettingsRoute: typeof AppFieldsFieldIdMapsBiomassSettingsRoute
  AppFieldsFieldIdMapsPrescriptionsCreateRoute: typeof AppFieldsFieldIdMapsPrescriptionsCreateRoute
  AppPrecifertNRapeseedResultsResultIdAdminRoute: typeof AppPrecifertNRapeseedResultsResultIdAdminRoute
  AppPrecifertNTypeResultsResultIdSettingsYieldIdRoute: typeof AppPrecifertNTypeResultsResultIdSettingsYieldIdRoute
}

const AppRouteChildren: AppRouteChildren = {
  AppAdminRoute: AppAdminRouteWithChildren,
  AppConnectivityRoute: AppConnectivityRoute,
  AppDashboardRoute: AppDashboardRoute,
  AppFieldsRoute: AppFieldsRoute,
  AppMapRoute: AppMapRoute,
  AppPrecifertNRoute: AppPrecifertNRoute,
  AppPrescriptionsRoute: AppPrescriptionsRoute,
  AppSupportRoute: AppSupportRoute,
  AppSynthesisRoute: AppSynthesisRoute,
  AppProfileLazyRoute: AppProfileLazyRoute,
  AppSettingsLazyRoute: AppSettingsLazyRoute,
  AppFieldsFieldIdRoute: AppFieldsFieldIdRouteWithChildren,
  AppFieldsCreateLazyRoute: AppFieldsCreateLazyRoute,
  AppFieldsFieldIdCutRoute: AppFieldsFieldIdCutRoute,
  AppFieldsFieldIdEditRoute: AppFieldsFieldIdEditRoute,
  AppPrecifertNTypeResultsResultIdRoute: AppPrecifertNTypeResultsResultIdRoute,
  AppPrecifertNRapeseedResultsResultIdRoute:
    AppPrecifertNRapeseedResultsResultIdRoute,
  AppFieldsFieldIdMapsEditableMapTypeCreateRoute:
    AppFieldsFieldIdMapsEditableMapTypeCreateRoute,
  AppFieldsFieldIdMapsBiomassSettingsRoute:
    AppFieldsFieldIdMapsBiomassSettingsRoute,
  AppFieldsFieldIdMapsPrescriptionsCreateRoute:
    AppFieldsFieldIdMapsPrescriptionsCreateRoute,
  AppPrecifertNRapeseedResultsResultIdAdminRoute:
    AppPrecifertNRapeseedResultsResultIdAdminRoute,
  AppPrecifertNTypeResultsResultIdSettingsYieldIdRoute:
    AppPrecifertNTypeResultsResultIdSettingsYieldIdRoute,
}

const AppRouteWithChildren = AppRoute._addFileChildren(AppRouteChildren)

interface AuthRouteChildren {
  AuthLoginRoute: typeof AuthLoginRoute
  AuthResetPasswordRoute: typeof AuthResetPasswordRoute
  AuthLostPasswordLazyRoute: typeof AuthLostPasswordLazyRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthLoginRoute: AuthLoginRoute,
  AuthResetPasswordRoute: AuthResetPasswordRoute,
  AuthLostPasswordLazyRoute: AuthLostPasswordLazyRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AppAdminRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/connectivity': typeof AppConnectivityRoute
  '/dashboard': typeof AppDashboardRoute
  '/fields': typeof AppFieldsRoute
  '/map': typeof AppMapRoute
  '/precifert-n': typeof AppPrecifertNRoute
  '/prescriptions': typeof AppPrescriptionsRoute
  '/support': typeof AppSupportRoute
  '/synthesis': typeof AppSynthesisRoute
  '/login': typeof AuthLoginRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/profile': typeof AppProfileLazyRoute
  '/settings': typeof AppSettingsLazyRoute
  '/lost-password': typeof AuthLostPasswordLazyRoute
  '/statistics': typeof AppAdminStatisticsRoute
  '/fields/$fieldId': typeof AppFieldsFieldIdRouteWithChildren
  '/accounts': typeof AppAdminAccountsLazyRoute
  '/users': typeof AppAdminUsersLazyRoute
  '/fields/create': typeof AppFieldsCreateLazyRoute
  '/fields/$fieldId/pk': typeof AppFieldsFieldIdPkRoute
  '/fields/$fieldId/cut': typeof AppFieldsFieldIdCutRoute
  '/fields/$fieldId/edit': typeof AppFieldsFieldIdEditRoute
  '/accounts/create': typeof AppAdminAccountsCreateLazyRoute
  '/users/create': typeof AppAdminUsersCreateLazyRoute
  '/fields/$fieldId/': typeof AppFieldsFieldIdIndexRoute
  '/fields/$fieldId/maps/$mapType': typeof AppFieldsFieldIdMapsMapTypeRoute
  '/precifert-n/$type/results/$resultId': typeof AppPrecifertNTypeResultsResultIdRoute
  '/precifert-n/rapeseed/results/$resultId': typeof AppPrecifertNRapeseedResultsResultIdRoute
  '/accounts/$accountId/edit': typeof AppAdminAccountsAccountIdEditLazyRoute
  '/users/$userId/edit': typeof AppAdminUsersUserIdEditLazyRoute
  '/fields/$fieldId/maps/$editableMapType/create': typeof AppFieldsFieldIdMapsEditableMapTypeCreateRoute
  '/fields/$fieldId/maps/biomass/settings': typeof AppFieldsFieldIdMapsBiomassSettingsRoute
  '/fields/$fieldId/maps/prescriptions/create': typeof AppFieldsFieldIdMapsPrescriptionsCreateRoute
  '/precifert-n/rapeseed/results/$resultId/admin': typeof AppPrecifertNRapeseedResultsResultIdAdminRoute
  '/precifert-n/$type/results/$resultId/settings/$yieldId': typeof AppPrecifertNTypeResultsResultIdSettingsYieldIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AppAdminRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/connectivity': typeof AppConnectivityRoute
  '/dashboard': typeof AppDashboardRoute
  '/fields': typeof AppFieldsRoute
  '/map': typeof AppMapRoute
  '/precifert-n': typeof AppPrecifertNRoute
  '/prescriptions': typeof AppPrescriptionsRoute
  '/support': typeof AppSupportRoute
  '/synthesis': typeof AppSynthesisRoute
  '/login': typeof AuthLoginRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/profile': typeof AppProfileLazyRoute
  '/settings': typeof AppSettingsLazyRoute
  '/lost-password': typeof AuthLostPasswordLazyRoute
  '/statistics': typeof AppAdminStatisticsRoute
  '/accounts': typeof AppAdminAccountsLazyRoute
  '/users': typeof AppAdminUsersLazyRoute
  '/fields/create': typeof AppFieldsCreateLazyRoute
  '/fields/$fieldId/pk': typeof AppFieldsFieldIdPkRoute
  '/fields/$fieldId/cut': typeof AppFieldsFieldIdCutRoute
  '/fields/$fieldId/edit': typeof AppFieldsFieldIdEditRoute
  '/accounts/create': typeof AppAdminAccountsCreateLazyRoute
  '/users/create': typeof AppAdminUsersCreateLazyRoute
  '/fields/$fieldId': typeof AppFieldsFieldIdIndexRoute
  '/fields/$fieldId/maps/$mapType': typeof AppFieldsFieldIdMapsMapTypeRoute
  '/precifert-n/$type/results/$resultId': typeof AppPrecifertNTypeResultsResultIdRoute
  '/precifert-n/rapeseed/results/$resultId': typeof AppPrecifertNRapeseedResultsResultIdRoute
  '/accounts/$accountId/edit': typeof AppAdminAccountsAccountIdEditLazyRoute
  '/users/$userId/edit': typeof AppAdminUsersUserIdEditLazyRoute
  '/fields/$fieldId/maps/$editableMapType/create': typeof AppFieldsFieldIdMapsEditableMapTypeCreateRoute
  '/fields/$fieldId/maps/biomass/settings': typeof AppFieldsFieldIdMapsBiomassSettingsRoute
  '/fields/$fieldId/maps/prescriptions/create': typeof AppFieldsFieldIdMapsPrescriptionsCreateRoute
  '/precifert-n/rapeseed/results/$resultId/admin': typeof AppPrecifertNRapeseedResultsResultIdAdminRoute
  '/precifert-n/$type/results/$resultId/settings/$yieldId': typeof AppPrecifertNTypeResultsResultIdSettingsYieldIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_app': typeof AppRouteWithChildren
  '/_auth': typeof AuthRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/_app/_admin': typeof AppAdminRouteWithChildren
  '/_app/connectivity': typeof AppConnectivityRoute
  '/_app/dashboard': typeof AppDashboardRoute
  '/_app/fields': typeof AppFieldsRoute
  '/_app/map': typeof AppMapRoute
  '/_app/precifert-n': typeof AppPrecifertNRoute
  '/_app/prescriptions': typeof AppPrescriptionsRoute
  '/_app/support': typeof AppSupportRoute
  '/_app/synthesis': typeof AppSynthesisRoute
  '/_auth/login': typeof AuthLoginRoute
  '/_auth/reset-password': typeof AuthResetPasswordRoute
  '/_app/profile': typeof AppProfileLazyRoute
  '/_app/settings': typeof AppSettingsLazyRoute
  '/_auth/lost-password': typeof AuthLostPasswordLazyRoute
  '/_app/_admin/statistics': typeof AppAdminStatisticsRoute
  '/_app/fields_/$fieldId': typeof AppFieldsFieldIdRouteWithChildren
  '/_app/_admin/accounts': typeof AppAdminAccountsLazyRoute
  '/_app/_admin/users': typeof AppAdminUsersLazyRoute
  '/_app/fields_/create': typeof AppFieldsCreateLazyRoute
  '/_app/fields_/$fieldId/pk': typeof AppFieldsFieldIdPkRoute
  '/_app/fields_/$fieldId_/cut': typeof AppFieldsFieldIdCutRoute
  '/_app/fields_/$fieldId_/edit': typeof AppFieldsFieldIdEditRoute
  '/_app/_admin/accounts_/create': typeof AppAdminAccountsCreateLazyRoute
  '/_app/_admin/users_/create': typeof AppAdminUsersCreateLazyRoute
  '/_app/fields_/$fieldId/': typeof AppFieldsFieldIdIndexRoute
  '/_app/fields_/$fieldId/maps/$mapType': typeof AppFieldsFieldIdMapsMapTypeRoute
  '/_app/precifert-n_/$type/results/$resultId': typeof AppPrecifertNTypeResultsResultIdRoute
  '/_app/precifert-n_/rapeseed/results/$resultId': typeof AppPrecifertNRapeseedResultsResultIdRoute
  '/_app/_admin/accounts_/$accountId/edit': typeof AppAdminAccountsAccountIdEditLazyRoute
  '/_app/_admin/users_/$userId/edit': typeof AppAdminUsersUserIdEditLazyRoute
  '/_app/fields_/$fieldId_/maps/$editableMapType/create': typeof AppFieldsFieldIdMapsEditableMapTypeCreateRoute
  '/_app/fields_/$fieldId_/maps/biomass_/settings': typeof AppFieldsFieldIdMapsBiomassSettingsRoute
  '/_app/fields_/$fieldId_/maps/prescriptions_/create': typeof AppFieldsFieldIdMapsPrescriptionsCreateRoute
  '/_app/precifert-n_/rapeseed/results/$resultId_/admin': typeof AppPrecifertNRapeseedResultsResultIdAdminRoute
  '/_app/precifert-n_/$type/results/$resultId_/settings/$yieldId': typeof AppPrecifertNTypeResultsResultIdSettingsYieldIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/about'
    | '/connectivity'
    | '/dashboard'
    | '/fields'
    | '/map'
    | '/precifert-n'
    | '/prescriptions'
    | '/support'
    | '/synthesis'
    | '/login'
    | '/reset-password'
    | '/profile'
    | '/settings'
    | '/lost-password'
    | '/statistics'
    | '/fields/$fieldId'
    | '/accounts'
    | '/users'
    | '/fields/create'
    | '/fields/$fieldId/pk'
    | '/fields/$fieldId/cut'
    | '/fields/$fieldId/edit'
    | '/accounts/create'
    | '/users/create'
    | '/fields/$fieldId/'
    | '/fields/$fieldId/maps/$mapType'
    | '/precifert-n/$type/results/$resultId'
    | '/precifert-n/rapeseed/results/$resultId'
    | '/accounts/$accountId/edit'
    | '/users/$userId/edit'
    | '/fields/$fieldId/maps/$editableMapType/create'
    | '/fields/$fieldId/maps/biomass/settings'
    | '/fields/$fieldId/maps/prescriptions/create'
    | '/precifert-n/rapeseed/results/$resultId/admin'
    | '/precifert-n/$type/results/$resultId/settings/$yieldId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/about'
    | '/connectivity'
    | '/dashboard'
    | '/fields'
    | '/map'
    | '/precifert-n'
    | '/prescriptions'
    | '/support'
    | '/synthesis'
    | '/login'
    | '/reset-password'
    | '/profile'
    | '/settings'
    | '/lost-password'
    | '/statistics'
    | '/accounts'
    | '/users'
    | '/fields/create'
    | '/fields/$fieldId/pk'
    | '/fields/$fieldId/cut'
    | '/fields/$fieldId/edit'
    | '/accounts/create'
    | '/users/create'
    | '/fields/$fieldId'
    | '/fields/$fieldId/maps/$mapType'
    | '/precifert-n/$type/results/$resultId'
    | '/precifert-n/rapeseed/results/$resultId'
    | '/accounts/$accountId/edit'
    | '/users/$userId/edit'
    | '/fields/$fieldId/maps/$editableMapType/create'
    | '/fields/$fieldId/maps/biomass/settings'
    | '/fields/$fieldId/maps/prescriptions/create'
    | '/precifert-n/rapeseed/results/$resultId/admin'
    | '/precifert-n/$type/results/$resultId/settings/$yieldId'
  id:
    | '__root__'
    | '/'
    | '/_app'
    | '/_auth'
    | '/about'
    | '/_app/_admin'
    | '/_app/connectivity'
    | '/_app/dashboard'
    | '/_app/fields'
    | '/_app/map'
    | '/_app/precifert-n'
    | '/_app/prescriptions'
    | '/_app/support'
    | '/_app/synthesis'
    | '/_auth/login'
    | '/_auth/reset-password'
    | '/_app/profile'
    | '/_app/settings'
    | '/_auth/lost-password'
    | '/_app/_admin/statistics'
    | '/_app/fields_/$fieldId'
    | '/_app/_admin/accounts'
    | '/_app/_admin/users'
    | '/_app/fields_/create'
    | '/_app/fields_/$fieldId/pk'
    | '/_app/fields_/$fieldId_/cut'
    | '/_app/fields_/$fieldId_/edit'
    | '/_app/_admin/accounts_/create'
    | '/_app/_admin/users_/create'
    | '/_app/fields_/$fieldId/'
    | '/_app/fields_/$fieldId/maps/$mapType'
    | '/_app/precifert-n_/$type/results/$resultId'
    | '/_app/precifert-n_/rapeseed/results/$resultId'
    | '/_app/_admin/accounts_/$accountId/edit'
    | '/_app/_admin/users_/$userId/edit'
    | '/_app/fields_/$fieldId_/maps/$editableMapType/create'
    | '/_app/fields_/$fieldId_/maps/biomass_/settings'
    | '/_app/fields_/$fieldId_/maps/prescriptions_/create'
    | '/_app/precifert-n_/rapeseed/results/$resultId_/admin'
    | '/_app/precifert-n_/$type/results/$resultId_/settings/$yieldId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppRoute: typeof AppRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
  AboutLazyRoute: typeof AboutLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppRoute: AppRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
  AboutLazyRoute: AboutLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_app",
        "/_auth",
        "/about"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_app": {
      "filePath": "_app.tsx",
      "children": [
        "/_app/_admin",
        "/_app/connectivity",
        "/_app/dashboard",
        "/_app/fields",
        "/_app/map",
        "/_app/precifert-n",
        "/_app/prescriptions",
        "/_app/support",
        "/_app/synthesis",
        "/_app/profile",
        "/_app/settings",
        "/_app/fields_/$fieldId",
        "/_app/fields_/create",
        "/_app/fields_/$fieldId_/cut",
        "/_app/fields_/$fieldId_/edit",
        "/_app/precifert-n_/$type/results/$resultId",
        "/_app/precifert-n_/rapeseed/results/$resultId",
        "/_app/fields_/$fieldId_/maps/$editableMapType/create",
        "/_app/fields_/$fieldId_/maps/biomass_/settings",
        "/_app/fields_/$fieldId_/maps/prescriptions_/create",
        "/_app/precifert-n_/rapeseed/results/$resultId_/admin",
        "/_app/precifert-n_/$type/results/$resultId_/settings/$yieldId"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/login",
        "/_auth/reset-password",
        "/_auth/lost-password"
      ]
    },
    "/about": {
      "filePath": "about.lazy.tsx"
    },
    "/_app/_admin": {
      "filePath": "_app/_admin.tsx",
      "parent": "/_app",
      "children": [
        "/_app/_admin/statistics",
        "/_app/_admin/accounts",
        "/_app/_admin/users",
        "/_app/_admin/accounts_/create",
        "/_app/_admin/users_/create",
        "/_app/_admin/accounts_/$accountId/edit",
        "/_app/_admin/users_/$userId/edit"
      ]
    },
    "/_app/connectivity": {
      "filePath": "_app/connectivity.tsx",
      "parent": "/_app"
    },
    "/_app/dashboard": {
      "filePath": "_app/dashboard.tsx",
      "parent": "/_app"
    },
    "/_app/fields": {
      "filePath": "_app/fields.tsx",
      "parent": "/_app"
    },
    "/_app/map": {
      "filePath": "_app/map.tsx",
      "parent": "/_app"
    },
    "/_app/precifert-n": {
      "filePath": "_app/precifert-n.tsx",
      "parent": "/_app"
    },
    "/_app/prescriptions": {
      "filePath": "_app/prescriptions.tsx",
      "parent": "/_app"
    },
    "/_app/support": {
      "filePath": "_app/support.tsx",
      "parent": "/_app"
    },
    "/_app/synthesis": {
      "filePath": "_app/synthesis.tsx",
      "parent": "/_app"
    },
    "/_auth/login": {
      "filePath": "_auth/login.tsx",
      "parent": "/_auth"
    },
    "/_auth/reset-password": {
      "filePath": "_auth/reset-password.tsx",
      "parent": "/_auth"
    },
    "/_app/profile": {
      "filePath": "_app/profile.lazy.tsx",
      "parent": "/_app"
    },
    "/_app/settings": {
      "filePath": "_app/settings.lazy.tsx",
      "parent": "/_app"
    },
    "/_auth/lost-password": {
      "filePath": "_auth/lost-password.lazy.tsx",
      "parent": "/_auth"
    },
    "/_app/_admin/statistics": {
      "filePath": "_app/_admin/statistics.tsx",
      "parent": "/_app/_admin"
    },
    "/_app/fields_/$fieldId": {
      "filePath": "_app/fields_/$fieldId.tsx",
      "parent": "/_app",
      "children": [
        "/_app/fields_/$fieldId/pk",
        "/_app/fields_/$fieldId/",
        "/_app/fields_/$fieldId/maps/$mapType"
      ]
    },
    "/_app/_admin/accounts": {
      "filePath": "_app/_admin/accounts.lazy.tsx",
      "parent": "/_app/_admin"
    },
    "/_app/_admin/users": {
      "filePath": "_app/_admin/users.lazy.tsx",
      "parent": "/_app/_admin"
    },
    "/_app/fields_/create": {
      "filePath": "_app/fields_/create.lazy.tsx",
      "parent": "/_app"
    },
    "/_app/fields_/$fieldId/pk": {
      "filePath": "_app/fields_/$fieldId/pk.tsx",
      "parent": "/_app/fields_/$fieldId"
    },
    "/_app/fields_/$fieldId_/cut": {
      "filePath": "_app/fields_/$fieldId_/cut.tsx",
      "parent": "/_app"
    },
    "/_app/fields_/$fieldId_/edit": {
      "filePath": "_app/fields_/$fieldId_/edit.tsx",
      "parent": "/_app"
    },
    "/_app/_admin/accounts_/create": {
      "filePath": "_app/_admin/accounts_/create.lazy.tsx",
      "parent": "/_app/_admin"
    },
    "/_app/_admin/users_/create": {
      "filePath": "_app/_admin/users_/create.lazy.tsx",
      "parent": "/_app/_admin"
    },
    "/_app/fields_/$fieldId/": {
      "filePath": "_app/fields_/$fieldId/index.tsx",
      "parent": "/_app/fields_/$fieldId"
    },
    "/_app/fields_/$fieldId/maps/$mapType": {
      "filePath": "_app/fields_/$fieldId/maps/$mapType.tsx",
      "parent": "/_app/fields_/$fieldId"
    },
    "/_app/precifert-n_/$type/results/$resultId": {
      "filePath": "_app/precifert-n_/$type.results.$resultId.tsx",
      "parent": "/_app"
    },
    "/_app/precifert-n_/rapeseed/results/$resultId": {
      "filePath": "_app/precifert-n_/rapeseed.results.$resultId.tsx",
      "parent": "/_app"
    },
    "/_app/_admin/accounts_/$accountId/edit": {
      "filePath": "_app/_admin/accounts_/$accountId.edit.lazy.tsx",
      "parent": "/_app/_admin"
    },
    "/_app/_admin/users_/$userId/edit": {
      "filePath": "_app/_admin/users_/$userId.edit.lazy.tsx",
      "parent": "/_app/_admin"
    },
    "/_app/fields_/$fieldId_/maps/$editableMapType/create": {
      "filePath": "_app/fields_/$fieldId_/maps/$editableMapType/create.tsx",
      "parent": "/_app"
    },
    "/_app/fields_/$fieldId_/maps/biomass_/settings": {
      "filePath": "_app/fields_/$fieldId_/maps/biomass_.settings.tsx",
      "parent": "/_app"
    },
    "/_app/fields_/$fieldId_/maps/prescriptions_/create": {
      "filePath": "_app/fields_/$fieldId_/maps/prescriptions_/create.tsx",
      "parent": "/_app"
    },
    "/_app/precifert-n_/rapeseed/results/$resultId_/admin": {
      "filePath": "_app/precifert-n_/rapeseed.results.$resultId_.admin.tsx",
      "parent": "/_app"
    },
    "/_app/precifert-n_/$type/results/$resultId_/settings/$yieldId": {
      "filePath": "_app/precifert-n_/$type.results.$resultId_.settings.$yieldId.tsx",
      "parent": "/_app"
    }
  }
}
ROUTE_MANIFEST_END */
