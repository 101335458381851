import { tokenKey } from './token-key';

export const getAccessToken = () => {
  const token = localStorage.getItem(tokenKey);

  try {
    return JSON.parse(token as string);
  } catch {
    return token;
  }
};

export default getAccessToken;
