import { queryOptions } from '@tanstack/react-query';

import { supportApi } from '../apis/support';

export const supportQueries = {
  tutorials: queryOptions({
    queryKey: ['support', 'tutorials'],
    queryFn: supportApi.getTutorials,
  }),
};
