import { createFormActions, createFormContext } from '@mantine/form';
import { useTranslation } from 'react-i18next';

import { UpdatePkAdviceDto } from '@fields/data-access';

interface PkFormValues {
  soil_type?: string;
  crop?: string;
  expected_yield?: number;
  withResidue?: boolean;
  residue: number | null;
  P2O5_impasse?: string;
  K2O_impasse?: string;

  organic?: boolean;
  product: string | null;
  product_qt: number | null;
  is_simplified: boolean;
  P2O5_value: number | null;
  K2O_value: number | null;
}

type TransformedValues = Omit<UpdatePkAdviceDto, 'field'>;

type TransformValues = (values: PkFormValues) => TransformedValues;

const [PkFormProvider, usePkFormContext, useForm] = createFormContext<
  PkFormValues,
  TransformValues
>();

export interface UsePkForm {
  FormValues: PkFormValues;
  TransformedValues: TransformedValues;
  TransformValues: TransformValues;
}

interface PkFormParams {
  initialValues?: PkFormValues;
}

export function usePkForm({ initialValues }: PkFormParams) {
  const { t } = useTranslation();
  return useForm({
    name: 'pk-form',
    mode: 'controlled',
    initialValues: {
      organic: false,
      is_simplified: false,
      residue: null,
      product: null,
      product_qt: null,
      P2O5_value: null,
      K2O_value: null,
      ...(initialValues ?? {}),
    },
    transformValues: (values) => {
      const { soil_type, crop, product, organic, withResidue, ...rest } =
        values;

      const nextValues: TransformedValues = {
        ...rest,
        product: product ? Number(product) : null,
      };

      if (soil_type) {
        nextValues.soil_type = Number(soil_type);
      }

      if (crop) {
        nextValues.crop = Number(crop);
      }

      return nextValues;
    },
  });
}

export const pkFormActions = createFormActions<PkFormValues>('pk-form');

export { PkFormProvider, usePkFormContext };
