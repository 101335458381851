import { List, ThemeIcon } from '@mantine/core';
import { IconCircleCheck, IconCircleDashed } from '@tabler/icons-react';

interface CheckListProps {
  items: {
    value?: string | number | boolean | null;
    label: string;
    unit?: string;
    optional?: boolean;
  }[];
  withIcon?: boolean;
}

const icons = {
  true: IconCircleCheck,
  false: IconCircleDashed,
};

export function CheckList({ items, withIcon = true }: CheckListProps) {
  return (
    <List spacing="xs" withPadding>
      {items.map((item) => {
        const Icon = icons[item.value ? 'true' : 'false'];
        return (
          <List.Item
            icon={
              withIcon ? (
                <ThemeIcon
                  color={
                    item.value ? 'pf-green' : item.optional ? 'yellow' : 'red'
                  }
                  radius="xl"
                  size="sm"
                >
                  <Icon style={{ width: '90%', height: '90%' }} />
                </ThemeIcon>
              ) : null
            }
            key={item.label}
            style={{
              listStyleType: 'none',
            }}
          >
            {item.label} : {item.value || '-'} {item.value ? item.unit : ''}
          </List.Item>
        );
      })}
    </List>
  );
}

export default CheckList;
