import { Button, Dialog, Overlay, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import { useRegisterSW } from 'virtual:pwa-register/react';

import classes from './reload-prompt.module.css';

export function ReloadPrompt() {
  const { t } = useTranslation();
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.log('SW Registered: ' + r);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });


  // const close = () => {
  //   setOfflineReady(false);
  //   setNeedRefresh(false);
  // };

  return (
    <div className={classes.container}>
      {/* Conditionally render the Overlay only when needRefresh is true */}
      {(needRefresh) && (
        <Overlay backgroundOpacity={0.75} color="#000" />
      )}
      
      {(needRefresh) && (
        <Dialog
          opened
          radius="xs"
          size="xxl"
        >
          <Text fw={500} mb="xxl">
            {t('pwa.need_refresh')}
          </Text>
          <Group justify="end" mt="xl">
            <Button onClick={() => updateServiceWorker(true)}>
              {t('pwa.reload')}
            </Button>
            {/* <Button onClick={() => close()} variant="subtle">
              Fermer
            </Button> */}
          </Group>
        </Dialog>
      )}
    </div>
  );
}

export default ReloadPrompt;
