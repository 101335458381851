import {Text} from '@mantine/core';
import { DeepKeys } from '@tanstack/table-core';
import dayjs from 'dayjs';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';


import { AdviceStatusCell } from '../advice-status-cell/advice-status-cell';
import { YieldCell } from '../common-advice-table/yield-cell';
import { StatusColumnFilter } from '../status-column-filter/status-column-filter';

import { FieldNameCell } from './field-name-cell';


import {
  CommonAdvice,
  FORM_STATUS,
  useValues,
  Wheat2Advice,
} from '@advices/data-access';
import { asCbData, CropEnum, fromBacki18n } from '@data-access';

// eslint-disable-next-line @typescript-eslint/ban-types
type accessorKey = DeepKeys<CommonAdvice> | (string & {});
type ColVisibilityState = Partial<Record<accessorKey, boolean>>;

export const initialColState: ColVisibilityState = {
  'field.thumbnail': true,
  'field.name': true,
  'field.farm.name': true,
  nitrogen_to_apply: true,
  product: true,
  repartition_method: true,
  date : true,
  status: true,
};

export const useTable = () => {
  const { t, i18n } = useTranslation();
  const values = useValues({ type: CropEnum.WHEAT2 });

  const columns = useMemo<MRT_ColumnDef<Wheat2Advice>[]>(
    () => [
      {
        accessorKey: 'field.thumbnail',
        enableColumnFilter: false,
        header: '',
        enableSorting: false,
        enableColumnActions: false,
        size: 32,
        Cell: ({ cell, row }) => {
          const thumb = cell.getValue<string>();
          if (thumb) {
            return <img alt="thumbnail" src={thumb} width="32" />;
          }
        },
      },
      {
        accessorKey: 'field.name',
        header: t('table.columns.field-name'),
        Cell: ({ cell, row }) => {
          const value = cell.getValue<string>();

          return (
            <FieldNameCell
              fieldId={row.original.field.id}
              mapId={row.original.mapId}
              rowId={row.id}
              value={value}
            />
          );
        },
      },
      {
        accessorKey: 'field.farm.name',
        header: t('table.columns.farm-name'),
        minSize: 250,
      },
      {
        accessorKey: 'nitrogen_to_apply',
        Cell: ({ cell }) => `${cell.getValue() || '-'} u/ha`,
        header: "Dose d'azote",
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'product',
        accessorFn: (row) => fromBacki18n(row.product, i18n.language),
        header: t('table.columns.product'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: asCbData(values.data?.product),
        },
      },
      {
        accessorKey: 'repartition_method',
        header: 'Stratégie de répartition',
        filterVariant: 'select',
        Cell: ({ cell }) => t(`common.repartition_method.${cell.getValue()}`),
        mantineFilterSelectProps: {
          data: values.data?.repartition_method,
        },
      },
      // {
      //   accessorKey: 'date',
      //   header: t('table.columns.date'),
      //   Cell: ({ cell }) => {
      //     const date = cell.getValue<string>(); 
      //     return <Text>{date ? dayjs(date).format('DD/MM/YYYY') : ''}</Text>;
      //   },
      //   filterVariant: 'date-range',
      // },
      {
        accessorKey: 'status',
        header: t('table.columns.status'),
        maxSize: 100,
        Cell: ({ cell }) => {
          const status = cell.getValue<FORM_STATUS>();

          return <AdviceStatusCell status={status} context="wheat2"/>;
        },
        Filter: ({ column, table }) => {
          return (
            <StatusColumnFilter
              onChange={(value) => column.setFilterValue(value)}
              onClear={() => column.setFilterValue(null)}
              value={column.getFilterValue() as FORM_STATUS}
            />
          );
        },
      },
      {
        accessorKey: 'variation',
        header: t('common.properties.variation'),
        Cell: ({ cell, row }) => {
          const value = cell.getValue<number>();

          return (
            <YieldCell
              mean_dose={value}
              mean_yield={false}
              status={row.original.computation_status}
            />
          );
        },
      }
    ],
    [t, values.data, i18n.language],
  );

  return { columns };
};
