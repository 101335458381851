import { Text, ThemeIcon } from '@mantine/core';
import { IconCloudFilled } from '@tabler/icons-react';
import { memo } from 'react';

import { MAP_TYPES } from '@fields/data-access';
import { TsrButtonLink } from '@ui';

interface FieldNameCellProps {
  rowId: string;
  value: string;
  fieldId: number;
  mapId?: number;
}

export const FieldNameCell = memo(
  ({ rowId, value, fieldId, mapId }: FieldNameCellProps) => {
    return (
      <TsrButtonLink
        disabled={!mapId}
        fullWidth
        justify="start"
        maw={250}
        ml="calc(var(--button-padding-x-compact-md) * -1)"
        params={{
          mapType: MAP_TYPES.PRESCRIPTIONS,
          fieldId: fieldId.toString(),
        }}
        search={{ mapId: Number(mapId) }}
        size="compact-md"
        td="underline"
        to="/fields/$fieldId/maps/$mapType"
        tt="capitalize"
        variant="subtle"
      >
        <Text span truncate="end">
          {value}
        </Text>
      </TsrButtonLink>
    );
  },
);
