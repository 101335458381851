import { ActionIcon, Box, LoadingOverlay } from '@mantine/core';
import { DateFormatter, DateValue, YearPickerInput } from '@mantine/dates';
import { usePrevious } from '@mantine/hooks';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useRouter } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useAtom } from 'jotai/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './campaign-select.module.css';

import { Campaign, campaignAtom, useCampaigns } from '@data-access';

export const campaignSelectGetYearControlProps = (
  date: Date,
  campaigns: Campaign[] = [],
) => {
  const campaignsYears = campaigns.map((campaign) => campaign.year);
  if (!campaignsYears?.includes(date.getFullYear())) {
    return { disabled: true };
  }

  return {};
};

export const CampaignSelect = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [selectedCampaign, setSelectedCampaign] = useState<{
    id: number;
    year: number;
  }>();
  const [campaign, setCampaign] = useAtom(campaignAtom);
  const previousCampaign = usePrevious(campaign);

  const value = useMemo(() => {
    return selectedCampaign
      ? dayjs().set('year', selectedCampaign.year).toDate()
      : new Date();
  }, [selectedCampaign]);

  const campaigns = useCampaigns();

  const formatter: DateFormatter = ({ type, date, locale, format }) => {
    if (type === 'default') {
      return `${t('common.campaign').toUpperCase()} ${dayjs(date as Date)
        .locale(locale)
        .format(format)}`;
    }

    return '';
  };

  const handleOnChange = (val: DateValue) => {
    if (!val) {
      return;
    }
    const nextCampaign = campaigns.data?.find(
      (c) => c.year === val.getFullYear(),
    );
    if (nextCampaign && nextCampaign.id !== selectedCampaign?.id) {
      setSelectedCampaign(
        campaigns.data?.find((c) => c.year === val.getFullYear()),
      );
      router.invalidate();

      router.navigate({
        to: '/fields',
      });
    }
  };

  const handlePreviousNext = (next: boolean) => {
    if (!campaigns.data) {
      return;
    }
    // get the index of the selected campaign
    const index = campaigns.data?.findIndex(
      (c) => c.year === selectedCampaign?.year,
    );

    // roll in the campaigns array
    let newIndex;
    if (next) {
      newIndex = index < campaigns.data.length - 1 ? index + 1 : 0;
    } else {
      newIndex = index > 0 ? index - 1 : campaigns.data.length - 1;
    }

    setSelectedCampaign(campaigns.data[newIndex]);
    router.invalidate();

    router.navigate({
      to: '/fields',
    });
  };

  // useeffect that init the selected campaign
  useEffect(() => {
    if (campaigns.data) {
      if (!selectedCampaign) {
        setSelectedCampaign(
          campaigns.data.find((c) => c.id === campaign) ||
            campaigns.data.find((c) => c.year === new Date().getFullYear()) ||
            campaigns.data[0],
        );
      }
    }
  }, [campaigns.data, campaign, selectedCampaign]);

  // useeffect that sets the campaign on selected campaign change
  useEffect(() => {
    const campaignChanged = campaign !== previousCampaign;
    const campaignsSynched = selectedCampaign?.id === campaign;

    // Change campaign if the selected campaign is not synched with the campaign atom
    // and the campaign has not changed.
    // This first check means the user has changed the campaign from the select
    // If the campaign has changed, the user may have changed it from another tab
    // and atomStorage has changed it's value from the localstorage subscription
    // this check prevents an infinite loop
    if (selectedCampaign && !campaignsSynched && !campaignChanged) {
      setCampaign(selectedCampaign.id);
    } else if (!campaignsSynched && campaignChanged && campaigns.data) {
      // Here we set the selected campaign to the campaign atom value
      // to keep the ui select up to date between tabs
      setSelectedCampaign(
        campaigns.data?.find((c) => c.id === campaign) ||
          campaigns.data?.find((c) => c.year === new Date().getFullYear()) ||
          campaigns.data[0],
      );
    }
  }, [
    campaign,
    campaigns.data,
    previousCampaign,
    selectedCampaign,
    setCampaign,
  ]);

  return (
    <Box pos="relative">
      <LoadingOverlay
        loaderProps={{ size: 'sm' }}
        overlayProps={{
          radius: 'xs',
        }}
        visible={campaigns.isLoading}
      />
      <YearPickerInput
        classNames={{
          input: styles.input,
          section: styles.section,
        }}
        clearable={false}
        getYearControlProps={(date) =>
          campaignSelectGetYearControlProps(date, campaigns.data)
        }
        leftSection={
          <ActionIcon
            onClick={() => handlePreviousNext(false)}
            variant="transparent"
          >
            <IconChevronLeft color="white" />
          </ActionIcon>
        }
        onChange={handleOnChange}
        rightSection={
          <ActionIcon
            onClick={() => handlePreviousNext(true)}
            variant="transparent"
          >
            <IconChevronRight color="white" />
          </ActionIcon>
        }
        value={value}
        valueFormatter={formatter}
      />
    </Box>
  );
};

export default CampaignSelect;
