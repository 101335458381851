import { Button, ButtonProps } from '@mantine/core';
import { createLink } from '@tanstack/react-router';
import { forwardRef } from 'react';

const LinkButton = forwardRef<HTMLAnchorElement, ButtonProps>((props, ref) => (
  <Button component="a" ref={ref} {...props} />
));

export const TsrButtonLink = createLink(LinkButton);

export default TsrButtonLink;
