import { lighten, Paper, Text } from '@mantine/core';
import { useMemo } from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
  Bar,
  ZAxis,
  XAxis,
  YAxis,
  RectangleProps,
  Rectangle,
  Tooltip,
} from 'recharts';

import { BoxPlotData } from '../../data-access/types/stats';

import { FORM_STATUS } from '@advices/data-access';

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    const { min, max, upperQuartile, lowerQuartile, median, mean } = payload[0].payload;
    return (
      <Paper>
        <Text fw="bold">{label}</Text>
        <Text>Min: {min?.toFixed(2)}</Text>
        <Text>1er quartile: {lowerQuartile?.toFixed(2)}</Text>
        <Text>Médiane: {median?.toFixed(2)}</Text>
        <Text>Moyenne: {mean?.toFixed(2)}</Text>
        <Text>2ème quartile: {upperQuartile?.toFixed(2)}</Text>
        <Text>Max: {max?.toFixed(2)}</Text>
      </Paper>
    );
  }

  return null;
};

const HorizonBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  const barWidth = width * 0.3;

  return (
    <line
      stroke={props.stroke}
      strokeWidth={3}
      x1={x + (width - barWidth) / 2}
      x2={x + (width + barWidth) / 2}
      y1={y}
      y2={y}
    />
  );
};

const VerticalBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (x == null || y == null || width == null || height == null) {
    return null;
  }

  return (
    <line
      stroke={props.stroke}
      strokeWidth={3}
      x1={x + width / 2}
      x2={x + width / 2}
      y1={y + height}
      y2={y}
    />
  );
};

interface BoxPlotChartProps {
  boxPlots: BoxPlotData[];
  fillColor?: string;
  strokeColor?: string;
  containerStyle?: React.CSSProperties;
  domain: [number, number];
}

export function BoxPlotChart({
  boxPlots,
  strokeColor = '#E2C949',
  fillColor = lighten(strokeColor, 0.3),
  containerStyle = {},
  domain,
}: BoxPlotChartProps) {
  const data = useMemo(
    () =>
      boxPlots.map((v) => {
        return {
          ...v,
          min: v.min,
          bottomWhisker: v.lowerQuartile - v.min,
          bottomBox: v.median - v.lowerQuartile,
          topBox: v.upperQuartile - v.median,
          topWhisker: v.max - v.upperQuartile,
          median: v.median,
          size: 250,
        };
      }),
    [boxPlots],
  );

  const IsComplete = boxPlots.some(
    (plot) => plot.status === FORM_STATUS.FINISHED,
  );

  const range = domain[1] - domain[0];
  const tickInterval = range <= 10 ? 1 : range <= 50 ? 5 : 8;

  const yTicks = Array.from(
    { length: Math.floor(range / tickInterval) + 1 },
    (_, i) => domain[0] + i * tickInterval
  );

  return (
    <ResponsiveContainer
      minHeight={300}
      style={{
        ...containerStyle,
        opacity: IsComplete ? 1 : 0.2,
      }}
    >
      <ComposedChart data={data}>
        <CartesianGrid  horizontal={true} strokeDasharray="3 3" vertical={false} />
        <Bar dataKey="min" fill="none" stackId="a" />
        <Bar
          dataKey="bar-min"
          shape={<HorizonBar stroke={strokeColor} />}
          stackId="a"
        />
        <Bar
          dataKey="bottomWhisker"
          shape={<VerticalBar stroke={strokeColor} />}
          stackId="a"
        />
        <Bar
          dataKey="bottomBox"
          fill={fillColor}
          shape={<Rectangle stroke={strokeColor} strokeWidth={3} />}
          stackId="a"
        />
        {/* <Bar
          dataKey="bar-avg"
          shape={<HorizonBar stroke={'strokeColor'} />}
          stackId="a"
        /> */}
        <Bar
          dataKey="topBox"
          fill={fillColor}
          shape={<Rectangle stroke={strokeColor} strokeWidth={3} />}
          stackId="a"
        />
        <Bar
          dataKey="topWhisker"
          shape={<VerticalBar stroke={strokeColor} />}
          stackId="a"
        />
        <Bar
          dataKey="bar-max"
          shape={<HorizonBar stroke={strokeColor} />}
          stackId="a"
        />

        <Tooltip content={<CustomTooltip />} cursor={false} />
        <ZAxis dataKey="size" range={[0, 250]} type="number" />
        <XAxis axisLine={false} dataKey="name" tickLine={false} />
        <YAxis
          allowDataOverflow
          axisLine={true}
          domain={domain}
          tickLine={true}
          ticks={yTicks} // Add the ticks prop here
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default BoxPlotChart;
