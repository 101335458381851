import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useFiltersStore, useQueriesWithGlobal } from '@data-access';
import { prescriptionsQueries } from '@fields/data-access';

export function usePrescriptions() {
  const page = useFiltersStore.use['prescriptions']().pagination.pageIndex + 1;
  const sorting = useFiltersStore.use['prescriptions']().sorting;
  const filters = useFiltersStore.use['prescriptions']().filters;
  const queries = useQueriesWithGlobal(prescriptionsQueries);

  const query = useQuery({
    ...queries.list({ page, sorting, filters }),
    placeholderData: keepPreviousData,
  });

  return query;
}

export default usePrescriptions;
