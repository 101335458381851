import { Layer, Source } from 'react-map-gl';

export type SatzonesFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.MultiPolygon,
  Record<string, any>
>;

/* eslint-disable-next-line */
export interface ZoningLayerProps {
  data: SatzonesFeatureCollection;
  id?: string;
  filled?: boolean;
  outline?: boolean;
}

const polygonOutlineLayerStyle = {
  type: 'line' as const,
  paint: {
    'line-color': '#000',
    'line-width': 3,
  },
};

export function ZoningLayer({
  data,
  id,
  filled = false,
  outline = true,
}: ZoningLayerProps) {
  return (
    <Source data={data} id="zoning-data" type="geojson">
      <Layer
        id={id}
        paint={{
          'fill-color': ['get', 'color'],
          'fill-opacity': filled ? 1 : 0.5,
        }}
        type="fill"
      />
      {outline ? (
        <Layer
          id={id ? `${id}_outline` : undefined}
          {...polygonOutlineLayerStyle}
        />
      ) : null}
    </Source>
  );
}

export default ZoningLayer;
