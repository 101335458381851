import { Account } from '../types/account';
import { CreateAccountDto, UpdateAccountDto } from '../types/dto';
import { ListAccountsResponse } from '../types/list-accounts';
import { ValuesResponse } from '../types/values';

import {
  formatFilters,
  Filters,
  privateClient,
  handleRequest,
  MultisectActionDto,
} from '@data-access';

type UpdateOneArgs = {
  id: number;
  data: UpdateAccountDto;
};

export const accountsApi = {
  getAll: (filters: Filters) => {
    return privateClient
      .get('accounts/', {
        searchParams: {
          ...formatFilters(filters),
        },
      })
      .json<ListAccountsResponse>();
  },
  getOneById: (id: number) => {
    return privateClient.get(`accounts/${id}/`).json<Account>();
  },
  deleteOne: (id: number) => {
    return privateClient.delete(`accounts/${id}/`).json();
  },
  deleteMany: (data: MultisectActionDto) => {
    return privateClient.delete(`accounts/bulk_delete/`, {
      json: data,
    });
  },
  createOne: (account: CreateAccountDto) => {
    return handleRequest<Account>(
      privateClient
        .post(`accounts/`, {
          json: account,
        })
        .json(),
    );
  },
  updateOne: ({ id, data }: UpdateOneArgs) => {
    return handleRequest<Account>(
      privateClient
        .patch(`accounts/${id}/`, {
          json: data,
        })
        .json(),
    );
  },

  getValues: async () => {
    const res = await privateClient
      .get(`accounts/values/`)
      .json<ValuesResponse>();

    const organization = res.organization?.map(({ id, name }) => ({
      value: id.toString(),
      label: name,
    }));

    const module = res.module?.map(({ id, name }) => ({
      value: id.toString(),
      label: name,
    }));

    return {
      ...res,
      organization,
      module,
    };
  },
};
