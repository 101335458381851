export const data = [
  {
    id: '1',
    name: 'Farm 1',
    farm: 'Farm 1',
    crop: 'Wheat',
    area: 100,
    yield: 1000,
  },
  {
    id: '2',
    name: 'Farm 2',
    farm: 'Farm 2',
    crop: 'Barley',
    area: 200,
    yield: 2000,
  },
  {
    id: '3',
    name: 'Farm 3',
    farm: 'Farm 3',
    crop: 'Wheat',
    area: 300,
    yield: 3000,
  },
  {
    id: '4',
    name: 'Farm 4',
    farm: 'Farm 4',
    crop: 'Barley',
    area: 400,
    yield: 4000,
  },
  {
    id: '5',
    name: 'Farm 5',
    farm: 'Farm 5',
    crop: 'Wheat',
    area: 500,
    yield: 5000,
  },
];

export const getData = async () => {
  // fake promise
  const res: { data: typeof data } = await new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: data,
      });
    }, 1000);
  });
  return res;
};
