import { ThemeIcon, Tooltip } from '@mantine/core';
import { IconCalendarPause, IconCheck, IconX } from '@tabler/icons-react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './advice-status-cell.module.css';

import { FORM_STATUS } from '@advices/data-access';

export const STYLES = {
  [FORM_STATUS.FINISHED]: {
    icon: IconCheck,
    color: 'pf-green',
  },
  [FORM_STATUS.INCOMPLETE]: {
    icon: IconX,
    color: 'red',
  },
  [FORM_STATUS.WAITING]: {
    icon: IconCalendarPause,
    color: 'gray.6',
  },
  [FORM_STATUS.NO_MODULATION]: {
    icon: IconCheck,
    color: 'gray.6',
  },
};

export interface AdviceStatusCellProps {
  status: FORM_STATUS;
  context?: string;
  onClick?: () => void;
}

export const AdviceStatusCell = forwardRef<any, AdviceStatusCellProps>(
  (props, ref) => {
    const { t } = useTranslation();
    const { context } = props;

    if (
      props.status === FORM_STATUS.PENDING ||
      props.status === FORM_STATUS.ERROR
    ) {
      return 'not implemented';
    }

    const Icon = STYLES[props.status].icon;
    const color = STYLES[props.status].color;

    // Determine the tooltip label based on the status
    let tooltipLabel = '';
    if (props.status === FORM_STATUS.NO_MODULATION) {
      tooltipLabel = t('advices.no_modulation_tooltip');
    } else if (props.status === FORM_STATUS.WAITING) {
      if (context === 'rapeseed') {
        tooltipLabel = t('advices.waiting_tooltip_rapeseed');
      } else if (context === 'wheat2') {
        tooltipLabel = t('advices.waiting_tooltip_wheat2');
      } else {
        tooltipLabel = t('advices.waiting_tooltip');
      }
    }


    return (
      <Tooltip
        disabled={
          props.status !== FORM_STATUS.WAITING &&
          props.status !== FORM_STATUS.NO_MODULATION
        }
        label={tooltipLabel}
        maw={250}
        multiline
      >
        <ThemeIcon
          className={classes.button}
          color={color}
          onClick={props.onClick}
          radius="md"
          ref={ref}
          size="md"
          variant="filled"
        >
          <Icon stroke={3.5} style={{ width: '80%', height: '80%' }} />
        </ThemeIcon>
      </Tooltip>
    );
  },
);

export default AdviceStatusCell;
