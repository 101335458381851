import { createFormContext } from '@mantine/form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { CreateCommonAdviceDto } from '@advices/data-access';

export interface CommonAdviceFormValues {
  field: number[];
  variety?: string;
  seed_date?: Date;
  yield_potential?: string;
  olympic_yield?: number;
  X_dose?: number;
  nitrogen_already_applied?: number;
  product?: string;
  
  is_hidden?: boolean;
  disable_mail?: boolean;
  restart?: boolean;
  data_source?: string;
}

export type CommonAdviceFormTransformedValues = Omit<
  CreateCommonAdviceDto,
  'crop'
>;

type TransformValues = (
  values: CommonAdviceFormValues
) => CommonAdviceFormTransformedValues;

const [CommonAdviceFormProvider, useCommonAdviceFormContext, useForm] =
  createFormContext<CommonAdviceFormValues, TransformValues>();

export function useCommonAdviceForm() {
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      field: [],
    },
    validate: {
      field: (value) => {
        if (value.length === 0) {
          return t('form.required');
        }
      },
    },
    transformValues: (values) => {
      const { field, seed_date, variety, product, ...rest } =
        values;

      const transformedValues: CommonAdviceFormTransformedValues = {
        field: field.length === 1 ? field[0] : field,
        ...rest,
      };

      if (seed_date) {
        transformedValues.seed_date = dayjs(seed_date).format('YYYY-MM-DD');
      }

      if (variety) {
        transformedValues.variety = Number(variety);
      }

      if (product) {
        transformedValues.product = Number(product);
      }

      return transformedValues;
    },
  });

  return form;
}

export { CommonAdviceFormProvider, useCommonAdviceFormContext };
