import {
  Paper,
  SimpleGrid,
  Box,
  Title,
  List,
  Button,
  Table,
  Group,
  ThemeIcon,
} from '@mantine/core';
import { IconChartBar, IconCheckupList } from '@tabler/icons-react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import classes from './pk-data-card.module.css';

import { fromBacki18n, useQueriesWithGlobal } from '@data-access';
import { fieldsQueries } from '@fields/data-access';
import { ManureIcon, SkipIcon } from '@ui';

interface PkDataCardProps {
  fieldId: number;
  onEdit: () => void;
}

export function PkDataCard({ onEdit, fieldId }: PkDataCardProps) {
  const { t, i18n } = useTranslation();
  const queries = useQueriesWithGlobal(fieldsQueries);
  const advice = useSuspenseQuery(queries.pkAdvice(Number(fieldId)));
  const pkValues = useQuery(queries.pkValues(fieldId));

  const {
    soil_type,
    crop,
    expected_yield,
    residue,
    product,
    product_qt,
    K2O_impasse,
    P2O5_impasse,
    is_simplified,
    K2O_value,
    P2O5_value,
    P2O5_export,
    P2O5_mean,
    P2O5_residue,
    K2O_export,
    K2O_mean,
    K2O_residue,
  } = advice.data;

  return (
    <>
      <Paper>
        <SimpleGrid cols={4}>
          <Box>
            <Group wrap="nowrap">
              <ThemeIcon color="dark" variant="transparent">
                <IconCheckupList />
              </ThemeIcon>
              <Title lineClamp={1} order={3}>
                {t('advices.agronomic_data')}
              </Title>
            </Group>
            <List>
              <List.Item>
                {t('common.properties.soil_type_list_label')}{' '}
                {soil_type?.name ?? '-'}
              </List.Item>
              <List.Item>
                {t('common.properties.crop_list_label')}{' '}
                {fromBacki18n(crop ?? null, i18n.language, '-')}
              </List.Item>
              <List.Item>
                {t('common.properties.expected_yield_list_label')}{' '}
                {expected_yield ?? '-'} {crop?.unit || '-'}/ha
              </List.Item>
              <List.Item>
                {t('common.properties.residue_list_label')}{' '}
                {residue ? t('common.yes') : t('common.no')}
              </List.Item>
            </List>
          </Box>
          <Box>
            <Group wrap="nowrap">
              <ThemeIcon variant="transparent">
                <SkipIcon />
              </ThemeIcon>
              <Title lineClamp={1} order={3}>
                {t('advice.impasse')}
              </Title>
            </Group>
            <List>
              <List.Item>
                P205 :{' '}
                {
                  pkValues.data?.P2O5_impasse.find(
                    (i) => i.value === P2O5_impasse?.toString(),
                  )?.label
                }
              </List.Item>
              <List.Item>
                K2O :{' '}
                {
                  pkValues.data?.K2O_impasse.find(
                    (i) => i.value === K2O_impasse?.toString(),
                  )?.label
                }
              </List.Item>
            </List>
          </Box>
          <Box>
            <Group wrap="nowrap">
              <ThemeIcon variant="transparent">
                <ManureIcon />
              </ThemeIcon>
              <Title lineClamp={1} order={3}>
                {t('advice.organic_fert')}
              </Title>
            </Group>
            <List>
              <List.Item>
                {t('common.properties.product_list_label')}{' '}
                {fromBacki18n(product ?? null, i18n.language, '-')}
              </List.Item>
              <List.Item>
                {t('common.properties.product_qt_list_label')}{' '}
                {product_qt ?? '-'} {product?.unit}
              </List.Item>
            </List>
          </Box>
          {is_simplified ? (
            <Box>
              <Group wrap="nowrap">
                <ThemeIcon color="dark" variant="transparent">
                  <IconChartBar style={{ transform: 'rotate(90deg)' }} />
                </ThemeIcon>
                <Title lineClamp={1} order={3}>
                  {t('advice.teneur_P2O5_K2O')}
                </Title>
              </Group>
              <List>
                <List.Item>
                  {t('common.properties.P2O5_list_label')} {P2O5_value} ppm
                </List.Item>
                <List.Item>
                  {t('common.properties.K2O_list_label')} {K2O_value} ppm
                </List.Item>
              </List>
            </Box>
          ) : null}
        </SimpleGrid>
        <Button onClick={onEdit} style={{ float: 'right' }}>
          {t('common.edit_data')}
        </Button>
      </Paper>
      <Paper>
        <Title order={3}>{t('advices.pk.data_card.export_title')}</Title>
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th />
              <Table.Th>{t('advices.pk.data_card.primary_export_th')}</Table.Th>
              <Table.Th>
                {t('advices.pk.data_card.secondary_export_th')}
              </Table.Th>
              <Table.Th>{t('advices.pk.data_card.needs_th')}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>P</Table.Td>
              <Table.Td>{P2O5_export?.toFixed(2) ?? '-'} U/ha</Table.Td>
              <Table.Td>{P2O5_residue?.toFixed(2) ?? '-'} U/ha</Table.Td>
              <Table.Td>{P2O5_mean?.toFixed(2) ?? '-'} U/ha</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td>K</Table.Td>
              <Table.Td>{K2O_export?.toFixed(2) ?? '-'} U/ha</Table.Td>
              <Table.Td>{K2O_residue?.toFixed(2) ?? '-'} U/ha</Table.Td>
              <Table.Td>{K2O_mean?.toFixed(2) ?? '-'} U/ha</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Paper>
    </>
  );
}

export default PkDataCard;
