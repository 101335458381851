import { Box, Button, Paper, Text, Title } from '@mantine/core';

import classes from './tutorial-card.module.css';

interface TutorialCardProps {
  name: string;
  description: string;
  tutorial: string;
}

export function TutorialCard({
  name,
  description,
  tutorial,
}: TutorialCardProps) {
  return (
    <Paper className={classes.container}>
      <Box>
        <Title order={3}>{name}</Title>
        <Text className={classes.description}>{description}</Text>
      </Box>
      <Button
        className={classes.button}
        component="a"
        href={tutorial}
        target="_blank"
      >
        Accéder au tutoriel
      </Button>
    </Paper>
  );
}

export default TutorialCard;
