import { Text, deepMerge } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useMutationState } from '@tanstack/react-query';
import { VisibilityState } from '@tanstack/table-core';
import {
  MRT_Row,
  MRT_TableOptions,
  MRT_RowSelectionState,
} from 'mantine-react-table';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ResultsDataColumnControl from '../results-data-column-control/results-data-column-control';

import { useTable, resultsColState, dataColState } from './use-table';

import {
  RapeseedListAdvice,
  useDeleteAdvice,
  useRapeseedAdvices,
} from '@advices/data-access';
import { CropEnum, useFiltersTableOptions } from '@data-access';
import { PfTable, useRowSelection } from '@ui';

export interface RapeseedAdviceTableProps {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: any;
  onEdit: (id: number) => void;
  needValidation?: boolean;
}

export function RapeseedAdviceTable({
  rowSelection,
  setRowSelection,
  onEdit,
  needValidation,
}: RapeseedAdviceTableProps) {
  const { t } = useTranslation();
  const filtersTableOptions =
    useFiltersTableOptions<RapeseedListAdvice>('advices');

  const advices = useRapeseedAdvices();

  const deleteAdvice = useDeleteAdvice();
  const { columns } = useTable();

  const mutations = useMutationState();
  const lastMutation = mutations[mutations.length - 1];

  const rowSelectionProps = useRowSelection<RapeseedListAdvice>({
    data: advices.data?.results || [],
    rowSelection,
    setRowSelection,
  });

  const tableOptions: MRT_TableOptions<RapeseedListAdvice> = deepMerge<
    MRT_TableOptions<RapeseedListAdvice>
  >(
    {
      data: advices.data?.results || [],
      columns,
      rowCount: advices.data?.count || 0,

      enableFilters: !needValidation,
      enableColumnFilters: !needValidation,

      initialState: {
        columnVisibility: dataColState as VisibilityState,
        showColumnFilters: true,
      },
      state: {
        rowSelection,
        showProgressBars: lastMutation?.status === 'pending',
        // showProgressBars:
        //   updateManyFields.isPending ||
        //   fields.isRefetching ||
        //   deleteField.isPending,
      },
      onRowSelectionChange: setRowSelection,

      mantinePaperProps: {
        h: '100%',
      },

      ...rowSelectionProps,

      renderTopToolbarCustomActions: ({ table }) => (
        <ResultsDataColumnControl
          dataColState={dataColState as VisibilityState}
          needValidation={needValidation}
          resultsColState={resultsColState as VisibilityState}
          table={table as any}
        />
      ),
    },
    filtersTableOptions,
  );

  const handleDelete = useCallback(
    (row: MRT_Row<RapeseedListAdvice>) => {
      modals.openConfirmModal({
        title: t('advices.delete.title'),

        children: (
          <Text>
            {t('advices.delete.content1')}{' '}
            <Text fw={700} size="lg" span>
              {row.original.field.name}
            </Text>{' '}
            ? {t('plots.delete.content2')}
          </Text>
        ),

        labels: { confirm: t('common.delete'), cancel: t('common.cancel') },

        confirmProps: { color: 'red' },

        onConfirm: () =>
          deleteAdvice.mutate({
            id: row.original.id,
            crop: CropEnum.RAPESEED,
          }),
      });
    },
    [deleteAdvice, t],
  );

  const handleEdit = (row: MRT_Row<RapeseedListAdvice>) => {
    onEdit(row.original.id);
  };

  return (
    <PfTable
      onDeleteActionClick={handleDelete}
      onEditActionClick={handleEdit}
      options={tableOptions}
    />
  );
}

export default RapeseedAdviceTable;
