import { queryOptions } from '@tanstack/react-query';
import { LngLatBounds } from 'mapbox-gl';

import { fieldsApi, GetPkResultsArgs } from '../apis/fields';
import { GetMapDto } from '../types/dto';

import { fieldMapsQueries } from './field-maps';

import { Filters, Globals, useQueriesWithGlobal } from '@data-access';

export const fieldsQueries = (global: Globals) => ({
  all: () => [global, 'fields'],

  maxBounds: () =>
    queryOptions({
      queryKey: [...fieldsQueries(global).all(), 'maxBounds'],
      queryFn: () => fieldsApi.getMaxBounds(),
    }),
  lists: () => [...fieldsQueries(global).all(), 'list'],
  list: (filters: Filters) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).lists(), filters],
      queryFn: () => fieldsApi.getAll(filters),
    }),

  details: () => [...fieldsQueries(global).all(), 'detail'],
  detail: (id: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).details(), id],
      queryFn: () => fieldsApi.getOne(id),
    }),
  heatmapComputationStatus: (id: number) =>
    queryOptions({
      queryKey: [
        ...fieldsQueries(global).details(),
        id,
        'heatmapComputationStatus',
      ],
      queryFn: () => fieldsApi.getHeatmapComputationStatus(id),
      refetchInterval: (query) => {
        // refetch while status !== 'FINISHED'
        return query.state.data?.status === 'FINISHED' ? false : 5000;
      },
    }),
  map: (args: GetMapDto) =>
    queryOptions({
      queryKey: [
        ...fieldsQueries(global).detail(args.fieldId).queryKey,
        'maps',
        args.type,
        args.id,
      ],
      queryFn: () => fieldsApi.getMap(args),
    }),

  bounded: () => [...fieldsQueries(global).all(), 'bounded'],
  boundedList: (filters: {
    bounds: LngLatBounds;
    cluster: boolean;
    zoom: number;
  }) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).bounded(), filters],
      queryFn: () =>
        fieldsApi.getInBounds(filters.bounds, filters.cluster, filters.zoom),
    }),
  boundedHeatmaps: (filters: {
    bounds: LngLatBounds;
    layer: string;
    date: string;
  }) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).bounded(), filters, 'heatmaps'],
      queryFn: () => fieldsApi.getHeatmapsInBounds(filters),
    }),

  values: () =>
    queryOptions({
      queryKey: [...fieldsQueries(global).all(), 'values'],
      queryFn: () => fieldsApi.getValues(),
    }),

  ...fieldMapsQueries(global),

  // pk
  pk: (fieldId: number) => [
    ...fieldsQueries(global).detail(fieldId).queryKey,
    'pk',
  ],
  pkTuto: (fieldId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).pk(fieldId), 'tuto'],
      queryFn: () => fieldsApi.getPkTuto(fieldId),
    }),
  pkValues: (fieldId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).pk(fieldId), 'values'],
      queryFn: () => fieldsApi.getPkValues(fieldId),
    }),
  pkAdvice: (fieldId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).pk(fieldId), 'advice'],
      queryFn: () => fieldsApi.getPkAdvice(fieldId),
    }),
  pkResults: (args: GetPkResultsArgs) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).pk(args.fieldId), 'results', args],
      queryFn: () => fieldsApi.getPkResults(args),
    }),
});

export const useFieldsQueries = () => useQueriesWithGlobal(fieldsQueries);
