import { bbox } from '@turf/turf';
import { Map, PaddingOptions, PointLike } from 'mapbox-gl';

type Params = {
  geojson?: GeoJSON.FeatureCollection | GeoJSON.Feature;
  map?: Map;
  animate?: boolean;
  padding?: number | PaddingOptions;
  offset?: PointLike;
};

export const zoomOnFeature = ({
  geojson,
  map,
  animate = true,
  padding = 50,
  offset = [0, 0],
}: Params) => {
  if (geojson && map) {
    const [x1, y1, x2, y2] = bbox(geojson);

    map.fitBounds([x1, y1, x2, y2], {
      padding: padding,
      animate,
      maxDuration: 1500,
      speed: 1.5,
      offset,
    });
  }
};
