import { SnapDirectSelect } from 'mapbox-gl-draw-snap-on-mode';

const DirectSelectMode = SnapDirectSelect;

const _ondrag = DirectSelectMode.onDrag;

DirectSelectMode.onDrag = function (state, e) {
  const result = _ondrag?.apply(this, [state, e]);

  const feature = state.feature.toGeoJSON();

  this.map.fire('draw.liveUpdate', {
    features: [feature],
  });

  return result;
};

export default DirectSelectMode;
