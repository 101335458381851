import { createFormContext, isNotEmpty } from '@mantine/form';
import { featureCollection, multiPolygon } from '@turf/turf';
import { Feature, Polygon } from 'geojson';
import { useTranslation } from 'react-i18next';

import { divide, ProductRel } from '@data-access';
import { CreatePrescriptionDto } from '@fields/data-access';

interface PrescriptionFormZone {
  color: string;
  value: number;
  uValue: number;
  area: number;
  key: string;
}

interface PrescriptionFormValues {
  name: string;
  group: string | null;
  input: string | null;
  asUnit: boolean;
  unitRatio: 'N_value' | 'P_value' | 'K_value' | null;
  ratio: number;
  map: Feature<Polygon>[];
  zones: PrescriptionFormZone[];
  average: number;
  uAverage: number;
  total: number;
  uTotal: number;
}

export type TransformValues = (
  values: PrescriptionFormValues,
) => Omit<CreatePrescriptionDto, 'field'>;

const [PrescriptionFormProvider, usePrescriptionFormContext, useForm] =
  createFormContext<PrescriptionFormValues, TransformValues>();

interface PrescriptionFormParams {
  products: ProductRel[];
  fieldArea: number;
}

export function usePrescriptionForm({
  products,
  fieldArea,
}: PrescriptionFormParams) {
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      name: '',
      group: null,
      input: null,
      asUnit: false,
      unitRatio: 'N_value',
      ratio: 1,
      map: [],
      zones: [],
      average: 0,
      total: 0,
      uAverage: 0,
      uTotal: 0,
    },
    validate: {
      name: isNotEmpty(t('form.required')),
      group: isNotEmpty(t('form.required')),
      input: isNotEmpty(t('form.required')),
      zones: {
        color: isNotEmpty(t('form.required')),
      },
    },
    transformValues: (values) => {
      const { zones, input, map, name } = values;

      const groupedFeatures: {
        [id: string]: Feature<
          Polygon,
          {
            color: string;
            value: number;
          }
        >[];
      } = {};

      /**
       * Group features by the color property
       * this step is needed because multipolygon are flattened by the draw tool
       * we need to group them by their color to re-create the zoning multipolygon
       */
      map.forEach((feature) => {
        const color = feature.properties?.color;
        const zone = zones.find((z) => z.color === color);
        const value = zone?.value || 0;
        const draft = {
          ...feature,
          properties: {
            color,
            value,
          },
        };
        if (!groupedFeatures[color]) {
          groupedFeatures[color] = [];
        }
        groupedFeatures[color].push(draft);
      });

      // Create a MultiPolygon from each group
      const multiPolygons = Object.values(groupedFeatures).map((group) => {
        const polygons = group.map((f) => f.geometry.coordinates);
        return multiPolygon(polygons, {
          ...group[0].properties,
        });
      });

      return {
        name,
        product: Number(input),
        map: featureCollection(multiPolygons),
      };
    },
  });

  form.watch('group', ({ value, previousValue }) => {
    // reset input when group changes
    return form.setValues({ input: null, asUnit: false });
  });

  form.watch('input', ({ value, previousValue }) => {
    // on input change, update unitRatio to select the first viable one
    const selectedProduct = products.find(
      (product) => product.id === Number(value),
    );
    const nextUnitRatio = getDefaultUnitRatio(selectedProduct);
    return form.setValues({ unitRatio: nextUnitRatio });
  });

  // update ratio when unitRatio changes
  // `ratio` is an inner state of the form used to transform the quantity on submit
  form.watch('unitRatio', ({ value, previousValue }) => {
    let nextRatio = 1;
    const selectedProduct = products.find(
      (product) => product.id === Number(form.getValues().input),
    );
    if (value) {
      nextRatio = selectedProduct?.[value] || 1;
    }
    // update all zones uValue by multiplying the value by the new ratio
    const updatedZones = form.getValues().zones.map((zone) => ({
      ...zone,
      uValue: zone.value * nextRatio,
    }));
    return form.setValues({ zones: updatedZones, ratio: nextRatio });
  });

  return form;
}

export function getDefaultUnitRatio(product?: ProductRel) {
  let nextRatio = null;
  if (product?.N_value) {
    nextRatio = 'N_value' as const;
  } else if (product?.P_value) {
    nextRatio = 'P_value' as const;
  } else if (product?.K_value) {
    nextRatio = 'K_value' as const;
  }

  return nextRatio;
}

export function calculateTotal(
  zones: PrescriptionFormZone[],
  fieldArea: number,
) {
  const total = zones.reduce(
    (acc: number, zone: { value: number; area: number }) =>
      acc + zone.value * zone.area,
    0,
  );

  const uTotal = zones.reduce(
    (acc: number, zone: { uValue: number; area: number }) =>
      acc + zone.uValue * zone.area,
    0,
  );

  const average = divide(total, fieldArea);
  const uAverage = divide(uTotal, fieldArea);

  return { total, average, uTotal, uAverage };
}

export const getTotalArea = (zones: PrescriptionFormZone[]) => {
  return zones.reduce((acc, zone) => acc + zone.area, 0);
};

export { PrescriptionFormProvider, usePrescriptionFormContext };
