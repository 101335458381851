import { Box, Group } from '@mantine/core';
import { Map } from 'mapbox-gl';
import { Suspense, useEffect, useState } from 'react';
import { MapProvider, useMap } from 'react-map-gl';

import FieldMapsLayout from '../../../../components/field-maps-layout/field-maps-layout';
import useSharedFieldMaps from '../../../../hooks/use-shared-field-maps/shared-field-maps';
import { pagesMapping } from '../../page';

import { MAP_TYPES } from '@fields/data-access';

export enum MAP_DISPLAY_TYPES {
  COMPARE_LEFT = 'compare-left',
  COMPARE_RIGHT = 'compare-right',
  SINGLE = 'single',
}

export function CompareMapsPage() {
  const { field, compare, navigate } = useSharedFieldMaps({
    type: MAP_DISPLAY_TYPES.SINGLE,
  });
  const [compareState, setCompareState] = useState({
    left:
      field.data.properties.maps.find((map) => map.numbers && map.numbers > 0)
        ?.type ?? MAP_TYPES.SATELLITE,
    right: MAP_TYPES.SATELLITE,
  });

  const leftType =
    compare?.[0] ??
    field.data.properties.maps.find((map) => map.numbers && map.numbers > 0)
      ?.type ??
    MAP_TYPES.SATELLITE;
  const rightType = compare?.[1] ?? MAP_TYPES.SATELLITE;

  const Left = pagesMapping[leftType];

  const Right = pagesMapping[rightType];

  useEffect(() => {
    if (!compare) {
      navigate({
        replace: true,
        search: {
          compare: [compareState.left, compareState.right],
        },
      });
    }
  }, [compare, compareState.left, compareState.right, navigate]);

  return (
    <MapProvider>
      <Box h="100%" style={{ overflow: 'hidden' }}>
        <Group grow h="100%">
          <Suspense fallback={<FieldMapsLayout.Pending />}>
            <Left type={MAP_DISPLAY_TYPES.COMPARE_LEFT} />
          </Suspense>
          <Suspense fallback={<FieldMapsLayout.Pending />}>
            <Right type={MAP_DISPLAY_TYPES.COMPARE_RIGHT} />
          </Suspense>
        </Group>
      </Box>
    </MapProvider>
  );
}

export default CompareMapsPage;
