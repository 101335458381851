import {
  Button,
  Combobox,
  ComboboxItem,
  Group,
  rem,
  Select,
  Stack,
  Text,
  useCombobox,
} from '@mantine/core';
import {
  IconChevronDown,
  IconMapMinus,
  IconMapPlus,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { flatten } from '@turf/turf';
import { Feature, Point } from 'geojson';
import { MapEvent, MapMouseEvent } from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef, Popup } from 'react-map-gl';

import { FieldMapsLayout } from '../../../../components/field-maps-layout/field-maps-layout';
import SelectMapType from '../../../../components/select-map-type/select-map-type';
import useSharedFieldMaps from '../../../../hooks/use-shared-field-maps/shared-field-maps';
import { MAP_DISPLAY_TYPES } from '../compare-maps/page';

import { EDITTABLE_MAP_TYPES } from '@fields/data-access';
import { PfMap, SAMPLES_LAYER_ID, SamplesLayers, zoomOnFeature } from '@map';
import { ActionsMenu, ActionsMenuItem } from '@ui';

type Options = ComboboxItem & { date: string };

interface SamplesMapProps {
  type: MAP_DISPLAY_TYPES;
}

export function SamplesMap({ type }: SamplesMapProps) {
  const { t } = useTranslation();

  const {
    field,
    fieldId,
    compare,
    queries,
    handleMapLoad,
    handleCompareChange,
  } = useSharedFieldMaps({ type });
  const [selectedMap, setSelectedMap] = useState<Options | null>(null);
  const [selectedPoint, setSelectedPoint] = useState<Feature<Point> | null>(
    null,
  );
  const mapRef = useRef<MapRef>(null);

  const summary = useQuery(queries.samplesSummary(Number(fieldId)));
  const samplesMap = useQuery({
    ...queries.samplesMap(Number(selectedMap?.value)),
    enabled: selectedMap !== null,
  });
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = summary.data?.options.map((item) => (
    <Combobox.Option key={item.value} value={item.value}>
      <Text>{item.label}</Text>
      <Text size="xs">{item.date}</Text>
    </Combobox.Option>
  ));

  // TODO: inmplement menu actions
  const menuActions: ActionsMenuItem[] = [
    {
      // TODO: only accessible to ADMIN
      type: 'link',
      label: 'Ajouter une carte',
      icon: IconMapPlus,
      props: {
        to: '/fields/$fieldId/maps/$editableMapType/create',
        params: { fieldId, editableMapType: EDITTABLE_MAP_TYPES.PRESCRIPTIONS },
      },
    },
    {
      type: 'action',
      label: t('common.delete'),
      icon: IconMapMinus,
      color: 'red',
      onClick: () => {
        console.log('delete');
      },
    },
  ];

  const handleMapCursor = (e: MapMouseEvent) => {
    if (e.type === 'mouseenter') {
      e.target.getCanvas().style.cursor = 'pointer';
    }

    if (e.type === 'mouseleave') {
      e.target.getCanvas().style.cursor = '';
    }

    if (e.type === 'click' && e.features?.length) {
      setSelectedPoint(flatten(e.features[0]).features[0]);
    }
  };

  useEffect(() => {
    // Select first map type by default
    if (summary.data?.options.length && !selectedMap) {
      setSelectedMap(summary.data.options[0]);
    }
  }, [selectedMap, summary.data]);

  useEffect(() => {
    // TODO: This is a bug, the map should be zoomed on the feature only
    if (mapRef.current && samplesMap.data?.map.features.length) {
      zoomOnFeature({
        map: mapRef.current?.getMap(),
        geojson: samplesMap.data.map,
      });
    }
  }, [samplesMap.data]);

  return (
    <FieldMapsLayout
      content={
        <PfMap
          interactiveLayerIds={[SAMPLES_LAYER_ID]}
          onClick={handleMapCursor}
          onLoad={handleMapLoad}
          onMouseDown={handleMapCursor}
          onMouseEnter={handleMapCursor}
          ref={mapRef}
        >
          {samplesMap.data?.map ? (
            <SamplesLayers data={samplesMap.data.map} />
          ) : null}
          {selectedPoint && (
            <Popup
              closeOnClick={false}
              latitude={selectedPoint.geometry.coordinates[1]}
              longitude={selectedPoint.geometry.coordinates[0]}
              onClose={() => setSelectedPoint(null)}
            >
              <Text>{selectedPoint.properties?.name}</Text>
            </Popup>
          )}
        </PfMap>
      }
      header={
        <Group h="100%" justify="space-between" p="md">
          <Group>
            {type !== 'single' ? (
              <SelectMapType
                maps={field.data.properties.maps}
                onChange={handleCompareChange}
                value={type === 'compare-left' ? compare?.[0] : compare?.[1]}
              />
            ) : null}
            <Combobox
              onOptionSubmit={(val) => {
                const item = summary.data?.options.find((i) => i.value === val);
                if (item) {
                  setSelectedMap(item);
                }
                combobox.closeDropdown();
              }}
              position="bottom-start"
              store={combobox}
              width="target"
              withinPortal={false}
            >
              <Combobox.Target>
                <Button
                  h="fit-content"
                  onClick={() => combobox.toggleDropdown()}
                  py={rem(2)}
                  rightSection={<IconChevronDown />}
                  styles={{
                    inner: { height: 'fit-content' },
                  }}
                  variant="outline"
                >
                  <Stack align="start" component="span" gap={0} h="fit-content">
                    <Text size="sm">{selectedMap?.label}</Text>
                    <Text lh={0.9} size="xs">
                      {selectedMap?.date}
                    </Text>
                  </Stack>
                </Button>
              </Combobox.Target>

              <Combobox.Dropdown>
                <Combobox.Options>{options}</Combobox.Options>
              </Combobox.Dropdown>
            </Combobox>
            <Select />
          </Group>
          <Group>
            <Button>Créer un plan</Button>
            <ActionsMenu items={menuActions}>
              <ActionsMenu.Button />
            </ActionsMenu>
          </Group>
        </Group>
      }
    />
  );
}

export default SamplesMap;
