import { Paper, Group, Title } from '@mantine/core';
import { IconFileDownload, IconMapDown, IconTrash } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { MRT_RowSelectionState } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';

import { fieldMapsApi, fieldsApi } from '@fields/data-access';
import { ActionsMenu, ActionsMenuItem } from '@ui';

interface PrescriptionsListHeaderProps {
  rowSelection: MRT_RowSelectionState;
  onDownloadSynthesis: () => void;
  onDelete: () => void;
  onExport: () => void;
  isLoading?: boolean;
}

export function PrescriptionsListHeader({
  rowSelection,
  onDownloadSynthesis,
  onDelete,
  onExport,
  isLoading,
}: PrescriptionsListHeaderProps) {
  const { t } = useTranslation();

  const actions: ActionsMenuItem[] = [
    {
      type: 'action',
      icon: IconFileDownload,
      label: 'Télécharger une synthèse',
      onClick: () => onDownloadSynthesis(),
    },
    {
      type: 'action',
      icon: IconMapDown,
      label: t('common.export'),
      onClick: () => onExport(),
    },
    {
      type: 'action',
      icon: IconTrash,
      label: t('common.delete'),
      color: 'red',
      onClick: () => onDelete(),
    },
  ];

  return (
    <Paper>
      <Group justify="space-between">
        <Title order={2}>{t('prescriptions.listTitle')}</Title>
        <Group>
          <ActionsMenu items={actions}>
            <ActionsMenu.Button
              disabled={!Object.keys(rowSelection).length}
              loading={isLoading}
            />
          </ActionsMenu>
        </Group>
      </Group>
    </Paper>
  );
}

export default PrescriptionsListHeader;
