import { Accordion, Text } from '@mantine/core';
import { IconCheckupList, IconLeaf, IconSparkles } from '@tabler/icons-react';
import { isValid, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import CheckList from '../check-list/check-list';

import styles from './rapeseed-form-info.module.css';

import { formatDate, RapeseedNitrogenForm } from '@data-access';

interface RapeseedFormInfoProps {
  form: RapeseedNitrogenForm;
  withItemsIcon?: boolean;
}

function isValidDate(date: string | undefined): boolean {
  if (!date) return false;
  return isValid(parseISO(date));
}

export function RapeseedFormInfo({
  form,
  withItemsIcon = true,
}: RapeseedFormInfoProps) {
  const { t } = useTranslation();

  const agronomicList = [
    {
      value: formatDate({
        format: 'display',
        date: form.seed_date,
      }),
      label: t('common.seed_date'),
    },
    {
      value: t(`advices.properties.sol_types.${form.sol}`),
      label: t('common.properties.sol'),
    },
    {
      value: form.rdt,
      label: t('common.olympic_yield'),
      unit: 'q/ha',
    },
    {
      value: form.cz_pois_prt ? t('common.yes') : t('common.no'),
      label: t('advices.rapseed_result.cz_pois_prt_label'),
    },
    {
      value: form.cz_leg ? t('common.yes') : t('common.no'),
      label: t('advices.rapseed_result.cz_leg_label'),
    },
  ];

  const biomassList = [
    {
      value: isValidDate(form.biomass_start?.date)
      ? formatDate({ format: 'display', date: form.biomass_start?.date })
      : '-',
      label: t('advices.rapseed_result.biomass_start_label'),
    },
    {
      value: isValidDate(form.biomass_end?.date)
      ? formatDate({ format: 'display', date: form.biomass_end?.date })
      : '-',
      label: t('advices.rapseed_result.biomass_end_label'),
    },
  ];

  const historicFertList = [
    {
      value: form.pdt_org_hist?.name,
      label: t('advices.properties.pdt_org1'),
      optional: true,
    },
    {
      value: t(`advices.properties.frq_ranges.${form.frq_org}`),
      // unit: form.pdt_org_hist?.unit,
      label: t('advices.properties.frq_org'),
      optional: true,
    },
  ];

  const fallFert1List = [
    {
      value: form.pdt_org1?.name,
      label: t('advices.properties.pdt_org1'),
      optional: true,
    },
    {
      value: form.qte_pdt_ependu1,
      label: t('advices.properties.qte_pdt_ependu'),
      unit: form.pdt_org1?.unit,
      optional: true,
    },
  ];

  const fallFert2List = [
    {
      value: form.pdt_org2?.name,
      label: t('advices.properties.pdt_org1'),
      optional: true,
    },
    {
      value: form.qte_pdt_ependu2,
      label: t('advices.properties.qte_pdt_ependu'),
      unit: form.pdt_org2?.unit,
      optional: true,
    },
  ];

  const fertList = [
    {
      value: form.nitrogen_already_applied,
      label: t('common.nitrogen_already_applied'),
      unit: 'u/ha',
      optional: true,
    },
    {
      value: form.product?.name,
      label: t('table.columns.product'),
    },
  ];

  const adviceList = [
    {
      value: form.mean_dose,
      label: t('advices.rapeseed.mean_dose_label'),
      unit: 'u/ha',
    },
    {
      value: form.mean_product,
      label: t('advices.rapeseed.mean_product_label'),
      unit: form.product?.unit,
    },
  ];

  const accordion = [
    {
      title: t('advices.agronomic_data'),
      items: agronomicList,
      icon: <IconCheckupList />,
    },
    {
      title: t('advices.properties.biomass_legend'),
      items: biomassList,
      icon: <IconLeaf />,
    },
    {
      title: t('advices.form.historic_fertilizer_legend'),
      items: historicFertList,
      icon: <IconLeaf />,
    },
    {
      title: t('advices.fall_fert_1'),
      items: fallFert1List,
      icon: <IconLeaf />,
    },
    {
      title: t('advices.fall_fert_2'),
      items: fallFert2List,
      icon: <IconLeaf />,
    },
    {
      title: t('advices.fertilizing'),
      items: fertList,
      icon: <IconLeaf />,
    },
    {
      title: t('common.advices'),
      items: adviceList,
      icon: <IconSparkles />,
    },
  ];

  return (
    <Accordion defaultValue={accordion.map(({ title }) => title)} multiple>
      {accordion.map(({ title, items, icon }) => (
        <Accordion.Item key={title} value={title}>
          <Accordion.Control icon={icon}>
            <Text fw="bold" size="lg">
              {title}
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <CheckList items={items} withIcon={withItemsIcon} />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default RapeseedFormInfo;
