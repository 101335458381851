import { useSuspenseQuery } from '@tanstack/react-query';
import { useParams, useSearch, useNavigate } from '@tanstack/react-router';
import { MapEvent } from 'mapbox-gl';

import { MAP_DISPLAY_TYPES } from '../../pages/maps/pages/compare-maps/page';

import { useQueriesWithGlobal } from '@data-access';
import { fieldsQueries, MAP_TYPES } from '@fields/data-access';
import { zoomOnFeature } from '@map';

interface SharedFieldMapsArgs {
  type: MAP_DISPLAY_TYPES;
}

export function useSharedFieldMaps({ type }: SharedFieldMapsArgs) {
  const { fieldId } = useParams({
    from: '/_app/fields_/$fieldId/maps/$mapType',
  });
  const { mapId, compare } = useSearch({
    from: '/_app/fields_/$fieldId/maps/$mapType',
  });
  const navigate = useNavigate({
    from: '/fields/$fieldId/maps/$mapType',
  });

  const queries = useQueriesWithGlobal(fieldsQueries);
  const field = useSuspenseQuery(queries.detail(Number(fieldId)));

  const navigateMapIdSp = (id?: string) => {
    if (type === MAP_DISPLAY_TYPES.SINGLE) {
      navigate({
        replace: true,
        search: (prev) => ({
          ...prev,
          mapId: id ? Number(id) : undefined,
        }),
      });
    }
  };

  const handleMapLoad = (e: MapEvent) => {
    zoomOnFeature({
      map: e.target,
      geojson: field.data,
    });
  };

  // This method is used to change the compare search param on select change
  const handleCompareChange = (value: string | null) => {
    if (!value || !compare) {
      return;
    }

    if (type === MAP_DISPLAY_TYPES.COMPARE_LEFT) {
      navigate({
        replace: true,
        search: {
          compare: [value as MAP_TYPES, compare[1]],
        },
      });
    } else if (type === MAP_DISPLAY_TYPES.COMPARE_RIGHT) {
      navigate({
        replace: true,
        search: {
          compare: [compare[0], value as MAP_TYPES],
        },
      });
    }
  };

  return {
    fieldId,
    mapId,
    compare,
    navigate,
    queries,
    field,
    handleMapLoad,
    handleCompareChange,
    navigateMapIdSp,
  };
}

export default useSharedFieldMaps;
