import { Fieldset, NumberInput, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useCommonAdviceFormContext } from '../../../hooks/use-common-advice-form/common-advice-form';
import InfoLabel from '../../info-label/info-label';

import { useValues } from '@advices/data-access';
import { asCbData } from '@data-access';

interface FertilizerFieldsetProps {
  type: string;
}

export const FertilizerFieldset = ({ type }: FertilizerFieldsetProps) => {
  const { t, i18n } = useTranslation();
  const form = useCommonAdviceFormContext();
  const selectedFields = form.getValues().field;

  const values = useValues({
    type,
    fieldId: selectedFields.length === 1 ? selectedFields[0] : null,
  });

  return (
    <Fieldset legend={t('advices.fertilizing')} mt="md" variant="filled">
      <NumberInput
        label={
          <InfoLabel
            info={t('advices.properties.nitrogen_already_applied_info')}
            label={t('advices.properties.nitrogen_already_applied')}
          />
        }
        {...form.getInputProps('nitrogen_already_applied')}
      />

      <Select
        allowDeselect={false}
        data={asCbData(values.data?.product)}
        label={
          <InfoLabel
            info={t('advices.properties.product_info')}
            label={t('advices.properties.product')}
          />
        }
        mt="sm"
        searchable
        {...form.getInputProps('product')}
      />
    </Fieldset>
  );
};
