import { Paper, ScrollArea, Skeleton, Title } from '@mantine/core';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import classes from './map-info.module.css';

import { useQueriesWithGlobal } from '@data-access';
import {
  fieldsQueries,
  MAP_TYPES,
  MapTypeToApiRoute,
} from '@fields/data-access';
import { CommonFormInfo, RapeseedFormInfo, PkFormInfo } from '@ui';

interface MapInfoProps {
  type: MAP_TYPES;
  mapId: number;
}

export function MapInfo({ type, mapId }: MapInfoProps) {
  const { t } = useTranslation();
  const queries = useQueriesWithGlobal(fieldsQueries);

  const map = useQuery({
    ...queries.mapDetail({
      type: MapTypeToApiRoute[type],
      mapId,
    }),
  });

  if (
    !map.data?.form?.pk &&
    !map.data?.form?.common &&
    !map.data?.form?.rapeseed
  ) {
    return null;
  }

  if (map.isLoading) {
    return <Skeleton height="100%" width="100%" />;
  }

  return (
    <Paper className={classes.container} p={0}>
      <ScrollArea.Autosize h="100%">
        <Title order={3} p="md" pb={0}>
          Information de la carte
        </Title>
        {map.data?.form?.pk ? (
          <PkFormInfo form={map.data?.form?.pk} withItemsIcon={false} />
        ) : null}
        {map.data?.form?.common ? (
          <CommonFormInfo form={map.data?.form?.common} withItemsIcon={false} />
        ) : null}
        {map.data?.form?.rapeseed ? (
          <RapeseedFormInfo
            form={map.data?.form?.rapeseed}
            withItemsIcon={false}
          />
        ) : null}
      </ScrollArea.Autosize>
    </Paper>
  );
}

export default MapInfo;
