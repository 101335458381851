import {
  ActionIcon,
  Box,
  Grid,
  Group,
  Image,
  Overlay,
  Paper,
  rem,
  Stack,
  Text,
} from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { NotFoundRouteComponent } from '@tanstack/react-router';
import { t } from 'i18next';

import { FieldNavbar } from '../../components/field-navbar/field-navbar';

import classes from './page.module.css';

import { PfMap } from '@map';
import { FullMainHeight, TsrButtonLink } from '@ui';

export const NotFoundPage: NotFoundRouteComponent = () => {
  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar>
          <Stack className={classes.navCol}>
            <Paper className={classes.navHeader}>
              <Group>
                <Box>
                  <Image
                    alt="plot thumbnail"
                    h={36}
                    src={'https://placehold.co/50x50'}
                  />
                </Box>
                <Stack flex="1" gap={0} h={rem(36)} justify="center"></Stack>
                <ActionIcon disabled variant="outline">
                  <IconDotsVertical />
                </ActionIcon>
              </Group>
            </Paper>
            <Paper className={classes.navBar} p={0}>
              <FieldNavbar disabled fieldId="" links={[]} />
            </Paper>
          </Stack>
        </FullMainHeight.Sidebar>

        <Paper h="100%" p={0} pos="relative">
          <Overlay className={classes.overlay}>
            <Paper>
              <Text mb="md" size="lg">
                {t('field.notFound.title')}
              </Text>
              <TsrButtonLink fullWidth to={'/fields'}>
                {t('field.notFound.back')}
              </TsrButtonLink>
            </Paper>
          </Overlay>
          <PfMap></PfMap>
        </Paper>
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
};

export default NotFoundPage;
