import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-react-table/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import '../styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import { MyRouterContext } from '../main';

import { DefaultPendingComponent, ReloadPrompt } from '@ui';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

const ReactQueryDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/react-query-devtools').then((res) => ({
          default: res.ReactQueryDevtools,
        })),
      );

dayjs.locale('fr');

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootComponent,
  pendingComponent: DefaultPendingComponent,
});

function RootComponent() {
  const { i18n } = useTranslation();

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <ReloadPrompt />
      <ReactQueryDevtools buttonPosition="top-left" initialIsOpen={false} />
      <TanStackRouterDevtools position="bottom-right" />
      <Notifications />
      <ModalsProvider>
        <DatesProvider
          settings={{
            locale: i18n.language,
          }}
        >
          <Outlet />
        </DatesProvider>
      </ModalsProvider>
    </>
  );
}
