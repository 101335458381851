import { deepMerge, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  MRT_Row,
  MRT_TableOptions,
  MRT_RowSelectionState,
} from 'mantine-react-table';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useTable } from './use-table';

import {
  useDeleteAdvice,
  Wheat2Advice,
  useWheat2Advices,
} from '@advices/data-access';
import { CropEnum, useFiltersTableOptions } from '@data-access';
import { PfTable, useRowSelection } from '@ui';

export interface Wheat2AdviceTableProps {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: any;
  onEdit: (id: number) => void;
  needValidation?: boolean;
}

export function Wheat2AdviceTable({
  rowSelection,
  setRowSelection,
  onEdit,
  needValidation,
}: Wheat2AdviceTableProps) {
  const { t } = useTranslation();
  const filtersTableOptions = useFiltersTableOptions<Wheat2Advice>('advices');

  const advices = useWheat2Advices();
  const deleteAdvice = useDeleteAdvice();
  const { columns } = useTable();

  const rowSelectionProps = useRowSelection<Wheat2Advice>({
    data: advices.data?.results || [],
    rowSelection,
    setRowSelection,
  });

  const tableOptions: MRT_TableOptions<Wheat2Advice> = deepMerge<
    MRT_TableOptions<Wheat2Advice>
  >(
    {
      data: advices.data?.results || [],
      columns,
      rowCount: advices.data?.count || 0,

      enableFilters: !needValidation,
      enableColumnFilters: !needValidation,
      initialState: {
        // columnVisibility: dataColState as VisibilityState,
        showColumnFilters: true,
      },
      state: {
        isLoading: advices.isLoading,
        rowSelection,
        showProgressBars: advices.isRefetching,
      },
      onRowSelectionChange: setRowSelection,

      mantinePaperProps: {
        h: '100%',
      },

      ...rowSelectionProps,
      // renderTopToolbarCustomActions: ({ table }) => (
      //   <ResultsDataColumnControl
      //     dataColState={dataColState as VisibilityState}
      //     needValidation={needValidation}
      //     resultsColState={resultsColState as VisibilityState}
      //     table={table as any}
      //   />
      // ),
    },
    filtersTableOptions,
  );

  const handleDelete = useCallback(
    (row: MRT_Row<Wheat2Advice>) => {
      modals.openConfirmModal({
        title: t('advices.delete.title'),

        children: (
          <Text>
            {t('advices.delete.content1')}{' '}
            <Text fw={700} size="lg" span>
              {row.original.field.name}
            </Text>{' '}
            ? {t('plots.delete.content2')}
          </Text>
        ),

        labels: { confirm: t('common.delete'), cancel: t('common.cancel') },

        confirmProps: { color: 'red' },

        onConfirm: () =>
          deleteAdvice.mutate({
            id: row.original.id,
            crop: CropEnum.WHEAT2,
          }),
      });
    },
    [deleteAdvice, t],
  );

  const handleEdit = (row: MRT_Row<Wheat2Advice>) => {
    onEdit(row.original.id);
  };

  return (
    <PfTable
      onDeleteActionClick={handleDelete}
      onEditActionClick={handleEdit}
      options={tableOptions}
    />
  );
}

export default Wheat2AdviceTable;
