import { Paper } from '@mantine/core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { featureCollection } from '@turf/turf';
import { MapEvent } from 'mapbox-gl';

import { useQueriesWithGlobal } from '@data-access';
import { fieldsQueries } from '@fields/data-access';
import { PfMap, PlotsLayer, zoomOnFeature } from '@map';

export function FieldMap() {
  const { fieldId } = useParams({ from: '/_app/fields_/$fieldId' });

  const queries = useQueriesWithGlobal(fieldsQueries);
  const { data } = useSuspenseQuery(queries.detail(Number(fieldId)));

  const handleLoad = (map: MapEvent) => {
    zoomOnFeature({
      map: map.target,
      geojson: data,
    });
  };

  return (
    <Paper h="100%" p={0}>
      <PfMap onLoad={handleLoad}>
        <PlotsLayer data={featureCollection([data])} />
      </PfMap>
    </Paper>
  );
}

export default FieldMap;
