import { Campaign } from '../types/campaign.types';

import { privateClient } from './api-client';

export const sharedApi = {
  campaigns: async () => {
    const campaigns = await privateClient.get('campaigns/').json<Campaign[]>();

    return campaigns.sort((a, b) => a.year - b.year);
  },
  cgu: async () => {
    const cgu = await privateClient.get('accounts/tutorial/').json<{
      file: string;
    }>();

    return cgu;
  },
};
