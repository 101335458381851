import { tokenKey } from './token-key';

export const setAccessToken = (token: string | null) => {
  if (token) {
    localStorage.setItem(tokenKey, token);
  } else {
    localStorage.removeItem(tokenKey);
  }
};

export default setAccessToken;
