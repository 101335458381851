import { DrawerProps, Drawer as MDrawer } from '@mantine/core';
import clsx from 'clsx';

import classes from './drawer.module.css';

export function Drawer({ children, classNames = {}, ...props }: DrawerProps) {
  return (
    <MDrawer
      classNames={{
        ...classNames,
        root: clsx(classes.drawer, classNames.root),
        content: clsx(classes.drawerContent, classNames.content),
        header: clsx(classes.drawerHeader, classNames.header),
        title: clsx(classes.drawerTitle, classNames.title),
        body: clsx(classes.drawerBody, classNames.body),
      }}
      closeButtonProps={{
        c: 'dark',
      }}
      radius="xs"
      {...props}
    >
      {children}
    </MDrawer>
  );
}

export default Drawer;
