import { Container, Title, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { TsrButtonLink } from '../tsr-button-link/tsr-button-link';

import { Illustration } from './illustration';
import classes from './not-found.module.css';

export function NotFound() {
  const { t } = useTranslation();

  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <Illustration className={classes.image} />
        <div className={classes.content}>
          <Title className={classes.title}>{t('not-found.title')}</Title>
          <Text
            c="dimmed"
            className={classes.description}
            size="lg"
            ta="center"
          >
            {t('not-found.description')}
          </Text>
          <Group justify="center">
            <TsrButtonLink size="md" to="/dashboard">
              {t('not-found.back')}
            </TsrButtonLink>
          </Group>
        </div>
      </div>
    </Container>
  );
}

export default NotFound;
