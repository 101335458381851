import { queryOptions } from '@tanstack/react-query';

import { authenticationAPI } from '../apis/authentication-api';

export const authQueries = {
  loggedUser: () =>
    queryOptions({
      queryKey: ['logged-user'],
      queryFn: authenticationAPI.getLoggedUser,
    }),
};
