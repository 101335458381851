import { createFormContext } from '@mantine/form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { CreateRapeseedAdviceDto } from '@advices/data-access';

export interface RapeseedAdviceFormValues {
  field: number[];
  variety?: string;
  seed_date?: Date;
  sol?: string;
  rdt?: number;
  cz_pois_prt: boolean;
  cz_leg: boolean;
  biomass_start?: string;
  biomass_end?: string;
  pdt_org_hist?: string | null;
  frq_org?: string | null;
  pdt_org1?: string | null;
  qte_pdt_ependu1?: number | null;
  n_teneur1?: number | null;
  pdt_org2?: string | null;
  qte_pdt_ependu2?: number | null;
  n_teneur2?: number | null;
  nitrogen_already_applied?: number;
  product?: string;
  disable_mail?: boolean;
  restart?: boolean;
  is_hidden?: boolean;
}

type TransformValues = (
  values: RapeseedAdviceFormValues,
) => CreateRapeseedAdviceDto;

export const rapeseedFormInitialValues = {
  field: [],
  cz_pois_prt: false,
  cz_leg: false,
};

const [RapeseedAdviceFormProvider, useRapeseedAdviceFormContext, useForm] =
  createFormContext<RapeseedAdviceFormValues, TransformValues>();

export function useRapeseedAdviceForm() {
  const { t } = useTranslation();
  const form = useForm({
    // mode: 'uncontrolled',
    initialValues: rapeseedFormInitialValues,
    validate: {
      field: (value) => {
        if (value.length === 0) {
          return t('form.required');
        }
      },
    },
    transformValues: (values) => {
      const { field, seed_date, variety, product, ...rest } = values;

      const transformedValues: CreateRapeseedAdviceDto = {
        field: field.length === 1 ? field[0] : field,
        variety: variety ? Number(variety) : null,
        product: product ? Number(product) : null,
        ...rest,
      };

      if (seed_date) {
        transformedValues.seed_date = dayjs(seed_date).format('YYYY-MM-DD');
      }

      return transformedValues;
    },
  });

  return form;
}

export { RapeseedAdviceFormProvider, useRapeseedAdviceFormContext };
