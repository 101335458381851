import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { EDITTABLE_MAP_TYPES } from '@fields/data-access';

const mapTypes = z.object({
  editableMapType: z.nativeEnum(EDITTABLE_MAP_TYPES),
  fieldId: z.string(),
});

export const Route = createFileRoute(
  '/_app/fields_/$fieldId_/maps/$editableMapType/create',
)({
  params: {
    parse: mapTypes.parse,
    stringify: (params) => ({
      editableMapType: params.editableMapType,
      fieldId: params.fieldId,
    }),
  },
});
