import { MRT_RowSelectionState } from 'mantine-react-table';
import React from 'react';

import BarleyAdviceTable from '../../../components/barley-advice-table/barley-advice-table';
import CommonAdviceTable from '../../../components/common-advice-table/common-advice-table';
import RapeseedAdviceTable from '../../../components/rapeseed-advice-table/rapeseed-advice-table';
import Wheat2AdviceTable from '../../../components/wheat2-advice-table/wheat2-advice-table';

import { CropEnum } from '@data-access';

export interface AdviceTableProps {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: any;
  selectedType: string;
  onEdit: (id: number) => void;
  needValidation?: boolean;
}

const AdviceTable: React.FC<AdviceTableProps> = ({
  rowSelection,
  setRowSelection,
  selectedType,
  onEdit,
  needValidation,
}) => {
  switch (selectedType) {
    case CropEnum.RAPESEED: {
      return (
        <RapeseedAdviceTable
          needValidation={needValidation}
          onEdit={onEdit}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      );
    }

    case CropEnum.WHEAT2: {
      return (
        <Wheat2AdviceTable
          needValidation={needValidation}
          onEdit={onEdit}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      );
    }

    case CropEnum.BARLEY: {
      return (
        <BarleyAdviceTable
        needValidation={needValidation}
        onEdit={onEdit}
        rowSelection={rowSelection}
        selectedType={selectedType}
        setRowSelection={setRowSelection}
        />
      );
    }

    default: {
      return (
        <CommonAdviceTable
          needValidation={needValidation}
          onEdit={onEdit}
          rowSelection={rowSelection}
          selectedType={selectedType}
          setRowSelection={setRowSelection}
        />
      );
    }
  }
};

export default AdviceTable;
