import { MRT_RowSelectionState } from 'mantine-react-table';
import { useState } from 'react';

import { FieldsListHeader } from '../../components/fields-list-header/fields-list-header';
import { FieldsTable } from '../../components/fields-table/fields-table';

import { ErrorPage } from './page.error';

import { FullMainHeight } from '@ui';

export function FieldsListPage() {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  return (
    <FullMainHeight.Root>
      <FullMainHeight.Header>
        <FieldsListHeader
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      </FullMainHeight.Header>

      <FullMainHeight.Content>
        <FieldsTable
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      </FullMainHeight.Content>
    </FullMainHeight.Root>
  );
}

FieldsListPage.Error = ErrorPage;

export default FieldsListPage;
