export enum MAP_TYPES {
  SOIL = 'soil',
  SATELLITE = 'satellite',
  YIELD = 'yield',
  PRESCRIPTIONS = 'prescriptions',
  SAMPLES = 'samples',
  POTENTIAL = 'potential',
  BIOMASS = 'biomass',
  OTHER = 'other',
  COMPARE = 'compare',
}

export const MAP_TYPES_ARRAY = Object.values(MAP_TYPES);

export enum EDITTABLE_MAP_TYPES {
  SOIL = MAP_TYPES.SOIL,
  YIELD = MAP_TYPES.YIELD,
  PRESCRIPTIONS = MAP_TYPES.PRESCRIPTIONS,
}

export const MapTypeToApiRoute = {
  [MAP_TYPES.SOIL]: 'soilmaps',
  [MAP_TYPES.SATELLITE]: 'heatmaps',
  [MAP_TYPES.YIELD]: 'yieldmaps',
  [MAP_TYPES.PRESCRIPTIONS]: 'nitrogenmaps',
  [MAP_TYPES.SAMPLES]: 'samplesmaps',
  [MAP_TYPES.POTENTIAL]: 'potentialmaps',
  [MAP_TYPES.BIOMASS]: 'biomassmaps',
  [MAP_TYPES.OTHER]: 'othermaps',
  [MAP_TYPES.COMPARE]: '',
};
