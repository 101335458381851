import {
  Stack,
  Paper,
  Group,
  rem,
  ActionIcon,
  Box,
  Skeleton,
} from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { ErrorRouteComponent } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import classes from './page.module.css';

import { CommonError, FullMainHeight } from '@ui';

export const ErrorPage: ErrorRouteComponent = ({ error, reset }) => {
  const { t } = useTranslation();

  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar size="lg">
          <Stack className={classes.navCol}>
            <Paper className={classes.navHeader}>
              <Group>
                <Box>
                  <Skeleton animate={false} height={rem(36)} width={rem(36)} />
                </Box>
                <Stack flex="1" gap={0} h={rem(36)} justify="center">
                  <Skeleton
                    animate={false}
                    height={rem(20)}
                    mb="xs"
                    width="100%"
                  />
                  <Skeleton animate={false} height={rem(16)} width="50%" />
                </Stack>
                <ActionIcon disabled variant="outline">
                  <IconDotsVertical />
                </ActionIcon>
              </Group>
            </Paper>
            <Paper className={classes.navBar}>
              <Skeleton
                animate={false}
                height={rem(55)}
                mb="lg"
                radius={0}
                width="100%"
              />
            </Paper>
          </Stack>
        </FullMainHeight.Sidebar>

        <Stack className={classes.navCol}>
          <Paper className={classes.navHeader}>
            <Skeleton
              animate={false}
              h={rem(36)}
              radius="xs"
              width={rem(274)}
            />
          </Paper>
          <CommonError
            description={t('common.error.pageError')}
            error={error}
            reset={reset}
          />
        </Stack>
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
};

export default ErrorPage;
