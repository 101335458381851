import { DonutChart, DonutChartCell } from '@mantine/charts';
import { Box, Group, Paper, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { BoxPlotData } from '../../data-access/types/stats';
import { BoxPlotChart } from '../box-plot-chart/box-plot-chart';

import { CropStatisticsCardError } from './crop-statistics-card.error';
import classes from './crop-statistics-card.module.css';

import { cropsIcons } from '@ui';

interface CropStatisticsCardProps {
  boxPlotData: BoxPlotData[];
  totalArea: number;
  totalFields: number;
  donutData: DonutChartCell[];
  crop: string;
  color?: string;
  domain: [number, number];
}

export function CropStatisticsCard({
  boxPlotData,
  totalArea,
  totalFields,
  donutData,
  crop,
  color,
  domain,
}: CropStatisticsCardProps) {
  const { t } = useTranslation();
  const Icon = cropsIcons[crop];
  return (
    <Paper
      style={{
        overflow: 'visible',
      }}
    >
      <Group align="stretch">
        <BoxPlotChart
          boxPlots={boxPlotData}
          containerStyle={{ flex: 3 }}
          domain={domain}
          strokeColor={color}
        />
        <Stack align="center" flex="0 0 140px" justify="center">
          <Box mt="xl" ta="center">
            <Text>{totalFields} parcelles</Text>
            <Text>{totalArea.toFixed(2)} ha</Text>
          </Box>
          <DonutChart
            className={classes.donut}
            data={donutData}
            size={130}
            thickness={17}
            withTooltip={true}
          />
        </Stack>
      </Group>
      <Group align="center" mt="md" pl="xl">
        {Icon ? <Icon size={40} /> : null}
        <Text fw="bold"> {t(`common.${crop}`)}</Text>
      </Group>
    </Paper>
  );
}

export { CropStatisticsCardError };

export default CropStatisticsCard;
