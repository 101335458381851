import { AspectRatio, Box, Button, Group, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { News } from '../../data-access/types';

import classes from './news-card.module.css';

export function NewsCard({ title, short_description, link, image }: News) {
  const { t } = useTranslation();

  return (
    <Group align="start" className={classes.container}>
      <AspectRatio flex="0 0 auto" ratio={500 / 400}>
        <img alt={title} height={180} src={image} />
      </AspectRatio>

      <Stack flex="1" justify="space-between">
        <Box>
          <Text className={classes.title}>{title}</Text>
          <Text>{short_description}</Text>
        </Box>
        <Button component="a" href={link} target="_blank">
          {t('dashboard.read-more')}
        </Button>
      </Stack>
    </Group>
  );
}

export default NewsCard;
