import { BarleyIcon } from './barley-icon';
import { CanolaIcon } from './canola-icon';
import { WheatIcon } from './wheat-icon';

export const cropsIcons: Record<
  string,
  typeof BarleyIcon | typeof WheatIcon | typeof CanolaIcon
> = {
  barley: BarleyIcon,
  wheat: WheatIcon,
  rapeseed: CanolaIcon,
};
