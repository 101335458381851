import { isNotEmpty, useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';

import { ProcessSoilMapDto } from 'modules/fields/data-access/src/lib/types/dto';

interface SoilMapFormValues {
  map_id?: number;
  name?: string;
  column_name: string;
  soil_map_type: string;
}

type TransformValues = (values: SoilMapFormValues) => ProcessSoilMapDto;

export const useSoilMapForm = (fieldId: number) => {
  const { t } = useTranslation();
  return useForm<SoilMapFormValues, TransformValues>({
    initialValues: {
      column_name: '',
      soil_map_type: '',
    },
    validate: {
      map_id: isNotEmpty(t('form.required')),
      column_name: isNotEmpty(t('form.required')),
      soil_map_type: isNotEmpty(t('form.required')),
    },
    transformValues: (values) => {
      return {
        ...values,
        map_id: values.map_id!,
        soil_map_type: Number(values.soil_map_type),
      };
    },
  });
};
