import { Group, Stack, rem, ActionIcon, Image, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import {
  IconCut,
  IconDotsVertical,
  IconDownload,
  IconEdit,
  IconTrash,
} from '@tabler/icons-react';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import ActionsMenu, { ActionsMenuItem } from '../actions-menu/actions-menu';
import TsrTextLink from '../tsr-text-link/tsr-text-link';

import styles from './field-header.module.css';

import { fromBacki18n, typedTo } from '@data-access';
import {
  fieldsApi,
  useDeleteField,
  useFieldsQueries,
} from '@fields/data-access';

interface FieldHeaderProps {
  fieldId: string;
}

export function FieldHeader({ fieldId }: FieldHeaderProps) {
  const { t, i18n } = useTranslation();

  const fieldqueries = useFieldsQueries();
  const {
    data: { properties },
  } = useSuspenseQuery(fieldqueries.detail(Number(fieldId)));
  const deleteField = useDeleteField();
  const exportField = useMutation({
    mutationFn: fieldsApi.export,
  });

  const handleDelete = () => {
    modals.openConfirmModal({
      title: t('plots.delete.title'),

      children: (
        <Text>
          {t('plots.delete.content1')}{' '}
          <Text fw={700} size="lg" span>
            {properties.name}
          </Text>{' '}
          ? {t('plots.delete.content2')}
        </Text>
      ),

      labels: { confirm: t('common.delete'), cancel: t('common.cancel') },

      confirmProps: { color: 'red' },

      onConfirm: () => deleteField.mutate(Number(fieldId)),
    });
  };

  const actions: ActionsMenuItem[] = [
    {
      label: t('common.edit'),
      icon: IconEdit,
      type: 'link',
      props: { to: typedTo('/fields/$fieldId/edit'), params: { fieldId } },
    },
    {
      label: t('common.export'),
      icon: IconDownload,
      type: 'action',
      onClick: () => exportField.mutate({ ids: [Number(fieldId)] }),
    },
    {
      label: t('common.cut'),
      icon: IconCut,
      type: 'link',
      props: { to: typedTo('/fields/$fieldId/cut'), params: { fieldId } },
    },
    {
      label: t('common.delete'),
      icon: IconTrash,
      type: 'action',
      onClick: handleDelete,
      color: 'red',
    },
  ];

  return (
    <Group preventGrowOverflow>
      <Link
        params={{ fieldId }}
        style={{
          flexShrink: 0,
          flexGrow: 0,
        }}
        to={`/fields/$fieldId`}
      >
        <Image
          alt="field boundary thumbnail"
          h={36}
          src={properties.thumbnail || 'https://placehold.co/50x50'}
        />
      </Link>
      <Stack
        flex="1"
        gap={0}
        h={rem(36)}
        justify="center"
        style={{
          flexWrap: 'nowrap',
          minWidth: 0,
        }}
      >
        <TsrTextLink
          fw="bold"
          lh={1}
          params={{ fieldId }}
          size="lg"
          to="/fields/$fieldId"
          truncate="end"
        >
          {properties.name}
        </TsrTextLink>
        <Text lh={1} size="sm">
          {properties.area.toFixed(2)} ha -{' '}
          {fromBacki18n(properties.species, i18n.language)}
        </Text>
      </Stack>
      <ActionsMenu items={actions}>
        <ActionIcon variant="outline">
          <IconDotsVertical />
        </ActionIcon>
      </ActionsMenu>
    </Group>
  );
}

export default FieldHeader;
