import { Checkbox, Fieldset, Select } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRapeseedAdviceFormContext } from '../../../hooks/use-rapeseed-advice-form/rapeseed-advice-form';
import InfoLabel from '../../info-label/info-label';

import { useValues } from '@advices/data-access';
import { asCbData, CropEnum } from '@data-access';

export const HistoricFertilizerFieldset = () => {
  const { t, i18n } = useTranslation();
  const form = useRapeseedAdviceFormContext();
  const selectedFields = form.getValues().field;
  const [checked, setChecked] = useState(false);

  const values = useValues({
    type: CropEnum.RAPESEED,
    fieldId: selectedFields.length === 1 ? selectedFields[0] : null,
  });

  const handleChecked = () => {
    const next = !checked;
    setChecked(next);
    if (next) {
      form.setValues({
        pdt_org_hist: null,
        frq_org: null,
      });
    }
  };

  useEffect(() => {
    const { pdt_org_hist, frq_org } = form.getValues();
    const isEmpty = !pdt_org_hist && !frq_org;
    setChecked(isEmpty);
  }, []);

  return (
    <Fieldset
      legend={
        <InfoLabel
          info={t('advices.form.historic_fertilizer_legend_info')}
          label={t('advices.form.historic_fertilizer_legend')}
        />
      }
      mt="md"
      variant="filled"
    >
      <Checkbox
        checked={checked}
        label={t('advices.form.no_fall_fertilizer')}
        onChange={handleChecked}
      />
      <Fieldset disabled={checked} mt="sm" variant="unstyled">
        <Select
          {...form.getInputProps('pdt_org_hist')}
          data={asCbData(values.data?.pdt_org_hist(i18n.language))}
          label={t('advices.properties.pdt_org1')}
          required={!checked}
        />

        <Select
          {...form.getInputProps('frq_org')}
          data={values.data?.frq_org}
          label={t('advices.properties.frq_org')}
          mt="sm"
          required={!checked}
        />
      </Fieldset>
    </Fieldset>
  );
};
