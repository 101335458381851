import { Paper, Group, Title, Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import styles from './synthesis-header.module.css';

export function SynthesisHeader() {
  const { t } = useTranslation();

  return (
    <Paper>
      <Group justify="space-between">
        <Title order={2}>{t('synthesis.title')}</Title>
        <Group>
          <Button color="red.9">{t('synthesis.download')}</Button>
          <Button color="red.9">{t('synthesis.import')}</Button>
        </Group>
      </Group>
    </Paper>
  );
}

export default SynthesisHeader;
