import { captureException } from '@sentry/react';
import { HTTPError } from 'ky';

export const handleRequest = async <T>(request: Promise<T>) => {
  try {
    const res = await request;
    return res;
  } catch (error) {
    if (error instanceof HTTPError) {
      const err = await error.response.text();
      captureException(error, { extra: { response: err } });
      throw new Error(err);
    } else {
      captureException(error);
      throw error;
    }
  }
};
