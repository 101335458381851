import { AppShell, Group, rem, Skeleton } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useRouter } from '@tanstack/react-router';

import AppHeader from '../app-header/app-header';
import AppNavbar from '../app-navbar/app-navbar';

import { useAuth } from '@auth/data-access';
import { sharedApi } from '@data-access';

export function AppLayoutSkeleton() {
  return (
    <AppShell
      footer={{ height: 30 }}
      header={{ height: { base: 60, md: 70, lg: 80 } }}
      navbar={{
        width: { base: 200, md: 300, lg: 400 },
        breakpoint: 'sm',
      }}
      padding="md"
    >
      <AppShell.Header>
        <Skeleton h="100%" radius={0} w="100%" />
      </AppShell.Header>
      <AppShell.Navbar>
        <Skeleton h="100%" radius={0} w="100%" />
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
      <AppShell.Footer bg="gray.1"></AppShell.Footer>
    </AppShell>
  );
}

export function AppLayout() {
  const router = useRouter();
  const { logout } = useAuth();

  const cgu = useQuery({
    queryKey: ['cgu'],
    queryFn: () => sharedApi.cgu(),
  });

  const handleLogout = () => {
    logout().then(() => {
      router.invalidate();
    });
  };

  return (
    <AppShell
      footer={{ height: 30 }}
      header={{ height: { base: 60 } }}
      navbar={{
        width: rem(60),
        breakpoint: 0,
      }}
      padding="md"
    >
      <AppShell.Header>
        <AppHeader logout={handleLogout} />
      </AppShell.Header>
      <AppShell.Navbar>
        <AppNavbar />
      </AppShell.Navbar>
      <AppShell.Main bg="gray.1">
        <Outlet />
      </AppShell.Main>
      <AppShell.Footer bg="gray.1" px="md">
        <Group h="100%" justify="end">
          <a href={cgu.data?.file} rel="noopener noreferrer" target="_blank">
            CGU & CGV
          </a>
        </Group>
      </AppShell.Footer>
    </AppShell>
  );
}

export default AppLayout;
