import { Paper, Title } from '@mantine/core';

import PrescriptionMapForm from '../../../../components/prescription-map-form/prescription-map-form';

import styles from './page.module.css';

import { PfMap } from '@map';
import { FullMainHeight } from '@ui';

export function CreatePrescriptionMapPage() {
  return (
    <FullMainHeight.Root>
      <FullMainHeight.Header>
        <Paper>
          <Title order={2} size="h4">
            Ajouter une carte de rendement
          </Title>
        </Paper>
      </FullMainHeight.Header>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar>
          <Paper h="100%">
            <PrescriptionMapForm />
          </Paper>
        </FullMainHeight.Sidebar>
        <Paper h="100%" p={0}>
          <PfMap />
        </Paper>
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
}

export default CreatePrescriptionMapPage;
