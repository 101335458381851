import { NumberInput, NumberInputProps } from '@mantine/core';
import { forwardRef } from 'react';

import {
  getTotalArea,
  usePrescriptionFormContext,
} from '../../../../hooks/prescriptions/use-prescription-form/prescription-form';
import classes from '../prescription-form.module.css';

import { divide } from '@data-access';

export const TotalInput = forwardRef<HTMLInputElement>((props, ref) => {
  const form = usePrescriptionFormContext();

  const { asUnit, ratio, uTotal, zones, total } = form.getValues();

  const totalArea = getTotalArea(zones);

  const { onChange, ...inputProps } = form.getInputProps(
    asUnit ? 'uTotal' : 'total',
  );

  const handleChange: NumberInputProps['onChange'] = (value) => {
    const castedValue = typeof value === 'number' ? value : parseFloat(value);
    if (!asUnit) {
      // on total change, update zones values
      // calculate new average, uTotal, uAverage
      const average = divide(castedValue, totalArea);
      const zonesDraft = zones.map((zone) => {
        const draftValue = divide(zone.value * castedValue, total);
        return {
          ...zone,
          value: draftValue,
          uValue: draftValue * ratio,
        };
      });
      form.setValues({
        zones: zonesDraft,
        average,
        uTotal: castedValue * ratio,
        uAverage: average * ratio,
      });
    } else {
      // on uTotal change, update zones values
      const uAverage = divide(castedValue, totalArea);
      const zonesDraft = zones.map((zone) => {
        const draftUValue = divide(zone.uValue * castedValue, uTotal);
        return {
          ...zone,
          uValue: draftUValue,
          value: divide(draftUValue, ratio),
        };
      });
      form.setValues({
        zones: zonesDraft,
        uAverage,
        total: divide(castedValue, ratio),
        average: divide(uAverage, ratio),
      });
    }

    // call mantine onChange
    onChange(value);
  };

  return (
    <NumberInput
      {...inputProps}
      classNames={{ input: classes.quantityInput }}
      decimalScale={2}
      fixedDecimalScale
      hideControls
      onChange={handleChange}
      ref={ref}
      step={0.1}
      ta="right"
      w={100}
    />
  );
});
