import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { MAP_TYPES } from '@fields/data-access';
import { MapsPage } from '@fields/feature';

const mapTypes = z.object({
  mapType: z.nativeEnum(MAP_TYPES),
});

const mapSearchSchema = z.object({
  mapId: z.number().optional(),
  compare: z.array(z.nativeEnum(MAP_TYPES)).length(2).optional(),
});

export const Route = createFileRoute('/_app/fields_/$fieldId/maps/$mapType')({
  params: {
    parse: mapTypes.parse,
    stringify: (params) => ({ mapType: params.mapType }),
  },
  validateSearch: zodSearchValidator(mapSearchSchema),

  component: MapsPage,
  errorComponent: MapsPage.Error,
});
