import {
  ActionIcon,
  Button,
  Group,
  Paper,
  ScrollArea,
  Stack,
  Title,
} from '@mantine/core';
import { useDisclosure, useListState } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import PkDataCard from '../../components/pk-data-card/pk-data-card';
import PkForm from '../../components/pk-form/pk-form';
import PkPlanTableForm from '../../components/pk-plan-table-form/pk-plan-table-form';
import { pkFormActions, UsePkForm } from '../../hooks/use-pk-form/pk-form';

import { FORM_STATUS } from '@advices/data-access';
import { useQueriesWithGlobal } from '@data-access';
import { fieldsQueries, useUpdatePkAdvice } from '@fields/data-access';
import { Drawer } from '@ui';

export function PkPage() {
  const { t } = useTranslation();
  const { fieldId } = useParams({
    from: '/_app/fields_/$fieldId/pk',
  });

  const queries = useQueriesWithGlobal(fieldsQueries);
  const advice = useSuspenseQuery(queries.pkAdvice(Number(fieldId)));
  const tuto = useQuery(queries.pkTuto(Number(fieldId)));

  const [opened, actions] = useDisclosure();
  const [values, handlers] = useListState(
    advice.data.status === FORM_STATUS.FINISHED ? [{}] : [],
  );

  const updateAdvice = useUpdatePkAdvice();

  const handleSubmit = (v: UsePkForm['TransformedValues']) => {
    updateAdvice.mutate(
      {
        field: Number(fieldId),
        ...v,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      },
    );
  };

  const handleClose = () => {
    pkFormActions.reset();
    updateAdvice.reset();
    actions.close();
  };

  return (
    <Stack h="100%">
      <Paper flex="0 0 auto">
        <Button
          component="a"
          download
          href={tuto.data?.file}
          style={{ float: 'right' }}
        >
          {t('advices.list.tuto')}
        </Button>
      </Paper>
      <ScrollArea>
        <Stack>
          <PkDataCard fieldId={Number(fieldId)} onEdit={actions.open} />
          {values.map((value, index) => (
            <Paper key={index}>
              <Group>
                <Title order={3}>
                  {t('advice.fert_plan')} n°{index + 1}
                </Title>
                <ActionIcon
                  color="red"
                  disabled={index === 0}
                  onClick={() => handlers.remove(index)}
                >
                  <IconTrash />
                </ActionIcon>
              </Group>
              <PkPlanTableForm fieldId={Number(fieldId)} />
            </Paper>
          ))}
          <Button
            disabled={advice.data.status !== FORM_STATUS.FINISHED}
            onClick={() => handlers.append({})}
            w="fit-content"
          >
            {t('advices.add_fert_plan')}
          </Button>
        </Stack>
      </ScrollArea>

      <Drawer
        onClose={handleClose}
        opened={opened}
        position="right"
        title={t('common.edit_data')}
      >
        <PkForm
          error={updateAdvice.error}
          fieldId={Number(fieldId)}
          initialValues={{
            soil_type: advice.data.soil_type?.id.toString(),
            crop: advice.data.crop?.id.toString(),
            expected_yield: advice.data.expected_yield ?? undefined,
            withResidue: advice.data.residue !== null,
            residue: advice.data.residue ?? null,
            P2O5_impasse: advice.data.P2O5_impasse?.toString() ?? undefined,
            K2O_impasse: advice.data.K2O_impasse?.toString() ?? undefined,
            organic: advice.data.product !== null,
            product: advice.data.product?.id.toString() ?? null,
            product_qt: advice.data.product_qt ?? null,
            is_simplified: advice.data.is_simplified,
            P2O5_value: advice.data.P2O5_value ?? null,
            K2O_value: advice.data.K2O_value ?? null,
          }}
          isPending={updateAdvice.isPending}
          onCancel={handleClose}
          onSubmit={handleSubmit}
        />
      </Drawer>
    </Stack>
  );
}

export default PkPage;
