import { Group, HoverCard, ThemeIcon } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { ReactNode } from 'react';

import classes from './info-label.module.css';

interface InfoLabelProps {
  label: string;
  info: ReactNode;
}

export function InfoLabel({ label, info }: InfoLabelProps) {
  return (
    <HoverCard
      arrowPosition="center"
      classNames={{
        dropdown: classes.dropdown,
        arrow: classes.arrow,
      }}
      openDelay={250}
      position="left-start"
      width="target"
      withArrow
    >
      <HoverCard.Target>
        <Group component="span" gap="x3s" w="100%">
          {label}
          <ThemeIcon size="xs" variant="transparent">
            <IconInfoCircle />
          </ThemeIcon>
        </Group>
      </HoverCard.Target>
      <HoverCard.Dropdown>{info}</HoverCard.Dropdown>
    </HoverCard>
  );
}

export default InfoLabel;
