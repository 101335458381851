import { ActionIcon, Combobox, Group, useCombobox } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EnFlagIcon from '../icons/en-flag';
import FrFlagIcon from '../icons/fr-flag';

import styles from './lang-select.module.css';

interface Item {
  value: 'fr' | 'en';
  label: string;
}

const icons = {
  fr: (
    <FrFlagIcon
      className={styles['flag-icon']}
      style={{ width: '100%', height: '100%' }}
    />
  ),
  en: (
    <EnFlagIcon
      className={styles['flag-icon']}
      // style={{ width: '100%', height: '100%' }}
    />
  ),
};

const data: Item[] = [
  { value: 'fr', label: 'Français' },
  { value: 'en', label: 'English' },
];

export const LangSelect = () => {
  const { i18n } = useTranslation();
  const [value, setValue] = useState<string>(i18n.language);
  const selectedOption = data.find((item) => item.value === value);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = data
    .filter((item) => item.value !== value)
    .map((item) => (
      <Combobox.Option
        className={styles['option']}
        key={item.value}
        value={item.value}
      >
        <div className={styles['option-inner']}>
          <Group>{icons[item.value]}</Group>
        </div>
      </Combobox.Option>
    ));

  useEffect(() => {
    i18n.changeLanguage(value);
  }, [i18n, value]);

  return (
    <Combobox
      onOptionSubmit={(val) => {
        setValue(val);
        combobox.closeDropdown();
      }}
      store={combobox}
    >
      <Combobox.Target>
        {selectedOption ? (
          <ActionIcon
            aria-label={selectedOption.label}
            className={styles['flag-action-icon']}
            onClick={() => combobox.toggleDropdown()}
            radius="xl"
            size="lg"
            variant="default"
          >
            {icons[selectedOption.value]}
          </ActionIcon>
        ) : (
          <div />
        )}
      </Combobox.Target>

      <Combobox.Dropdown className={styles.dropdown}>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default LangSelect;
