import { useMutation } from '@tanstack/react-query';

import { authenticationAPI } from '../../apis/authentication-api';

export function useResetPassword() {
  return useMutation({
    mutationFn: authenticationAPI.resetPassword,
  });
}

export default useResetPassword;
