import { Paper, Group, Title } from '@mantine/core';
import { ErrorRouteComponent } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { CommonError, FullMainHeight } from '@ui';

export const ErrorPage: ErrorRouteComponent = ({ error, reset }) => {
  const { t } = useTranslation();

  return (
    <FullMainHeight.Root>
      <FullMainHeight.Header>
        <Paper>
          <Group justify="space-between">
            <Group>
              <Title order={2}>{t('advices.list.title')}</Title>
            </Group>
          </Group>
        </Paper>
      </FullMainHeight.Header>
      <FullMainHeight.Content>
        <CommonError
          description={t('common.error.pageError')}
          error={error}
          reset={reset}
        />
      </FullMainHeight.Content>
    </FullMainHeight.Root>
  );
};

export default ErrorPage;
