import { Text } from '@mantine/core';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { fromBacki18n, SpeciesRel } from '@data-access';
import { useFields, Field, useValues } from '@fields/data-access';
import { isMultieditRow, TsrButtonLink } from '@ui';

export const useFieldsTable = () => {
  const { t, i18n } = useTranslation();
  const fields = useFields();
  const values = useValues();

  const data = useMemo(() => {
    if (!fields.data) {
      return [];
    }

    return fields.data.results.features.map((f) => ({
      id: f.id as number,
      ...f.properties,
    }));
  }, [fields.data]);

  const columns = useMemo<MRT_ColumnDef<Field>[]>(
    () => [
      {
        accessorKey: 'thumbnail',
        enableEditing: false,
        enableColumnFilter: false,
        header: '',
        enableSorting: false,
        enableColumnActions: false,
        size: 32,
        Cell: ({ cell, row }) => {
          const thumb = cell.getValue<string>();
          if (thumb) {
            return <img alt="thumbnail" src={thumb} width="32" />;
          }
        },
      },
      {
        accessorKey: 'name',
        enableEditing: false,
        header: t('fields.col.field-name'),
        Cell: ({ cell, row }) => {
          if (isMultieditRow(row.id)) {
            return cell.getValue<string>();
          }

          return (
            <TsrButtonLink
              fullWidth
              justify="start"
              maw={250}
              ml="calc(var(--button-padding-x-compact-md) * -1)"
              params={{ fieldId: row.original.id.toString() }}
              size="compact-md"
              td="underline"
              to="/fields/$fieldId"
              tt="capitalize"
              variant="subtle"
            >
              <Text span truncate="end">
                {cell.getValue<string>()}
              </Text>
            </TsrButtonLink>
          );
        },
      },
      {
        accessorKey: 'farm.name',
        enableEditing: false,
        header: t('fields.col.farm-name'),
      },
      {
        accessorKey: 'area',
        enableEditing: false,
        header: t('common.area'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
        Cell: ({ cell }) =>
          cell.getValue<number>()?.toFixed(2).replace('.', ',').concat(' ha'),
      },
      {
        accessorKey: 'species',
        header: t('common.crop'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: values.data?.species(i18n.language),
        },
        editVariant: 'select',
        mantineEditSelectProps: {
          variant: 'default',
          allowDeselect: false,
          data: values.data?.species(i18n.language),
        },
        enableEditing(row) {
          return isMultieditRow(row.id);
        },
        Cell: ({ cell }) => {
          const species = cell.getValue<SpeciesRel>();
          const name = fromBacki18n(species, i18n.language);
          return name;
        },
      },
    ],
    [i18n.language, t, values.data],
  );

  return {
    fields,
    data,
    columns,
  };
};
