import { useMutation } from '@tanstack/react-query';

import { fieldsApi } from '../../apis/fields';

export function useDeleteField() {
  const mutation = useMutation({
    mutationFn: fieldsApi.deleteOne,
  });

  return mutation;
}

export default useDeleteField;
