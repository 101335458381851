import { Box, Kbd, Button, Group, Text } from '@mantine/core';
import { Trans, useTranslation } from 'react-i18next';

import { GuidanceMode } from '../draw-toolbox-control';

interface GuideProps {
  guidanceMode: GuidanceMode;
  isSelecting: boolean;
  handleDrawPassingCut: () => void;
  handleEndGuidanceMode: (confirm: boolean) => void;
}

const Guide = ({
  guidanceMode,
  isSelecting,
  handleDrawPassingCut,
  handleEndGuidanceMode,
}: GuideProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Text size="lg">{t(`map.drawTools.${guidanceMode}Title`)}</Text>
      <Text mb="lg" size="sm">
        <Trans
          components={{
            kbd: <Kbd size="xs" />,
          }}
          i18nKey={`map.drawTools.${guidanceMode}Description`}
          t={t}
        />
      </Text>

      {guidanceMode === 'cut' || guidanceMode === 'split' ? (
        <Button
          disabled={!isSelecting}
          fullWidth
          mb="xs"
          onClick={handleDrawPassingCut}
          size="compact-sm"
        >
          {t(`map.drawTools.drawCutout`)}
        </Button>
      ) : null}
      <Group gap="xs" grow>
        <Button onClick={() => handleEndGuidanceMode(true)} size="compact-sm">
          {t(`map.drawTools.${guidanceMode}Confirm`)}
        </Button>
        <Button
          color="red"
          onClick={() => handleEndGuidanceMode(false)}
          size="compact-sm"
        >
          {t(`map.drawTools.${guidanceMode}Cancel`)}
        </Button>
      </Group>
    </Box>
  );
};

export default Guide;
