import { ACCOUNTS_STORAGE_KEY } from '../stores/accounts.atom';

export const getAccounts = () => {
  const token = localStorage.getItem(ACCOUNTS_STORAGE_KEY);

  if (!token) {
    return [];
  }

  return JSON.parse(token) as number[];
};

export default getAccounts;
