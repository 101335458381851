import { useQuery } from '@tanstack/react-query';

import { advicesQueries } from '../../queries/advices';

import { useFiltersStore, useQueriesWithGlobal } from '@data-access';

interface Params {
  type: string;
}

export function useCommonAdvices({ type }: Params) {
  const page = useFiltersStore.use['advices']().pagination.pageIndex + 1;
  const sorting = useFiltersStore.use['advices']().sorting;
  const filters = useFiltersStore.use['advices']().filters;

  const queries = useQueriesWithGlobal(advicesQueries);

  const query = useQuery(
    queries.list({
      crop: type,
      filters: { filters, page, sorting },
    }),
  );

  return query;
}

export default useCommonAdvices;
