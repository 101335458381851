import { Fieldset, Select, NumberInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useCommonAdviceFormContext } from '../../../hooks/use-common-advice-form/common-advice-form';
import InfoLabel from '../../info-label/info-label';

import { useValues } from '@advices/data-access';
import { asCbData } from '@data-access';

interface AgronomicDataFieldsetProps {
  type: string;
}

export const AgronomicDataFieldset = ({ type }: AgronomicDataFieldsetProps) => {
  const { t } = useTranslation();
  const form = useCommonAdviceFormContext();
  const selectedFields = form.getValues().field;

  const frenchDateParser = (value: string) => {
    const [day, month, year] = value.split(/[/\s-]+/).map(Number);
    return new Date(year, month - 1, day);
  };
  
  const values = useValues({
    type,
    fieldId: selectedFields.length === 1 ? selectedFields[0] : null,
  });

  return (
    <Fieldset legend={t('advices.agronomic_data')} variant="filled">
      <Select
        allowDeselect={false}
        data={asCbData(values.data?.variety)}
        label={t('common.variety')}
        searchable
        {...form.getInputProps('variety')}
      />

      <DateInput
        {...form.getInputProps('seed_date')}
        dateParser={frenchDateParser}
        defaultDate={dayjs(values.data?.seed_date).toDate()}
        // key is needed to ensure that date input will be re-rendered to set default date
        key={dayjs(values.data?.seed_date).toDate().toString()}
        label={t('common.seed_date')}
        mt="sm"
        rightSection={<IconCalendar height="60%" width="60%" />}
        valueFormat="DD/MM/YYYY"
      />

      <Select
        data={values.data?.yield_potential?.map((item) => ({
          value: item,
          label: t(`common.properties.yield_potential.${item}`),
        }))}
        label={
          <InfoLabel
            info={t('common.properties.yield_potential_info')}
            label={t('advices.properties.yield_potential')}
          />
        }
        mt="sm"
        {...form.getInputProps('yield_potential')}
      />

      <NumberInput
        label={
          <InfoLabel
            info={t('common.properties.olympic_yield_info')}
            label={t('advices.properties.olympic_yield')}
          />
        }
        mt="sm"
        {...form.getInputProps('olympic_yield')}
      />

      <NumberInput
        label={
          <InfoLabel
            info={t('advices.properties.x_dose_info')}
            label={t('advices.properties.x_dose')}
          />
        }
        mt="sm"
        {...form.getInputProps('X_dose')}
      />
    </Fieldset>
  );
};
