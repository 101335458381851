import { useMutation } from '@tanstack/react-query';

import { fieldsApi } from '../../apis/fields';

export function useUpdateFields() {
  const mutation = useMutation({
    mutationFn: fieldsApi.updateMany,
  });

  return mutation;
}

export default useUpdateFields;
