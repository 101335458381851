import { Box, BoxProps, Tooltip, UnstyledButton, rem } from '@mantine/core';
import { Link, LinkProps } from '@tanstack/react-router';

import styles from './navbar-link.module.css';

interface NavbarLinkProps {
  icon: React.FC<any>;
  label: string;
  to: LinkProps['to'];
  active?: boolean;
  onClick?(): void;
  hiddenFrom?: BoxProps['hiddenFrom'];
  visibleFrom?: BoxProps['visibleFrom'];
}

export function NavbarLink({
  icon: Icon,
  label,
  active,
  to,
  ...boxProps
}: NavbarLinkProps) {
  return (
    <Box {...boxProps}>
      <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
        <UnstyledButton
          className={styles.link}
          component={Link}
          data-active={active || undefined}
          to={to}
        >
          <Icon stroke={'1.5'} style={{ width: rem(20), height: rem(20) }} />
        </UnstyledButton>
      </Tooltip>
    </Box>
  );
}

export default NavbarLink;
