import { useId, useResizeObserver } from '@mantine/hooks';
import { Map as IMap, MapEvent } from 'mapbox-gl';
import { forwardRef, useEffect, useRef } from 'react';
import { Layer, Map, MapRef, Source } from 'react-map-gl';

const baseViewState = {
  longitude: 2.73457,
  latitude: 47.4138,
  zoom: 6,
};

type MapProps = React.ComponentProps<typeof Map>;

/* eslint-disable-next-line */
export interface PfMapProps extends MapProps {
  classnames?: {
    container?: string;
  };
  children?: React.ReactNode;
}

const isTest =
  typeof process !== 'undefined'
    ? process.env['NEXT_PUBLIC_CYPRESS'] === 'true'
    : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - import is used by vite
      import.meta.env.VITE_CYPRESS === 'true';

export const PfMap = forwardRef<MapRef, PfMapProps>((props, ref) => {
  const id = useId();
  const [containerRef, rect] = useResizeObserver();
  const mapRef = useRef<IMap>();
  const token = useRef(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - import is used by vite
    import.meta.env.VITE_MAPBOX_TOKEN,
  );

  const handleLoad = (e: MapEvent) => {
    const map = e.target as IMap;
    map.resize();

    props.onLoad?.(e);
    mapRef.current = map;
    if (!map.hasImage('rounded')) {
      map.loadImage('./rounded.png', (error, image) => {
        if (error || !image) return;
        map.addImage('rounded', image, {
          content: [3, 3, 13, 13],
          stretchX: [[7, 9]],
          stretchY: [[7, 9]],
        });
      });
    }
    // add .map-loaded class to body
    if (typeof document !== 'undefined') {
      document.body.setAttribute('data-testid', 'map-loaded');
    }
  };

  const { initialViewState, children, classnames = {} } = props;

  useEffect(() => {
    mapRef.current?.resize();
  }, [rect]);

  return (
    <div
      className={classnames.container}
      ref={containerRef}
      style={{ width: '100%', height: '100%' }}
    >
      <Map
        {...props}
        attributionControl={false}
        dragRotate={false}
        id={props.id || id}
        initialViewState={initialViewState || baseViewState}
        interactiveLayerIds={props.interactiveLayerIds}
        logoPosition="bottom-right"
        mapStyle={
          !isTest
            ? 'mapbox://styles/mapbox/satellite-streets-v12'
            : {
                version: 8,
                glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
                layers: [
                  {
                    id: 'background',
                    type: 'background',
                    paint: { 'background-color': 'rgb(4,7,14)' },
                  },
                ],
                sources: {},
              }
        }
        mapboxAccessToken={!isTest ? token.current : undefined}
        onClick={props.onClick}
        onLoad={handleLoad}
        ref={ref}
        refreshExpiredTiles={false}
        reuseMaps
        style={{ width: '100%', height: '100%' }}
        testMode={isTest}
      >
        <Source
          data={{ type: 'FeatureCollection', features: [] }}
          id="empty"
          type="geojson"
        >
          <Layer id="z-index-2" type="symbol" />
          <Layer beforeId="z-index-2" id="z-index-1" type="symbol" />
        </Source>
        {children}
      </Map>
    </div>
  );
});

export default PfMap;
