import { Paper, Title } from '@mantine/core';

import { SoilMapForm } from '../../../../components/soil-map-form/soil-map-form';
import { useCreateMapPageShared } from '../../../../hooks/use-create-map-page-shared/create-map-page-shared';

import { MAP_TYPES, useProcessSoilMap } from '@fields/data-access';
import { PfMap } from '@map';
import { FullMainHeight } from '@ui';
import { ProcessSoilMapDto } from 'modules/fields/data-access/src/lib/types/dto';

export function CreateSoilMapPage() {
  const { t, navToField, handleLoad, fieldId } = useCreateMapPageShared();

  const processMap = useProcessSoilMap();

  const handleSubmit = (values: ProcessSoilMapDto) => {
    processMap.mutate(values, {
      onSuccess: () => {
        navToField(MAP_TYPES.SOIL);
      },
    });
  };

  return (
    <FullMainHeight.Root>
      <FullMainHeight.Header>
        <Paper>
          <Title order={2}>{t('field.soil-map.add-title')}</Title>
        </Paper>
      </FullMainHeight.Header>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar>
          <Paper h="100%">
            <SoilMapForm
              fieldId={Number(fieldId)}
              loading={processMap.isPending}
              onSubmit={handleSubmit}
            />
          </Paper>
        </FullMainHeight.Sidebar>
        <Paper h="100%" p={0}>
          <PfMap onLoad={handleLoad} />
        </Paper>
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
}

export default CreateSoilMapPage;
