import { Accordion, Text } from '@mantine/core';
import {
  IconCheckupList,
  IconArrowRightDashed,
  IconCircleLetterN,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import CheckList from '../check-list/check-list';

import styles from './pk-form-info.module.css';

import { fromBacki18n, PkForm } from '@data-access';

export interface PkFormInfoProps {
  form: PkForm;
  withItemsIcon?: boolean;
}

export function PkFormInfo({ form, withItemsIcon = true }: PkFormInfoProps) {
  const { t } = useTranslation();

  const agronomicList = [
    {
      value: form.soil_type?.name,
      label: 'Type de sol',
    },
    {
      value: form.crop.name,
      label: 'Culture à fertiliser',
    },
    {
      value: form.expected_yield,
      label: 'Objectif de rendement',
      unit: form.crop.unit,
    },
    {
      value: form.residue ? t('common.yes') : t('common.no'),
      label: 'Résidus exportés',
    },
  ];

  const impasseList = [
    {
      value: form.P2O5_impasse,
      label: 'P205',
    },
    {
      value: form.K2O_impasse,
      label: 'K20',
    },
  ];

  const fertList = [
    {
      value: form.product?.name ?? null,
      label: 'Intrant',
    },
    {
      value: form.product_qt,
      label: 'Quantité',
      unit: form.product?.unit,
    },
  ];

  const accordion = [
    {
      title: t('advices.agronomic_data'),
      items: agronomicList,
      icon: <IconCheckupList />,
    },
    {
      title: 'Impasse de fertilisation',
      items: impasseList,
      icon: <IconArrowRightDashed />,
    },
    {
      title: 'Fertilisation organique',
      items: fertList,
      icon: <IconCircleLetterN />,
    },
  ];

  return (
    <Accordion defaultValue={accordion.map(({ title }) => title)} multiple>
      {accordion.map(({ title, items, icon }) => (
        <Accordion.Item key={title} value={title}>
          <Accordion.Control icon={icon}>
            <Text fw="bold" size="lg">
              {title}
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <CheckList items={items} withIcon={withItemsIcon} />
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

export default PkFormInfo;
