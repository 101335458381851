import { Fieldset, Select, NumberInput, Checkbox } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useRapeseedAdviceFormContext } from '../../../hooks/use-rapeseed-advice-form/rapeseed-advice-form';
import InfoLabel from '../../info-label/info-label';

import { SoilTypeInfo } from './soil-type-info';

import { useValues } from '@advices/data-access';
import { asCbData, CropEnum } from '@data-access';


export const AgronomicDataFieldset = () => {
  const { t } = useTranslation();
  const form = useRapeseedAdviceFormContext();
  const selectedFields = form.getValues().field;

  const frenchDateParser = (value: string) => {
    const [day, month, year] = value.split(/[/\s-]+/).map(Number);
    return new Date(year, month - 1, day);
  };

  const values = useValues({
    type: CropEnum.RAPESEED,
    fieldId: selectedFields.length === 1 ? selectedFields[0] : null,
  });

  return (
    <Fieldset legend={t('advices.agronomic_data')} variant="filled">
      <Select
        {...form.getInputProps('variety')}
        data={asCbData(values.data?.variety)}
        label={t('common.variety')}
      />

      <DateInput
        {...form.getInputProps('seed_date')}
        dateParser={frenchDateParser}
        defaultDate={dayjs(values.data?.seed_date).toDate()}
        // key is needed to ensure that date input will be re-rendered to set default date
        key={dayjs(values.data?.seed_date).toDate().toString()}
        label={t('common.seed_date')}
        mt="sm"
        rightSection={<IconCalendar height="60%" width="60%" />}
        valueFormat="DD/MM/YYYY" 
      />

      <Select
        {...form.getInputProps('sol')}
        data={values.data?.sol?.map((item) => ({
          value: item,
          label: t(`advices.properties.sol_types.${item}`),
        }))}        
        label={
          <InfoLabel info={<SoilTypeInfo />} label={t('common.soilType')} />
        }
        mt="sm"
      />

      <NumberInput
        {...form.getInputProps('rdt')}
        hideControls
        label={
          <InfoLabel
            info={t('advices.rapeseed.rdt_info')}
            label={t('advices.properties.rdt')}
          />
        }
        mt="sm"
      />

      <Checkbox
        {...form.getInputProps('cz_pois_prt', { type: 'checkbox' })}
        label={t('advices.properties.cz_pois_prt')}
        mt="lg"
      />
      <Checkbox
        {...form.getInputProps('cz_leg', { type: 'checkbox' })}
        label={
          <InfoLabel
            info={t('advices.properties.cz_leg_info')}
            label={t('advices.properties.cz_leg')}
          />
        }
        mt="sm"
      />
    </Fieldset>
  );
};
