import { rem } from '@mantine/core';

import { manureImage } from '@assets';

interface ManureIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export function ManureIcon({ size, style, ...others }: ManureIconProps) {
  return (
    <svg
      fill="none"
      style={{ width: rem(size), height: rem(size), ...style }}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <image height={512} href={manureImage} width={512} />
    </svg>
  );
}
