import { OnChangeFn, RowSelectionState } from '@tanstack/table-core';
import {
  MRT_RowData,
  MRT_RowSelectionState,
  MRT_TableOptions,
} from 'mantine-react-table';
import { useEffect, useMemo } from 'react';

import { MULTI_EDIT_ROW_ID } from './multiedit-row';

type UseRowSelectionParams<TData extends MRT_RowData> = {
  data: TData[];
  rowSelection?: MRT_RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
};

type UseRowSelection<TData extends MRT_RowData> = {
  enableRowSelection: MRT_TableOptions<TData>['enableRowSelection'];
  selectAllMode?: MRT_TableOptions<TData>['selectAllMode'];
  onRowSelectionChange?: MRT_TableOptions<TData>['onRowSelectionChange'];
  mantineSelectAllCheckboxProps?: MRT_TableOptions<TData>['mantineSelectAllCheckboxProps'];
  mantineSelectCheckboxProps?: MRT_TableOptions<TData>['mantineSelectCheckboxProps'];
};

export const useRowSelection = <T extends MRT_RowData>({
  data,
  rowSelection,
  setRowSelection,
}: UseRowSelectionParams<T>): UseRowSelection<T> => {
  const props: UseRowSelection<T> = useMemo(() => {
    if (!setRowSelection || !rowSelection) {
      return {
        enableRowSelection: false,
      };
    }
    return {
      enableRowSelection: (row) => row.id !== MULTI_EDIT_ROW_ID,
      selectAllMode: 'page',
      onRowSelectionChange: (c) => {
        setRowSelection(c);
      },
      mantineSelectAllCheckboxProps: ({ table }) => {
        const selectedRows = table.getState().rowSelection;
        const setSelectedRows = table.setRowSelection;
        return {
          checked: selectedRows.all,
          indeterminate:
            !selectedRows.all && Object.keys(selectedRows).length > 0,
          onChange: () => {
            setSelectedRows((p) => {
              if (p.all) {
                return {};
              } else {
                const idsMap = data.reduce(
                  (acc, row: T) => {
                    acc[row.id] = true;
                    return acc;
                  },
                  {} as Record<string, boolean>,
                );
                return { all: true, ...idsMap };
              }
            });
          },
        };
      },
      mantineSelectCheckboxProps: ({ row, table }) => {
        const setSelectedRows = table.setRowSelection;
        const selectedRows = table.getState().rowSelection;

        return {
          checked: selectedRows.all || row.getIsSelected(),
          onClick: (e) => {
            row.getToggleSelectedHandler();
            setSelectedRows((p) => {
              if (p.all) {
                delete p.all;
              }
              return p;
            });
          },
        };
      },
    };
  }, [data, rowSelection, setRowSelection]);

  useEffect(() => {
    if (!setRowSelection || !rowSelection) {
      return;
    }
    // On data change toggle row selection for newly fetched data
    const isNewDataSelected = data.some((row) => rowSelection[row.id]);
    if (data.length && !isNewDataSelected && rowSelection.all) {
      const idsMap = data.reduce(
        (acc, row) => {
          acc[row.id] = true;
          return acc;
        },
        {} as Record<string, boolean>,
      );
      setRowSelection((prev) => ({ ...prev, all: true, ...idsMap }));
    }
  }, [data, rowSelection, setRowSelection]);

  return props;
};
