import { BarChart, BarChartProps } from '@mantine/charts';

import OverlayedBarLegend from '../overlayed-bar-legend/overlayed-bar-legend';

interface OverlayedBarChartProps {
  data: BarChartProps['data'];
  referenceValue?: number;
  referenceLabel?: string;
  baselineDataKey: string;
  actualDataKey: string;
  title: React.ReactNode;
  actualDataColor?: string;
}

export function OverlayedBarChart({
  data,
  referenceValue,
  baselineDataKey,
  actualDataKey,
  title,
  referenceLabel,
  actualDataColor,
}: OverlayedBarChartProps) {
  return (
    <BarChart
      barChartProps={{ barGap: -52 }}
      barProps={(series) => {
        if (series.name === baselineDataKey) {
          return { barSize: 60 };
        }
        if (series.name === actualDataKey) {
          return { barSize: 45 };
        }
        return {};
      }}
      data={data}
      dataKey="name"
      h={350}
      legendProps={{
        verticalAlign: 'bottom',
        content: (
          <OverlayedBarLegend
            referenceLabel={referenceLabel}
            title={title}
            withReference={!!referenceValue}
          />
        ),
      }}
      referenceLines={
        referenceValue
          ? [{ stroke: 'red', strokeWidth: 3, y: referenceValue }]
          : undefined
      }
      series={[
        { name: baselineDataKey, color: '#D9D9D9' },
        { name: actualDataKey, color: actualDataColor ?? '#E2C949' },
      ]}
      withLegend
    />
  );
}

export default OverlayedBarChart;
