import { Group } from '@mantine/core';

import classes from './full-main-height.module.css';

interface FullMainHeightFlexContentProps {
  children: React.ReactNode;
}

export const FullMainHeightFlexContent = ({
  children,
}: FullMainHeightFlexContentProps) => {
  return (
    <div className={classes.contentWrapper}>
      <Group
        align="start"
        className={classes.content}
        grow
        preventGrowOverflow={false}
        wrap="nowrap"
      >
        {children}
      </Group>
    </div>
  );
};
