import { Grid, Paper, Group, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { TsrButtonLink } from '@ui';

export function DashboardHeader() {
  const { t } = useTranslation();
  return (
    <>
      <Grid.Col span={{ base: 12, lg: 8 }}>
        <Paper>
          <Group justify="space-between">
            <Title order={2}>{t('dashboard.title')}</Title>
            <TsrButtonLink disabled to="/synthesis">
              {t('dashboard.seeSynthesis')}
            </TsrButtonLink>
          </Group>
        </Paper>
      </Grid.Col>
      <Grid.Col order={{ base: 3, lg: 2 }} span={{ base: 12, lg: 4 }}>
        <Paper>
          <Title order={2}>{t('dashboard.newsTitle')}</Title>
        </Paper>
      </Grid.Col>
    </>
  );
}

export default DashboardHeader;
