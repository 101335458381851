import { queryOptions } from '@tanstack/react-query';

import { fieldMapsApi } from '../apis/field-maps';

import { fieldsQueries } from './fields-queries';

import { Globals, MultisectActionDto } from '@data-access';

export const fieldMapsQueries = (global: Globals) => ({
  maps: () => [...fieldsQueries(global).all(), 'maps'],
  mapDetail: (params: { type: string; mapId: number }) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).maps(), params.type, params.mapId],
      queryFn: () => fieldMapsApi.getMap(params),
    }),

  soil: () => [...fieldsQueries(global).maps(), 'soil'],
  soilSummary: (fieldId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).soil(), 'summary', { fieldId }],
      queryFn: () => fieldMapsApi.getSoilMapSummary(fieldId),
    }),
  soilMap: (soilMapId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).soil(), 'map', soilMapId],
      queryFn: () => fieldMapsApi.getSoilMap(soilMapId),
    }),

  heatmaps: () => [...fieldsQueries(global).maps(), 'heatmaps'],
  heatmapsSummary: (args: { fieldId: number; heatmapType: string }) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).heatmaps(), 'summary', args],
      queryFn: () => fieldMapsApi.getHeatmaps(args),
    }),

  yield: () => [...fieldsQueries(global).maps(), 'yield'],
  yieldSummary: (fieldId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).yield(), 'summary', { fieldId }],
      queryFn: () => fieldMapsApi.getYieldMapSummary(fieldId),
    }),
  yieldMap: (yieldMapId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).yield(), 'map', yieldMapId],
      queryFn: () => fieldMapsApi.getYieldMap(yieldMapId),
    }),

  prescriptions: () => [...fieldsQueries(global).maps(), 'prescriptions'],
  prescriptionsSummary: (fieldId: number) =>
    queryOptions({
      queryKey: [
        ...fieldsQueries(global).prescriptions(),
        'summary',
        { fieldId },
      ],
      queryFn: () => fieldMapsApi.getNitrogenMapSummary(fieldId),
    }),
  prescriptionsMap: (prescriptionsMapId: number) =>
    queryOptions({
      queryKey: [
        ...fieldsQueries(global).prescriptions(),
        'map',
        prescriptionsMapId,
      ],
      queryFn: () => fieldMapsApi.getNitrogenMap(prescriptionsMapId),
    }),
  prescriptionsExportValues: (data: MultisectActionDto) =>
    queryOptions({
      queryKey: [
        ...fieldsQueries(global).prescriptions(),
        'exportValues',
        data,
      ],
      queryFn: () => fieldMapsApi.getNitrogenExportValues(data),
    }),

  potentials: () => [...fieldsQueries(global).maps(), 'potentials'],
  potentialsSummary: (fieldId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).potentials(), 'summary', { fieldId }],
      queryFn: () => fieldMapsApi.getPotentialMapSummary(fieldId),
    }),
  potentialsMap: (potentialsMapId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).potentials(), 'map', potentialsMapId],
      queryFn: () => fieldMapsApi.getPotentialMap(potentialsMapId),
    }),

  biomass: () => [...fieldsQueries(global).maps(), 'biomass'],
  biomassSummary: (fieldId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).biomass(), 'summary', { fieldId }],
      queryFn: () => fieldMapsApi.getBiomassMapSummary(fieldId),
    }),
  biomassMap: (biomassMapId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).biomass(), 'map', biomassMapId],
      queryFn: () => fieldMapsApi.getBiomassMap(biomassMapId),
    }),

  samples: () => [...fieldsQueries(global).maps(), 'samples'],
  samplesSummary: (fieldId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).samples(), 'summary', { fieldId }],
      queryFn: () => fieldMapsApi.getSamplesSummary(fieldId),
    }),
  samplesMap: (samplesMapId: number) =>
    queryOptions({
      queryKey: [...fieldsQueries(global).samples(), 'map', samplesMapId],
      queryFn: () => fieldMapsApi.getSamplesMap(samplesMapId),
    }),
});
