import { ActionIcon, ActionIconProps } from '@mantine/core';
import { createLink } from '@tanstack/react-router';
import { forwardRef } from 'react';

const LinkActionIcon = forwardRef<HTMLAnchorElement, ActionIconProps>(
  (props, ref) => <ActionIcon component="a" {...props} ref={ref} />
);

export const TsrAILink = createLink(LinkActionIcon);

export default TsrAILink;
