import { useMutation } from '@tanstack/react-query';

import { advicesApi } from '../../apis/advices.api';

export function useDeleteAdvice() {
  return useMutation({
    mutationFn: advicesApi.deleteOne,
  });
}

export default useDeleteAdvice;
