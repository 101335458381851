import {
  Stack,
  Paper,
  Group,
  Text,
  Select,
  Slider,
  Box,
  Checkbox,
  Button,
} from '@mantine/core';
import { TransformedValues, useForm } from '@mantine/form';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useParams, useRouter } from '@tanstack/react-router';
import { featureCollection } from '@turf/turf';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, Source } from 'react-map-gl';

import classes from './page.module.css';

import { advicesApi, useAdvicesQueries } from '@advices/data-access';
import { layersAdminOptions, useHandleNavBack } from '@data-access';
import { MAP_TYPES, useFieldsQueries } from '@fields/data-access';
import {
  PfMap,
  PfMapProps,
  PLOTS_LAYER_ID,
  PlotsLayer,
  SelectImageSlider,
  zoomOnFeature,
} from '@map';
import { FieldHeader, FullMainHeight } from '@ui';

export function RapeseedAdviceAdminPage() {
  const { t } = useTranslation();
  const { fieldId } = useParams({
    from: '/_app/fields_/$fieldId_/maps/biomass_/settings',
  });
  const { navigate } = useRouter();

  const handleBack = useHandleNavBack({
    defaultNavigation: {
      to: '/fields/$fieldId/maps/$mapType',
      params: {
        fieldId,
        mapType: MAP_TYPES.BIOMASS,
      },
    },
  });
  const [heatmapType, setHeatmapType] = useState('RGB');
  const [rasterOpacity, setRasterOpacity] = useState(100);
  const [rasterOpacityEnd, setRasterOpacityEnd] = useState(100);
  const queries = useAdvicesQueries();
  const fieldsQueries = useFieldsQueries();

  const field = useSuspenseQuery(fieldsQueries.detail(Number(fieldId)));
  const { data } = useQuery(
    queries.rapeseedAdmin({
      fieldId: field.data.id,
      layer: heatmapType,
    }),
  );
  const updateSettings = useMutation({
    mutationFn: advicesApi.updateRapeseedSettings,
  });

  const initialSelected = useMemo(
    () =>
      data?.data
        .filter((heatmap) => !heatmap.clouds)
        .map((heatmap) => heatmap.id.toString()) || [],
    [data?.data],
  );

  const form = useForm({
    initialValues: {
      is_hidden: false,
      disable_mail: false,
      selectedHeatmaps: initialSelected || [],
      selectedHeatmap: initialSelected[0] || null,
    },
    transformValues(values) {
      const { is_hidden, disable_mail, selectedHeatmaps } = values;

      const add = selectedHeatmaps
        .filter((id) => !initialSelected?.includes(id))
        .map((id) => Number(id));

      const d = initialSelected
        ?.filter((id) => !selectedHeatmaps.includes(id))
        .map((id) => Number(id));

      const draft = {
        is_hidden,
        disable_mail,
        add,
        delete: d,
      };

      return draft;
    },
  });

  const heatmapData = data?.data.find(
    (heatmap) => heatmap.id.toString() === form.getValues().selectedHeatmap,
  );

  const handleMapLoad: PfMapProps['onLoad'] = (map) => {
    zoomOnFeature({
      geojson: field.data,
      map: map.target,
    });
  };

  const formatUrl = (url: string) => {
    // rreplace the BBOX search parameter with {bbox-epsg-3857}
    const bbox = 'bbox-value';
    const urlObject = new URL(url);
    urlObject.searchParams.set('BBOX', bbox);
    return urlObject.href.replace(bbox, '{bbox-epsg-3857}');
  };

  const handleHeatmapChange = (value: string) => {
    form.setFieldValue('selectedHeatmap', value);
  };

  const handleHeatmapsChange = (value: string[]) => {
    form.setFieldValue('selectedHeatmaps', value);
  };

  type Transformed = TransformedValues<typeof form>;

  const handleSubmit = (v: Transformed) => {
    updateSettings.mutate({
      fieldId: field.data.id,
      ...v,
      },
      {
        onSuccess: () => {
          navigate({
            to: '/fields/$fieldId',
            params: {
              fieldId,
            },
          })
      },
    });
  };

  useEffect(() => {
    if (data) {
      form.initialize({
        is_hidden: data.is_hidden,
        disable_mail: data.disable_mail,
        selectedHeatmaps: initialSelected || [],
        selectedHeatmap: initialSelected?.[0] || null,
      });
    }
  }, [data, initialSelected]);

  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar size="lg">
          <Stack className={classes.navCol}>
            <Paper className={classes.navHeader}>
              <FieldHeader fieldId={fieldId} />
            </Paper>
            <Paper className={classes.navBar}>
              <form
                className={classes.form}
                onSubmit={form.onSubmit(handleSubmit)}
              >
                <Box>
                  <Checkbox
                    {...form.getInputProps('disable_mail', {
                      type: 'checkbox',
                    })}
                    label="Bloquer l'envoi de mail"
                    labelPosition="left"
                    size="md"
                    styles={{
                      body: {
                        justifyContent: 'space-between',
                      },
                    }}
                    w="60%"
                  />
                  <Checkbox
                    {...form.getInputProps('is_hidden', {
                      type: 'checkbox',
                    })}
                    label="Masquer le conseil"
                    labelPosition="left"
                    mt="md"
                    size="md"
                    styles={{
                      body: {
                        justifyContent: 'space-between',
                      },
                    }}
                    w="60%"
                  />
                </Box>
                <Group>
                  <Button type="submit">Enregistrer</Button>
                  <Button color="red" onClick={handleBack} type="button">
                    Retour
                  </Button>
                </Group>
              </form>
            </Paper>
          </Stack>
        </FullMainHeight.Sidebar>
        <Stack h="100%" style={{ overflow: 'hidden' }}>
          <Paper>
            <Group justify="end">
              <Box miw={250}>
                <Text size="md">Opacité de la carte</Text>
                <Slider
                  marks={[{ value: 20 }, { value: 50 }, { value: 80 }]}
                  onChange={setRasterOpacity}
                  onChangeEnd={setRasterOpacityEnd}
                  value={rasterOpacity}
                />
              </Box>
              <Select
                data={layersAdminOptions(t)}
                onChange={(v) => setHeatmapType(v ?? 'LAI')}
                value={heatmapType}
              />
            </Group>
          </Paper>
          <Paper h="100%" p={0}>
            <Stack gap={0} h="100%">
              <PfMap onLoad={handleMapLoad}>
                {heatmapData ? (
                  <Source
                    bounds={data?.maxbounds}
                    id="wms-sources"
                    minZoom={14}
                    tileSize={256}
                    tiles={[formatUrl(heatmapData.url)]}
                    type="raster"
                  >
                    <Layer
                      beforeId={`${PLOTS_LAYER_ID}_outline`}
                      id="wms-layer"
                      minzoom={14}
                      paint={{
                        'raster-opacity': rasterOpacityEnd / 100,
                        'raster-opacity-transition': { duration: 0 },
                      }}
                      source="wms-sources"
                      type="raster"
                    />
                  </Source>
                ) : null}
                <PlotsLayer data={featureCollection([field.data])} outline />
              </PfMap>
              <SelectImageSlider
                key={heatmapType + data?.data?.length}
                multiSelect={false}
                onChange={handleHeatmapChange}
                onSubValueChange={handleHeatmapsChange}
                options={
                  data?.data.map((heatmap) => ({
                    value: heatmap.id.toString(),
                    src: heatmap.thumbnail,
                    valueLabel: heatmap.date,
                    manual_check: false
                  })) || []
                }
                subValue={form.getValues().selectedHeatmaps}
                value={form.getValues().selectedHeatmap || ''}
              />
            </Stack>
          </Paper>
        </Stack>
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
}

export default RapeseedAdviceAdminPage;
