import { Grid, Group, Switch, Text } from '@mantine/core';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './zoning-legend.module.css';

import {
  fromBacki18n,
  ProductRel,
  ZoningFeatureCollection,
} from '@data-access';

/* eslint-disable-next-line */
export interface ZoningLegendProps {
  title?: string;
  switchable?: boolean;
  legend: {
    zone: number;
    color: string;
    value?: number;
    value_N?: number;
    percent?: number;
    area?: number;
  }[];
  product?: ProductRel | null;
  unit?: string;
  total?: number;
  total_N?: number;
  mean?: number;
  mean_N?: number;
}

export function ZoningLegend({
  legend,
  unit,
  title,
  product,
  switchable = false,
  total,
  total_N,
  mean,
  mean_N,
}: ZoningLegendProps) {
  const { t, i18n } = useTranslation();
  const [absoluteUnit, setAbsoluteUnit] = useState(false);

  const displayTotal = useMemo(() => {
    if (!total) {
      return null;
    }

    if (absoluteUnit) {
      return total_N || total;
    }

    return total;
  }, [absoluteUnit, total, total_N]);

  const displayMean = useMemo(() => {
    if (!mean) {
      return null;
    }

    if (absoluteUnit) {
      return mean_N || mean;
    }

    return mean;
  }, [absoluteUnit, mean, mean_N]);

  const displayUnit = useMemo(() => {
    if (!unit) {
      return null;
    }
    if (absoluteUnit) {
      return 'u/ha';
    }
    return unit;
  }, [absoluteUnit, unit]);

  return (
    <div className={classes['container']}>
      {switchable ? (
        <Switch
          checked={absoluteUnit}
          label={t('common.legend-absolute-unit')}
          mb="xs"
          onChange={() => setAbsoluteUnit((value) => !value)}
        />
      ) : null}

      {title ? <Text className={classes.title}>{title}</Text> : null}

      {product ? (
        <Text>
          {absoluteUnit
            ? t('common.nitrogen-unit')
            : fromBacki18n(product, i18n.language)}{' '}
          ({displayUnit})
        </Text>
      ) : null}

      {displayTotal ? (
        <Text>
          Utilisation Totale : {displayTotal.toFixed(2)}{' '}
          {displayUnit?.split('/')[0]}
        </Text>
      ) : null}

      {legend.map((item) => {
        const value = absoluteUnit ? item.value_N : item.value;
        // let label = `Zone ${item.zone}`;
        let label = ``;
        if (item.value) {
          label = `${label} ${value}`;
        }
        if (displayUnit) {
          label = `${label} ${displayUnit}`;
        }

        return (
          <div className={classes.gridContainer} key={item.zone}>
            <Group className={classes.item} gap="x2s">
              <div
                className={classes.legendColor}
                style={{
                  backgroundColor: item.color,
                }}
              />
              <Text component="span" size="sm">
                {label}
              </Text>
            </Group>
            {item.area ? (
              <Group>
                <Text className={classes.area} component="span" size="sm">
                  {item.area.toFixed(2)} ha
                </Text>
              </Group>
            ) : null}
            {item.percent ? (
              <Group>
                <Text
                  className={classes.percent}
                  component="span"
                  size="sm"
                  style={{
                    '--width': `${item.percent.toFixed()}%`,
                  }}
                >
                  {item.percent.toFixed()} %
                </Text>
              </Group>
            ) : null}
          </div>
        );
      })}
      {displayMean ? (
        <Text className={classes['legend-average']}>
          {t('common.average')} {displayMean.toFixed(2)} {displayUnit}
        </Text>
      ) : null}
    </div>
  );
}

export function formatBiomassLegend(
  map?: ZoningFeatureCollection,
): ZoningLegendProps {
  if (map) {
    const l = map.features.reduce((acc, feature) => {
      return {
        ...acc,
        [feature.properties.zone]: {
          zone: feature.properties.zone,
          color: feature.properties.color,
          value: feature.properties.value.toFixed(2),
          value_N: feature.properties.value_N?.toFixed(2),
          percent: feature.properties.percent,
          area: feature.properties.area,
        },
      };
    }, {});

    return {
      title: 'Biomasse (kg/m²)',
      legend: Object.values(l),
      unit: 'kg/m²',
    };
  }
  return {
    legend: [],
  };
}
export function formatNitrogenLegend(args: {
  map?: ZoningFeatureCollection;
  product?: ProductRel;
  switchable?: boolean;
}): ZoningLegendProps {
  const { map, product, switchable } = args;
  if (map) {
    const l = map.features.reduce((acc, feature) => {
      return {
        ...acc,
        [feature.properties.zone]: {
          zone: feature.properties.zone,
          color: feature.properties.color,
          value: feature.properties.value.toFixed(2),
          value_N: feature.properties.value_N?.toFixed(2),
          percent: feature.properties.percent,
          area: feature.properties.area,
        },
      };
    }, {});

    return {
      legend: Object.values(l),
      unit: product?.unit,
      product: product,
      switchable,
    };
  }
  return {
    legend: [],
  };
}

export default ZoningLegend;
