import {
  Menu,
  rem,
  DefaultMantineColor,
  Anchor,
  ButtonProps,
  Button,
  MenuItemProps,
} from '@mantine/core';
import { Icon, IconChevronDown } from '@tabler/icons-react';
import { Link, LinkProps } from '@tanstack/react-router';
import { AnchorHTMLAttributes, MouseEventHandler, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

export type ActionsMenuItem = {
  label: string;
  icon: Icon;
  color?: DefaultMantineColor;
} & (
  | { type: 'link'; props: LinkProps }
  | {
      type: 'action';
      onClick: MouseEventHandler<HTMLButtonElement>;
      props?: MenuItemProps;
    }
  | {
      type: 'native-link';
      props: MenuItemProps & AnchorHTMLAttributes<HTMLAnchorElement>;
    }
);

const ActionsButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <Button
        color="red"
        ref={ref}
        rightSection={<IconChevronDown />}
        {...props}
      >
        {t('common.actions')}
      </Button>
    );
  },
);

interface ActionsMenuProps {
  items: ActionsMenuItem[];
  children?: React.ReactNode;
}

export function ActionsMenu({ items, children }: ActionsMenuProps) {
  return (
    <Menu position="bottom-end">
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        {items.map((item, index) => {
          const I = item.icon;
          if (item.type === 'link') {
            return (
              <Menu.Item
                color={item.color}
                component={Link}
                key={index}
                leftSection={<I style={{ width: rem(14), height: rem(14) }} />}
                {...(item.props as any)}
              >
                {item.label}
              </Menu.Item>
            );
          }

          if (item.type === 'native-link') {
            return (
              <Menu.Item
                color={item.color}
                component={'a'}
                key={index}
                leftSection={<I style={{ width: rem(14), height: rem(14) }} />}
                {...(item.props as any)}
              >
                {item.label}
              </Menu.Item>
            );
          }

          return (
            <Menu.Item
              {...item.props}
              color={item.color}
              key={index}
              leftSection={<I style={{ width: rem(14), height: rem(14) }} />}
              onClick={item.onClick}
            >
              {item.label}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}

ActionsMenu.Button = ActionsButton;

export default ActionsMenu;
