import { UseFormReturnType, TransformedValues } from '@mantine/form';
import { _TransformValues } from '@mantine/form/lib/types';

export const getDirtyValues = <V, T extends _TransformValues<V>>(
  form: UseFormReturnType<V, T>,
) => {
  const values = form.getTransformedValues() as Record<string, unknown>;
  type Transformed = TransformedValues<typeof form>;

  const dirtyValues = Object.keys(values).reduce(
    (acc, key) => {
      if (form.isDirty(key)) {
        acc[key] = values[key];
      }
      return acc;
    },
    {} as Record<string, unknown>,
  );

  return dirtyValues as Partial<Transformed>;
};
