import {
  Title,
  ScrollArea,
  TextInput,
  Select,
  Fieldset,
  FileButton,
  Button,
  Switch,
  NumberInput,
  Group,
  Transition,
  Alert,
  Text,
} from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useRouter } from '@tanstack/react-router';
import { FeatureCollection, MultiPolygon } from 'geojson';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFieldFormContext } from '../../hooks/use-field-form/field-form';

import classes from './field-form.module.css';

import { getDirtyValues } from '@data-access';
import { CreateFieldDto, useUploadFile, useValues } from '@fields/data-access';

export interface FieldFormProps {
  type: 'create' | 'edit';
  onFileUploaded: (data: FeatureCollection<MultiPolygon>) => void;
  onSubmit: (
    data:
      | { type: 'create'; values: CreateFieldDto }
      | { type: 'edit'; values: Partial<CreateFieldDto> },
  ) => void;
  loading?: boolean;
}

export function FieldForm({
  type,
  onSubmit,
  onFileUploaded,
  loading,
}: FieldFormProps) {
  const [file, setFile] = useState<File | null>(null);
  const { t, i18n } = useTranslation();
  const { history, navigate } = useRouter();
  const form = useFieldFormContext();
  const uploadFile = useUploadFile();

  const options = useValues();

  const handleFileChange = (f: File | null) => {
    if (f) {
      setFile(f);
      uploadFile.mutate(f, {
        onSuccess: (data) => {
          form.setFieldValue('is_multiple', data.is_multiple);
          onFileUploaded(data.data);
        },
      });
    }
  };

  const handleSubmit = (values: CreateFieldDto) => {
    if (type === 'create') {
      onSubmit({ type: 'create', values });
    }
    if (type === 'edit') {
      const dirtyValues = getDirtyValues(form);
      onSubmit({ type: 'edit', values: dirtyValues });
    }
  };

  const handleBack = () => {
    if (history.location.state.key) {
      history.back();
    } else
      navigate({
        to: '/fields',
      });
  };

  return (
    <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Title order={2}>
        {type === 'create'
          ? t('field.form.createTitle')
          : t('field.form.editTitle')}
      </Title>

      <ScrollArea className={classes.inputsContainer}>
        {!form.getValues().is_multiple ? (
          <TextInput
            label={t('field.form.nameLabel')}
            required
            {...form.getInputProps('name')}
          />
        ) : null}

        <Select
          allowDeselect={false}
          data={options.data?.farm}
          label={t('field.form.farmLabel')}
          mt="sm"
          required
          searchable
          {...form.getInputProps('farm')}
        />

        <Select
          allowDeselect={true}
          clearable
          data={options.data?.species(i18n.language)}
          label={t('field.form.cropLabel')}
          mt="sm"
          searchable
          {...form.getInputProps('species')}
        />

        <Select
          allowDeselect={true}
          clearable
          data={options.data?.variety.filter(
            (v) => v.parent.toString() === form.getValues().species,
          )}
          label={t('field.form.varietyLabel')}
          mt="sm"
          searchable
          {...form.getInputProps('variety')}
        />

        <Fieldset
          hidden={type === 'edit'}
          legend={t('field.form.importLegend')}
          mt="md"
          variant="filled"
        >
          <FileButton accept="zip" onChange={handleFileChange}>
            {(props) => (
              <Button {...props} loading={uploadFile.isPending}>
                {t('field.form.fileLabel')}
              </Button>
            )}
          </FileButton>
          {file ? (
            <Text mt="sm" size="sm">
              {t('field.form.fileSelected')} {file.name}
            </Text>
          ) : null}

          {uploadFile.data && uploadFile.data.is_multiple ? (
            <>
              <Switch
                label={t('field.form.isMultipleLabel')}
                mt="md"
                {...form.getInputProps('is_multiple', {
                  type: 'checkbox',
                })}
              />

              <Select
                allowDeselect={false}
                data={uploadFile.data.columns}
                label={t('field.form.fileColSourceLabel')}
                mt="sm"
                searchable
                {...form.getInputProps('fileColSource')}
              />
            </>
          ) : null}
        </Fieldset>

        <Fieldset
          legend={t('field.form.multiannualLegend')}
          mt="md"
          variant="filled"
        >
          <Select
            allowDeselect={false}
            data={options.data?.soil_types}
            label={t('field.form.soilTypeLabel')}
            searchable
            {...form.getInputProps('soil_type')}
          />

          <NumberInput
            label={t('field.form.wheatPotentialLabel')}
            mt="sm"
            {...form.getInputProps('potential_wheat')}
          />

          <NumberInput
            label={t('field.form.barleyPotentialLabel')}
            mt="sm"
            {...form.getInputProps('potential_barley')}
          />

          <NumberInput
            label={t('field.form.rapePotentialLabel')}
            mt="sm"
            {...form.getInputProps('potential_rapeseed')}
          />
        </Fieldset>
      </ScrollArea>

      <Group justify="flex-end">
        <Transition
          duration={400}
          mounted={!!form.errors['boundaries']}
          timingFunction="ease"
          transition="fade-up"
        >
          {(styles) => (
            <Alert
              color="red"
              icon={<IconInfoCircle />}
              onClose={() => form.clearFieldError('boundaries')}
              style={styles}
              title={t('field.form.boundaryRequiredTitle')}
              variant="filled"
              w="100%"
              withCloseButton
            >
              {t('field.form.boundaryRequiredContent')}
            </Alert>
          )}
        </Transition>

        <Button disabled={!form.isDirty()} loading={loading} type="submit">
          {type === 'edit' ? t('common.save') : t('common.create')}
        </Button>
        <Button color="red" onClick={handleBack} type="button">
          {t('common.back')}
        </Button>
      </Group>
    </form>
  );
}

export default FieldForm;
