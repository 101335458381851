import { DEFAULT_THEME } from '@mantine/core';

import { constantsModes, highlightPropertyName } from './constants';

const blue = DEFAULT_THEME.colors.blue[7];
const orange = DEFAULT_THEME.colors.yellow[7];
const red = DEFAULT_THEME.colors.red[9];

const white = '#fff';

const defaults = (filled: boolean) => [
  // Polygons
  //   Solid fill
  //   Active state defines color
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      [
        'in',
        'mode',
        constantsModes.DRAW_POLYGON,
        constantsModes.DRAW_LINE_STRING,
        constantsModes.SIMPLE_SELECT,
        constantsModes.DIRECT_SELECT,
        constantsModes.PASSING_DRAW_MODE,
      ],
      ['!has', `user_${highlightPropertyName}`],
    ],
    paint: {
      'fill-color': [
        'case',
        ['==', ['get', 'active'], 'true'],
        orange,
        ['case', ['has', 'user_color'], ['get', 'user_color'], blue],
      ],
      'fill-opacity': filled ? 1 : 0.1,
    },
  },
  // Lines
  // Polygon
  //   Matches Lines AND Polygons
  //   Active state defines color
  {
    id: 'gl-draw-lines',
    type: 'line',
    filter: [
      'all',
      ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
      [
        'in',
        'mode',
        constantsModes.DRAW_POLYGON,
        constantsModes.DRAW_LINE_STRING,
        constantsModes.SIMPLE_SELECT,
        constantsModes.DIRECT_SELECT,
        constantsModes.PASSING_DRAW_MODE,
      ],
      ['!has', `user_${highlightPropertyName}`],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': [
        'case',
        ['==', ['get', 'active'], 'true'],
        orange,
        ['case', ['has', 'user_color'], ['get', 'user_color'], blue],
      ],
      'line-dasharray': [
        'case',
        ['==', ['get', 'active'], 'true'],
        [0.2, 2],
        [2, 0],
      ],
      'line-width': 2,
    },
  },
  // vertex point halos
  {
    id: 'gl-draw-polygon-ands-line-vertex-halo-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 9,
      'circle-color': '#fff',
    },
  },
  // vertex points
  {
    id: 'gl-draw-line-vertex-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': orange,
    },
  },
  // Points
  //   Circle with an outline
  //   Active state defines size and color
  {
    id: 'gl-draw-point-outer',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'feature']],
    paint: {
      'circle-radius': ['case', ['==', ['get', 'active'], 'true'], 7, 5],
      'circle-color': white,
    },
  },
  {
    id: 'gl-draw-point-inner',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'feature']],
    paint: {
      'circle-radius': ['case', ['==', ['get', 'active'], 'true'], 5, 3],
      'circle-color': ['case', ['==', ['get', 'active'], 'true'], orange, blue],
    },
  },
  // Polygons
  //   Solid stroke
  //   Active state defines color
  {
    id: 'gl-draw-polygon-stroke',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      [
        'in',
        'mode',
        constantsModes.DRAW_POLYGON,
        constantsModes.DRAW_LINE_STRING,
        constantsModes.SIMPLE_SELECT,
        constantsModes.DIRECT_SELECT,
        constantsModes.PASSING_DRAW_MODE,
      ],
      ['!has', `user_${highlightPropertyName}`],
    ],
    paint: {
      'line-color': filled
        ? 'black'
        : [
            'case',
            ['==', ['get', 'active'], 'true'],
            orange,
            ['case', ['has', 'user_color'], ['get', 'user_color'], blue],
          ],
      'line-width': 2,
    },
  },
  // Vertex
  //   Visible when editing polygons and lines
  //   Similar behaviour to Points
  //   Active state defines size
  {
    id: 'gl-draw-vertex-outer',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'vertex'],
      ['!=', 'mode', 'simple_select'],
    ],
    paint: {
      'circle-radius': ['case', ['==', ['get', 'active'], 'true'], 7, 5],
      'circle-color': white,
    },
  },
  {
    id: 'gl-draw-vertex-inner',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'vertex'],
      ['!=', 'mode', 'simple_select'],
    ],
    paint: {
      'circle-radius': ['case', ['==', ['get', 'active'], 'true'], 5, 3],
      'circle-color': orange,
    },
  },
  // Midpoint
  //   Visible when editing polygons and lines
  //   Tapping or dragging them adds a new vertex to the feature
  {
    id: 'gl-draw-midpoint',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 3,
      'circle-color': orange,
    },
  },
];

export const customTheme = (filled = true) => [
  ...defaults(filled),

  {
    id: 'static-polygon-fill',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    paint: {
      'fill-color': [
        'case',
        ['has', 'user_color'],
        ['get', 'user_color'],
        blue,
      ],
      // zone opacity
      'fill-opacity': filled ? 1 : 0.1,
    },
  },
  {
    id: 'static-polygon-stroke',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    paint: {
      'line-color': filled
        ? 'black'
        : ['case', ['has', 'user_color'], ['get', 'user_color'], blue],
      'line-width': 2,
    },
  },
  {
    id: 'cut_polygon-polygon-fill',
    type: 'fill',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      // [
      //   'in',
      //   'mode',
      //   constantsModes.PASSING_DRAW_MODE,
      //   constantsModes.PASSING_DRAW_LINE_STRING_MODE,
      // ],
      ['has', `user_${highlightPropertyName}`],
    ],
    paint: {
      'fill-color': red,
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'cut_polygon-polygon-stroke',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      // [
      //   'in',
      //   'mode',
      //   constantsModes.PASSING_DRAW_MODE,
      //   constantsModes.PASSING_DRAW_LINE_STRING_MODE,
      // ],
      ['has', `user_${highlightPropertyName}`],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': red,
      'line-width': 3,
    },
  },
];
