import {
  Button,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { IconKey, IconUser } from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import classes from './login-form.module.css';

import { precifieldLogo } from '@assets';
import { LangSelect } from '@ui';

export type LoginFormValues = {
  email: string;
  password: string;
};

/* eslint-disable-next-line */
export interface LoginFormProps {
  onSubmit: (event: LoginFormValues) => void;
  withIcons?: boolean;
  isFetching?: boolean;
  isError?: boolean;
  error?: string;
}

export function LoginForm(props: LoginFormProps) {
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: isEmail(t('auth.emailError')),
      password: isNotEmpty(t('form.required')),
    },
  });

  const handleSubmit = (values: LoginFormValues) => {
    props.onSubmit(values);
  };

  const error = () => {
    let text = t('auth.unknownError');

    if (props.error?.includes('provided credentials')) {
      text = t('auth.credentialsError');
    }

    return <Text c="red">{text}</Text>;
  };

  return (
    <Paper
      className={clsx(classes.container, 'border-light')}
      p="md"
      shadow="sm"
      withBorder
    >
      <Group justify="center">
        <img
          alt="Precifield logotype"
          className={classes.logo}
          src="/precifield-logo.webp"
        />
      </Group>
      <Group justify="end">
        <LangSelect />
      </Group>
      <form
        className={classes['form']}
        onSubmit={form.onSubmit((values) => handleSubmit(values))}
      >
        <TextInput
          color="primary"
          error={form.errors.email as string}
          label={t('auth.email')}
          leftSection={props.withIcons ? <IconUser /> : undefined}
          leftSectionPointerEvents="none"
          placeholder="john.doe@gmail.com"
          required
          type="email"
          variant="default"
          {...form.getInputProps('email')}
        />

        <Stack gap="xs">
          <PasswordInput
            color="primary"
            error={form.errors.password as string}
            id="password-input"
            label={t('auth.password')}
            leftSection={props.withIcons ? <IconKey /> : undefined}
            placeholder="********"
            required
            type="password"
            variant="default"
            {...form.getInputProps('password')}
          />

          <Group justify="end">
            <Link className={classes.forgotLink} to="/lost-password">
              {t('auth.forgotPassword')}
            </Link>
          </Group>
        </Stack>

        <div className={classes['form__footer']}>
          {props.isError ? error() : null}
          <input
            disabled={props.isFetching}
            style={{ display: 'none' }}
            type="submit"
          />
          <Button
            disabled={props.isFetching}
            fullWidth
            size="large"
            type="submit"
          >
            {t('auth.login')}
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default LoginForm;
