import { Fieldset, Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useWheat2AdviceFormContext } from '../../../hooks/use-wheat2-advice-form/wheat2-advice-form';


export const SettingsFieldset = () => {
  const form = useWheat2AdviceFormContext();
  const { t } = useTranslation();
  

  return (
    <Fieldset legend="Paramètres du conseil" mt="md" variant="filled">
      <Checkbox
        {...form.getInputProps('is_hidden', { type: 'checkbox' })}
        label="Masquer le conseil pour l'utilisateur"
      />
      <Checkbox
        label={t('advices.disable_mail')}
        {...form.getInputProps('disable_mail', { type: 'checkbox' })}
        mt="sm"
      />
      <Checkbox
        {...form.getInputProps('restart', { type: 'checkbox' })}
        label={t('advices.restart')}
        mt="sm"
      />
    </Fieldset>
  );
};


export const SettingsFieldsetDistri = () => {
  const form = useWheat2AdviceFormContext();
  const { t } = useTranslation();
  
  return (
    <Fieldset legend="Paramètres du conseil" mt="md" variant="filled">
      <Checkbox
        label={t('advices.disable_mail')}
        {...form.getInputProps('disable_mail', { type: 'checkbox' })}
      />
    </Fieldset>
  );
};