import {
  FileButton,
  Button,
  TextInput,
  Select,
  Text,
  Fieldset,
  Transition,
  Group,
  Box,
} from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useYieldMapForm } from './use-yield-map-form';
import classes from './yield-map-form.module.css';

import { MAP_TYPES, useUploadYieldMap } from '@fields/data-access';
import { TsrButtonLink } from '@ui';
import { ProcessYieldMapDto } from 'modules/fields/data-access/src/lib/types/dto';

interface YieldMapFormProps {
  loading?: boolean;
  fieldId: number;
  onSubmit: (values: ProcessYieldMapDto) => void;
}

export function YieldMapForm({
  loading,
  fieldId,
  onSubmit,
}: YieldMapFormProps) {
  const { t, i18n } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const form = useYieldMapForm(fieldId);

  const uploadFile = useUploadYieldMap();

  const handleFileChange = (f: File | null) => {
    if (f) {
      form.reset();
      setFile(f);
      uploadFile.mutate(
        {
          fieldId,
          file: f,
        },
        {
          onSuccess: (data) => {
            if (data.map_id) {
              form.setFieldValue('map_id', data.map_id);
            }
          },
        },
      );
    }
  };

  return (
    <form className={classes.form} onSubmit={form.onSubmit(onSubmit)}>
      <Box className={classes.inputsContainer}>
        <FileButton accept="zip" onChange={handleFileChange}>
          {(props) => (
            <Button {...props} loading={uploadFile.isPending}>
              {t('field.form.fileLabel')}
            </Button>
          )}
        </FileButton>
        {file ? (
          <Text mt="sm" size="sm">
            {t('field.form.fileSelected')} {file.name}
          </Text>
        ) : null}

        <Transition
          duration={400}
          mounted={!!uploadFile.data}
          timingFunction="ease"
          transition="fade"
        >
          {(styles) => (
            <Fieldset
              disabled={!uploadFile.data}
              mt="sm"
              style={styles}
              variant="filled"
            >
              <TextInput
                label={t('field.form.mapNameLabel')}
                mt="sm"
                required
                {...form.getInputProps('name')}
              />

              <Select
                data={uploadFile.data?.column_name}
                label={t('field.form.columnNameLabel')}
                mt="sm"
                required
                {...form.getInputProps('col')}
              />

              <Select
                data={uploadFile.data?.species(i18n.language)}
                label={t('field.form.yield-map-species-label')}
                mt="sm"
                required
                {...form.getInputProps('crop')}
              />
            </Fieldset>
          )}
        </Transition>
      </Box>

      <Group justify="start">
        <Button loading={loading} type="submit">
          {t('common.add')}
        </Button>
        <TsrButtonLink
          color="red"
          params={{
            fieldId: fieldId.toString(),
            mapType: MAP_TYPES.YIELD,
          }}
          to="/fields/$fieldId/maps/$mapType"
        >
          {t('common.back')}
        </TsrButtonLink>
      </Group>
    </form>
  );
}

export default YieldMapForm;
