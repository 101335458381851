import { Fieldset, Select, NumberInput, Switch, Checkbox } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRapeseedAdviceFormContext } from '../../../hooks/use-rapeseed-advice-form/rapeseed-advice-form';
import InfoLabel from '../../info-label/info-label';

import { useValues } from '@advices/data-access';
import { asCbData, CropEnum } from '@data-access';

export const FallFertilizerFieldset = () => {
  const { t, i18n } = useTranslation();
  const form = useRapeseedAdviceFormContext();
  const selectedFields = form.getValues().field;
  const [checked, setChecked] = useState(false);
  const [secondInput, setSecondInput] = useState(false);
  const [qteUsnit, setQteUnits] = useState({
    1: '',
    2: '',
  });

  const values = useValues({
    type: CropEnum.RAPESEED,
    fieldId: selectedFields.length === 1 ? selectedFields[0] : null,
  });

  const handleChecked = () => {
    const next = !checked;
    setChecked(next);
    if (next) {
      setSecondInput(false);
      form.setValues({
        pdt_org1: null,
        qte_pdt_ependu1: null,
        n_teneur1: null,
        pdt_org2: null,
        qte_pdt_ependu2: null,
        n_teneur2: null,
      });
    }
  };

  const handleSecondInput = () => {
    const next = !secondInput;
    setSecondInput(next);
    if (!next) {
      form.setValues({
        pdt_org2: null,
        qte_pdt_ependu2: null,
        n_teneur2: null,
      });
    }
  };

  const handlePdtOrgChange = (order: number, value?: string | null) => {
    const next = values.data
      ?.pdt_org(i18n.language)
      ?.find((pdt) => pdt.value === value);
    if (next) {
      setQteUnits((units) => {
        const nexUnits = {
          ...units,
          [order]: next.unit,
        };
        return nexUnits;
      });
      form.setFieldValue(`n_teneur${order}`, next.N_value);
    }
  };

  form.watch('pdt_org1', ({ value }) => {
    handlePdtOrgChange(1, value);
  });
  form.watch('pdt_org2', ({ value }) => {
    handlePdtOrgChange(2, value);
  });

  useEffect(() => {
    const {
      pdt_org1,
      pdt_org2,
      qte_pdt_ependu1,
      qte_pdt_ependu2,
      n_teneur1,
      n_teneur2,
    } = form.getValues();
    const isEmpty =
      !pdt_org1 &&
      !qte_pdt_ependu1 &&
      !n_teneur1 &&
      !pdt_org2 &&
      !qte_pdt_ependu2 &&
      !n_teneur2;

    const hasSecondInput = !!pdt_org2 || !!qte_pdt_ependu2 || !!n_teneur2;

    if (isEmpty) {
      setChecked(true);
    }

    if (hasSecondInput) {
      setSecondInput(true);
    }
  }, []);

  return (
    <Fieldset
      legend={
        <InfoLabel
          info={t('advices.form.fall_fertilizer_legend_info')}
          label={t('advices.form.fall_fertilizer_legend')}
        />
      }
      mt="md"
      variant="filled"
    >
      <Checkbox
        checked={checked}
        label={t('advices.form.no_fall_fertilizer')}
        onChange={handleChecked}
      />

      <Fieldset disabled={checked} mt="sm" variant="unstyled">
        <Select
          {...form.getInputProps('pdt_org1')}
          data={asCbData(values.data?.pdt_org(i18n.language))}
          label={t('advices.properties.pdt_org1')}
          required={!checked}
        />

        <NumberInput
          {...form.getInputProps('qte_pdt_ependu1')}
          hideControls
          label={t('advices.properties.qte_pdt_ependu')}
          mt="sm"
          required={!checked}
          rightSection={qteUsnit[1]}
          rightSectionWidth={50}
        />

        <NumberInput
          {...form.getInputProps('n_teneur1')}
          hideControls
          // key is used to force rerender when n_teneur1 is null and is needed because null is not a valid value for number input
          key={`${form.getValues().n_teneur1 === null}`}
          label={t('advices.properties.n_teneur')}
          mt="sm"
          required={!checked}
          rightSection="U/t"
          rightSectionWidth={50}
        />

        <Switch
          checked={secondInput}
          label={t('advices.form.second_fall_fertilizer')}
          mt="sm"
          onChange={handleSecondInput}
        />

        {secondInput ? (
          <>
            <Select
              {...form.getInputProps('pdt_org2')}
              data={asCbData(values.data?.pdt_org(i18n.language))}
              label={t('advices.properties.pdt_org1')}
              mt="sm"
              required={!checked}
            />

            <NumberInput
              {...form.getInputProps('qte_pdt_ependu2')}
              hideControls
              label={t('advices.properties.qte_pdt_ependu')}
              mt="sm"
              required={!checked}
              rightSection={qteUsnit[2]}
              rightSectionWidth={50}
            />

            <NumberInput
              {...form.getInputProps('n_teneur2')}
              hideControls
              label={t('advices.properties.n_teneur')}
              mt="sm"
              required={!checked}
              rightSection="U/t"
              rightSectionWidth={50}
            />
          </>
        ) : null}
      </Fieldset>
    </Fieldset>
  );
};
