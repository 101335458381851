import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { ResetPassword } from '@auth/feature';

const resetPasswordSearchSchema = z.object({
  token: z.string().catch(''),
});

export const Route = createFileRoute('/_auth/reset-password')({
  validateSearch: (search) => resetPasswordSearchSchema.parse(search),
  beforeLoad: ({ search }) => {
    if (!search.token) {
      throw redirect({
        to: '/login',
      });
    }
  },
  component: ResetPassword,
});
