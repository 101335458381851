import { Portal, Switch } from '@mantine/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import styles from './legend-control.module.css';

/* eslint-disable-next-line */
export interface LegendControlProps {
  children?: React.ReactNode;
  opacity?: number;
}

export function LegendControl(props: LegendControlProps) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(true);
  const map = useMap();
  const mapId = map.current?.getMap().getContainer().id;

  const handleChange = (value: boolean) => {
    setChecked(value);
  };

  if (!mapId) {
    return null;
  }

  return (
    <>
      <Portal
        target={
          document &&
          (document
            .getElementById(mapId)
            ?.getElementsByClassName(
              'mapboxgl-ctrl-top-left'
            )[0] as HTMLElement)
        }
      >
        <div className={`${styles['container']} mapboxgl-ctrl`}>
          <Switch
            checked={checked}
            label={t('common.legend')}
            labelPosition="right"
            onChange={(e) => handleChange(e.target.checked)}
          ></Switch>
        </div>
      </Portal>

      {checked ? (
        <Portal
          target={
            document &&
            (document
              .getElementById(mapId)
              ?.getElementsByClassName(
                'mapboxgl-ctrl-bottom-left'
              )[0] as HTMLElement)
          }
        >
          <div
            className={`${styles['container']} mapboxgl-ctrl`}
            style={{
              backgroundColor: props.opacity ? 'rgba(255, 255, 255, 0.8)' : '',
            }}
          >
            {props.children}
          </div>
        </Portal>
      ) : null}
    </>
  );
}

export default LegendControl;
