import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useTranslation } from 'react-i18next';
import { useControl } from 'react-map-gl';

import styles from './geocoder-control.module.css';

/* eslint-disable-next-line */
export interface GeocoderControlProps {
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export function GeocoderControl(props: GeocoderControlProps) {
  const { i18n } = useTranslation();
  useControl(
    () =>
      new MapboxGeocoder({
        accessToken: import.meta.env.VITE_MAPBOX_TOKEN,
        marker: false,
        language: i18n.language,
        ...props,
      }),
    {
      position: props.position,
    }
  );

  return null;
}

export default GeocoderControl;
