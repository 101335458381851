import { CropEnum } from '@data-access';

export const cropOptions = [
  {
    value: '1',
    label: 'Blé',
    type: CropEnum.WHEAT,
  },
  {
    value: '4',
    label: 'Orge',
    type: CropEnum.BARLEY,
  },
  {
    value: '6',
    label: 'Colza',
    type: CropEnum.RAPESEED,
  },
];
