import { useMutation } from '@tanstack/react-query';

import { fieldsApi } from '../../apis/fields';

export function useUpdateField() {
  const mutation = useMutation({
    mutationFn: fieldsApi.updateOne,
  });

  return mutation;
}

export default useUpdateField;
