import { Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { useBlocker, useNavigate, useSearch } from '@tanstack/react-router';
import { MRT_RowSelectionState } from 'mantine-react-table';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AdvicesListHeader } from '../../components/advices-list-header/advices-list-header';
import { CommonAdviceFormTransformedValues } from '../../hooks/use-common-advice-form/common-advice-form';

import AdviceForm from './components/advice-form';
import AdviceTable from './components/advice-table';
import { ErrorPage } from './page.error';

import {
  advicesApi,
  advicesQueries,
  CreateRapeseedAdviceDto,
  CreateWheat2AdviceDto,
  useCreateCommonAdvice,
  useCreateRapeseedAdvice,
  useCreateWheat2Advice,
  useUpdateCommonAdvice,
  useUpdateCommonAdvices,
  useUpdateRapeseedAdvice,
  useUpdateRapeseedAdvices,
  useUpdateWheat2Advice,
  useUpdateWheat2Advices,
} from '@advices/data-access';
import { CropEnum, useColFilters, useQueriesWithGlobal } from '@data-access';
import { FullMainHeight } from '@ui';

export function AdvicesListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate({
    from: '/precifert-n',
  });
  const { type } = useSearch({
    from: '/_app/precifert-n',
  });
  const queries = useQueriesWithGlobal(advicesQueries);
  const nitrogenTypes = useSuspenseQuery(queries.types());

  const [selectedType, setSelectedType] = useState<string>(
    type || nitrogenTypes.data?.[0].name,
  );
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const selectsIds = Object.keys(rowSelection).map(Number);
  const [filters, setFilters] = useColFilters('advices');
  const [fieldsFilters, setFieldsFilters, resetFieldsFilters] =
    useColFilters('plots');

  const [mode, setMode] = useState<'create' | 'edit'>('create');
  const [opened, { open, close }] = useDisclosure(false);
  const [needValidation, setNeedValidation] = useState(false);

  const createCommonAdvice = useCreateCommonAdvice();
  const updateCommonAdvice = useUpdateCommonAdvice();
  const updateManyCommonAdvices = useUpdateCommonAdvices();

  const createWheat2Advice = useCreateWheat2Advice();
  const updateWheat2Advice = useUpdateWheat2Advice();
  const updateManyWheat2Advices = useUpdateWheat2Advices();

  const createRapeseedAdvice = useCreateRapeseedAdvice();
  const updateRapeseedAdvice = useUpdateRapeseedAdvice();
  const updateManyRapeseedAdvices = useUpdateRapeseedAdvices();

  const deleteMany = useMutation({
    mutationFn: advicesApi.deleteMany,
    onSuccess: () => {
      setRowSelection({});
    },
  });
  const downloadMany = useMutation({
    mutationFn: advicesApi.downloadMany,
  });

  useBlocker({
    blockerFn: () => window.confirm(t('common.confirm_navigation')),
    condition: needValidation,
  });

  const resetState = () => {
    close();
    setRowSelection({});
    setMode('create');
    resetFieldsFilters();

    // reset mutations
    createCommonAdvice.reset();
    updateCommonAdvice.reset();
    updateManyCommonAdvices.reset();
    createRapeseedAdvice.reset();
    updateRapeseedAdvice.reset();
    updateManyRapeseedAdvices.reset();
  };

  const handleChange = useCallback(
    (value: string | null) => {
      if (value) {
        setSelectedType(value);
        navigate({
          replace: true,
          search: (prev) => ({
            type: value,
          }),
        });
      }
    },
    [navigate],
  );

  const handleNewAdvice = () => {
    resetState();
    const parentId = nitrogenTypes.data
      ?.find((s) => s.name === selectedType)
      ?.parent.id.toString();

    if (parentId) {
      setFieldsFilters([
        {
          id: 'species',
          value: parentId,
        },
      ]);
    }
    open();
  };

  const handleEditAdvice = useCallback(
    (id?: number) => {
      if (id) {
        setRowSelection({ [id]: true });
      }
      setMode('edit');
      open();
    },

    [open],
  );

  function cleanPayload(payload: any): any {
    const cleanedPayload: any = {};
    for (const key in payload) {
      if (payload[key] !== null && payload[key] !== undefined) {
        cleanedPayload[key] = payload[key];
      }
    }
    return cleanedPayload;
  }  

  const handleDeleteSelection = () => {
    modals.openConfirmModal({
      title: t('advices.delete_many.title'),

      children: (
        <Text>
          {t('advices.delete_many.content1')} {t('plots.delete.content2')}
        </Text>
      ),

      labels: { confirm: t('common.delete'), cancel: t('common.cancel') },

      confirmProps: { color: 'red' },

      onConfirm: () => {
        if (rowSelection.all) {
          deleteMany.mutate({
            ids: [],
            all: true,
            selectedFilters: filters,
            crop: selectedType,
          });
        } else {
          const ids = Object.keys(rowSelection).map((id) => Number(id));
          deleteMany.mutate({
            ids,
            crop: selectedType,
          });
        }
      },
    });
  };

  const handleDownloadRecaps = () => {
    if (rowSelection.all) {
      downloadMany.mutate({
        ids: [],
        all: true,
        selectedFilters: filters,
        crop: selectedType,
      });
    } else {
      const ids = Object.keys(rowSelection).map((id) => Number(id));
      downloadMany.mutate({
        ids,
        crop: selectedType,
      });
    }
  };

  const handleCommonAdviceSubmit = (
    values: CommonAdviceFormTransformedValues,
  ) => {
    if (mode === 'create') {
      createCommonAdvice.mutate(
        {
          crop: selectedType,
          ...values,
        },
        {
          onSuccess: (res) => {
            resetState();
            if (Array.isArray(res)) {
              const ids = res.map((r) => r.id);
              // If multiple advice created, filter by ids and block navigation until user has validated
              if (ids.length > 1) {
                setNeedValidation(true);
                setFilters([
                  {
                    id: 'id',
                    value: ids,
                  },
                ]);
              }
            }
          },
        },
      );
    }

    const cleanedValues = cleanPayload(values);
    const { field, ...rest } = cleanedValues;

    if (mode === 'edit' && selectsIds.length === 1) {
      updateCommonAdvice.mutate(
        {
          id: selectsIds[0],
          crop: selectedType,
          ...rest,
        },
        {
          onSuccess: () => resetState(),
        },
      );
    }

    if (mode === 'edit' && selectsIds.length > 1) {
      updateManyCommonAdvices.mutate(
        {
          id: selectsIds,
          crop: selectedType,
          ...rest,
        },
        {
          onSuccess: () => resetState(),
        },
      );
    }
  };

  const handleWheat2AdviceSubmit = (values: CreateWheat2AdviceDto) => {
    if (mode === 'create') {
      createWheat2Advice.mutate(values, {
        onSuccess: (res) => {
          resetState();
          if (Array.isArray(res)) {
            const ids = res.map((r) => r.id);
            // If multiple advice created, filter by ids and block navigation until user has validated
            if (ids.length > 1) {
              setNeedValidation(true);
              setFilters([
                {
                  id: 'id',
                  value: ids,
                },
              ]);
            }
          }
        },
      });
    }

    const { field, ...rest } = values;

    if (mode === 'edit' && selectsIds.length === 1) {
      updateWheat2Advice.mutate(
        {
          id: selectsIds[0],
          ...rest,
        },
        {
          onSuccess: () => resetState(),
        },
      );
    }

    if (mode === 'edit' && selectsIds.length > 1) {
      updateManyWheat2Advices.mutate(
        {
          id: selectsIds,
          ...rest,
        },
        {
          onSuccess: () => resetState(),
        },
      );
    }
  };

  const handleRapeseedAdviceSubmit = (values: CreateRapeseedAdviceDto) => {
    if (mode === 'create') {
      createRapeseedAdvice.mutate(values, {
        onSuccess: (res) => {
          resetState();
          if (Array.isArray(res)) {
            const ids = res.map((r) => r.id);
            // If multiple advice created, filter by ids and block navigation until user has validated
            if (ids.length > 1) {
              setNeedValidation(true);
              setFilters([
                {
                  id: 'id',
                  value: ids,
                },
              ]);
            }
          }
        },
      });
    }
    if (mode === 'edit' && selectsIds.length === 1) {
      const { field, ...rest } = values;
      updateRapeseedAdvice.mutate(
        {
          id: selectsIds[0],
          ...rest,
        },
        {
          onSuccess: () => resetState(),
        },
      );
    }

    if (mode === 'edit' && selectsIds.length > 1) {
      const { field, ...rest } = cleanPayload(values);
      updateManyRapeseedAdvices.mutate(
        {
          id: selectsIds,
          ...rest,
        },
        {
          onSuccess: () => resetState(),
        },
      );
    }
  };

  const handleValidateFields = () => {
    const idsFilter = filters.filter((f) => f.id === 'id')[0];
    if (idsFilter) {
      const ids = idsFilter.value as number[];
      updateManyCommonAdvices.mutate(
        {
          id: ids,
          crop: selectedType,
          is_valid: true,
        },
        {
          onSuccess: () => {
            setFilters([]);
            setNeedValidation(false);
            resetState();
            updateCommonAdvice.reset();
          },
        },
      );
    }
  };

  return (
    <FullMainHeight.Root>
      <FullMainHeight.Header>
        <AdvicesListHeader
          needValidation={needValidation}
          onCropChange={handleChange}
          onDelete={handleDeleteSelection}
          onDownload={handleDownloadRecaps}
          onEditAdvice={handleEditAdvice}
          onNewAdvice={handleNewAdvice}
          onValidateFields={handleValidateFields}
          selectedType={selectedType}
          selecting={Object.keys(rowSelection).length > 0}
        />
      </FullMainHeight.Header>
      <FullMainHeight.Content>
        <AdviceTable
          needValidation={needValidation}
          onEdit={handleEditAdvice}
          rowSelection={rowSelection}
          selectedType={selectedType}
          setRowSelection={setRowSelection}
        />
      </FullMainHeight.Content>

      <AdviceForm
        initialIds={Object.keys(rowSelection).map(Number)}
        mode={mode}
        onClose={resetState}
        onSubmitCommon={handleCommonAdviceSubmit}
        onSubmitRapeseed={handleRapeseedAdviceSubmit}
        onSubmitWheat2={handleWheat2AdviceSubmit}
        opened={opened}
        selectedType={selectedType}
      />
    </FullMainHeight.Root>
  );
}

AdvicesListPage.Error = ErrorPage;

export default AdvicesListPage;
