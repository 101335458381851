import { Paper, rem } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useParams, useRouter, useBlocker } from '@tanstack/react-router';
import { flatten } from '@turf/turf';
import { Feature, FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import { MapEvent } from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef } from 'react-map-gl';

import FieldForm, {
  FieldFormProps,
} from '../../components/field-form/field-form';
import FieldsInBounds from '../../components/fields-in-bounds/fields-in-bounds';
import {
  FieldFormProvider,
  useFieldForm,
} from '../../hooks/use-field-form/field-form';

import { ErrorPage } from './page.error';

import { useQueriesWithGlobal } from '@data-access';
import {
  fieldsQueries,
  useCreateField,
  useUpdateField,
} from '@fields/data-access';
import {
  DrawToolboxControl,
  GeocoderControl,
  PfMap,
  SatelliteImageryControl,
  StyleControl,
  zoomOnFeature,
} from '@map';
import { FullMainHeight } from '@ui';

export function ManageFieldPage() {
  const { t } = useTranslation();
  const { fieldId } = useParams({ strict: false });
  const { navigate } = useRouter();
  const [pageType] = useState<'edit' | 'create'>(fieldId ? 'edit' : 'create');
  const mapRef = useRef<MapRef>(null);
  const form = useFieldForm();
  useBlocker({
    blockerFn: () => window.confirm(t('common.confirm_navigation')),
    condition: form.isDirty(),
  });

  const queries = useQueriesWithGlobal(fieldsQueries);
  const field = useQuery({
    ...queries.detail(Number(fieldId)),
    enabled: !!fieldId,
  });
  const createField = useCreateField();
  const updateField = useUpdateField();

  const [initialPolygon, setInitialPolygon] = useState(
    field.data && flatten(field.data),
  );

  const navigateToField = (id: number) =>
    navigate({
      to: `/fields/$fieldId`,
      params: { fieldId: id.toString() },
    });

  const handleLoad = (map: MapEvent) => {
    zoomOnFeature({
      map: map.target,
      geojson: field.data,
    });
  };

  const handleDrawEnd = (features: Feature<Polygon>[]) => {
    form.setFieldValue('boundaries', features);
  };

  const handleFileUploaded = (data: FeatureCollection<MultiPolygon>) => {
    setInitialPolygon(flatten(data));
    form.setFieldValue('boundaries', flatten(data).features);
    if (data.features.length) {
      zoomOnFeature({
        map: mapRef?.current?.getMap(),
        geojson: data,
      });
    }
  };

  // const handleMapClick = useCallback(
  //   (e: MapMouseEvent) => {
  //     fieldsInBounds.handleMapClick(e);
  //   },
  //   [fieldsInBounds]
  // );

  const handleSubmit: FieldFormProps['onSubmit'] = ({ type, values }) => {
    if (type === 'create') {
      createField.mutate(values, {
        onSuccess: () => {
          form.resetDirty();
        },
      });
    }

    if (type === 'edit' && fieldId ) {

      const sanitizedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, value === undefined ? null : value])
      );

      const { is_multiple, ...data } = sanitizedValues;

      console.log('data', data)

      updateField.mutate(
        {
          id: Number(fieldId),
          ...data,
        },
        {
          onSuccess: () => {
            form.resetDirty();
          },
        },
      );
    }
  };

  useEffect(() => {
    if (field.data) {
      zoomOnFeature({
        map: mapRef?.current?.getMap(),
        geojson: field.data,
      });
      setInitialPolygon(flatten(field.data));

      form.setInitialValues({
        name: field.data.properties.name,
        farm: field.data.properties.farm.id.toString(),
        species: field.data.properties.species?.id.toString(),
        variety: field.data.properties.variety?.id.toString(),
        soil_type: field.data.properties.soil_type?.id.toString(),
        potential_wheat: field.data.properties.potential_wheat,
        potential_barley: field.data.properties.potential_barley,
        potential_rapeseed: field.data.properties.potential_rapeseed,
      });
      form.reset();
      form.resetDirty();
    }
  }, [field.data]);

  useEffect(() => {
    if (updateField.isSuccess) {
      navigateToField(updateField.data.id);
    }
  }, [updateField]);

  useEffect(() => {
    if (createField.isSuccess) {
      if (!Array.isArray(createField.data)) {
        navigateToField(createField.data.id);
      } else {
        navigate({ to: '/fields' });
      }
    }
  }, [createField]);

  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar>
          <Paper flex="1 0 auto" h="100%" maw={rem(400)}>
            <FieldFormProvider form={form}>
              <FieldForm
                loading={createField.isPending || updateField.isPending}
                onFileUploaded={handleFileUploaded}
                onSubmit={handleSubmit}
                type={pageType}
              />
            </FieldFormProvider>
          </Paper>
        </FullMainHeight.Sidebar>
        <Paper flex="1 1 auto" h="100%" p={0}>
          <PfMap onLoad={handleLoad} ref={mapRef}>
            <FieldsInBounds
              fieldId={fieldId}
              hideClusters
              withSatelliteControl={pageType === 'edit'}
            />
            <StyleControl />
            <DrawToolboxControl
              guidanceControls={['draw', 'edit', 'cut']}
              initialPolygon={initialPolygon}
              onDrawEnd={handleDrawEnd}
            />
            <GeocoderControl />
          </PfMap>
        </Paper>
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
}

ManageFieldPage.Error = ErrorPage;

export default ManageFieldPage;
