import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { RapeseedResultPage } from '@advices/feature';
const searchSchema = z.object({
  edit: z.boolean().optional(),
});
export const Route = createFileRoute(
  '/_app/precifert-n_/rapeseed/results/$resultId',
)({
  validateSearch: zodSearchValidator(searchSchema),
  component: RapeseedResultPage,
});
