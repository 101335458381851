import { useMutation, useQueryClient } from '@tanstack/react-query';

import { accountsApi } from '../../apis/accounts';
import { accountsQueries } from '../../queries/accounts';

import { useQueriesWithGlobal } from '@data-access';

export function useDeleteAccount() {
  const client = useQueryClient();
  const queries = useQueriesWithGlobal(accountsQueries);

  const mutation = useMutation({
    mutationFn: accountsApi.deleteOne,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: queries.all(),
      });
    },
  });

  return mutation;
}

export default useDeleteAccount;
