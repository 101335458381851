import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { dashboardQueries } from '../../queries/dashboard.queries';

import { useFiltersStore, useQueriesWithGlobal } from '@data-access';

export function useAdminStats() {
  const page = useFiltersStore.use['adminStats']().pagination.pageIndex + 1;
  const sorting = useFiltersStore.use['adminStats']().sorting;
  const filters = useFiltersStore.use['adminStats']().filters;
  const queries = useQueriesWithGlobal(dashboardQueries);

  const query = useQuery({
    ...queries.adminStats({ page, sorting, filters }),
    placeholderData: keepPreviousData,
  });

  return query;
}

export default useAdminStats;
