import { Button, Checkbox, Group, List, Select, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MRT_RowSelectionState } from 'mantine-react-table';

import classes from './export-prescription-modal.module.css';

import { useColFilters, useQueriesWithGlobal } from '@data-access';
import { fieldsApi, fieldsQueries } from '@fields/data-access';

interface FormValues {
  console: string | null;
}

interface ExportPrescriptionModalProps {
  selection: MRT_RowSelectionState;
  onClose: () => void;
}

export function ExportPrescriptionModal({
  selection,
  onClose,
}: ExportPrescriptionModalProps) {
  const queries = useQueriesWithGlobal(fieldsQueries);
  const [filters, setFilters] = useColFilters('prescriptions');
  const values = useQuery(
    queries.prescriptionsExportValues(
      selection.all
        ? { ids: [], all: true, selectedFilters: filters }
        : { ids: Object.keys(selection).map((id) => Number(id)) },
    ),
  );

  const exportMap = useMutation({
    mutationFn: fieldsApi.prescriptions.export,
    onSuccess: () => {
      onClose();
    },
  });

  const form = useForm<FormValues>({
    initialValues: {
      console: null,
    },
  });

  const handleSubmit = (v: FormValues) => {
    if (selection.all) {
      exportMap.mutate({
        console: Number(v.console),
        ids: [],
        all: true,
        selectedFilters: filters,
      });
    } else {
      const ids = Object.keys(selection).map((id) => Number(id));
      exportMap.mutate({
        console: Number(v.console),
        ids,
      });
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Text>Bilan de l'export</Text>
      <List my="sm" withPadding>
        {values.data?.summary.map((summary) => (
          <List.Item key={summary.product}>
            <Text fs="italic" size="sm">
              {summary.product} - {summary.total.toFixed(2)} {summary.unit}
            </Text>
          </List.Item>
        ))}
      </List>
      <Select
        {...form.getInputProps('console')}
        data={
          values.data?.console.map((console) => ({
            value: console.id.toString(),
            label: console.name,
          })) ?? []
        }
        label="Console"
        required
      />

      <Checkbox
        label="En validant cet export, je m’engage à avoir pris connaissance de la carte de préconisation et accepte de l’utiliser sous mon entière responsabilité."
        mt="lg"
        required
      />

      <Group mt="md">
        <Button loading={exportMap.isPending} type="submit">
          Exporter
        </Button>
        <Button color="red" onClick={onClose} type="button">
          Annuler
        </Button>
      </Group>
    </form>
  );
}

export default ExportPrescriptionModal;
