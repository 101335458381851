import { Group, Paper, rem } from '@mantine/core';
import { ErrorRouteComponent } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { PfMap } from '@map';
import { CommonError, FullMainHeight } from '@ui';

export const ErrorPage: ErrorRouteComponent = ({ error, reset }) => {
  const { t } = useTranslation();

  return (
    <FullMainHeight>
      <Group h="100%">
        <Paper flex="1 0 auto" h="100%" maw={rem(400)} p={0}>
          <CommonError
            description={t('common.error.pageError')}
            error={error}
            reset={reset}
          />
        </Paper>
        <Paper flex="1 1 auto" h="100%" p={0}>
          <PfMap></PfMap>
        </Paper>
      </Group>
    </FullMainHeight>
  );
};

export default ErrorPage;
