import { Badge, Box, Button, Group, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRouter } from '@tanstack/react-router';
import { RowSelectionState } from '@tanstack/table-core';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AccountsTable, {
  AccountsTableProps,
} from '../accounts-table/accounts-table';
import Modal from '../modal/modal';

import classes from './accounts-selector.module.css';

import { accountsAtom } from '@data-access';

type RenderBottomToolbarCustomActions = NonNullable<
  NonNullable<AccountsTableProps['options']>['renderBottomToolbarCustomActions']
>;

export function AccountsSelector() {
  const { t } = useTranslation();
  const [opened, actions] = useDisclosure();
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const router = useRouter();

  const renderActions: RenderBottomToolbarCustomActions = useCallback(
    ({ table }) => (
      <Group>
        <Button
          onClick={() => {
            const ids = Object.keys(rowSelection).map((id) => Number(id));
            setAccounts(ids);
            actions.close();

            router.navigate({
              to: '/fields',
            });
          }}
        >
          {t('common.confirm')}
        </Button>

        <Button color="red" onClick={actions.close}>
          {t('common.cancel')}
        </Button>

        <Button
          onClick={() => {
            setAccounts([]);
            setRowSelection({});
            actions.close();

            router.navigate({
              to: '/fields',
            });
          }}
          variant="subtle"
        >
          {t('common.reset')}
        </Button>
      </Group>
    ),
    [actions, rowSelection, setAccounts, t, router],
  );

  return (
    <>
      <Button
        color="pf-green"
        onClick={actions.open}
        rightSection={
          <Badge
            color={accounts.length > 0 ? 'orange' : 'pf-green'}
            variant="light"
          >
            {accounts.length}
          </Badge>
        }
        variant="white"
      >
        Compte
      </Button>
      <Modal
        classNames={{
          root: clsx(classes.modal),
          content: clsx(classes.modalContent),
          header: clsx(classes.modalHeader),
          title: clsx(classes.modalTitle),
          body: clsx(classes.modalBody),
        }}
        onClose={actions.close}
        opened={opened}
        size="80%"
        title="Rechercher un compte"
      >
        <Box maw="100%">
          <AccountsTable
            options={{
              enableFullScreenToggle: false,

              renderBottomToolbarCustomActions: renderActions,
              enableRowSelection: true,
              enableMultiRowSelection: false,
              onRowSelectionChange: setRowSelection,
              initialState: {
                rowSelection: accounts.reduce(
                  (acc, id) => {
                    acc[id] = true;
                    return acc;
                  },
                  {} as Record<number, boolean>,
                ),
              },
            }}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            withActions={false}
          />
        </Box>
      </Modal>
    </>
  );
}

export default AccountsSelector;
