import { Stack, Paper, Skeleton } from '@mantine/core';

import classes from './field-maps-layout.module.css';

interface FieldMapsLayoutProps {
  header: React.ReactNode;
  content: React.ReactNode;
}

const Pending = () => (
  <Stack h="100%">
    <Skeleton className={classes.header} p={0} />
    <Skeleton h="100%" p={0} />
  </Stack>
);

export function FieldMapsLayout({ header, content }: FieldMapsLayoutProps) {
  return (
    <Stack h="100%" style={{ overflow: 'hidden' }}>
      <Paper className={classes.header} p={0}>
        {header}
      </Paper>
      <Paper h="100%" p={0}>
        {content}
      </Paper>
    </Stack>
  );
}

FieldMapsLayout.Pending = Pending;

export default FieldMapsLayout;
