import { Button, Portal, Stack, Text } from '@mantine/core';
import { useDebouncedCallback } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawEvent,
  DrawMode,
  DrawModeChangeEvent,
  DrawSelectionChangeEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';
// import defaultDrawStyle from '@mapbox/mapbox-gl-draw/src/lib/theme';
// import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import { flatten } from '@turf/turf';
import { Feature, FeatureCollection, LineString, Polygon } from 'geojson';
// @ts-expect-error - types are not available
import * as mapboxGlDrawPassingMode from 'mapbox-gl-draw-passing-mode';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SnapDirectSelect, SnapPolygonMode } from 'mapbox-gl-draw-snap-on-mode';
import { useCallback, useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useControl, useMap } from 'react-map-gl';

import FeatureSize from '../feature-size/feature-size';

import Guide from './components/guide';
import styles from './draw-toolbox-control.module.css';
import { CutPolygonMode } from './modes/cut-polygon';
import DirectSelectMode from './modes/direct-select-mode';
import { SplitPolygonMode } from './modes/split-polygon';
import { StaticMode } from './modes/static-mode';
import { constantsModes, highlightPropertyName } from './utils/constants';
import { customTheme } from './utils/theme';
import { turfSplit } from './utils/turf-split';

export type GuidanceMode =
  | 'static'
  | 'draw'
  | 'edit'
  | 'cut'
  | 'split'
  | 'paint';

export interface DrawToolboxControlProps {
  guidanceControls?: GuidanceMode[];
  onDrawEnd?: (features: Feature<Polygon>[]) => void;
  initialPolygon?: GeoJSON.Feature | GeoJSON.FeatureCollection;
  paintColor?: string;
  paintfilled?: boolean;
  withSizes?: boolean;
}

export const DrawToolboxControl = memo(function DrawToolboxControl({
  guidanceControls = ['draw', 'edit', 'cut', 'split'],
  onDrawEnd,
  initialPolygon,
  paintColor,
  paintfilled = false,
  withSizes = true,
}: DrawToolboxControlProps) {
  const { t } = useTranslation();
  const [ready, setReady] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [featuresToSplit, setFeaturesToSplit] = useState<
    Feature<Polygon>[] | null
  >(null);
  const [guidanceMode, setGuidanceMode] = useState<GuidanceMode>('static');
  const [drawMode, setDrawMode] = useState<DrawMode>('static');
  const [previousDrawState, setPreviousDrawState] =
    useState<FeatureCollection>();
  const [draws, setDraws] = useState<FeatureCollection>();

  const { current: map } = useMap();
  const mapId = map?.getMap().getContainer().id;
  const draw: MapboxDraw = useControl(
    () =>
      new MapboxDraw({
        displayControlsDefault: false,
        styles: customTheme(paintfilled),
        keybindings: true,
        modes: {
          ...MapboxDraw.modes,
          direct_select: DirectSelectMode,
          passing_draw_polygon: mapboxGlDrawPassingMode.passing_draw_polygon,
          passing_draw_line_string:
            mapboxGlDrawPassingMode.passing_draw_line_string,
          static: StaticMode,
          [constantsModes.CUT_POLYGON_MODE]: CutPolygonMode,
          [constantsModes.SPLIT_POLYGON_MODE]: SplitPolygonMode,
          draw_polygon: SnapPolygonMode,
          // direct_select: SnapDirectSelect,
        },
        defaultMode: 'static',
        userProperties: true,
        snap: true,
        snapOptions: {
          snapPx: 5, // defaults to 15
          snapToMidPoints: true, // defaults to false
          snapVertexPriorityDistance: 0.0025, // defaults to 1.25
          sources: ['plots-data'],
        },
        guides: false,
      }) as any,
    (c) => setReady(true),
    (c) => null,
    { position: 'bottom-right' },
  );

  const changeDrawMode = useCallback(
    (
      newMode: DrawMode,
      options?: {
        featureIds?: string[];
        featureId?: string;
      },
    ) => {
      draw.changeMode(newMode as string, options);
      setDrawMode(newMode);
      return newMode;
    },
    [draw],
  );

  const handleStartGuidanceMode = (newMode: GuidanceMode) => {
    const ids = map
      ?.getStyle()
      ?.layers.filter((l) => l.id.includes('gl-draw'))
      .map((l) => l.id);

    if (ids?.length) {
      ids.forEach((id) => {
        map?.moveLayer(id, 'z-index-1');
      });
    }
    setGuidanceMode(newMode);
    setPreviousDrawState(draw.getAll());

    switch (newMode) {
      case 'draw':
        changeDrawMode('draw_polygon');
        break;
      case 'edit':
      case 'cut':
      case 'split':
      case 'paint':
        changeDrawMode('simple_select');
        break;
    }
  };

  const handleDrawPassingCut = () => {
    if (guidanceMode === 'split' && drawMode === 'simple_select') {
      const selectedFeatures = draw.getSelected()
        .features as Feature<Polygon>[];
      if (selectedFeatures.length === 0) {
        alert(t('map.drawtools.split_selection_error'));
        return;
      }

      setFeaturesToSplit(selectedFeatures);

      selectedFeatures.forEach((f) => {
        draw.setFeatureProperty(f.id!.toString(), highlightPropertyName, true);
      });

      return changeDrawMode('draw_line_string');
    }

    if (guidanceMode === 'cut' && drawMode === 'simple_select') {
      return changeDrawMode('cut_polygon');
    }
  };

  const handleEndGuidanceMode = (confirm = false) => {
    if (!confirm && previousDrawState) {
      draw.set(previousDrawState);
    }

    if (guidanceMode === 'split') {
      draw.getAll().features.forEach((f) => {
        // delete line string
        if (f.geometry.type === 'LineString') {
          draw.delete(f.id!.toString());
        } else if (f.properties?.ghost) {
          // remove ghost property
          draw.setFeatureProperty(f.id!.toString(), 'ghost', undefined);
        }
      });
    }

    setFeaturesToSplit(null);
    setPreviousDrawState(undefined);
    setGuidanceMode('static');
    changeDrawMode('static');

    if (onDrawEnd && confirm) {
      const allFeatures = draw.getAll().features as Feature<Polygon>[];
      onDrawEnd(allFeatures);
    }
  };

  useEffect(() => {
    if (!ready) {
      return;
    }

    const onDrawModeChange = (e: DrawModeChangeEvent) => {
      setDrawMode((currentMode) => {
        // keep user in drawing mode while in draw guidance mode
        if (currentMode === 'draw_polygon' && e.mode === 'simple_select') {
          return changeDrawMode('draw_polygon');
        }

        return e.mode;
      });
    };
    map?.on('draw.modechange', onDrawModeChange);

    return () => {
      map?.off('draw.modechange', onDrawModeChange);
    };
  }, [changeDrawMode, guidanceMode, map, ready]);

  useEffect(() => {
    if (!ready) {
      return;
    }

    const handleSelectionChange = (e: DrawSelectionChangeEvent) => {
      // is selecting polygons only (lines are used for cutting)
      setIsSelecting(
        e.features.length > 0 &&
          e.features.some(
            (f) =>
              f.geometry.type === 'Polygon' ||
              f.geometry.type === 'MultiPolygon',
          ),
      );

      if (guidanceMode === 'paint' && e.features.length > 0) {
        const id = e.features[0].id?.toString();
        if (id && paintColor) {
          draw.setFeatureProperty(id, 'color', paintColor);
        }
        changeDrawMode('simple_select');
      }

      if (guidanceMode === 'edit' && e.features.length > 0) {
        changeDrawMode('direct_select', {
          featureId: e.features[0].id?.toString(),
        });
      }
    };
    map?.on('draw.selectionchange', handleSelectionChange);

    return () => {
      map?.off('draw.selectionchange', handleSelectionChange);
    };
  }, [changeDrawMode, draw, guidanceMode, map, paintColor, ready]);

  const handleDraw = useDebouncedCallback(
    (e: DrawCreateEvent | DrawUpdateEvent | DrawDeleteEvent) => {
      const currentDrawnFeatures = draw.getAll();
      try {
        if (
          e.features.length === 1 &&
          e.features[0].geometry.type === 'LineString'
        ) {
          const cutLine = e.features[0] as Feature<LineString>;
          const ghostPolygons = currentDrawnFeatures.features.filter(
            (f) => f.properties?.ghost,
          );
          const splittedFeatures = featuresToSplit?.map((p) =>
            turfSplit(p, cutLine, { ghost: true }),
          );
          const nextDrawnFeatures = splittedFeatures
            ?.filter((s) => !!s)
            .map((s) => s.features)
            .flat();

          // remove ghost and base features before adding new ones
          draw.delete(ghostPolygons.map((p) => p.id!.toString()));
          draw.delete(featuresToSplit?.map((p) => p.id!.toString()) || []);

          // add new features
          nextDrawnFeatures?.forEach((f) => draw.add(f));

          // hide notification if any
          notifications.hide('split-error');
        }
      } catch (error) {
        map?.getMap().fire('draw.split_error');
      }
      setDraws(draw.getAll());
    },
    50,
  );

  useEffect(() => {
    if (!ready) {
      return;
    }

    map?.on('draw.liveUpdate', handleDraw);
    map?.on('draw.create', handleDraw);
    map?.on('draw.update', handleDraw);
    map?.on('draw.delete', handleDraw);

    return () => {
      map?.off('draw.liveUpdate', handleDraw);
      map?.off('draw.create', handleDraw);
      map?.off('draw.update', handleDraw);
      map?.off('draw.delete', handleDraw);
    };
  }, [draw, featuresToSplit, handleDraw, map, ready]);

  useEffect(() => {
    if (initialPolygon) {
      draw.deleteAll();
      draw.add(initialPolygon);
      setDraws(draw.getAll());
    }
  }, [draw, initialPolygon]);

  useEffect(() => {
    const onSplitError = (e: any) => {
      notifications.show({
        id: 'split-error',
        title: t('map.drawtools.split_error_title'),
        message: t('map.drawtools.split_error_message'),
        color: 'red',
      });
    };

    map?.on('draw.split_error', onSplitError);

    return () => {
      map?.off('draw.split_error', onSplitError);
    };
  }, [map, t]);

  useEffect(() => {
    map?.on('contextmenu', (e) => {
      // Query for features under the cursor
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['gl-draw-vertex-inner.hot', 'gl-draw-vertex-inner.cold'], // Mapbox GL Draw point layers
      });

      if (features.length > 0) {
        const pointFeature = features[0];

        // Get the parent feature ID (e.g., LineString or Polygon ID)
        const parentFeatureId = pointFeature.properties?.parent;

        if (parentFeatureId) {
          draw.trash();
        }
      }
    });
  }, [draw, map]);

  if (!mapId) {
    return null;
  }
  return (
    <>
      <Portal
        target={
          document &&
          (document
            .getElementById(mapId)
            ?.getElementsByClassName(
              'mapboxgl-ctrl-top-left',
            )[0] as HTMLElement)
        }
      >
        <div className={`${styles['container']} mapboxgl-ctrl`}>
          {drawMode === 'static' ? (
            <Stack gap="xs">
              <Text>{t(`map.drawTools.title`)}</Text>
              {guidanceControls.map((control) => (
                <Button
                  key={control}
                  onClick={() => handleStartGuidanceMode(control)}
                  size="compact-sm"
                >
                  {t(`map.drawTools.${control}Title`)}
                </Button>
              ))}
            </Stack>
          ) : (
            <Guide
              guidanceMode={guidanceMode}
              handleDrawPassingCut={handleDrawPassingCut}
              handleEndGuidanceMode={handleEndGuidanceMode}
              isSelecting={isSelecting}
            />
          )}
        </div>
      </Portal>

      {draws && withSizes ? (
        <FeatureSize features={flatten(draws).features} withBorders={false} />
      ) : null}
    </>
  );
});

export default DrawToolboxControl;
