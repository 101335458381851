import { useForm } from '@mantine/form';

interface PrescriptiondMapFormValues {
  file: File | null;
  name?: string;
  col: string;
  group: string;
  input: string;
}

interface PrescriptiondMapFormTransformedValues {
  file: File;
  name?: string;
  col: string;
  type: number;
  crop: number;
}

type TransformValues = (
  values: PrescriptiondMapFormValues
) => PrescriptiondMapFormTransformedValues;

export const usePrescriptiondMapForm = () => {
  return useForm<PrescriptiondMapFormValues, TransformValues>({
    initialValues: {
      file: null,
      col: '',
      group: '',
      input: '',
    },
  });
};
