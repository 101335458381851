import {
  Button,
  Center,
  Group,
  Loader,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import {
  IconChevronDown,
  IconCloudFilled,
  IconEdit,
  IconFileDownload,
  IconTrash,
} from '@tabler/icons-react';
import { useSuspenseQuery, useQuery, useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExportPrescriptionModal from '../../../../components/export-prescription-modal/export-prescription-modal';
import ExtendedCombobox from '../../../../components/extended-combobox/extended-combobox';
import { FieldMapsLayout } from '../../../../components/field-maps-layout/field-maps-layout';
import SelectMapType from '../../../../components/select-map-type/select-map-type';
import useSharedFieldMaps from '../../../../hooks/use-shared-field-maps/shared-field-maps';
import { MAP_DISPLAY_TYPES } from '../compare-maps/page';

import { FORM_STATUS } from '@advices/data-access';
import { fromBacki18n, PRESCRIPTION_FROM_TYPES } from '@data-access';
import { fieldMapsApi } from '@fields/data-access';
import {
  formatNitrogenLegend,
  LegendControl,
  PfMap,
  ZoningLayer,
  ZoningLegend,
} from '@map';
import { ActionsMenu, ActionsMenuItem, Drawer } from '@ui';

interface PrescriptionMapProps {
  type: MAP_DISPLAY_TYPES;
}

export function PrescriptionMap({ type }: PrescriptionMapProps) {
  const { t, i18n } = useTranslation();
  const [exportOpened, exportActions] = useDisclosure();

  const {
    field,
    fieldId,
    mapId,
    compare,
    queries,
    handleMapLoad,
    handleCompareChange,
    navigateMapIdSp,
  } = useSharedFieldMaps({
    type,
  });

  const summary = useSuspenseQuery(
    queries.prescriptionsSummary(Number(fieldId)),
  );

  const [selectedMap, setSelectedMap] = useState<string | null>(
    mapId?.toString() ?? summary.data[0]?.id.toString() ?? null,
  );

  const map = useQuery({
    ...queries.prescriptionsMap(Number(selectedMap)),
    enabled: selectedMap !== null,
    refetchInterval(query) {
      return query.state.data?.status !== FORM_STATUS.FINISHED ? 5000 : false;
    },
  });

  const deleteMap = useMutation({
    mutationFn: fieldMapsApi.deleteNitrogenMap,
    onSuccess: (data) => {
      const newSummary = summary.data.filter((item) => item.id !== data);
      const newSelectedMap = newSummary[0]?.id.toString() ?? null;
      setSelectedMap(newSelectedMap);
    },
  });

  const selectedMapData = summary.data.find(
    (item) => item.id.toString() === selectedMap,
  );

  const options = summary.data.map((item) => ({
    value: item.id.toString(),
    label: item.name,
    description: [
      fromBacki18n(item.product, i18n.language),
      `${t('common.created_date_prefix')} ${dayjs(item?.created_at).format(
        `DD/MM/YYYY [${t('common.time_prefix')}] H[h]mm`,
      )}`,
    ],
  }));

  const items: ActionsMenuItem[] = [
    {
      icon: IconEdit,
      label: 'Créer une copie',
      type: 'link',
      props: {
        disabled: selectedMap === null,
        params: {
          fieldId,
        },
        search:
          selectedMap !== null
            ? {
                mapType: PRESCRIPTION_FROM_TYPES.NITROGEN,
                mapId: Number(selectedMap),
              }
            : {},
        to: '/fields/$fieldId/maps/prescriptions/create',
      },
    },
    {
      icon: IconFileDownload,
      label: 'Exporter',
      type: 'action',
      props: {
        disabled: selectedMap === null,
      },
      onClick() {
        exportActions.open();
      },
    },
    {
      icon: IconTrash,
      label: 'Supprimer la carte',
      color: 'red',
      type: 'action',
      props: {
        disabled: selectedMap === null,
      },
      onClick() {
        modals.openConfirmModal({
          title: 'Suppression de la carte',
          children: 'Êtes-vous sûr de vouloir supprimer cette carte ?',
          labels: {
            confirm: 'Supprimer',
            cancel: 'Annuler',
          },
          confirmProps: {
            color: 'red',
          },
          onConfirm: () => {
            deleteMap.mutate(Number(selectedMap));
          },
        });
      },
    },
  ];

  useEffect(() => {
    if (mapId === undefined && selectedMap !== null) {
      navigateMapIdSp(selectedMap);
    } else if (mapId && selectedMap === null) {
      navigateMapIdSp();
    } else if (mapId && selectedMap && selectedMap !== mapId.toString()) {
      navigateMapIdSp(selectedMap);
    }
  }, [mapId, navigateMapIdSp, selectedMap]);

  return (
    <FieldMapsLayout
      content={
        <>
          {map.data?.status === FORM_STATUS.FINISHED ? (
            <PfMap onLoad={handleMapLoad}>
              <ZoningLayer data={map.data.map} filled outline={false} />
              <LegendControl>
                <ZoningLegend
                  {...formatNitrogenLegend({
                    map: map.data?.map,
                    product: map.data?.product,
                    switchable: selectedMapData?.map_type === 'nitrogen',
                  })}
                  {...map.data.legend}
                />
              </LegendControl>
            </PfMap>
          ) : null}

          {map.data?.status === FORM_STATUS.PENDING ? (
            <Center h="100%" p="xl">
              <Stack align="center">
                <Text fw="bold" size="xl" ta="center">
                  Nous sommes en train de préparer votre carte
                </Text>
                <Loader />
              </Stack>
            </Center>
          ) : null}

          {summary.data?.length === 0 ? (
            <Center h="100%" p="xl">
              <Stack>
                <Text fw="bold" size="xl" ta="center">
                  Aucune carte de préconisation disponible
                </Text>
              </Stack>
            </Center>
          ) : null}
        </>
      }
      header={
        <Group align="center" h="100%" justify="space-between" pl="md">
          <Group>
            {type !== 'single' ? (
              <SelectMapType
                maps={field.data.properties.maps}
                onChange={handleCompareChange}
                value={type === 'compare-left' ? compare?.[0] : compare?.[1]}
              />
            ) : null}
            {summary.data.length && selectedMap ? (
              <ExtendedCombobox
                inputProps={{
                  leftSection: map.data?.cloud ? (
                    <ThemeIcon color="orange" size="sm" variant="transparent">
                      <IconCloudFilled />
                    </ThemeIcon>
                  ) : null,
                }}
                isPending={summary.isPending || map.isFetching}
                onOptionSubmit={(val) => {
                  navigateMapIdSp(val);
                  setSelectedMap(val);
                }}
                options={options}
                selectedOption={selectedMap}
              />
            ) : null}
          </Group>

          {type === 'single' ? (
            <Group p="md">
              <ActionsMenu items={items}>
                <Button color="red" rightSection={<IconChevronDown />}>
                  Action
                </Button>
              </ActionsMenu>
            </Group>
          ) : null}

          {mapId ? (
            <Drawer
              onClose={exportActions.close}
              opened={exportOpened}
              position="right"
              title="Exportation"
            >
              <ExportPrescriptionModal
                onClose={exportActions.close}
                selection={{ [mapId]: true }}
              />
            </Drawer>
          ) : null}
        </Group>
      }
    />
  );
}

export default PrescriptionMap;
