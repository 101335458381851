import { Stack, Text } from '@mantine/core';
import { DeepKeys } from '@tanstack/table-core';
import dayjs from 'dayjs';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AdviceStatusCell } from '../advice-status-cell/advice-status-cell';
import { FieldNameCell } from '../common-advice-table/field-name-cell';
import { YieldCell } from '../common-advice-table/yield-cell';
import { StatusColumnFilter } from '../status-column-filter/status-column-filter';

import { BiomassCell } from './biomass-cell';

import {
  RapeseedListAdvice,
  FORM_STATUS,
  useValues,
  BiomassStart,
} from '@advices/data-access';
import { CropEnum, fromBacki18n } from '@data-access';

// eslint-disable-next-line @typescript-eslint/ban-types
type accessorKey = DeepKeys<RapeseedListAdvice> | (string & {});
type ColVisibilityState = Partial<Record<accessorKey, boolean>>;

export const initialColState: ColVisibilityState = {
  'field.thumbnail': true,
  'field.name': true,
  'field.farm.name': true,
  rdt: true,
  biomass_start: true,
  biomass_end: true,
  status: true,

  nbr_biomass: false,
  mean_dose: false,

  seed_date: true,
  sol: false,
  variety: false,
  nitrogen_already_applied: false,
  product: false,
};

export const resultsColState: ColVisibilityState = {
  ...initialColState,
  nbr_biomass: true,
  mean_dose: true,
};

export const dataColState: ColVisibilityState = {
  ...initialColState,
  seed_date: false,
  sol: false,
  nitrogen_already_applied: true,
  product: true,
};

export const useTable = () => {
  const { t, i18n } = useTranslation();
  const values = useValues({ type: CropEnum.RAPESEED });

  const columns = useMemo<MRT_ColumnDef<RapeseedListAdvice>[]>(
    () => [
      {
        accessorKey: 'field.thumbnail',
        enableColumnFilter: false,
        header: '',
        enableSorting: false,
        enableColumnActions: false,
        size: 32,
        Cell: ({ cell, row }) => {
          const thumb = cell.getValue<string>();
          if (thumb) {
            return <img alt="thumbnail" src={thumb} width="32" />;
          }
        },
      },
      {
        accessorKey: 'field.name',
        header: t('plots.fieldName'),
        Cell: ({ cell, row }) => {
          const value = cell.getValue<string>();

          return (
            <FieldNameCell
              rowId={row.id}
              type={CropEnum.RAPESEED}
              value={value}
            />
          );
        },
      },
      {
        accessorKey: 'field.farm.name',
        header: t('plots.farmName'),
        minSize: 250,
      },
      {
        accessorKey: 'seed_date',
        header: t('table.columns.seed_date'),
        Cell: ({ cell }) => {
          const date = cell.getValue<string>();
          return <Text>{date ? dayjs(date).format('DD/MM/YYYY') : ''}</Text>;
        },
        filterVariant: 'date-range',
      },
      {
        accessorKey: 'sol',
        header: t('common.properties.sol'),
        filterVariant: 'select',
        Cell: ({ cell }) => {
          return t(`advices.properties.sol_types.${cell.getValue()}`);
        },
        mantineFilterSelectProps: {
          data: values.data?.sol?.map((item) => ({
            value: item,
            label: t(`advices.properties.sol_types.${item}`),
          })),
        },
      },
      {
        accessorKey: 'rdt',
        Cell: ({ cell }) => `${cell.getValue() || '-'} q/ha`,
        header: t('common.olympicYield'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'nbr_biomass',
        header: t('common.properties.nbr_biomass'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'biomass_start',
        header: t('common.properties.biomass_start'),
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const value = cell.getValue<BiomassStart>();

          return <BiomassCell date={value.date} mean={value.mean} />;
        },
      },
      {
        accessorKey: 'biomass_end',
        header: t('common.properties.biomass_end'),
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const value = cell.getValue<BiomassStart>();

          return <BiomassCell date={value.date} mean={value.mean} />;
        },
      },
      {
        accessorKey: 'status',
        header: t('table.columns.status'),
        maxSize: 100,
        Cell: ({ cell }) => {
          const status = cell.getValue<FORM_STATUS>();

          return <AdviceStatusCell status={status} context='rapeseed'/>;
        },
        Filter: ({ column, table }) => {
          return (
            <StatusColumnFilter
              onChange={(value) => column.setFilterValue(value)}
              onClear={() => column.setFilterValue(null)}
              value={column.getFilterValue() as FORM_STATUS}
            />
          );
        },
      },
      {
        accessorKey: 'nitrogen_already_applied',
        Cell: ({ cell }) => `${cell.getValue() || '-'} u/ha`,
        header: t('table.columns.nitrogen_already_applied'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      },
      {
        accessorKey: 'product',
        accessorFn: (row) => fromBacki18n(row.product, i18n.language),
        header: t('table.columns.product'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: values.data?.product?.map(({ value, label }) => ({
            value,
            label,
          })),
        },
      },
      {
        accessorKey: 'mean_dose',
        header: t('common.properties.mean_dose'),
        Cell: ({ cell, row }) => {
          const value = cell.getValue<number>();

          return (
            <YieldCell
              mean_dose={value}
              mean_yield={false}
              status={row.original.computation_status}
            />
          );
        },
        filterVariant: 'range',
        filterFn: 'inNumberRange',
      }
    ],
    [t, values.data?.product, values.data?.sol, i18n],
  );

  return { columns };
};
