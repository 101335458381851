import { Fieldset, Checkbox, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { useCommonAdviceFormContext } from '../../../hooks/use-common-advice-form/common-advice-form';

import { useValues } from '@advices/data-access';

interface SettingsFieldsetProps {
  type: string;
}

export const SettingsFieldset = ({ type }: SettingsFieldsetProps) => {
  const { t } = useTranslation();
  const form = useCommonAdviceFormContext();
  const values = useValues({ type });

  return (
    <Fieldset legend={t('advices.settings')} mt="md" variant="filled">
      <Checkbox
        label={t('advices.hide_advice')}
        {...form.getInputProps('is_hidden', { type: 'checkbox' })}
      />
      <Checkbox
        label={t('advices.disable_mail')}
        {...form.getInputProps('disable_mail', { type: 'checkbox' })}
        mt="sm"
      />
      <Checkbox
        label={t('advices.restart')}
        {...form.getInputProps('restart', { type: 'checkbox' })}
        mt="sm"
      />
      <Select
        {...form.getInputProps('data_source')}
        data={values.data?.data_source}
        label={t('advices.properties.data_source')}
        mt="sm"
      />
    </Fieldset>
  );
};

export const SettingsFieldsetDistri = () => {
  const { t } = useTranslation();
  const form = useCommonAdviceFormContext();

  return (
    <Fieldset legend={t('advices.settings')} mt="md" variant="filled">
      <Checkbox
        label={t('advices.disable_mail')}
        {...form.getInputProps('disable_mail', { type: 'checkbox' })}
    />
    </Fieldset>
  );
};

