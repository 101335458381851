import { createFileRoute } from '@tanstack/react-router';

import { fieldLoader } from '../$fieldId';

export const Route = createFileRoute('/_app/fields_/$fieldId_/cut')({
  loader: async ({ context, params }) => {
    return fieldLoader({
      queryClient: context.queryClient,
      fieldId: params.fieldId,
    });
  },
});
