import { queryOptions } from '@tanstack/react-query';

import { GetCommonAdminDto, GetRapeseedAdminDto } from '../types/dto';

import { advicesApi } from './../apis/advices.api';

import { CropEnum, Filters, Globals, useQueriesWithGlobal } from '@data-access';

export const advicesQueries = (global: Globals) => ({
  all: () => [global, 'advices'],
  types: () =>
    queryOptions({
      queryKey: [...advicesQueries(global).all(), 'types'],
      queryFn: () => advicesApi.getTypes(),
    }),

  crop: (crop: string) => [...advicesQueries(global).all(), crop],
  tuto: (crop: string) =>
    queryOptions({
      queryKey: [...advicesQueries(global).crop(crop), 'tuto'],
      queryFn: () => advicesApi.getTuto(crop),
    }),

  lists: (crop: string) => [...advicesQueries(global).crop(crop), 'list'],
  list: (args: { crop: string; filters: Filters }) =>
    queryOptions({
      queryKey: [...advicesQueries(global).lists(args.crop), args.filters],
      queryFn: () => advicesApi.getAllCommon(args.crop, args.filters),
      refetchInterval(query) {
        return query.state.data?.hasPending ? 15000 : false;
      },
    }),

  details: (crop: string) => [...advicesQueries(global).crop(crop), 'detail'],
  detail: (args: { id: number; crop: string }) =>
    queryOptions({
      queryKey: [...advicesQueries(global).details(args.crop), args.id],
      queryFn: () => advicesApi.getOne(args),
    }),

  results: (crop: string) => [...advicesQueries(global).crop(crop), 'results'],
  result: (args: { id: number; crop: string }) =>
    queryOptions({
      queryKey: [...advicesQueries(global).results(args.crop), args.id],
      queryFn: () => advicesApi.getCommonResults(args),
    }),
  recap: (args: { formId: number; crop: string; yieldId?: number }) =>
    queryOptions({
      queryKey: [...advicesQueries(global).results(args.crop), 'recap', args],
      queryFn: () => advicesApi.getResultRecap(args),
    }),

  values: ({ crop, fieldId }: { crop: string; fieldId: number | null }) =>
    queryOptions({
      queryKey: [...advicesQueries(global).crop(crop), 'values', { fieldId }],
      queryFn: () => advicesApi.getValues({ crop, fieldId }),
    }),

  // wheat2
  wheat2Lists: () => [...advicesQueries(global).crop(CropEnum.WHEAT2), 'list'],
  wheat2List: (filters: Filters) =>
    queryOptions({
      queryKey: [...advicesQueries(global).wheat2Lists(), filters],
      queryFn: () => advicesApi.getAllWheat2(filters),
      refetchInterval(query) {
        return query.state.data?.hasPending ? 15000 : false;
      },
    }),
  wheat2Details: (args: { id: number }) =>
    queryOptions({
      queryKey: [...advicesQueries(global).details(CropEnum.WHEAT2), args.id],
      queryFn: () => advicesApi.getOneWheat2(args),
    }),

  // rapeseed
  rapeseedLists: () => [
    ...advicesQueries(global).crop(CropEnum.RAPESEED),
    'list',
  ],
  rapeseedList: (filters: Filters) =>
    queryOptions({
      queryKey: [...advicesQueries(global).rapeseedLists(), filters],
      queryFn: () => advicesApi.getAllRapeseed(filters),
      refetchInterval(query) {
        return query.state.data?.hasPending ? 15000 : false;
      },
    }),
  rapeseedDetail: (args: { id: number }) =>
    queryOptions({
      queryKey: [...advicesQueries(global).details(CropEnum.RAPESEED), args.id],
      queryFn: () => advicesApi.getOneRapeseed(args),
    }),
  rapeseedResult: (args: { id: number }) =>
    queryOptions({
      queryKey: [...advicesQueries(global).results(CropEnum.RAPESEED), args.id],
      queryFn: () => advicesApi.getRapeseedResult(args),
    }),
  biomassMaps: () => [
    ...advicesQueries(global).crop(CropEnum.RAPESEED),
    'biomassMaps',
  ],
  biomassMap: (mapId: number) =>
    queryOptions({
      queryKey: [...advicesQueries(global).biomassMaps(), mapId],
      queryFn: () => advicesApi.getBiomassMap(mapId),
    }),

  admin: () => [...advicesQueries(global).all(), 'admin'],
  commonAdmin: (params: GetCommonAdminDto) =>
    queryOptions({
      queryKey: [...advicesQueries(global).admin(), 'common', params],
      queryFn: () => advicesApi.getCommonAdmin(params),
    }),
  rapeseedAdmin: (params: GetRapeseedAdminDto) =>
    queryOptions({
      queryKey: [...advicesQueries(global).admin(), 'rapeseed', params],
      queryFn: () => advicesApi.getRapeseedAdmin(params),
    }),
  commonAdminValues: () =>
    queryOptions({
      queryKey: [...advicesQueries(global).admin(), 'common', 'values'],
      queryFn: () => advicesApi.getCommonAdminValues(),
    }),
});

export const useAdvicesQueries = () => {
  return useQueriesWithGlobal(advicesQueries);
};
