import { useMutation } from '@tanstack/react-query';

import { fieldMapsApi } from '../../apis/field-maps';

export function useProcessSoilMap() {
  return useMutation({
    mutationFn: fieldMapsApi.processSoilMap,
  });
}

export default useProcessSoilMap;
