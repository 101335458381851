import { Group, Box, Stack, Text } from '@mantine/core';
import { LegendProps } from 'recharts';

import styles from './overlayed-bar-legend.module.css';

interface OverlayedBarLegendProps extends LegendProps {
  withReference?: boolean;
  referenceLabel?: string;
  title?: React.ReactNode;
}

export function OverlayedBarLegend(props: OverlayedBarLegendProps) {
  return (
    <Group justify="space-between" mt="md" pl="md">
      <Box>{props.title}</Box>
      <Stack>
        {props.payload?.map((entry) => (
          <Group key={entry.value}>
            <Box
              className={styles.legendItem}
              style={{ backgroundColor: entry.color }}
            />
            <Text size="xs">{entry.value}</Text>
          </Group>
        ))}
        {props.withReference ? (
          <Group>
            <Box
              className={styles.referenceLegendItem}
              style={{ backgroundColor: 'red' }}
            />
            <Text size="xs">{props.referenceLabel}</Text>
          </Group>
        ) : null}
      </Stack>
    </Group>
  );
}

export default OverlayedBarLegend;
