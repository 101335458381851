import { createFileRoute, redirect } from '@tanstack/react-router';

import { ROLES } from '@data-access';

const accessControl = [ROLES.ADMIN, ROLES.DISTRI];

export const Route = createFileRoute('/_app/_admin')({
  beforeLoad(ctx) {
    if (
      !ctx.context.auth.user?.user_access ||
      !accessControl.includes(ctx.context.auth.user?.user_access)
    ) {
      redirect({
        to: '/dashboard',
      });
    }
  },
});
