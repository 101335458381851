import { constants } from '@mapbox/mapbox-gl-draw/';
import { Polygon } from 'geojson';

import { highlightPropertyName } from '../utils/constants';
import { turfSplit } from '../utils/turf-split';

export const modeName = 'split_polygon';

const { geojsonTypes } = constants;

/// when a (multi-)polygon feature is selected to be splitted, it gets highlighted.
/// here is the name of the property indicating the highlight.

export const defaultOptions = {
  highlightColor: '#222',
  lineWidth: 0.001,
  lineWidthUnit: 'kilometers',
};

export const SplitPolygonMode: MapboxDraw.DrawCustomMode = {
  toDisplayFeatures: function (state, geojson, display) {
    display(geojson);
  },
};

SplitPolygonMode.onSetup = function (opt) {
  const { highlightColor = defaultOptions.highlightColor } = opt || {};

  const main = this.getSelected()
    .filter((f) => f.type === 'Polygon' || f.type === 'MultiPolygon')
    .map((f) => {
      f.setProperty(highlightPropertyName, highlightColor);
      this.addFeature(f);
      return f.toGeoJSON();
    }) as GeoJSON.Feature<Polygon>[];

  if (main.length < 1) {
    throw new Error(
      'Please select a feature/features (Polygon or MultiPolygon) to split!',
    );
  }
  /// `onSetup` job should complete for this mode to work.
  /// so `setTimeout` is used to bupass mode change after `onSetup` is done executing.
  setTimeout(() => {
    this.changeMode('passing_draw_line_string', {
      onDraw: (cuttingLineString: any) => {
        main.forEach((feature) => {
          if (
            feature.geometry.type === geojsonTypes.POLYGON ||
            feature.geometry.type === geojsonTypes.MULTI_POLYGON
          ) {
            try {
              const afterSplit = turfSplit(feature, cuttingLineString);

              if (afterSplit && afterSplit.features.length > 0) {
                afterSplit.features.forEach((f) => {
                  const newFeature = this.newFeature(structuredClone(f));
                  newFeature.setProperty(highlightPropertyName, undefined);
                  console.log({ feature });

                  if (feature.id && this.getFeature(feature.id.toString())) {
                    this.deleteFeature(feature.id.toString());
                  }

                  this.addFeature(newFeature);
                });
              }
            } catch (error) {
              this.map.fire('draw.split_error', {
                message: 'Error splitting the feature!',
              });
            }
          } else {
            this.map.fire('draw.split_error', {
              message: 'The feature is not Polygon/MultiPolygon!',
            });
            console.info('The feature is not Polygon/MultiPolygon!');
          }
        });
      },
    });
  }, 0);

  return {
    main,
  };
};

export default SplitPolygonMode;
