import { useMutation } from '@tanstack/react-query';

import { fieldsApi } from '../../apis/fields';

export function useCreateField() {
  return useMutation({
    mutationFn: fieldsApi.createOne,
  });
}

export default useCreateField;
