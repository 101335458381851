import { generateColors } from '@mantine/colors-generator';
import {
  rem,
  createTheme,
  Button,
  Paper,
  Container,
  Select,
} from '@mantine/core';

const CONTAINER_SIZES: Record<string, string> = {
  xxs: rem(300),
  xs: rem(400),
  sm: rem(500),
  md: rem(600),
  lg: rem(700),
  xl: rem(800),
  xxl: rem(900),
};
export const theme = createTheme({
  // scale: 0.91,
  primaryColor: 'pf-green',
  primaryShade: 7,
  colors: {
    'pf-green': generateColors('#7FB758'),
  },
  defaultRadius: 'sm',
  spacing: {
    x3s: rem(4),
    x2s: rem(8),
  },

  components: {
    Select: Select.extend({
      defaultProps: {
        allowDeselect: false,
        searchable: true,
      },
    }),
    Button: Button.extend({
      defaultProps: {
        tt: 'uppercase',
      },
    }),
    Paper: Paper.extend({
      defaultProps: {
        p: 'md',
        radius: 'xs',
        shadow: 'xs',
      },
      styles: {
        root: { overflow: 'hidden' },
      },
    }),
    Container: Container.extend({
      vars: (_, { size, fluid }) => ({
        root: {
          '--container-size': fluid
            ? '100%'
            : size !== undefined && size in CONTAINER_SIZES
              ? CONTAINER_SIZES[size]
              : rem(size),
        },
      }),
    }),
  },
});
