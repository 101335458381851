import {
  NavigateOptions,
  RouterHistory,
  useNavigate,
  useRouter,
} from '@tanstack/react-router';

export const handleNavBack = (
  history: RouterHistory,
  navigate: () => Promise<void>,
) => {
  if (history.location.state.key) {
    history.back();
  } else navigate();
};

interface UseHandleNavBackParams {
  defaultNavigation: NavigateOptions;
}

export const useHandleNavBack = ({
  defaultNavigation,
}: UseHandleNavBackParams) => {
  const { history } = useRouter();

  const navigate = useNavigate();

  const handleNav = () => navigate(defaultNavigation);

  return () => handleNavBack(history, handleNav);
};
