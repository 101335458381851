import { Box, Grid } from '@mantine/core';
import { ErrorRouteComponent } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { DashboardHeader } from '../../components/dashboard-header/dashboard-header';

import { CommonError } from '@ui';

export const PageError: ErrorRouteComponent = ({ error, reset }) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <DashboardHeader />
      <Grid.Col h="100%" order={3} span={12}>
        <Box h={382}>
          <CommonError
            description={t('common.error.pageError')}
            error={error}
            reset={reset}
          />
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default PageError;
