import { ModalProps, Modal as MModal } from '@mantine/core';
import clsx from 'clsx';

import classes from './modal.module.css';

export function Modal({ children, classNames = {}, ...props }: ModalProps) {
  return (
    <MModal
      classNames={{
        ...classNames,
        root: clsx(classes.modal, classNames.root),
        content: clsx(classes.modalContent, classNames.content),
        header: clsx(classes.modalHeader, classNames.header),
        title: clsx(classes.modalTitle, classNames.title),
        body: clsx(classes.modalBody, classNames.body),
      }}
      closeButtonProps={{
        c: 'dark',
      }}
      radius="xs"
      {...props}
    >
      {children}
    </MModal>
  );
}

export default Modal;
