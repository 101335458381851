import { Text, deepMerge } from '@mantine/core';
import { modals } from '@mantine/modals';
import { OnChangeFn, RowSelectionState } from '@tanstack/table-core';
import dayjs from 'dayjs';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableOptions,
} from 'mantine-react-table';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PfTable, { PfTableProps, useRowSelection } from '../pf-table/pf-table';

import {
  ListAccount,
  useAccounts,
  useDeleteAccount,
} from '@accounts/data-access';
import { useFiltersTableOptions } from '@data-access';

/* eslint-disable-next-line */
export interface AccountsTableProps {
  options?: Partial<MRT_TableOptions<ListAccount>>;
  withActions?: boolean;
  rowSelection?: MRT_RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
}

export function AccountsTable({
  options = {},
  withActions = true,
  rowSelection,
  setRowSelection,
}: AccountsTableProps) {
  const { t } = useTranslation();
  const filtersTableOptions = useFiltersTableOptions<ListAccount>('accounts');
  const accounts = useAccounts();
  const deleteAccount = useDeleteAccount();

  const rowSelectionProps = useRowSelection<ListAccount>({
    data: accounts.data?.results || [],
    rowSelection,
    setRowSelection,
  });

  const tableColumns = useMemo<MRT_ColumnDef<ListAccount>[]>(
    () => [
      {
        accessorKey: 'last_name',
        header: t('common.properties.last_name'),
        accessorFn: (row) => `${row.last_name} ${row.first_name}`,
      },
      {
        accessorKey: 'email',
        header: t('common.properties.email'),
      },
      {
        accessorKey: 'organization',
        accessorFn: (row) => row.organization.name,
        header: t('common.properties.organization'),
      },
      // {
      //   accessorKey: 'manager',
      //   accessorFn: (row) =>
      //     `${row.manager?.first_name} ${row.manager?.last_name}`,
      //   header: t('common.properties.manager'),
      // },
      {
        accessorKey: 'total_area',
        header: t('common.properties.total_ha'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
        Cell: ({ cell }) => {
          const value = cell.getValue<number>();
          return `${value} ha`;
        },
      },
      {
        accessorKey: 'date_joined',
        header: t('common.properties.date_joined'),
        filterVariant: 'date-range',
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          if (value) {
            // return value;
            return dayjs(value).format('DD/MM/YYYY HH:mm');
          } else {
            return '-';
          }
        },
      },
      {
        accessorKey: 'last_login',
        filterVariant: 'date-range',
        header: t('common.properties.last_login'),
        minSize: 270,
        Cell: ({ cell }) => {
          const value = cell.getValue<string>();
          if (value) {
            return dayjs(value).format('DD/MM/YYYY HH:mm');
          } else {
            return '-';
          }
        },
      },
    ],
    [t],
  );

  const baseOptions = deepMerge<MRT_TableOptions<ListAccount>>(
    {
      columns: tableColumns,
      data: accounts.data?.results || [],

      rowCount: accounts.data?.count || 0,

      state: {
        rowSelection,
        showLoadingOverlay: accounts.isLoading,
        showProgressBars: accounts.isFetching || deleteAccount.isPending,
      },

      initialState: {
        showColumnFilters: true,
      },
      mantinePaperProps: {
        h: '100%',
      },
      enableTableFooter: true,
      ...rowSelectionProps,
    },
    options,
  );
  const tableOptions = deepMerge<MRT_TableOptions<ListAccount>>(
    baseOptions,
    filtersTableOptions,
  );

  const handleDelete = useCallback(
    (user: MRT_Row<ListAccount>) => {
      modals.openConfirmModal({
        title: t('accounts.delete_account_title'),

        children: (
          <Text>
            {t('accounts.confirm_delete_account')}{' '}
            <Text fw={700} size="lg" span>
              {user.original.last_name} {user.original.first_name}
            </Text>{' '}
            ? {t('common.permanent_action')}
          </Text>
        ),

        labels: { confirm: t('common.delete'), cancel: t('common.cancel') },

        confirmProps: { color: 'red' },

        onConfirm: () => {
          deleteAccount.mutate(user.original.id);
        },
      });
    },
    [deleteAccount, t],
  );

  const editActionLink: PfTableProps<ListAccount>['editActionLink'] = (row) => {
    return {
      to: '/accounts/$accountId/edit',
      params: { accountId: row.original.id.toString() },
    };
  };

  return (
    <PfTable<ListAccount>
      editActionLink={withActions ? editActionLink : undefined}
      onDeleteActionClick={withActions ? handleDelete : undefined}
      options={tableOptions}
    />
  );
}

export default AccountsTable;
