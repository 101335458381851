import {
  Stack,
  Paper,
  Group,
  Text,
  Title,
  ScrollArea,
  Center,
  Button,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconFileDownload, IconTrash } from '@tabler/icons-react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useParams, useSearch } from '@tanstack/react-router';
import { MapEvent } from 'mapbox-gl';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { BiomassMap } from '../../components/biomass-map/biomass-map';
import RapeseedAdviceForm from '../../components/rapeseed-advice-form/rapeseed-advice-form';

import classes from './page.module.css';

import {
  advicesQueries,
  CreateRapeseedAdviceDto,
  useUpdateRapeseedAdvice,
} from '@advices/data-access';
import { CropEnum, useQueriesWithGlobal } from '@data-access';
import {
  formatNitrogenLegend,
  LegendControl,
  PfMap,
  ZoningLayer,
  ZoningLegend,
  zoomOnFeature,
} from '@map';
import {
  ActionsMenu,
  ActionsMenuItem,
  FullMainHeight,
  TsrButtonLink,
  RapeseedFormInfo,
  FieldHeader,
} from '@ui';

export function RapeseedResultPage() {
  const { t } = useTranslation();
  const { edit } = useSearch({
    from: '/_app/precifert-n_/rapeseed/results/$resultId',
  });
  const [opened, { open, close }] = useDisclosure(edit ?? false);

  const { resultId } = useParams({
    from: '/_app/precifert-n_/rapeseed/results/$resultId',
  });

  const queries = useQueriesWithGlobal(advicesQueries);
  const { data } = useSuspenseQuery(
    queries.rapeseedResult({
      id: Number(resultId),
    }),
  );
  const recap = useQuery(
    queries.recap({
      formId: data.id,
      crop: CropEnum.RAPESEED,
    }),
  );
  const updateRapeseedAdvice = useUpdateRapeseedAdvice();

  const menuActions: ActionsMenuItem[] = [
    {
      type: 'native-link',
      label: 'Télécharger le récapitulatif',
      icon: IconFileDownload,
      props: {
        disabled: !recap.data?.file,
        target: '_blank',
        href: recap.data?.file,
      },
    },
    {
      type: 'action',
      label: 'Supprimer',
      color: 'red',
      icon: IconTrash,
      props: {
        disabled: true,
      },
      onClick: () => null,
    },
  ];

  const handleSubmit = (values: CreateRapeseedAdviceDto) => {
    const { field, ...rest } = values;

    updateRapeseedAdvice.mutate(
      {
        id: data.id,
        ...rest,
      },
      {
        onSuccess: () => close(),
      },
    );
  };

  const handleLoad = (e: MapEvent) => {
    zoomOnFeature({
      map: e.target,
      geojson: data.nitrogenmap,
    });
  };

  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar size="lg">
          <Stack className={classes.navCol}>
            <Paper className={classes.navHeader}>
              <FieldHeader fieldId={data.field.id.toString()} />
            </Paper>
            <Paper className={classes.navBar} p={0}>
              <Stack gap={0} h="100%" justify="space-between">
                <ScrollArea.Autosize>
                  <RapeseedFormInfo form={data} />
                </ScrollArea.Autosize>
                <Group p="md" wrap="nowrap">
                  <Button onClick={open}>{t('advices.edit_data')}</Button>
                  <TsrButtonLink
                    color="red"
                    search={{ type: CropEnum.RAPESEED }}
                    to="/precifert-n"
                  >
                    {t('common.back')}
                  </TsrButtonLink>
                </Group>
              </Stack>
            </Paper>
          </Stack>
        </FullMainHeight.Sidebar>
        <Stack className={classes.navCol}>
          <Paper>
            <Group justify="end">
              <Group>
                <ActionsMenu items={menuActions}>
                  <ActionsMenu.Button />
                </ActionsMenu>
              </Group>
            </Group>
          </Paper>
          <Paper h="100%">
            <Group grow h="100%">
              <Stack h="100%">
                <Title order={3}>{t('common.properties.biomass_start')}</Title>
                <Paper flex="1" p={0} shadow="unset">
                  {data.biomass_start?.id ? (
                    <Suspense fallback={<BiomassMap.Pending />}>
                      <BiomassMap mapId={data.biomass_start?.id} />
                    </Suspense>
                  ) : (
                    <BiomassMap.Error />
                  )}
                </Paper>
                <Title order={3}>{t('common.properties.biomass_end')}</Title>
                <Paper flex="1" p={0} shadow="unset">
                  {data.biomass_end?.id ? (
                    <Suspense fallback={<BiomassMap.Pending />}>
                      <BiomassMap mapId={data.biomass_end?.id} />
                    </Suspense>
                  ) : (
                    <BiomassMap.Error />
                  )}
                </Paper>
              </Stack>
              <Stack h="100%">
                <Title order={3}>{t('common.prescription_map')}</Title>
                <Paper flex="1" p={0} shadow="unset">
                  {data.nitrogenmap ? (
                    <PfMap onLoad={handleLoad}>
                      <ZoningLayer data={data.nitrogenmap} />
                      <LegendControl>
                        <ZoningLegend
                          {...formatNitrogenLegend({
                            map: data.nitrogenmap,
                            product: data.product,
                            switchable: true,
                          })}
                          {...data.legend}
                        />
                      </LegendControl>
                    </PfMap>
                  ) : (
                    <Center bg="gray.1" h="100%" p="xl">
                      <Text fw="bold" size="xl" ta="center">
                        {t('advices.nitrogen_map_unavailable')}
                      </Text>
                    </Center>
                  )}
                </Paper>
              </Stack>
            </Group>
          </Paper>
        </Stack>
        <RapeseedAdviceForm
          initialIds={[data.id]}
          mode="edit"
          onClose={close}
          onSubmit={handleSubmit}
          opened={opened}
        />
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
}

export default RapeseedResultPage;
