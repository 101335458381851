import {
  Stack,
  Paper,
  Group,
  Button,
  SegmentedControl,
  Box,
  Center,
  ThemeIcon,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconCloudFilled,
  IconFileDownload,
  IconTrash,
} from '@tabler/icons-react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useParams, useSearch } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommonAdviceForm } from '../../components/common-advice-form/common-advice-form';
import { CommonResultMaps } from '../../components/common-result-maps/common-result-maps';
import { CommonAdviceFormTransformedValues } from '../../hooks/use-common-advice-form/common-advice-form';

import { ErrorPage } from './page.error';
import classes from './page.module.css';
import { PendingPage } from './page.pending';

import {
  advicesQueries,
  CRYield,
  FORM_STATUS,
  useUpdateCommonAdvice,
} from '@advices/data-access';
import { useAuth } from '@auth/data-access';
import { ROLES, useQueriesWithGlobal } from '@data-access';
import {
  ActionsMenu,
  ActionsMenuItem,
  FullMainHeight,
  TsrButtonLink,
  CommonFormInfo,
  FieldHeader,
} from '@ui';

export function CommonAdviceResultPage() {
  const { t } = useTranslation();
  const { edit } = useSearch({
    from: '/_app/precifert-n_/$type/results/$resultId',
  });
  const { user } = useAuth();
  const { type, resultId } = useParams({
    from: '/_app/precifert-n_/$type/results/$resultId',
  });

  const [selectedYield, setSelectedYield] = useState<CRYield>();
  const [opened, { open, close }] = useDisclosure(edit ?? false);

  const queries = useQueriesWithGlobal(advicesQueries);
  const { data } = useSuspenseQuery(
    queries.result({
      id: Number(resultId),
      crop: type,
    }),
  );
  const updateAdvice = useUpdateCommonAdvice();
  const recap = useQuery({
    ...queries.recap({
      formId: data.id,
      yieldId: selectedYield?.id,
      crop: type,
    }),
    enabled: !!selectedYield,
  });

  const menuActions: ActionsMenuItem[] = [
    {
      type: 'native-link',
      label: 'Télécharger le récapitulatif',
      icon: IconFileDownload,
      props: {
        disabled: !recap.data?.file || recap.isLoading,
        target: '_blank',
        href: recap.data?.file,
      },
    },
    {
      type: 'action',
      label: 'Supprimer',
      color: 'red',
      icon: IconTrash,
      props: {
        disabled: true,
      },
      onClick: () => null,
    },
  ];

  const handleSelectYield = (id: string) => {
    const selected = data.yields.find((y) => y.id === Number(id));
    setSelectedYield(selected);
  };

  const handleSubmit = (values: CommonAdviceFormTransformedValues) => {
    const { field, ...rest } = values;

    updateAdvice.mutate(
      {
        id: data.id,
        crop: type,
        ...rest,
      },
      {
        onSuccess: close,
      },
    );
  };

  useEffect(() => {
    setSelectedYield(data.yields[0]);
  }, [data]);

  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar size="lg">
          <Stack className={classes.navCol}>
            <Paper className={classes.navHeader}>
              <FieldHeader fieldId={data.field.id.toString()} />
            </Paper>
            <Paper className={classes.navBar} p={0}>
              <Stack gap={0} h="100%" justify="space-between">
                <Box>
                  <CommonFormInfo form={data} />
                </Box>
                <Group p="md" wrap="nowrap">
                  <Button onClick={open}>{t('advices.edit_data')}</Button>
                  <TsrButtonLink
                    color="red"
                    search={{ type }}
                    to="/precifert-n"
                  >
                    {t('common.back')}
                  </TsrButtonLink>
                </Group>
              </Stack>
            </Paper>
          </Stack>
        </FullMainHeight.Sidebar>

        <Stack className={classes.navCol}>
          <Paper>
            <Group justify="space-between">
              <SegmentedControl
                color="pf-green"
                data={data.yields.map((map) => ({
                  value: map.id.toString(),
                  label: (
                    <Center>
                      {map.cloud ? (
                        <ThemeIcon
                          color="orange"
                          mr="xs"
                          size="sm"
                          variant="transparent"
                        >
                          <IconCloudFilled />{' '}
                        </ThemeIcon>
                      ) : null}
                      <span>{dayjs(map.date).format('Do MMMM')}</span>
                    </Center>
                  ),
                  disabled:
                    map.status === FORM_STATUS.INCOMPLETE ||
                    map.status === FORM_STATUS.WAITING,
                }))}
                defaultValue={selectedYield?.id.toString()}
                onChange={handleSelectYield}
                radius="xs"
              />
              <Group>
                {user?.user_access === ROLES.ADMIN ? (
                  <TsrButtonLink
                    color="dark"
                    disabled={!selectedYield}
                    params={{
                      type,
                      resultId,
                      yieldId: selectedYield?.id.toString() || '',
                    }}
                    to="/precifert-n/$type/results/$resultId/settings/$yieldId"
                  >
                    {t('advices.settings')}
                  </TsrButtonLink>
                ) : null}
                <ActionsMenu items={menuActions}>
                  <ActionsMenu.Button />
                </ActionsMenu>
              </Group>
            </Group>
          </Paper>
          <Paper h="100%" p={0}>
            <CommonResultMaps
              nitrogenlegend={selectedYield?.nitrogenlegend}
              nitrogenmap={selectedYield?.nitrogenmap || null}
              product={data.product}
              status={selectedYield?.status || FORM_STATUS.PENDING}
              yieldlegend={selectedYield?.yieldlegend}
              yieldmap={selectedYield?.yieldmap || null}
            />
          </Paper>
        </Stack>
        <CommonAdviceForm
          initialIds={[data.id]}
          mode="edit"
          onClose={close}
          onSubmit={handleSubmit}
          opened={opened}
          selectedType={type}
        />
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
}

CommonAdviceResultPage.Pending = PendingPage;
CommonAdviceResultPage.Error = ErrorPage;

export default CommonAdviceResultPage;
