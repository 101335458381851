import { Accordion, Group, Title, Badge, Tooltip, Text } from '@mantine/core';
import { IconMap, IconTools, IconCircleLetterN } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import classes from './field-navbar.module.css';

import { useAuth } from '@auth/data-access';
import { FieldAdvices, MAP_TYPES } from '@fields/data-access';
import { TsrNavLink } from '@ui';

interface FieldNavbarProps {
  fieldId: string;
  links: { type: MAP_TYPES; numbers: number | null }[];
  disabled?: boolean;
  advices?: FieldAdvices;
}

export function FieldNavbar({
  fieldId,
  links,
  disabled = false,
  advices,
}: FieldNavbarProps) {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <Accordion
      classNames={{
        item: classes.navbarItem,
      }}
      defaultValue={disabled ? [] : ['maps', 'advices']}
      multiple
    >
      <Accordion.Item value={'maps'}>
        <Accordion.Control disabled={disabled}>
          <Group>
            <IconMap />
            <Title order={3}>{t('field.nav.maps')}</Title>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          {links.map((link) => (
            <TsrNavLink
              classNames={{
                label: classes.navLinkLabel,
              }}
              disabled={link.numbers === -1}
              key={link.type}
              label={
                <Group justify="space-between" wrap="nowrap">
                  {t(`field.maps.${link.type}`)}
                  {link.numbers !== null ? (
                    <Badge
                      className={classes.badge}
                      radius="sm"
                      variant="outline"
                    >
                      {link.numbers === -1 ? '0' : link.numbers}
                    </Badge>
                  ) : null}
                </Group>
              }
              params={{ fieldId, mapType: link.type }}
              to={`/fields/$fieldId/maps/$mapType`}
            />
          ))}
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value={'tools'}>
        <Accordion.Control disabled={disabled}>
          <Group>
            <IconTools />
            <Title order={3}>{t('field.nav.tools')}</Title>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <TsrNavLink
            label={t('field.nav.cut')}
            params={{ fieldId }}
            to={`/fields/$fieldId/cut`}
          />
          <TsrNavLink
            label={t('field.nav.compare')}
            params={{ fieldId, mapType: MAP_TYPES.COMPARE }}
            to={`/fields/$fieldId/maps/$mapType`}
          />
          <TsrNavLink
            label={t('field.nav.draw_prescriptions')}
            params={{ fieldId }}
            to={`/fields/$fieldId/maps/prescriptions/create`}
          />
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value={'advices'}>
        <Accordion.Control disabled={disabled}>
          <Group>
            <IconCircleLetterN />
            <Title order={3}>{t('field.nav.advices')}</Title>
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <Tooltip
            disabled={user?.features.pk}
            label={t('field.nav.precifert-n_enable_feature')}
            maw={250}
            multiline
          >
            <span>
              <TsrNavLink
                disabled={!user?.features.pk}
                label={t('field.nav.pk')}
                params={{ fieldId }}
                to={`/fields/$fieldId/pk`}
              />
            </span>
          </Tooltip>
          <TsrNavLink
            disabled={!advices?.wheat}
            label={t('field.nav.precifert_n_wheat')}
            params={{
              type: 'wheat',
              resultId: advices?.wheat?.toString() || '',
            }}
            search={{
              edit: true,
            }}
            to="/precifert-n/$type/results/$resultId"
          />
          <TsrNavLink
            disabled={!advices?.barley}
            label={t('field.nav.precifprecifert_n_barley')}
            params={{
              type: 'barley',
              resultId: advices?.barley?.toString() || '',
            }}
            search={{
              edit: true,
            }}
            to="/precifert-n/$type/results/$resultId"
          />
          <TsrNavLink
            disabled={!advices?.rapeseed}
            label={t('field.nav.precifert_n_rapeseed')}
            params={{
              type: 'rapeseed',
              resultId: advices?.rapeseed?.toString() || '',
            }}
            search={{
              edit: true,
            }}
            to="/precifert-n/$type/results/$resultId"
          />
          <TsrNavLink
            disabled={!advices?.wheat2}
            label={t('field.nav.precifert_n_wheat2')}
            search={{
              type: 'wheat2',
            }}
            to="/precifert-n"
          />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

export default FieldNavbar;
