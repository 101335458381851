import { Paper, ScrollArea, Skeleton, Stack } from '@mantine/core';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Outlet, useParams, useSearch } from '@tanstack/react-router';
import { Suspense } from 'react';

import { FieldNavbar } from '../../components/field-navbar/field-navbar';
import { MapInfo } from '../../components/map-info/map-info';

import { ErrorPage } from './page.error';
import classes from './page.module.css';
import { NotFoundPage } from './page.not-found';
import { PendingPage } from './page.pending';

import { useQueriesWithGlobal } from '@data-access';
import { fieldsQueries } from '@fields/data-access';
import { FieldHeader, FullMainHeight } from '@ui';

export function FieldDetailsPage() {
  const { fieldId } = useParams({ from: '/_app/fields_/$fieldId' });
  const { mapType } = useParams({ strict: false });
  const { mapId } = useSearch({ strict: false });

  const queries = useQueriesWithGlobal(fieldsQueries);
  const {
    data: { properties },
  } = useSuspenseQuery(queries.detail(Number(fieldId)));

  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar>
          <Stack className={classes.navCol}>
            <Stack className={classes.navColInner}>
              <Paper className={classes.navHeader}>
                <FieldHeader fieldId={fieldId} />
              </Paper>
              <ScrollArea.Autosize>
                <Paper className={classes.navBar} p={0}>
                  <FieldNavbar
                    advices={properties.advices}
                    fieldId={fieldId}
                    links={properties.maps}
                  />
                  {mapType && mapId ? (
                    <MapInfo mapId={mapId} type={mapType} />
                  ) : null}
                </Paper>
              </ScrollArea.Autosize>
            </Stack>
          </Stack>
        </FullMainHeight.Sidebar>
        <Outlet />
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
}

FieldDetailsPage.Error = ErrorPage;
FieldDetailsPage.Pending = PendingPage;
FieldDetailsPage.NotFound = NotFoundPage;

export default FieldDetailsPage;
