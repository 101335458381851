import { Text, ThemeIcon, deepMerge } from '@mantine/core';
import { IconCloudFilled } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { LinkProps } from '@tanstack/react-router';
import dayjs from 'dayjs';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableOptions,
} from 'mantine-react-table';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import usePrescriptions from '../../../hooks/prescriptions/use-prescriptions/prescriptions';

import {
  fromBacki18n,
  ProductRel,
  SpeciesRel,
  useFiltersTableOptions,
  useQueriesWithGlobal,
} from '@data-access';
import {
  ListPrescription,
  MAP_TYPES,
  prescriptionsQueries,
} from '@fields/data-access';
import { isMultieditRow, PfTable, TsrButtonLink, useRowSelection } from '@ui';

/* eslint-disable-next-line */
export interface PrescriptionsTableProps {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: any;
}

export function PrescriptionsTable({
  rowSelection,
  setRowSelection,
}: PrescriptionsTableProps) {
  const { t, i18n } = useTranslation();
  const filtersTableOptions =
    useFiltersTableOptions<ListPrescription>('prescriptions');

  const queries = useQueriesWithGlobal(prescriptionsQueries);
  const prescriptions = usePrescriptions();

  const values = useQuery(queries.listValues());

  const data = useMemo(() => {
    if (!prescriptions.data) {
      return [];
    }

    return prescriptions.data.results;
  }, [prescriptions.data]);

  const rowSelectionProps = useRowSelection<ListPrescription>({
    data,
    rowSelection,
    setRowSelection,
  });

  const tableColumns = useMemo<MRT_ColumnDef<ListPrescription>[]>(
    () => [
      {
        accessorKey: 'field.thumbnail',
        enableColumnFilter: false,
        header: '',
        enableSorting: false,
        enableColumnActions: false,
        size: 32,
        Cell: ({ cell, row }) => {
          const thumb = cell.getValue<string>();
          if (thumb) {
            return <img alt="thumbnail" src={thumb} width="32" />;
          }
        },
      },
      {
        accessorKey: 'name',
        header: t('prescriptions.name'),
        Cell: ({ cell, row }) => {
          if (isMultieditRow(row.id)) {
            return cell.getValue<string>();
          }

          return (
            <TsrButtonLink
              fullWidth
              justify="start"
              leftSection={
                row.original.cloud ? (
                  <ThemeIcon color="orange" size="sm" variant="transparent">
                    <IconCloudFilled />
                  </ThemeIcon>
                ) : null
              }
              maw={250}
              ml="calc(var(--button-padding-x-compact-md) * -1)"
              params={{
                fieldId: row.original.field.id.toString(),
                mapType: MAP_TYPES.PRESCRIPTIONS,
              }}
              search={{ mapId: row.original.id }}
              size="compact-md"
              td="underline"
              to="/fields/$fieldId/maps/$mapType"
              tt="capitalize"
              variant="subtle"
            >
              <Text span truncate="end">
                {cell.getValue<string>()}
              </Text>
            </TsrButtonLink>
          );
        },
      },
      {
        accessorKey: 'product',
        Cell: ({ cell }) =>
          fromBacki18n(cell.getValue<ProductRel>(), i18n.language),
        header: t('prescriptions.product'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: values.data?.product.map((p) => ({
            value: p.id.toString(),
            label: fromBacki18n(p, i18n.language),
          })),
        },
      },
      {
        accessorKey: 'map_type',
        header: t('prescriptions.type'),
        filterVariant: 'select',
        Cell: ({ cell }) => t(`common.${cell.getValue()}`),
        mantineFilterSelectProps: {
          data: values.data?.map_type,
        },
      },
      {
        accessorKey: 'field.name',
        header: t('plots.fieldName'),
        Cell: ({ cell, row }) => {
          if (isMultieditRow(row.id)) {
            return cell.getValue<string>();
          }

          return (
            <TsrButtonLink
              fullWidth
              justify="start"
              maw={250}
              ml="calc(var(--button-padding-x-compact-md) * -1)"
              params={{ fieldId: row.original.field.id.toString() }}
              size="compact-md"
              td="underline"
              to="/fields/$fieldId"
              tt="capitalize"
              variant="subtle"
            >
              <Text span truncate="end">
                {cell.getValue<string>()}
              </Text>
            </TsrButtonLink>
          );
        },
      },
      {
        accessorKey: 'field.farm.name',
        header: t('plots.farmName'),
        minSize: 250,
      },
      {
        accessorKey: 'field.area',
        header: t('common.area'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
        Cell: ({ cell }) =>
          cell.getValue<number>()?.toFixed(2).replace('.', ',').concat(' ha'),
      },
      {
        accessorKey: 'field.species',
        Cell: ({ cell }) =>
          fromBacki18n(cell.getValue<SpeciesRel>(), i18n.language),
        header: t('common.crop'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: values.data?.species.map((s) => ({
            value: s.id.toString(),
            label: fromBacki18n(s, i18n.language),
          })),
        },
      },
      {
        accessorKey: 'created_at',
        Cell: ({ cell }) =>
          dayjs(cell.getValue<string>()).format('DD/MM/YYYY - HH:mm'),
        header: 'Date de création',
        filterVariant: 'date-range',
      },
    ],
    [t, values.data, i18n.language],
  );

  const tableOptions: MRT_TableOptions<ListPrescription> = deepMerge<
    MRT_TableOptions<ListPrescription>
  >(
    {
      data: data,
      columns: tableColumns,
      rowCount: prescriptions.data?.count || 0,

      initialState: {
        columnVisibility: {
          'mrt-row-pin': false,
        },
        showColumnFilters: true,
      },
      state: {
        isLoading: prescriptions.isLoading,
        rowSelection,
        showProgressBars:
          // updateManyFields.isPending ||
          prescriptions.isRefetching,
        // deleteField.isPending,
      },
      selectAllMode: 'page',
      onRowSelectionChange: setRowSelection,

      mantinePaperProps: {
        h: '100%',
      },
      ...rowSelectionProps,
    },
    filtersTableOptions,
  );

  return <PfTable options={tableOptions} />;
}

export default PrescriptionsTable;
