import {
  Button,
  Group,
  Box,
  Stepper,
  ScrollArea,
  Text,
  Alert,
  Transition,
} from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useMutationState, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RapeseedAdviceFormProvider,
  RapeseedAdviceFormValues,
  useRapeseedAdviceForm,
} from '../../hooks/use-rapeseed-advice-form/rapeseed-advice-form';
import {
  AdviceFormStep,
  useSharedAdviceForm,
} from '../../hooks/use-shared-advice-form/shared-advice-form';
import { FieldsTable } from '../fields-table/fields-table';

import { AgronomicDataFieldset } from './components/agronomic-data-fieldset';
import { BiomassFieldset } from './components/biomass-fieldset';
import { BiomassMapsFieldset } from './components/biomass-maps-fieldset';
import { FallFertilizerFieldset } from './components/fall-fertilizer-fieldset';
import { FertilizerFieldset } from './components/fertilizer-fieldset';
import { HistoricFertilizerFieldset } from './components/historic-fertilizer-fieldset';
import { SettingsFieldset, SettingsFieldsetDistri } from './components/settings-fieldset';
import classes from './rapeseed-advice-form.module.css';

import { advicesQueries, CreateRapeseedAdviceDto } from '@advices/data-access';
import { useAuth } from '@auth/data-access';
import { ROLES, useQueriesWithGlobal } from '@data-access';
import { Drawer } from '@ui';

interface RapeseedAdviceFormProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: CreateRapeseedAdviceDto) => void;
  initialIds?: number[];
  mode: 'create' | 'edit';
}

export function RapeseedAdviceForm({
  opened,
  onClose,
  onSubmit,
  initialIds,
  mode,
}: RapeseedAdviceFormProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const form = useRapeseedAdviceForm();
  const {
    handleSetRowSelection,
    handleSubmit,
    prevStep,
    stepState,
    fieldsSelectionState,
    resetForm,
  } = useSharedAdviceForm({ form, onClose, onSubmit, mode: mode });
  const [active] = stepState;
  const [fieldsSelection] = fieldsSelectionState;
  const queries = useQueriesWithGlobal(advicesQueries);

  const mutation = useMutationState({
    filters: {
      mutationKey: ['advices', 'rapeseed'],
    },
  });

  const latestMutation = mutation[mutation.length - 1];

  const advice = useQuery({
    ...queries.rapeseedDetail({ id: initialIds![0] }),
    enabled: mode === 'edit' && initialIds && initialIds.length === 1,
  });

  const nextStep = () => {
    if ((active === 1 && form.getValues().field.length === 1) || active === 2) {
      return t('common.save');
    }

    return t('common.next');
  };

  const title = () => {
    if (active === 0) {
      return t('advices.select-fields');
    } else if (active === 1) {
      return t('advices.edit_data');
    } else {
      return t('advices.confirm_data');
    }
  };

  useEffect(() => {
    // If the form is in edit mode and the advice data is available, set the initial values
    if (mode === 'edit' && advice.data && initialIds?.length === 1) {
      const initialValues: RapeseedAdviceFormValues = {
        field: [advice.data.field.id],
        variety: advice.data.variety?.id?.toString(),
        seed_date: advice.data.seed_date
          ? dayjs(advice.data.seed_date).toDate()
          : undefined,
        sol: advice.data.sol,
        rdt: advice.data.rdt,
        cz_pois_prt: advice.data.cz_pois_prt,
        cz_leg: advice.data.cz_leg,

        pdt_org_hist: advice.data.pdt_org_hist?.id.toString(),
        frq_org: advice.data.frq_org,

        pdt_org1: advice.data.pdt_org1?.id.toString(),
        qte_pdt_ependu1: advice.data.qte_pdt_ependu1,
        n_teneur1: advice.data.n_teneur1,
        pdt_org2: advice.data.pdt_org2?.id.toString(),
        qte_pdt_ependu2: advice.data.qte_pdt_ependu2,
        n_teneur2: advice.data.n_teneur2,

        nitrogen_already_applied: advice.data.nitrogen_already_applied,
        product: advice.data.product?.id.toString(),

        biomass_end:
          advice.data.biomass_end.id?.toString() ??
          advice.data.biomass_end.date,
        biomass_start:
          advice.data.biomass_start.id?.toString() ??
          advice.data.biomass_start.date,
        is_hidden: advice.data.is_hidden,
        disable_mail:advice.data.disable_mail,
        restart: advice.data.restart,
      };

      resetForm({
        initialValues,
        step: AdviceFormStep.FILL_FORM,
      });
    } else if (mode === 'edit' && initialIds && initialIds.length > 1) {
      // If the form is in edit mode and multiple advices are selected,
      // set the initial values of `field` only to skip the first step (if we don't set it form validation will fail)
      resetForm({
        initialValues: {
          field: initialIds,
        },
        step: AdviceFormStep.FILL_FORM,
      });
    }
  }, [advice.data, initialIds, mode, resetForm]);

  useEffect(() => {
    // If the latest mutation is successful, reset the form
    if (latestMutation?.status === 'success') {
      resetForm({
        initialValues: {
          field: [],
        },
      });
    }
  }, [latestMutation?.status, resetForm]);

  return (
    <Drawer
      classNames={{
        body: clsx({ [classes.drawerBodyHeight]: active === 0 }),
      }}
      closeOnClickOutside={false}
      onClose={prevStep}
      opened={opened}
      position="right"
      scrollAreaComponent={
        active === AdviceFormStep.FILL_FORM ? ScrollArea.Autosize : undefined
      }
      size="fit-content"
      title={title()}
    >
      <RapeseedAdviceFormProvider form={form}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Stepper
            active={active}
            classNames={{
              root: classes.stepper,
              steps: classes.steps,
              content: classes.stepContent,
            }}
          >
            <Stepper.Step>
              <FieldsTable
                rowSelection={fieldsSelection}
                setRowSelection={handleSetRowSelection}
              />
            </Stepper.Step>
            <Stepper.Step>
              <Box h="100%">
                <Text fw="bold" size="lg">
                  Vous avez sélectionné plusieurs parcelles
                </Text>
                <Text>
                  Une fois l'enregistrement effectué, les données saisies seront appliquées à l'ensemble des parcelles sélectionnées.
                </Text>
              </Box>
            </Stepper.Step>
            <Stepper.Step>
              <Group align="start">
                <Box flex="1 1 auto">
                  <AgronomicDataFieldset />

                  {form.getValues().field.length > 1 ? (
                    <BiomassFieldset />
                  ) : null}

                  <HistoricFertilizerFieldset />

                  <FallFertilizerFieldset />

                  <FertilizerFieldset />

                  {user?.user_access === ROLES.ADMIN ? (
                    <SettingsFieldset />
                  ) : null}
                  {user?.user_access === ROLES.DISTRI ? (
                    <SettingsFieldsetDistri />
                  ) : null}

                </Box>

                {form.getValues().field.length === 1 ? (
                  <Box flex="1 1 auto">
                    <BiomassMapsFieldset />
                  </Box>
                ) : null}
              </Group>
            </Stepper.Step>
          </Stepper>

          <Group className={classes.buttonsGroup} p="md">
            <Button
              loading={latestMutation?.status === 'pending'}
              type="submit"
            >
              {nextStep()}
            </Button>
            <Button color="red" onClick={prevStep} type="button">
              {active === 0 ? t('common.close') : t('common.previous')}
            </Button>
          </Group>
        </form>
      </RapeseedAdviceFormProvider>
    </Drawer>
  );
}

export default RapeseedAdviceForm;
