import {
  Paper,
  Group,
  Title,
  Select,
  Button,
  SelectProps,
} from '@mantine/core';
import { IconFileDownload, IconPlus, IconTrash } from '@tabler/icons-react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { cropOptions } from './crop-options';

import { advicesQueries } from '@advices/data-access';
import { useQueriesWithGlobal } from '@data-access';
import { ActionsMenu, ActionsMenuItem } from '@ui';

export { cropOptions };

interface AdvicesListHeaderProps {
  selectedType: string;
  needValidation: boolean;
  onCropChange: SelectProps['onChange'];
  selecting: boolean;
  onValidateFields: () => void;
  onNewAdvice: () => void;
  onEditAdvice: () => void;
  onDelete: () => void;
  onDownload: () => void;
}

export function AdvicesListHeader({
  selectedType,
  needValidation,
  onCropChange,
  onValidateFields,
  onNewAdvice,
  onEditAdvice,
  onDelete,
  selecting,
  onDownload,
}: AdvicesListHeaderProps) {
  const { t } = useTranslation();

  const queries = useQueriesWithGlobal(advicesQueries);
  const { data } = useSuspenseQuery(queries.types());
  const tuto = useQuery(queries.tuto(selectedType));

  const options = data.map((type) => ({
    value: type.name,
    label: t(`advices.types.${type.name}`),
  }));

  const items: ActionsMenuItem[] = [
    {
      type: 'action',
      label: t('advices.list.new'),
      icon: IconPlus,
      onClick: () => onNewAdvice(),
    },
    {
      type: 'action',
      label: 'Télécharger le récapitulatif',
      icon: IconFileDownload,
      onClick: () => onDownload(),
      props: {
        disabled: !selecting,
      },
    },
    {
      type: 'action',
      label: 'Supprimer',
      color: 'red',
      icon: IconTrash,
      onClick: () => {
        onDelete();
      },
      props: {
        disabled: !selecting,
      },
    },
  ];

  return (
    <Paper>
      <Group justify="space-between">
        <Group>
          <Title order={2}>
            {t('advices.list.title')}
            {needValidation ? ` - (${t('advices.list.validation-title')})` : ''}
          </Title>
          {!needValidation ? (
            <>
              <Select
                allowDeselect={false}
                data={options}
                onChange={onCropChange}
                value={selectedType}
              />
              <Button
                color="yellow"
                disabled={!selecting}
                onClick={() => onEditAdvice()}
              >
                Saisir mes données
              </Button>
            </>
          ) : null}
        </Group>
        <Group>
          {needValidation ? (
            <Button onClick={onValidateFields}>
              {t('advices.list.save-data')}
            </Button>
          ) : (
            <Button component="a" download href={tuto.data?.file}>
              {t('advices.list.tuto')}
            </Button>
          )}
          <ActionsMenu items={items}>
            <ActionsMenu.Button />
          </ActionsMenu>
        </Group>
      </Group>
    </Paper>
  );
}

export default AdvicesListHeader;
