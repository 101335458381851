import { Code } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { advicesApi } from '../../apis/advices.api';

export function useUpdateCommonAdvice() {
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['advices', 'common', 'update'],
    mutationFn: advicesApi.updateCommon,
    onError: (error) => {
      notifications.show({
        title: t('common.error.title'),
        message: <Code>{error.message}</Code>,
        color: 'red',
        withCloseButton: true,
        autoClose: 10000,
      });
    },
  });
}

export default useUpdateCommonAdvice;
