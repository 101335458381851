import { useMutation } from '@tanstack/react-query';

import { fieldsApi } from '../../apis/fields';

export function useUploadFile() {
  return useMutation({
    mutationFn: fieldsApi.uploadFile,
  });
}

export default useUploadFile;
