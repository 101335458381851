import { createFormContext, isNotEmpty } from '@mantine/form';
import { featureCollection, multiPolygon } from '@turf/turf';
import { Feature, Polygon } from 'geojson';
import { useTranslation } from 'react-i18next';

import { CreateFieldDto } from '@fields/data-access';

interface FieldFormValues {
  name: string;
  farm: string;
  species?: string;
  variety?: string;
  boundaries?: Feature<Polygon>[];
  is_multiple?: boolean;
  fileColSource?: string;

  soil_type?: string;
  potential_wheat?: number;
  potential_barley?: number;
  potential_rapeseed?: number;
}

export type TransformValues = (values: FieldFormValues) => CreateFieldDto;

const [FieldFormProvider, useFieldFormContext, useForm] = createFormContext<
  FieldFormValues,
  TransformValues
>();

export function useFieldForm() {
  const { t } = useTranslation();
  const form = useForm({
    initialValues: {
      name: '',
      farm: '',

      boundaries: [],
    },
    validate: {
      name: (value, values) => {
        if (!values.is_multiple) {
          isNotEmpty(t('forms.required'))(value);
        }

        return undefined;
      },
      farm: isNotEmpty(t('forms.required')),

      boundaries: (value) => {
        if (value?.length === 0) {
          return t('forms.required');
        }

        return undefined;
      },
      // fileColSource in required only if is_multiple is true
      fileColSource: (value, values) => {
        if (values.is_multiple) {
          return isNotEmpty(t('forms.required'))(value);
        }
        return undefined;
      },
    },
    transformValues(values) {
      const {
        name,
        farm,
        species,
        soil_type,
        potential_rapeseed,
        potential_barley,
        potential_wheat,
        is_multiple,
        fileColSource,
        boundaries,
        variety,
      } = values;

      const transformedValues: CreateFieldDto = {
        name,
        farm: Number(farm),

        boundaries: featureCollection([]),
      };
      if (potential_wheat) {
        transformedValues.potential_wheat = potential_wheat;
      }

      if (potential_barley) {
        transformedValues.potential_barley = potential_barley;
      }

      if (potential_rapeseed) {
        transformedValues.potential_rapeseed = potential_rapeseed;
      }
      
      if (species) {
        transformedValues.species = Number(species);
      } else {
        transformedValues.species = undefined;
      }

      if (variety) {
        transformedValues.variety = Number(variety);
      } else {
        transformedValues.variety = undefined;
      }

      if (soil_type) {
        transformedValues.soil_type = Number(soil_type);
      }

      if (boundaries) {
        // If is_multiple, we need to add the name of the field to the properties of the polygon
        if (is_multiple && fileColSource) {
          transformedValues.is_multiple = true;

          const groupedFeatures: { [id: string]: Feature<Polygon>[] } = {};
          const unknownFeatures: Feature<Polygon>[] = [];

          /**
           * Group features by the unique-precifield-upload-id
           * file://./../../../../../data-access/src/lib/apis/fields.ts (uploadFile)
           * this step is needed because multipolygon received from upload are flattened by the draw tool
           * we need to group them by their id to re-create the multipolygon
           */
          boundaries.forEach((f) => {
            const id = f.properties?.['unique-precifield-upload-id'];
            if (id) {
              if (!groupedFeatures[id]) {
                groupedFeatures[id] = [];
              }
              groupedFeatures[id].push(f);
            } else {
              unknownFeatures.push(f);
            }
          });

          // Create a MultiPolygon from each group
          const multiPolygons = Object.values(groupedFeatures).map((group) => {
            const polygons = group.map((f) => f.geometry.coordinates);
            return multiPolygon(polygons, {
              name: group[0].properties![fileColSource] as string,
            });
          });

          // Create a MultiPolygon for each unknown features
          unknownFeatures.forEach((f) => {
            multiPolygons.push(
              multiPolygon([f.geometry.coordinates], {
                name: f.properties![fileColSource] as string,
              }),
            );
          });

          transformedValues.boundaries = featureCollection(multiPolygons);
        } else {
          // If not is_multiple, we need to create a MultiPolygon from each feature
          const mP = multiPolygon(
            boundaries.map((f) => f.geometry.coordinates),
            {},
          );

          transformedValues.boundaries = featureCollection([mP]);
        }
      }

      return transformedValues;
    },
  });

  return form;
}

export { FieldFormProvider, useFieldFormContext };
