import { Center, Group, Loader, Stack, Text } from '@mantine/core';
import {
  keepPreviousData,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ExtendedCombobox from '../../../../components/extended-combobox/extended-combobox';
import { FieldMapsLayout } from '../../../../components/field-maps-layout/field-maps-layout';
import SelectMapType from '../../../../components/select-map-type/select-map-type';
import useSharedFieldMaps from '../../../../hooks/use-shared-field-maps/shared-field-maps';
import { MAP_DISPLAY_TYPES } from '../compare-maps/page';

import { FORM_STATUS } from '@advices/data-access';
import { PRESCRIPTION_FROM_TYPES } from '@data-access';
import {
  LegendControl,
  PfMap,
  ZoningLayer,
  ZoningLegend,
  ZoningLegendProps,
} from '@map';
import { TsrButtonLink } from '@ui';

interface YieldPotentialMapProps {
  type: MAP_DISPLAY_TYPES;
}

export function YieldPotentialMap({ type }: YieldPotentialMapProps) {
  const { t } = useTranslation();
  const {
    field,
    fieldId,
    mapId,
    queries,
    handleMapLoad,
    compare,
    handleCompareChange,
    navigateMapIdSp,
  } = useSharedFieldMaps({ type });

  const summary = useSuspenseQuery(queries.potentialsSummary(Number(fieldId)));
  const [selectedMap, setSelectedMap] = useState(
    mapId?.toString() ?? summary.data[0]?.id.toString() ?? null,
  );
  const map = useQuery({
    ...queries.potentialsMap(Number(selectedMap)),
    enabled: selectedMap !== null,
    placeholderData: keepPreviousData,
    refetchInterval(query) {
      return query.state.data?.status !== FORM_STATUS.FINISHED ? 5000 : false;
    },
  });

  const options = summary.data.map((item) => ({
    value: item.id.toString(),
    label: item.name,
    description: [
      `${t('common.created_date_prefix')} ${dayjs(item.created_at).format(
        `DD/MM/YYYY [${t('common.time_prefix')}] H[h]mm`,
      )}`,
    ],
  }));

  const legend = (): ZoningLegendProps => {
    const m = map.data?.map;
    if (m) {
      const l = m.features.reduce((acc, feature) => {
        return {
          ...acc,
          [feature.properties.zone]: {
            zone: feature.properties.zone,
            color: feature.properties.color,
            value: feature.properties.value.toFixed(2),
            value_N: feature.properties.value_N?.toFixed(2),
            percent: feature.properties.percent,
            area: feature.properties.area,
          },
        };
      }, {});

      return {
        title: 'Rendement (q/ha)',
        legend: Object.values(l),
        unit: 'q/ha',
      };
    }
    return {
      legend: [],
    };
  };

  useEffect(() => {
    if (mapId === undefined && selectedMap !== null && type === 'single') {
      navigateMapIdSp(selectedMap);
    }
  }, [mapId, navigateMapIdSp, selectedMap, type]);

  return (
    <FieldMapsLayout
      content={
        <>
          {map.data?.status === FORM_STATUS.FINISHED ? (
            <PfMap onLoad={handleMapLoad}>
              <ZoningLayer data={map.data.map} filled outline={false} />
              <LegendControl>
                <ZoningLegend {...legend()} mean={map.data.mean} />
              </LegendControl>
            </PfMap>
          ) : null}

          {map.data?.status === FORM_STATUS.PENDING ? (
            <Center h="100%" p="xl">
              <Stack align="center">
                <Text fw="bold" size="xl" ta="center">
                  Nous sommes en train de préparer votre carte
                </Text>
                <Loader />
              </Stack>
            </Center>
          ) : null}

          {summary.data?.length === 0 ? (
            <Center h="100%" p="xl">
              <Stack>
                <Text fw="bold" size="xl" ta="center">
                  Aucune carte de potentiel de rendement n'est disponible
                </Text>
              </Stack>
            </Center>
          ) : null}
        </>
      }
      header={
        <Group h="100%" justify="space-between" p="md">
          <Group>
            {type !== 'single' ? (
              <SelectMapType
                maps={field.data.properties.maps}
                onChange={handleCompareChange}
                value={type === 'compare-left' ? compare?.[0] : compare?.[1]}
              />
            ) : null}
            {summary.data.length ? (
              <ExtendedCombobox
                isPending={summary.isPending || map.isFetching}
                onOptionSubmit={(val) => {
                  navigateMapIdSp(val);
                  setSelectedMap(val);
                }}
                options={options}
                selectedOption={selectedMap}
              />
            ) : null}
          </Group>

          <Group>
            <TsrButtonLink
              disabled={selectedMap === null}
              params={{
                fieldId,
              }}
              search={
                selectedMap !== null
                  ? {
                      mapType: PRESCRIPTION_FROM_TYPES.POTENTIAL,
                      mapId: Number(selectedMap),
                    }
                  : {}
              }
              to="/fields/$fieldId/maps/prescriptions/create"
            >
              Créer une carte de préconisation
            </TsrButtonLink>
          </Group>
        </Group>
      }
    />
  );
}

export default YieldPotentialMap;
