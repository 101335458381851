import { useQuery } from '@tanstack/react-query';

import { authQueries } from '../../queries/auth.queries';

interface UseLoggedUserParams {
  enabled?: boolean;
}

export function useLoggedUser(props: UseLoggedUserParams = {}) {
  return useQuery({
    ...authQueries.loggedUser(),
    ...props,
  });
}

export default useLoggedUser;
