import { createFileRoute } from '@tanstack/react-router';

import { DashboardPage } from '@dashboard';

export const Route = createFileRoute('/_app/dashboard')({
  loader: () => {
    // return fake promise
    return new Promise((resolve) => {
      setTimeout(() => {
        // throw test error
        // throw new Error('Test error');
        resolve({});
      }, 1000);
    });
  },
  component: DashboardPage,
  pendingComponent: DashboardPage.Pending,
  errorComponent: DashboardPage.Error,
});
