import { FileButton, Button, TextInput, Select, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import styles from './prescription-map-form.module.css';
import { usePrescriptiondMapForm } from './use-prescription-map-form';

export function PrescriptionMapForm() {
  const { t } = useTranslation();
  const form = usePrescriptiondMapForm();

  return (
    <form onSubmit={form.onSubmit(console.log)}>
      <FileButton accept="image/png,image/jpeg" {...form.getInputProps('file')}>
        {(props) => (
          <Button size="xs" {...props}>
            Charger depuis un fichier
          </Button>
        )}
      </FileButton>
      {form.values.file ? (
        <Text mt="sm" size="sm">
          {t('field.form.fileSelected')} {form.values.file.name}
        </Text>
      ) : null}

      <TextInput
        label="Nom de la carte (optionnel)"
        mt="sm"
        size="xs"
        {...form.getInputProps('name')}
      />

      <Select
        label="Colonne de la valeur"
        mt="sm"
        required
        size="xs"
        {...form.getInputProps('col')}
      />

      <Select
        label="Groupe d'intrants"
        mt="sm"
        required
        size="xs"
        {...form.getInputProps('type')}
      />

      <Select
        label="Intrant"
        mt="sm"
        required
        size="xs"
        {...form.getInputProps('crop')}
      />
    </form>
  );
}

export default PrescriptionMapForm;
