import { Button, Group } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableOptions,
} from 'mantine-react-table';
import { ChangeEvent, FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getData } from './data';
import styles from './plots-data-grid.module.css';

import { MrtEditCellCustomInput, PfTable } from '@ui';

interface Plot {
  name: string;
  farm: string;
  area: number;
  crop: string;
  yield: number;
}

export function PlotsDataGrid() {
  const { t } = useTranslation();
  const [resetKey, setResetKey] = useState(0);
  const [currentEdits, setCurrentEdits] = useState<
    Record<string, { yield: string }>
  >({});

  const query = useQuery({
    queryKey: ['synthesis', 'plots'],
    queryFn: getData,
  });

  const tableColumns = useMemo<MRT_ColumnDef<Plot>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('common.name'),
        enableEditing: false,
      },
      {
        accessorKey: 'farm',
        header: t('common.farm'),
        enableEditing: false,
      },
      {
        accessorKey: 'area',
        header: t('common.area'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
        enableEditing: false,
      },
      {
        accessorKey: 'crop',
        header: t('common.crop'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: [],
        },
        enableEditing: true,
        editVariant: 'select',
        mantineEditSelectProps: {
          variant: 'default',
          allowDeselect: false,
          data: [
            { value: 'Wheat', label: 'Wheat' },
            { value: 'Barley', label: 'Barley' },
          ],
        },
      },
      {
        accessorKey: 'yield',
        header: t('common.yield'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
        Edit: ({ cell, column, table }) => (
          <MrtEditCellCustomInput<Plot>
            cell={cell}
            mantineEditNumberInputProps={{
              rightSection: 'q/ha',
              rightSectionWidth: 40,
            }}
            table={table}
            type="number"
          />
        ),
        enableEditing: true,
      },
    ],
    [t]
  );

  const handleEditInputChange = (
    row: MRT_Row<Plot>,
    column: MRT_Column<Plot>,
    value: FormEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement> | string
  ) => {
    setCurrentEdits((prev) => ({
      ...prev,
      [row.id]: {
        ...prev[row.id],
        [column.id]: value,
      },
    }));
  };

  const options: MRT_TableOptions<Plot> = {
    columns: tableColumns,
    data: query.data?.data ?? [],

    state: {
      isLoading: query.isLoading,
    },
    initialState: {
      showColumnFilters: true,
      pagination: {
        pageSize: 10,
        pageIndex: 0,
      },
    },

    enableEditing: true,
    editDisplayMode: 'table',

    mantineEditTextInputProps: ({ row, column }) => ({
      onChange: (value) => {
        handleEditInputChange(row, column, value);
      },
    }),
    mantineEditSelectProps: ({ row, column }) => ({
      onChange: (value) => {
        handleEditInputChange(row, column, value!);
      },
    }),
    renderTopToolbarCustomActions: (props) => {
      return (
        <Group>
          <Button disabled={Object.keys(currentEdits).length === 0}>
            {t('common.saveChanges')}
          </Button>
          <Button
            color="red"
            disabled={Object.keys(currentEdits).length === 0}
            onClick={() => {
              setCurrentEdits({});
              setResetKey((prev) => prev + 1);
            }}
          >
            {t('common.undoChanges')}
          </Button>
        </Group>
      );
    },
  };

  return (
    <PfTable
      enableMultiedit
      key={resetKey}
      onDeleteActionClick={() => null}
      onEditActionClick={() => null}
      options={options}
    />
  );
}

export default PlotsDataGrid;
