import { deepMerge } from '@mantine/core';
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_TableOptions,
} from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { fromBacki18n, useFiltersTableOptions } from '@data-access';
import { Field, useFields, useValues } from '@fields/data-access';
import { PfTable, useRowSelection } from '@ui';

/* eslint-disable-next-line */
export interface FieldsTableProps {
  rowSelection: MRT_RowSelectionState;
  setRowSelection: any;
}

export function FieldsTable({
  rowSelection,
  setRowSelection,
}: FieldsTableProps) {
  const { t, i18n } = useTranslation();
  const filtersTableOptions = useFiltersTableOptions<Field>('plots');

  const fields = useFields();
  const values = useValues();

  const data = useMemo(() => {
    if (!fields.data) {
      return [];
    }

    return fields.data.results.features.map((f) => ({
      id: f.id as number,
      ...f.properties,
    }));
  }, [fields.data]);

  const rowSelectionProps = useRowSelection<Field>({
    data,
    rowSelection,
    setRowSelection,
  });

  const tableColumns = useMemo<MRT_ColumnDef<Field>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('table.columns.field-name'),
      },
      {
        accessorKey: 'farm.name',
        header: t('table.columns.farm-name'),
      },
      {
        accessorKey: 'area',
        header: t('common.area'),
        filterVariant: 'range',
        filterFn: 'inNumberRange',
        Cell: ({ cell }) =>
          cell.getValue<number>()?.toFixed(2).replace('.', ',').concat(' ha'),
      },
      {
        accessorKey: 'species',
        accessorFn: (row) => fromBacki18n(row.species, i18n.language),
        header: t('common.crop'),
        filterVariant: 'select',
        mantineFilterSelectProps: {
          data: values.data?.species(i18n.language),
        },
      },
    ],
    [i18n.language, t, values.data],
  );

  const tableOptions: MRT_TableOptions<Field> = deepMerge<
    MRT_TableOptions<Field>
  >(
    {
      data: data,
      columns: tableColumns,
      rowCount: fields.data?.count || 0,

      initialState: {
        columnVisibility: {
          'mrt-row-pin': false,
        },
        showColumnFilters: true,
      },
      state: {
        isLoading: fields.isLoading,
        rowSelection,
        showProgressBars: fields.isRefetching,
      },

      enableFullScreenToggle: false,

      mantinePaperProps: {
        h: '100%',
        p: 0,
      },
      ...rowSelectionProps,
    },
    filtersTableOptions,
  );

  return <PfTable<Field> options={tableOptions} />;
}

export default FieldsTable;
