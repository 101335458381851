import { TutorialsResponse } from '../types/tutorials';

import { handleRequest, privateClient } from '@data-access';

export const supportApi = {
  getTutorials: async () => {
    return handleRequest<TutorialsResponse>(
      privateClient.get('supports/').json(),
    );
  },
  sendContactForm: async (data: { title: string; description: string }) => {
    return handleRequest<unknown>(
      privateClient.post('helpme/', {
        json: data,
      }),
    );
  },
};
