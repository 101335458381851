import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { CommonAdviceResultPage } from '@advices/feature';

const searchSchema = z.object({
  edit: z.boolean().optional(),
});

export const Route = createFileRoute(
  '/_app/precifert-n_/$type/results/$resultId',
)({
  validateSearch: zodSearchValidator(searchSchema),
  component: CommonAdviceResultPage,
  errorComponent: CommonAdviceResultPage.Error,
  pendingComponent: CommonAdviceResultPage.Pending,
});
