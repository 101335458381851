import { Button, Group, Paper, SimpleGrid, Stack, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';

import { ContactForm } from '../../components/contact-form/contact-form';
import { TutorialCard } from '../../components/tutorial-card/tutorial-card';
import { supportApi } from '../../data-access/apis/support';
import { supportQueries } from '../../data-access/queries/support';

import classes from './page.module.css';

export function SupportPage() {
  const [opened, actions] = useDisclosure();

  const { data } = useSuspenseQuery(supportQueries.tutorials);

  const mutation = useMutation({
    mutationFn: supportApi.sendContactForm,
    onSuccess: () => {
      actions.close();
    },
  });

  return (
    <Stack>
      <Paper>
        <Group justify="space-between">
          <Title order={2}>Support & Contact</Title>
          <Button onClick={actions.open}>Nous contacter</Button>
        </Group>
      </Paper>
      <SimpleGrid
        cols={{
          base: 1,
          sm: 2,
          md: 3,
          lg: 4,
        }}
      >
        {data.map((tutorial) => (
          <TutorialCard key={tutorial.name} {...tutorial} />
        ))}
      </SimpleGrid>

      <ContactForm
        loading={mutation.isPending}
        onClose={actions.close}
        onSubmit={(values) => mutation.mutate(values)}
        opened={opened}
        success={mutation.isSuccess}
      />
    </Stack>
  );
}

export default SupportPage;
