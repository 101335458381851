// Sentry initialization should be imported first!
import './instrument';

import { MantineProvider } from '@mantine/core';
import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { router } from './router';
import { theme } from './theme';

import { IAuthContext, AuthProvider, useAuth } from '@auth/data-access';

// Import the generated route tree

import './i18n';
// Create a new router instance

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  }),
});

export interface MyRouterContext {
  auth: IAuthContext;
  queryClient: QueryClient;
}

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }

  interface RouteContext {
    auth: IAuthContext;
    queryClient: QueryClient;
  }
}

function InnerApp() {
  const auth = useAuth();
  return <RouterProvider context={{ queryClient, auth }} router={router} />;
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        <AuthProvider>
          <InnerApp />
        </AuthProvider>
        {/* </Suspense> */}
      </MantineProvider>
    </QueryClientProvider>
  );
}

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
