import { NumberInput, NumberInputProps } from '@mantine/core';
import { forwardRef } from 'react';

import {
  getTotalArea,
  usePrescriptionFormContext,
} from '../../../../hooks/prescriptions/use-prescription-form/prescription-form';
import classes from '../prescription-form.module.css';

import { divide } from '@data-access';

export const AverageInput = forwardRef<HTMLInputElement>((props, ref) => {
  const form = usePrescriptionFormContext();

  const { asUnit, ratio, uTotal, zones, total } = form.getValues();

  const totalArea = getTotalArea(zones);

  const { onChange, ...inputProps } = form.getInputProps(
    asUnit ? 'uAverage' : 'average',
  );

  const handleChange: NumberInputProps['onChange'] = (value) => {
    const castedValue = typeof value === 'number' ? value : parseFloat(value);
    if (!asUnit) {
      // on average change, update zones values
      // calculate new total, uTotal, uAverage
      const totalTarget = castedValue * totalArea;
      const zonesDraft = zones.map((zone) => {
        const draftValue = divide(zone.value * totalTarget, total);
        return {
          ...zone,
          value: draftValue,
          uValue: draftValue * ratio,
        };
      });
      form.setValues({
        zones: zonesDraft,
        total: totalTarget,
        uTotal: totalTarget * ratio,
        uAverage: castedValue * ratio,
      });
    } else {
      // on uAverage change, update zones values
      const uTotalTarget = castedValue * totalArea;
      const zonesDraft = zones.map((zone) => {
        const draftUValue = divide(zone.uValue * uTotalTarget, uTotal);
        return {
          ...zone,
          uValue: draftUValue,
          value: divide(draftUValue, ratio),
        };
      });
      form.setValues({
        zones: zonesDraft,
        uTotal: uTotalTarget,
        total: divide(uTotalTarget, ratio),
        average: divide(castedValue, ratio),
      });
    }

    // call mantine onChange
    onChange(value);
  };

  return (
    <NumberInput
      {...inputProps}
      classNames={{ input: classes.quantityInput }}
      decimalScale={2}
      fixedDecimalScale
      hideControls
      onChange={handleChange}
      ref={ref}
      step={0.1}
      ta="right"
      w={100}
    />
  );
});
