import { queryOptions } from '@tanstack/react-query';

import { accountsApi } from '../apis/accounts';

import { Filters, Globals } from '@data-access';

export const accountsQueries = (global: Globals) => ({
  all: () => [global, 'accounts'],

  lists: () => [...accountsQueries(global).all(), 'list'],
  list: (filters: Filters) =>
    queryOptions({
      queryKey: [...accountsQueries(global).lists(), filters],
      queryFn: () => accountsApi.getAll(filters),
    }),

  details: () => [...accountsQueries(global).all(), 'detail'],
  detail: (id: number) =>
    queryOptions({
      queryKey: [...accountsQueries(global).details(), id],
      queryFn: () => accountsApi.getOneById(id),
    }),

  values: () =>
    queryOptions({
      queryKey: [...accountsQueries(global).all(), 'values'],
      queryFn: () => accountsApi.getValues(),
    }),
});
