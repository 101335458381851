import {
  Stack,
  Paper,
  Group,
  rem,
  ActionIcon,
  Box,
  Skeleton,
} from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';

import classes from './page.module.css';

import { FullMainHeight } from '@ui';

export function PendingPage() {
  return (
    <FullMainHeight.Root>
      <FullMainHeight.FlexContent>
        <FullMainHeight.Sidebar size="lg">
          <Stack className={classes.navCol}>
            <Paper className={classes.navHeader}>
              <Group>
                <Box>
                  <Skeleton height={rem(36)} width={rem(36)} />
                </Box>
                <Stack flex="1" gap={0} h={rem(36)} justify="center">
                  <Skeleton height={rem(20)} mb="xs" width="100%" />
                  <Skeleton height={rem(16)} width="50%" />
                </Stack>
                <ActionIcon disabled variant="outline">
                  <IconDotsVertical />
                </ActionIcon>
              </Group>
            </Paper>
            <Paper className={classes.navBar}>
              <Skeleton height={rem(55)} mb="lg" radius={0} width="100%" />
            </Paper>
          </Stack>
        </FullMainHeight.Sidebar>

        <Stack className={classes.navCol}>
          <Paper className={classes.navHeader}>
            <Skeleton h={rem(36)} radius="xs" width={rem(274)} />
          </Paper>
          <Skeleton height="100%" radius="xs" width="100%" />
        </Stack>
      </FullMainHeight.FlexContent>
    </FullMainHeight.Root>
  );
}

export default PendingPage;
