import { useParams } from '@tanstack/react-router';

import { ErrorPage } from './page.error';
import styles from './page.module.css';
import { BiomassMap } from './pages/biomass-map/page';
import CompareMapsPage, { MAP_DISPLAY_TYPES } from './pages/compare-maps/page';
import { PrescriptionMap } from './pages/prescription-map/page';
import { SamplesMap } from './pages/samples-map/page';
import { SatelliteMap } from './pages/satellite-map/page';
import { SoilMap } from './pages/soil-map/page';
import { YieldMap } from './pages/yield-map/page';
import { YieldPotentialMap } from './pages/yield-potential-map/page';

import { MAP_TYPES } from '@fields/data-access';

export const pagesMapping = {
  [MAP_TYPES.SOIL]: SoilMap,
  [MAP_TYPES.SATELLITE]: SatelliteMap,
  [MAP_TYPES.YIELD]: YieldMap,
  [MAP_TYPES.PRESCRIPTIONS]: PrescriptionMap,
  [MAP_TYPES.SAMPLES]: SamplesMap,
  [MAP_TYPES.POTENTIAL]: YieldPotentialMap,
  [MAP_TYPES.BIOMASS]: BiomassMap,
  [MAP_TYPES.OTHER]: () => (
    <div className={styles.page}>
      Hello /_app/fields/$fieldId/maps/$mapType!
    </div>
  ),
  [MAP_TYPES.COMPARE]: CompareMapsPage,
};

export function MapsPage() {
  const { mapType } = useParams({
    from: '/_app/fields_/$fieldId/maps/$mapType',
  });

  const Page = pagesMapping[mapType];

  return <Page type={MAP_DISPLAY_TYPES.SINGLE} />;
}

MapsPage.Error = ErrorPage;

export default MapsPage;
