import { Box, Image, Skeleton } from '@mantine/core';
import React, { useState, useCallback } from 'react';

type PropType = {
  src: string;
  alt: string;
  inView: boolean;
  index: number;
};

export const LazyLoadImage: React.FC<PropType> = (props) => {
  const { src, inView, alt } = props;
  const [hasLoaded, setHasLoaded] = useState(false);

  const setLoaded = useCallback(() => {
    if (inView) setHasLoaded(true);
  }, [inView, setHasLoaded]);

  return (
    <Box h="100%" pos="relative">
      {!hasLoaded && (
        <Skeleton
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        ></Skeleton>
      )}
      <Image
        alt={alt}
        data-src={src}
        fit="contain"
        h="100%"
        onLoad={setLoaded}
        src={inView ? src : ''}
      />
    </Box>
  );
};
