import { Paper, Container, Title, Group, Button, Text } from '@mantine/core';
import { captureException } from '@sentry/react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './common-error.module.css';

interface CommonErrorProps {
  error: unknown;
  reset: () => void;
  title?: string;
  description?: string;
  report?: boolean;
}

export const CommonError = ({
  error,
  reset,
  title,
  description,
  report = true,
}: CommonErrorProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    // Reset the query error boundary
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  useEffect(() => {
    if (error && report) {
      captureException(error);
    }
  }, [error, report]);

  return (
    <Paper className={classes.errorPaper}>
      <Container className={classes.errorContainer}>
        <Title className={classes.errorTitle}>
          {title ?? t('common.error.title')}
        </Title>

        <Text className={classes.errorDescription} size="lg">
          {description}
        </Text>

        <Group justify="center">
          <Button
            onClick={() => {
              reset();
              // Invalidate the route to reload the loader, and reset any router error boundaries
              router.invalidate();
            }}
            size="md"
            variant="white"
          >
            {t('common.error.refresh')}
          </Button>
        </Group>
      </Container>
    </Paper>
  );
};

export default CommonError;
