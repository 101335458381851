import { CAMPAING_STORAGE_KEY } from '../stores/campaign.atom';

export const getCampaign = () => {
  const token = localStorage.getItem(CAMPAING_STORAGE_KEY);

  try {
    return JSON.parse(token as string);
  } catch {
    return token;
  }
};

export default getCampaign;
