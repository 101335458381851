import { useParams } from '@tanstack/react-router';

import { CreatePrescriptionMapPage } from './pages/create-prescription-map/page';
import { CreateSoilMapPage } from './pages/create-soil-map/page';
import { CreateYieldMapPage } from './pages/create-yield-map/page';

import { MAP_TYPES } from '@fields/data-access';

const pagesMapping = {
  [MAP_TYPES.SOIL]: CreateSoilMapPage,
  [MAP_TYPES.YIELD]: CreateYieldMapPage,
  [MAP_TYPES.PRESCRIPTIONS]: CreatePrescriptionMapPage,
};

export function CreateMapsPage() {
  const { editableMapType } = useParams({
    from: '/_app/fields_/$fieldId_/maps/$editableMapType/create',
  });

  const Page = pagesMapping[editableMapType];

  return <Page />;
}

export default CreateMapsPage;
