import { rem } from '@mantine/core';

interface WheatIconProps extends React.ComponentPropsWithoutRef<'svg'> {
  size?: number | string;
}

export function WheatIcon({ size, style, ...others }: WheatIconProps) {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      style={{ width: rem(size), height: rem(size), ...style }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path d="M3.465 20.535 15.211 8.789m-6.304.442a3.663 3.663 0 0 1 .203 5.698l-.203.163a3.663 3.663 0 0 1-.202-5.698l.202-.163Zm2.931-2.93a3.663 3.663 0 0 1 .202 5.698l-.202.163a3.663 3.663 0 0 1-.202-5.698l.202-.163Zm2.93-2.931a3.663 3.663 0 0 1 .203 5.698l-.202.163a3.663 3.663 0 0 1-.203-5.698l.203-.163ZM20.63 9.23a3.663 3.663 0 0 1-5.698.203l-.163-.203a3.663 3.663 0 0 1 5.698-.202l.163.202Zm-2.93 2.93a3.663 3.663 0 0 1-5.699.203l-.163-.202a3.663 3.663 0 0 1 5.698-.203l.163.203Zm-2.931 2.931a3.663 3.663 0 0 1-5.698.203l-.164-.203a3.663 3.663 0 0 1 5.698-.202l.164.202Zm-8.862-2.86a3.663 3.663 0 0 1 .203 5.697l-.203.163a3.663 3.663 0 0 1-.202-5.698l.202-.163Zm5.862 5.86a3.663 3.663 0 0 1-5.698.203l-.164-.203a3.663 3.663 0 0 1 5.698-.202l.164.202Zm8.969-14.829a3.663 3.663 0 0 1-3.886 4.172l-.259-.027a3.663 3.663 0 0 1 4.145-4.145Z" />
    </svg>
  );
}
