import { useAtom } from 'jotai';

import { accountsAtom } from '../../stores/accounts.atom';
import { campaignAtom } from '../../stores/campaign.atom';

export type Globals = {
  campaign: number;
  accounts: number[];
};

export type QueriesFactory<T> = (globals: Globals) => T;

// Function overloads
export function useQueriesWithGlobal<T>(queriesFactory: QueriesFactory<T>): T;
export function useQueriesWithGlobal<T>(
  queriesFactory: QueriesFactory<T>[],
): T[];

// Implementation
export function useQueriesWithGlobal<T>(
  queriesFactory: QueriesFactory<T> | QueriesFactory<T>[],
): T | T[] {
  const [campaign] = useAtom(campaignAtom);
  const [accounts] = useAtom(accountsAtom);

  const globals: Globals = {
    campaign: campaign!,
    accounts: accounts,
  };

  if (Array.isArray(queriesFactory)) {
    return queriesFactory.map((factory) => factory(globals));
  } else {
    return queriesFactory(globals);
  }
}
