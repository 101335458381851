import {
  Table,
  Group,
  Select,
  NumberInput,
  ActionIcon,
  Text,
  Loader,
  Button,
  Box,
  ThemeIcon,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import {
  IconCircleMinus,
  IconCirclePlus,
  IconDropletFilled,
} from '@tabler/icons-react';
import { useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { usePkPlanForm } from '../../hooks/use-pk-plan-form/pk-plan-form';

import classes from './pk-plan-table-form.module.css';

import { useQueriesWithGlobal } from '@data-access';
import { fieldsApi, fieldsQueries, MAP_TYPES } from '@fields/data-access';

interface PkPlanTableFormProps {
  fieldId: number;
}

export function PkPlanTableForm({ fieldId }: PkPlanTableFormProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const form = usePkPlanForm();

  const queries = useQueriesWithGlobal(fieldsQueries);
  const advice = useQuery(queries.pkAdvice(Number(fieldId)));
  const pkValues = useQuery(queries.pkValues(fieldId));

  const [debouncedRows] = useDebouncedValue(form.getValues().rows, 200);
  const results = useQueries({
    queries: debouncedRows.map((row) => ({
      ...queries.pkResults({
        fieldId,
        product_ferti: Number(row.input),
        price: row.input_price || undefined,
      }),
      enabled: !!row.input,
    })),
  });

  const createMap = useMutation({
    mutationFn: fieldsApi.createPkMap,
    onSuccess(data, variables, context) {
      navigate({
        to: '/fields/$fieldId/maps/$mapType',
        params: {
          fieldId: fieldId.toString(),
          mapType: MAP_TYPES.PRESCRIPTIONS,
        },
        search: {
          mapId: data.map_instances[0],
        },
      });
    },
  });

  const totalPrice = results
    .reduce((acc, query) => {
      if (query.isLoading || !query.data) {
        return acc;
      }

      return acc + query.data.total_price;
    }, 0)
    ?.toFixed(2);

  const totalP2O5_applied = results
    .reduce((acc, query) => {
      if (query.isLoading || !query.data) {
        return acc;
      }

      return acc + query.data.P2O5_applied;
    }, 0)
    ?.toFixed(2);

  const totalK2O_applied = results
    .reduce((acc, query) => {
      if (query.isLoading || !query.data) {
        return acc;
      }

      return acc + query.data.K2O_applied;
    }, 0)
    ?.toFixed(2);

  return (
    <Table className={classes.table} mt="md" striped withRowBorders={false}>
      <Table.Thead>
        <Table.Tr>
          <Table.Th></Table.Th>
          <Table.Th>P205</Table.Th>
          <Table.Th>K20</Table.Th>
          <Table.Th>{t('common.mean_use')}</Table.Th>
          <Table.Th>{t('common.total_use')}</Table.Th>
          <Table.Th>{t('common.product_price')}</Table.Th>
          <Table.Th>{t('common.total_price')}</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        <Table.Tr>
          <Table.Td ta="right">
            <Text size="md">{t('common.COMIFER')}</Text>
          </Table.Td>
          <Table.Td>{advice.data?.P2O5_mean?.toFixed(2) ?? '-'} U/ha</Table.Td>
          <Table.Td>{advice.data?.K2O_mean?.toFixed(2) ?? '-'} U/ha</Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
        </Table.Tr>
        {form.getValues().rows.map((row, index: number) => {
          const query = results[index] as (typeof results)[number] | undefined;

          return (
            <Table.Tr key={index}>
              <Table.Td maw={250}>
                <Group grow>
                  <Select
                    {...form.getInputProps(`rows.${index}.group`)}
                    data={pkValues.data?.product_group(i18n.language)}
                    placeholder={t('prescriptions.groupLabel')}
                  />
                  <Select
                    {...form.getInputProps(`rows.${index}.input`)}
                    allowDeselect
                    clearable
                    data={pkValues.data
                      ?.product_ferti(i18n.language)
                      .filter((p) => p.parent.toString() === row.group)}
                    placeholder={t('advices.properties.pdt_org1')}
                  />
                </Group>
              </Table.Td>
              <Table.Td
                className={clsx({
                  [classes.tdWarning]: query?.data?.P2O5_warning,
                })}
              >
                <Group>
                  {query?.data?.P2O5_applied?.toFixed(2) ?? '-'} U/ha
                  {query?.data?.P2O5_warning ? (
                    <ThemeIcon color="red" size="sm" variant="transparent">
                      <IconDropletFilled />
                    </ThemeIcon>
                  ) : null}
                </Group>
              </Table.Td>
              <Table.Td
                className={clsx({
                  [classes.tdWarning]: query?.data?.K2O_warning,
                })}
              >
                <Group>
                  {query?.data?.K2O_applied?.toFixed(2) ?? '-'} U/ha
                  {query?.data?.K2O_warning ? (
                    <ThemeIcon color="red" size="sm" variant="transparent">
                      <IconDropletFilled />
                    </ThemeIcon>
                  ) : null}
                </Group>
              </Table.Td>
              <Table.Td>
                {query?.data?.mean_product_quantity?.toFixed(2) ?? '-'} kg/ha
              </Table.Td>
              <Table.Td>
                {query?.data?.total_product_quantity?.toFixed(2) ?? '-'} kg
              </Table.Td>
              <Table.Td>
                <Group>
                  <NumberInput
                    hideControls
                    styles={{ input: { textAlign: 'right' } }}
                    w={100}
                    {...form.getInputProps(`rows.${index}.input_price`)}
                  />
                  <Text>€/t</Text>
                </Group>
              </Table.Td>
              <Table.Td>
                {query?.data?.total_price?.toFixed(2) ?? '-'} €
              </Table.Td>
              <Table.Td>
                {query?.isLoading ? (
                  <Loader size="sm" />
                ) : (
                  <ActionIcon
                    color="red"
                    disabled={form.getValues().rows.length === 1}
                    onClick={() => form.removeListItem('rows', index)}
                    variant="subtle"
                  >
                    <IconCircleMinus />
                  </ActionIcon>
                )}
              </Table.Td>
            </Table.Tr>
          );
        })}
        <Table.Tr>
          <Table.Td ta="right">{t('prescriptions.total')}</Table.Td>
          <Table.Td>{totalP2O5_applied} U/ha</Table.Td>
          <Table.Td>{totalK2O_applied} U/ha</Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td></Table.Td>
          <Table.Td>{totalPrice} €</Table.Td>
          <Table.Td>
            <ActionIcon
              onClick={() =>
                form.insertListItem('rows', {
                  group: '',
                  input: '',
                  p205: '',
                  k2o: '',
                  average_utilization: '',
                  input_price: '',
                })
              }
              variant="subtle"
            >
              <IconCirclePlus />
            </ActionIcon>
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
      <Table.Tfoot>
        <Table.Tr>
          <Table.Td pt="sm">
            <Button
              disabled={
                form.getValues().rows.filter((row) => !row.input).length > 0
              }
              loading={createMap.isPending}
              onClick={() => {
                createMap.mutate({
                  fieldId,
                  product: form
                    .getValues()
                    .rows.map((row) => Number(row.input)),
                });
              }}
            >
              {t('prescriptions.createPrescriptionTitle')}
            </Button>
          </Table.Td>
        </Table.Tr>
      </Table.Tfoot>
    </Table>
  );
}

export default PkPlanTableForm;
