import {
  Paper,
  Group,
  Autocomplete,
  ComboboxStringData,
  Loader,
  CloseButton,
} from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TsrButtonLink } from '@ui';

interface FieldsMapHeaderProps {
  onSearchChange: (value: string) => void;
  onSearchSubmit: (value: string) => void;
  searchOptions: ComboboxStringData;
  isLoading: boolean;
}

export function FieldsMapHeader({
  onSearchChange,
  onSearchSubmit,
  searchOptions,
  isLoading,
}: FieldsMapHeaderProps) {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const handleChange = (v: string) => {
    setValue(v);
    onSearchChange(v);
  };

  const rightSection = () => {
    if (isLoading) {
      return <Loader size={16} />;
    }

    if (value) {
      return <CloseButton onClick={() => handleChange('')} />;
    }
    return <IconSearch />;
  };

  return (
    <Paper>
      <Group justify="space-between">
        <Autocomplete
          data={searchOptions}
          onChange={handleChange}
          onOptionSubmit={onSearchSubmit}
          placeholder={t('plots.searchPlaceholder')}
          rightSection={rightSection()}
          value={value}
        />
        <Group>
          <TsrButtonLink to="/fields/create">
            {t('common.createPlot')}
          </TsrButtonLink>
        </Group>
      </Group>
    </Paper>
  );
}

export default FieldsMapHeader;
