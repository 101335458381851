import { useSuspenseQuery } from '@tanstack/react-query';
import { useParams, useRouter } from '@tanstack/react-router';
import { MapEvent } from 'mapbox-gl';
import { useTranslation } from 'react-i18next';

import { useQueriesWithGlobal } from '@data-access';
import { fieldsQueries, MAP_TYPES } from '@fields/data-access';
import { zoomOnFeature } from '@map';

export function useCreateMapPageShared() {
  const { t } = useTranslation();
  const { fieldId } = useParams({
    from: '/_app/fields_/$fieldId_/maps/$editableMapType/create',
  });
  const { navigate } = useRouter();

  const queries = useQueriesWithGlobal(fieldsQueries);
  const { data } = useSuspenseQuery(queries.detail(Number(fieldId)));

  const navToField = (mapType: MAP_TYPES) =>
    navigate({
      to: '/fields/$fieldId/maps/$mapType',
      params: {
        fieldId,
        mapType: mapType,
      },
    });

  const handleLoad = (map: MapEvent) => {
    zoomOnFeature({
      map: map.target,
      geojson: data,
    });
  };

  return {
    handleLoad,
    navToField,
    t,
    fieldId,
  };
}

export default useCreateMapPageShared;
