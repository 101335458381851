import { Group, ActionIcon, deepMerge, Button, Flex } from '@mantine/core';
import {
  IconTrash,
  IconEdit,
  IconSquareMinusFilled,
  IconSquarePlus,
  IconFilter,
  IconFilterOff,
} from '@tabler/icons-react';
import { Link, LinkProps } from '@tanstack/react-router';
import clsx from 'clsx';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  MRT_Icons,
  MRT_Row,
  MRT_TableInstance,
  MRT_TableOptions,
  MRT_ToggleFullScreenButton,
  MRT_ShowHideColumnsButton,
  MantineReactTable,
  useMantineReactTable,
  MRT_Cell,
  MRT_Localization,
} from 'mantine-react-table';
import { MRT_Localization_EN } from 'mantine-react-table/locales/en/index.esm.mjs';
import { MRT_Localization_FR } from 'mantine-react-table/locales/fr/index.esm.mjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  isMultieditRow,
  MULTI_EDIT_ROW_ID,
  multiEditRow,
} from './multiedit-row';
import styles from './pf-table.module.css';
dayjs.extend(isBetween);

const localeMap: Record<string, MRT_Localization> = {
  fr: {
    ...MRT_Localization_FR,
    toggleFullScreen: 'Activer / Désactiver plein écran',
  },
  en: MRT_Localization_EN,
};

type RecordType = Record<string, any>;

/* eslint-disable-next-line */
export interface PfTableProps<T extends RecordType> {
  options: Required<Pick<MRT_TableOptions<T>, 'columns' | 'data'>> &
    Partial<MRT_TableOptions<T>>;
  onDeleteActionClick?: (row: MRT_Row<T>) => void;
  onEditActionClick?: (row: MRT_Row<T>) => void;
  editActionLink?: (row: MRT_Row<T>) => LinkProps;
  canRenderRowActions?: (row: MRT_Row<T>) => boolean;
  enableMultiedit?: boolean;
  showShowHideColMenu?: boolean;
  actions?: React.ReactNode;
  renderRowActionsExtension?: (props: {
    cell: MRT_Cell<T>;
    row: MRT_Row<T>;
    table: MRT_TableInstance<T>;
  }) => React.ReactNode;
  multieditRowColName?: string;
  onMultiEditRowSave?: () => void;
}

const icons: Partial<MRT_Icons> = {
  // change sort icon, connect internal props so that it gets styled correctly
  IconChevronDown: (props: any) => {
    if (props.className.includes('MRT_ExpandButton-module_up')) {
      return <IconSquareMinusFilled />;
    }
    return <IconSquarePlus />;
  },
};

export function PfTable<T extends RecordType>(props: PfTableProps<T>) {
  const { t, i18n } = useTranslation();

  const data = useMemo(() => {
    if (props.enableMultiedit && !props.options.state?.isLoading) {
      return [
        multiEditRow(
          props.multieditRowColName ||
            (props.options.columns.length
              ? (props.options.columns[0].accessorKey as string)
              : ''),

          t('table.multiEditRowLabel'),
        ),
        ...props.options.data,
      ];
    }

    return props.options.data;
  }, [
    props.enableMultiedit,
    props.multieditRowColName,
    props.options.columns,
    props.options.data,
    props.options.state?.isLoading,
    t,
  ]);

  const table = useMantineReactTable<T>(
    deepMerge<MRT_TableOptions<T>>(
      {
        columns: [],
        data: [],
        icons,
        localization: localeMap[i18n.language] || MRT_Localization_FR,
        enableStickyHeader: false,
        enableStickyFooter: true,

        initialState: {
          columnVisibility: {
            'mrt-row-pin': false,
          },
        },

        getRowId: (row, index) => {
          return `${row.id || index}`;
        },

        enableRowPinning: props.enableMultiedit
          ? (row) => row.id === MULTI_EDIT_ROW_ID
          : undefined,
        rowPinningDisplayMode: 'top',
        enableRowSelection: props.enableMultiedit
          ? (row) => row.id !== MULTI_EDIT_ROW_ID
          : undefined,

        mantineTableBodyRowProps: ({ table: tb, row }) => ({
          // display:
          //   !tb.getIsSomeRowsSelected() && row.id === `${multiEditRow.id}`
          //     ? 'none'
          //     : undefined,
          className: clsx({
            [styles.mutlieditRow]: row.id === MULTI_EDIT_ROW_ID,
          }),
          disabled: !tb.getIsSomeRowsSelected(),
        }),

        displayColumnDefOptions: {
          'mrt-row-actions': {
            header: '',
            size: 105,
          },
        },

        positionToolbarAlertBanner: 'none',
        enableGlobalFilter: false,
        enableRowActions: true,
        positionActionsColumn: 'last',

        renderRowActions: ({ row, cell, table: tb }) => {
          if (props.canRenderRowActions && !props.canRenderRowActions(row)) {
            return null;
          }

          if (isMultieditRow(row.id)) {
            return (
              <Button color="red.9" onClick={props.onMultiEditRowSave}>
                Valider
              </Button>
            );
          }

          return (
            <Group gap="md">
              {props.onDeleteActionClick ? (
                <ActionIcon
                  color="red"
                  onClick={() =>
                    props.onDeleteActionClick && props.onDeleteActionClick(row)
                  }
                  variant="subtle"
                >
                  <IconTrash />
                </ActionIcon>
              ) : null}
              {props.onEditActionClick ? (
                <ActionIcon
                  color="dark"
                  onClick={() =>
                    props.onEditActionClick && props.onEditActionClick(row)
                  }
                  variant="subtle"
                >
                  <IconEdit />
                </ActionIcon>
              ) : null}
              {props.editActionLink ? (
                <ActionIcon
                  color="dark"
                  component={Link}
                  variant="subtle"
                  {...(props.editActionLink(row) as any)}
                >
                  <IconEdit />
                </ActionIcon>
              ) : null}
              {props.renderRowActionsExtension
                ? props.renderRowActionsExtension({ row, cell, table: tb })
                : null}
            </Group>
          );
        },

        //customize built-in buttons in the top-right of top toolbar
        renderToolbarInternalActions: ({ table: tb }) => (
          <Flex align="center" gap="xs">
            {/* add custom button to print table  */}

            {props.options.enableColumnFilters !== false ? (
              <Button
                onClick={() => {
                  tb.setShowColumnFilters((show) => !show);
                }}
                rightSection={
                  tb.getState().showColumnFilters ? (
                    <IconFilterOff />
                  ) : (
                    <IconFilter />
                  )
                }
                variant="filled"
              >
                {t('table.filters')}
              </Button>
            ) : null}

            {props.options.enableColumnFilters &&
            tb.getState().columnFilters.length > 0 ? (
              <Button
                onClick={() => {
                  tb.resetColumnFilters();
                }}
                variant="subtle"
              >
                {t('table.clearFilters')}
              </Button>
            ) : null}

            {/* along-side built-in buttons in whatever order you want them */}
            <MRT_ShowHideColumnsButton
              className="show-hide-col-button"
              table={tb}
            />

            {props.options.enableFullScreenToggle !== false ? (
              <MRT_ToggleFullScreenButton table={tb} />
            ) : null}
          </Flex>
        ),
        mantinePaperProps: {
          shadow: 'xs',
          withBorder: false,
          p: 0,
        },
        mantineTableProps: {
          striped: true,
          withRowBorders: false,
        },
        mantineTableContainerProps: ({ table: tb }) => ({
          style: {
            maxHeight: `calc(100% - ${tb.refs.topToolbarRef.current?.offsetHeight}px - ${tb.refs.bottomToolbarRef.current?.offsetHeight}px)`,
            minHeight: `calc(100% - ${tb.refs.topToolbarRef.current?.offsetHeight}px - ${tb.refs.bottomToolbarRef.current?.offsetHeight}px)`,
          },
        }),
        mantineTableHeadCellProps: {
          style: {
            verticalAlign: 'bottom',
          },
        },

        filterFns: {
          dateBetween: (row, id, filterValue) => {
            const value = row.getValue<string>(id);
            const min = filterValue[0] ? dayjs(filterValue[0]) : undefined;
            const max = filterValue[1] ? dayjs(filterValue[1]) : undefined;
            const date = dayjs(value, 'YYYY-MM-DD');

            if (min && max) {
              return date.isBetween(min, max, 'day', '[]');
            } else if (min) {
              return date.isAfter(min);
            } else if (max) {
              return date.isBefore(max);
            } else {
              return true;
            }
          },
        },

        mantineFilterAutocompleteProps: {
          variant: 'filled',
          classNames: {
            root: styles['filter-input-root'],
            input: styles['filter-input-input'],
          },
        },
        mantineFilterSelectProps: {
          variant: 'filled',
          classNames: {
            root: styles['filter-input-root'],
            input: styles['filter-input-input'],
          },
        },
        mantineFilterTextInputProps: {
          variant: 'filled',
          classNames: {
            root: styles['filter-input-root'],
            input: styles['filter-input-input'],
          },
        },
        mantineFilterDateInputProps: {
          variant: 'filled',
          classNames: {
            root: styles['filter-input-root'],
            input: styles['filter-input-input'],
          },
        },
      },
      {
        ...props.options,
        data,
      },
    ),
  );

  useEffect(() => {
    if (!props.showShowHideColMenu) {
      return;
    }

    const showHideColButtonRef = document.querySelector(
      '.show-hide-col-button',
    ) as HTMLButtonElement;

    if (!showHideColButtonRef) {
      return;
    }

    showHideColButtonRef.click();
  }, []);

  useEffect(() => {
    if (!props.enableMultiedit || props.options.state?.isLoading) {
      return;
    }
    const row = table.getRow(MULTI_EDIT_ROW_ID);

    if (!row.getIsPinned()) {
      row.pin('top');
    }
  }, [props.enableMultiedit, props.options.state?.isLoading, table]);

  return <MantineReactTable table={table} />;
}

export * from './multiedit-row';
export * from './use-row-selection';

export default PfTable;
