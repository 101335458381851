import { useMutation } from '@tanstack/react-query';

import { fieldMapsApi } from '../../apis/field-maps';

export function useUploadSoilMap() {
  return useMutation({
    mutationFn: fieldMapsApi.uploadSoilMap,
  });
}

export default useUploadSoilMap;
