import { Paper, Stack } from '@mantine/core';
import { ErrorRouteComponent } from '@tanstack/react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { FieldsListHeader } from '../../components/fields-list-header/fields-list-header';

import styles from './page.module.css';

import { CommonError, FullMainHeight } from '@ui';

export const ErrorPage: ErrorRouteComponent = ({ error, reset }) => {
  const { t } = useTranslation();
  return (
    <FullMainHeight>
      <Stack className={styles.container}>
        <FieldsListHeader rowSelection={{}} setRowSelection={() => null} />
        <Paper
          className={clsx(styles['map-container'], 'border-light')}
          p={0}
          shadow="sm"
        >
          <CommonError
            description={t('common.error.pageError')}
            error={error}
            reset={reset}
          />
        </Paper>
      </Stack>
    </FullMainHeight>
  );
};

export default ErrorPage;
