import { Box, Grid, Group, Overlay, Paper, Stack, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { DashboardHeader } from '../../components/dashboard-header/dashboard-header';
import { NewsCard } from '../../components/news-card/news-card';
import { OverlayedBarChart } from '../../components/overlayed-bar-chart/overlayed-bar-chart';
import { dashboardQueries } from '../../data-access/queries/dashboard.queries';

import { PageError } from './page.error';
import { PendingPage } from './page.pending';

import { useQueriesWithGlobal } from '@data-access';
import { BarleyIcon, CanolaIcon, WheatIcon } from '@ui';

export function DashboardPage() {
  const { t } = useTranslation();
  const queries = useQueriesWithGlobal(dashboardQueries);
  const news = useQuery(queries.news());

  return (
    <Grid>
      <DashboardHeader />
      <Grid.Col order={{ base: 2, lg: 3 }} span={{ base: 12, lg: 8 }}>
        <Paper pos="relative">
          <Overlay backgroundOpacity={0.55} color="#fff" zIndex={1} />

          <Group grow>
            <OverlayedBarChart
              actualDataKey={t('dashboard.regionalAverage')}
              baselineDataKey={t('dashboard.farmAverage')}
              data={[]}
              referenceLabel={t('dashboard.actualAverage')}
              referenceValue={5000}
              title={
                <Group align="center">
                  <WheatIcon size={40} />
                  <Text fw="bold">{t('dashboard.wheatYieldPotential')}</Text>
                </Group>
              }
            />
            <OverlayedBarChart
              actualDataColor="#CB9A3C"
              actualDataKey={t('dashboard.regionalAverage')}
              baselineDataKey={t('dashboard.farmAverage')}
              data={[]}
              referenceLabel={t('dashboard.actualAverage')}
              referenceValue={5000}
              title={
                <Group align="center">
                  <BarleyIcon size={40} />
                  <Text fw="bold">{t('dashboard.barleyYieldPotential')}</Text>
                </Group>
              }
            />
          </Group>
        </Paper>
        <Paper mt="md" pos="relative" w="fit-content">
          <Overlay backgroundOpacity={0.55} color="#fff" zIndex={1} />
          <Box miw={300}>
            <OverlayedBarChart
              actualDataColor="#8BC02B"
              actualDataKey={t('dashboard.regionalAverage')}
              baselineDataKey={t('dashboard.farmAverage')}
              data={[]}
              title={
                <Group align="center">
                  <CanolaIcon size={40} />
                  <Text fw="bold">{t('dashboard.rapeBiomass')} </Text>
                </Group>
              }
            />
          </Box>
        </Paper>
      </Grid.Col>
      <Grid.Col order={4} span={{ base: 12, lg: 4 }}>
        <Paper>
          <Stack>
            {news.data?.map((item, index) => (
              <NewsCard key={index} {...item} />
            ))}
          </Stack>
        </Paper>
      </Grid.Col>
    </Grid>
  );
}

DashboardPage.Error = PageError;
DashboardPage.Pending = PendingPage;

export default DashboardPage;
