import { Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { modals } from '@mantine/modals';
import { MRT_Updater, MRT_RowSelectionState } from 'mantine-react-table';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { rapeseedFormInitialValues } from '../use-rapeseed-advice-form/rapeseed-advice-form';

export enum AdviceFormStep {
  SELECT_FIELDS = 0,
  MULTI_FORM_VALIDATION = 1,
  FILL_FORM = 2,
}

interface UseSharedAdviceFormParams {
  form: UseFormReturnType<any>;
  onClose?: () => void;
  onSubmit: (values: any) => void;
  mode: 'create' | 'edit';
}

export function useSharedAdviceForm({
  form,
  onClose,
  onSubmit,
  mode,
}: UseSharedAdviceFormParams) {
  const { t } = useTranslation();
  const stepState = useState(
    mode === 'edit' ? AdviceFormStep.FILL_FORM : AdviceFormStep.SELECT_FIELDS,
  );
  const fieldsSelectionState = useState<MRT_RowSelectionState>({});
  const [step, setStep] = stepState;
  const [fieldsSelection, setFieldsSelection] = fieldsSelectionState;

  const resetForm = useCallback(
    (args: { initialValues?: any; step?: number }) => {
      if (args.initialValues) {
        form.setInitialValues(args.initialValues);
      }
      setFieldsSelection({});
      form.reset();
      setStep(args.step || AdviceFormStep.SELECT_FIELDS);
    },
    [],
  );

  const handleSetRowSelection = (
    updater: MRT_Updater<MRT_RowSelectionState>,
  ) => {
    setFieldsSelection(updater);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formHasErrors = form.validate().hasErrors;
    const goToNextStep = () => {
      if (
        form.getValues().field.length === 1 &&
        step === AdviceFormStep.SELECT_FIELDS
      ) {
        // go to the last step if only one field is selected
        return setStep(AdviceFormStep.FILL_FORM);
      }
      return setStep((current) => current + 1);
    };
    const submitForm = () => {
      const values = form.getTransformedValues();
      onSubmit(values);
    };

    // Step 0 (Select fields): Validate the 'field' and proceed to the next step if no errors
    if (step === AdviceFormStep.SELECT_FIELDS) {
      if (!form.validateField('field').hasError) {
        goToNextStep();
      }
      return;
    }

    // Step 1 (Multi form validation): Proceed to fill form step
    if (step === AdviceFormStep.MULTI_FORM_VALIDATION) {
      return goToNextStep();
    }

    // Step 2 (Form data): Validate the entire form and handle submission
    if (step === AdviceFormStep.FILL_FORM) {
      if (!formHasErrors) {
        submitForm();
      }
    }
  };

  const prevStep = () => {
    // close the drawer if in select fields or in fill form (edit mode) step
    if (
      step === AdviceFormStep.SELECT_FIELDS ||
      (mode === 'edit' && step === AdviceFormStep.FILL_FORM)
    ) {
      if (form.isDirty()) {
        modals.openConfirmModal({
          title: t('common.error.unsaved_data_title'),

          children: <Text>{t('common.error.unsaved_data_description')}</Text>,

          labels: { confirm: t('common.close'), cancel: t('common.cancel') },

          confirmProps: { color: 'red' },

          onConfirm: () => {
            if (onClose) {
              // setFieldsSelection({});
              // form.reset();
              resetForm({ initialValues: rapeseedFormInitialValues });
              onClose();
            }
          },
        });
      } else if (onClose) {
        // setFieldsSelection({});
        // form.reset();
        resetForm({ initialValues: rapeseedFormInitialValues });
        onClose();
      }
    } else {
      if (
        step === AdviceFormStep.FILL_FORM &&
        form.getValues().field.length === 1
      ) {
        // go to the first step if only one field is selected
        setStep(AdviceFormStep.SELECT_FIELDS);
      } else {
        setStep((current) =>
          current > AdviceFormStep.SELECT_FIELDS ? current - 1 : current,
        );
      }
    }
  };

  useEffect(() => {
    const ids = Object.keys(fieldsSelection);
    if (ids.length > 0) {
      form.setFieldValue('field', ids);
    }
  }, [fieldsSelection]);

  return {
    stepState,
    fieldsSelectionState,
    handleSetRowSelection,
    handleSubmit,
    prevStep,
    resetForm,
  };
}

export default useSharedAdviceForm;
