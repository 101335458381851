import { NumberInput, NumberInputProps } from '@mantine/core';
import { forwardRef } from 'react';

import {
  calculateTotal,
  getTotalArea,
  usePrescriptionFormContext,
} from '../../../../hooks/prescriptions/use-prescription-form/prescription-form';
import classes from '../prescription-form.module.css';

import { divide } from '@data-access';

interface ZoneValueInputProps {
  index: number;
}

export const ZoneValueInput = forwardRef<HTMLInputElement, ZoneValueInputProps>(
  ({ index }, ref) => {
    const form = usePrescriptionFormContext();

    const { asUnit, ratio, zones } = form.getValues();

    const totalArea = getTotalArea(zones);

    const { onChange, ...inputProps } = form.getInputProps(
      `zones.${index}.${asUnit ? 'uValue' : 'value'}`,
    );

    const handleChange: NumberInputProps['onChange'] = (value) => {
      const castedValue = typeof value === 'number' ? value : parseFloat(value);

      const draftZones = zones.map((z, i) => {
        if (i === index) {
          if (!asUnit) {
            return {
              ...z,
              value: castedValue,
              uValue: castedValue * ratio,
            };
          }
          return {
            ...z,
            uValue: castedValue,
            value: divide(castedValue, ratio),
          };
        }
        return z;
      });

      form.setValues({
        zones: draftZones,

        // update average, total, uAverage, and uTotal
        ...calculateTotal(draftZones, totalArea),
      });

      // call mantine onChange
      onChange(value);
    };

    return (
      <NumberInput
        {...inputProps}
        classNames={{ input: classes.quantityInput }}
        decimalScale={2}
        hideControls
        onChange={handleChange}
        ref={ref}
      />
    );
  },
);
