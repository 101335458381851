import { ActionIcon, Stack } from '@mantine/core';
import {
  IconBinaryTree2,
  IconBuildingWarehouse,
  IconCircleArrowUp,
  IconCircleLetterN,
  IconDashboard,
  IconDeviceDesktopAnalytics,
  IconHelp,
  IconHierarchy3,
  IconHome,
  IconLogout,
  IconMap,
  IconPolygon,
  IconUserCircle,
  IconUsers,
} from '@tabler/icons-react';
import { getRouteApi, Link, LinkProps } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import NavbarLink from '../navbar-link/navbar-link';

import classes from './app-navbar.module.css';

import { useAuth } from '@auth/data-access';
import { ROLES } from '@data-access';
import { LangSelect } from '@ui';

const route = getRouteApi('/_app');

type Link = {
  label: string;
  to: LinkProps['to'];
  icon: typeof IconDashboard;
};

export function AppNavbar() {
  const navigate = route.useNavigate();
  const { logout, user } = useAuth();
  const { t } = useTranslation();

  const links: Link[] = [
    {
      to: '/dashboard',
      label: t('app.nav.dashboard'),
      icon: IconHome,
    },
    {
      to: '/map',
      label: t('app.nav.map'),
      icon: IconMap,
    },
    {
      to: '/fields',
      label: t('app.nav.fields'),
      icon: IconPolygon,
    },
    {
      to: '/precifert-n',
      label: t('app.nav.nitrogen'),
      icon: IconCircleLetterN,
    },
    {
      to: '/prescriptions',
      label: t('app.nav.prescriptions'),
      icon: IconCircleArrowUp,
    },
    {
      to: '/connectivity',
      label: t('app.nav.connectivity'),
      icon: IconBinaryTree2,
    },
  ];

  const adminLinks: Link[] = [
    {
      to: '/users',
      label: t('app.nav.users'),
      icon: IconUsers,
    },
    {
      to: '/accounts',
      label: t('app.nav.accounts'),
      icon: IconBuildingWarehouse,
    },
    {
      to: '/statistics',
      label: t('app.nav.statistics'),
      icon: IconDeviceDesktopAnalytics,
    },
  ];

  const responsiveLinks: Link[] = [
    {
      to: '/support',
      label: t('app.nav.help'),
      icon: IconHelp,
    },
    {
      to: '/profile',
      label: t('app.nav.profile'),
      icon: IconUserCircle,
    },
  ];

  const handleLogout = () => {
    if (window.confirm('Are you sure you want to logout?')) {
      logout();
      navigate({ to: '/' });
    }
  };

  return (
    <Stack className={classes.container} justify="space-between">
      <Stack gap="xs">
        {links.map((link) => (
          <NavbarLink {...link} key={link.label} />
        ))}
      </Stack>
      <Stack gap="xs">
        {user?.user_access === ROLES.ADMIN || user?.user_access === ROLES.DISTRI
          ? adminLinks.map((link) => <NavbarLink {...link} key={link.label} />)
          : null}
        {responsiveLinks.map((link) => (
          <NavbarLink {...link} hiddenFrom="xs" key={link.label} />
        ))}
        <Stack align="center" gap="xs" hiddenFrom="md">
          <LangSelect />

          <ActionIcon onClick={handleLogout} size={40} variant="filled">
            <IconLogout style={{ width: '70%', height: '70%' }} />
          </ActionIcon>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default AppNavbar;
