import {
  Alert,
  Button,
  Group,
  ScrollArea,
  Stepper,
  Text,
  Transition,
} from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useMutationState, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CommonAdviceFormProvider,
  CommonAdviceFormTransformedValues,
  CommonAdviceFormValues,
  useCommonAdviceForm,
} from '../../hooks/use-common-advice-form/common-advice-form';
import {
  AdviceFormStep,
  useSharedAdviceForm,
} from '../../hooks/use-shared-advice-form/shared-advice-form';
import { FieldsTable } from '../fields-table/fields-table';

import classes from './common-advice-form.module.css';
import { AgronomicDataFieldset } from './components/agronomic-data-fieldset';
import { FertilizerFieldset } from './components/fertilizer-fieldset';
import { SettingsFieldset, SettingsFieldsetDistri } from './components/settings-fieldset';

import { advicesQueries } from '@advices/data-access';
import { useAuth } from '@auth/data-access';
import { ROLES, useQueriesWithGlobal } from '@data-access';
import { Drawer } from '@ui';

interface CommonAdviceFormProps {
  selectedType: string;
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: CommonAdviceFormTransformedValues) => void;

  mode: 'create' | 'edit';
  initialIds?: number[];
}

export function CommonAdviceForm({
  opened,
  onClose,
  onSubmit,

  mode,
  initialIds,
  selectedType,
}: CommonAdviceFormProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const form = useCommonAdviceForm();
  const {
    handleSetRowSelection,
    handleSubmit,
    prevStep,
    stepState,
    fieldsSelectionState,
    resetForm,
  } = useSharedAdviceForm({ form, onClose, onSubmit, mode });
  const [active] = stepState;
  const [fieldsSelection] = fieldsSelectionState;
  const queries = useQueriesWithGlobal(advicesQueries);

  const mutation = useMutationState({
    filters: {
      mutationKey: ['advices', 'common'],
    },
  });

  const latestMutation = mutation[mutation.length - 1];

  const advice = useQuery({
    ...queries.detail({ id: initialIds![0], crop: selectedType }),
    enabled: mode === 'edit' && initialIds && initialIds.length === 1,
  });

  const title = () => {
    if (active === 0) {
      return t('advices.select-fields');
    } else if (active === 1) {
      return t('advices.edit_data');
    } else {
      return t('advices.confirm_data');
    }
  };

  const nextStep = () => {
    if ((active === 1 && form.getValues().field.length === 1) || active === 2) {
      return t('common.save');
    }

    return t('common.next');
  };

  useEffect(() => {
    // If the form is in edit mode and the advice data is available, set the initial values
    if (mode === 'edit' && advice.data && initialIds?.length === 1) {
      const initialValues: CommonAdviceFormValues = {
        field: [advice.data.field.id],
        variety: advice.data.variety?.id?.toString(),
        seed_date: advice.data.seed_date
          ? dayjs(advice.data.seed_date).toDate()
          : undefined,
        yield_potential: advice.data.yield_potential,
        olympic_yield: advice.data.olympic_yield,
        X_dose: advice.data.X_dose,
        nitrogen_already_applied: advice.data.nitrogen_already_applied,
        product: advice.data.product?.id.toString(),
        is_hidden: advice.data.is_hidden,
        disable_mail: advice.data.disable_mail,
        restart: advice.data.restart,
        data_source: advice.data.data_source,
      };

      resetForm({
        initialValues,
        step: AdviceFormStep.FILL_FORM,
      });
    } else if (mode === 'edit' && initialIds && initialIds.length > 1) {
      // If the form is in edit mode and multiple advices are selected,
      // set the initial values of `field` only to skip the first step (if we don't set it form validation will fail)
      resetForm({
        initialValues: {
          field: initialIds,
        },
        step: AdviceFormStep.FILL_FORM,
      });
    }
  }, [advice.data, initialIds, mode, resetForm]);

  useEffect(() => {
    // If the latest mutation is successful, reset the form
    if (latestMutation?.status === 'success') {
      resetForm({
        initialValues: {
          field: [],
        },
      });
    }
  }, [latestMutation?.status, resetForm]);

  return (
    <Drawer
      classNames={{
        body: clsx({ [classes.drawerBodyHeight]: active === 0 }),
      }}
      closeOnClickOutside={false}
      onClose={prevStep}
      opened={opened}
      position="right"
      scrollAreaComponent={
        active === AdviceFormStep.FILL_FORM ? ScrollArea.Autosize : undefined
      }
      size="auto"
      title={title()}
    >
      <CommonAdviceFormProvider form={form}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Stepper
            active={active}
            classNames={{
              root: classes.stepper,
              steps: classes.steps,
              content: classes.stepContent,
            }}
          >
            <Stepper.Step>
              {mode === 'create' ? (
                <FieldsTable
                  rowSelection={fieldsSelection}
                  setRowSelection={handleSetRowSelection}
                />
              ) : null}
            </Stepper.Step>
            <Stepper.Step>
              <Text fw="bold" size="lg">
                Vous avez sélectionné plusieurs parcelles
              </Text>
              <Text>
                Une fois l'enregistrement effectué, les données saisies seront appliquées à l'ensemble des parcelles sélectionnées.
              </Text>
            </Stepper.Step>
            <Stepper.Step>
              <AgronomicDataFieldset type={selectedType} />

              <FertilizerFieldset type={selectedType} />

              {user?.user_access === ROLES.ADMIN ? (
                <SettingsFieldset type={selectedType} />
              ) : null}
              {user?.user_access === ROLES.DISTRI ? (
                <SettingsFieldsetDistri />
              ) : null}
            </Stepper.Step>
          </Stepper>

          <Group className={clsx({ [classes.buttonsGroup]: true })} p="md">
            <Button
              loading={latestMutation?.status === 'pending'}
              type="submit"
            >
              {nextStep()}
            </Button>
            <Button color="red" onClick={prevStep} type="button">
              {active === 0 ? t('common.close') : t('common.previous')}
            </Button>
          </Group>
        </form>
      </CommonAdviceFormProvider>
    </Drawer>
  );
}

export default CommonAdviceForm;
