import {
  Button,
  Center,
  Group,
  Loader,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconEdit, IconMapPlus, IconTrash } from '@tabler/icons-react';
import { useSuspenseQuery, useQuery, useMutation } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldMapsLayout } from '../../../../components/field-maps-layout/field-maps-layout';
import SelectMapType from '../../../../components/select-map-type/select-map-type';
import useSharedFieldMaps from '../../../../hooks/use-shared-field-maps/shared-field-maps';
import { MAP_DISPLAY_TYPES } from '../compare-maps/page';

import { FORM_STATUS } from '@advices/data-access';
import { useAuth } from '@auth/data-access';
import { PRESCRIPTION_FROM_TYPES, ROLES } from '@data-access';
import { EDITTABLE_MAP_TYPES, fieldMapsApi } from '@fields/data-access';
import {
  LegendControl,
  PfMap,
  ZoningLayer,
  ZoningLegend,
  ZoningLegendProps,
} from '@map';
import { ActionsMenu, ActionsMenuItem, TsrButtonLink } from '@ui';

interface SoilMapProps {
  type: MAP_DISPLAY_TYPES;
}

export function YieldMap({ type }: SoilMapProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const {
    field,
    fieldId,
    mapId,
    compare,
    queries,
    handleMapLoad,
    handleCompareChange,
  } = useSharedFieldMaps({
    type,
  });
  const summary = useSuspenseQuery(queries.yieldSummary(Number(fieldId)));
  const [selectedMap, setSelectedMap] = useState<string | null>(
    mapId?.toString() ?? summary.data.options[0]?.value ?? null,
  );
  const yieldMap = useQuery({
    ...queries.yieldMap(Number(selectedMap)),
    enabled: selectedMap !== null,
    refetchInterval(query) {
      return query.state.data?.status !== FORM_STATUS.FINISHED ? 5000 : false;
    },
  });
  const deleteMap = useMutation({
    mutationFn: fieldMapsApi.deleteYieldmap,
    onSuccess(data, variables, context) {
      const filtered = summary.data.options.filter(
        (option) => option.value !== selectedMap,
      );
      // select first map in list
      setSelectedMap(filtered[0]?.value ?? null);
    },
  });

  const items: ActionsMenuItem[] = [
    {
      icon: IconMapPlus,
      label: 'Ajouter une carte',
      type: 'link',
      props: {
        to: '/fields/$fieldId/maps/$editableMapType/create',
        params: {
          fieldId,
          editableMapType: EDITTABLE_MAP_TYPES.YIELD,
        },
      },
    },
    {
      icon: IconEdit,
      label: 'Modifier',
      type: 'action',
      props: {
        disabled: true,
      },
      onClick: () => null,
    },
    {
      icon: IconTrash,
      label: 'Supprimer la carte',
      color: 'red',
      type: 'action',
      props: {
        disabled: selectedMap === null,
      },
      onClick() {
        modals.openConfirmModal({
          title: 'Supprimer la carte',
          content: 'Êtes-vous sûr de vouloir supprimer cette carte ?',
          labels: { confirm: t('common.delete'), cancel: t('common.cancel') },

          onConfirm: () => deleteMap.mutate(Number(selectedMap)),
        });
      },
    },
  ];

  const legend = (): ZoningLegendProps => {
    const map = yieldMap.data?.map;
    if (map) {
      const l = map.features.reduce((acc, feature) => {
        return {
          ...acc,
          [feature.properties.zone]: {
            zone: feature.properties.zone,
            color: feature.properties.color,
            value: feature.properties.value,
            percent: feature.properties.percent,
            area: feature.properties.area,
          },
        };
      }, {});

      const unit = yieldMap.data?.unit;

      return {
        title: `Rendement ${unit ? `(${unit})` : ''}`,
        legend: Object.values(l),
        unit: unit ?? '',
      };
    }
    return {
      legend: [],
    };
  };

  useEffect(() => {
    // Select the first map by default
    if (summary.data?.options.length && !selectedMap) {
      setSelectedMap(summary.data.options[0].value);
    }
  }, [selectedMap, summary.data]);

  return (
    <FieldMapsLayout
      content={
        <>
          {yieldMap.data?.status === FORM_STATUS.FINISHED ? (
            <PfMap onLoad={handleMapLoad}>
              <ZoningLayer data={yieldMap.data.map} filled outline={false} />
              <LegendControl>
                <ZoningLegend {...legend()} />
              </LegendControl>
            </PfMap>
          ) : null}

          {yieldMap.data?.status === FORM_STATUS.PENDING ? (
            <Center h="100%" p="xl">
              <Stack align="center">
                <Text fw="bold" size="xl" ta="center">
                  Nous sommes en train de préparer votre carte
                </Text>
                <Loader />
              </Stack>
            </Center>
          ) : null}

          {summary.data?.options.length === 0 ? (
            <Center h="100%" p="xl">
              <Stack>
                <Text fw="bold" size="xl" ta="center">
                  Aucune carte de rendement n'est disponible
                </Text>
              </Stack>
            </Center>
          ) : null}
        </>
      }
      header={
        <Group h="100%" justify="space-between" p="md">
          <Group>
            {type !== 'single' ? (
              <SelectMapType
                maps={field.data.properties.maps}
                onChange={handleCompareChange}
                value={type === 'compare-left' ? compare?.[0] : compare?.[1]}
              />
            ) : null}

            {summary.data?.options.length ? (
              <Select
                allowDeselect={false}
                data={summary.data?.options}
                disabled={summary.data?.options.length === 0}
                onChange={setSelectedMap}
                rightSection={yieldMap.isFetching ? <Loader size="xs" /> : null}
                value={selectedMap}
              />
            ) : null}
          </Group>
          <Group>
            <TsrButtonLink
              disabled={selectedMap === null}
              params={{
                fieldId,
              }}
              search={
                selectedMap !== null
                  ? {
                      mapType: PRESCRIPTION_FROM_TYPES.YIELDMAP,
                      mapId: Number(selectedMap),
                    }
                  : {}
              }
              to="/fields/$fieldId/maps/prescriptions/create"
            >
              Créer une carte de préconisation
            </TsrButtonLink>
            {user?.user_access === ROLES.ADMIN ? (
              <ActionsMenu items={items}>
                <ActionsMenu.Button />
              </ActionsMenu>
            ) : null}
          </Group>
        </Group>
      }
    />
  );
}

export default YieldMap;
