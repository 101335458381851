import {
  Button,
  Group,
  ScrollArea,
  Select,
  Stepper,
  Text,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { useMutationState, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdviceFormStep,
  useSharedAdviceForm,
} from '../../hooks/use-shared-advice-form/shared-advice-form';
import {
  useWheat2AdviceForm,
  Wheat2AdviceFormProvider,
  Wheat2AdviceFormValues,
} from '../../hooks/use-wheat2-advice-form/wheat2-advice-form';
import { FieldsTable } from '../fields-table/fields-table';
import InfoLabel from '../info-label/info-label';

import { SettingsFieldset, SettingsFieldsetDistri } from './components/settings-fieldset';
import classes from './wheat2-advice-form.module.css';

import {
  advicesQueries,
  CreateWheat2AdviceDto,
  useValues,
} from '@advices/data-access';
import { useAuth } from '@auth/data-access';
import { ROLES,CropEnum, useQueriesWithGlobal } from '@data-access';
import { Drawer } from '@ui';


interface Wheat2AdviceFormProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: CreateWheat2AdviceDto) => void;
  mode: 'create' | 'edit';
  initialIds?: number[];
}

export function Wheat2AdviceForm({
  opened,
  onClose,
  onSubmit,
  mode,
  initialIds,
}: Wheat2AdviceFormProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const form = useWheat2AdviceForm();
  const selectedFields = form.getValues().field;
  const {
    handleSetRowSelection,
    handleSubmit,
    prevStep,
    stepState,
    fieldsSelectionState,
    resetForm,
  } = useSharedAdviceForm({ form, onClose, onSubmit, mode });
  const [active] = stepState;
  const [fieldsSelection] = fieldsSelectionState;
  const queries = useQueriesWithGlobal(advicesQueries);

  const values = useValues({
    type: CropEnum.WHEAT2,
    fieldId: selectedFields.length === 1 ? selectedFields[0] : null,
  });

  const mutation = useMutationState({
    filters: {
      mutationKey: ['advices', 'wheat2'],
    },
  });

  const latestMutation = mutation[mutation.length - 1];

  const frenchDateParser = (value: string) => {
    const [day, month, year] = value.split(/[/\s-]+/).map(Number);
    return new Date(year, month - 1, day);
  };

  const advice = useQuery({
    ...queries.wheat2Details({ id: initialIds![0] }),
    enabled: mode === 'edit' && initialIds && initialIds.length === 1,
  });

  const title = () => {
    if (active === 0) {
      return t('advices.select-fields');
    } else if (active === 1) {
      return t('advices.edit_data');
    } else {
      return t('advices.confirm_data');
    }
  };

  const nextStep = () => {
    if ((active === 1 && form.getValues().field.length === 1) || active === 2) {
      return t('common.save');
    }

    return t('common.next');
  };

  useEffect(() => {
    // If the form is in edit mode and the advice data is available, set the initial values
    if (mode === 'edit' && advice.data && initialIds?.length === 1) {
      const initialValues: Wheat2AdviceFormValues = {
        field: [advice.data.field.id],
        product: advice.data.product?.id.toString(),
        nitrogen_to_apply: advice.data.nitrogen_to_apply,
        repartition_method: advice.data.repartition_method,
        date: advice.data.date
                  ? dayjs(advice.data.date).toDate()
                  : undefined,

        is_hidden: advice.data.is_hidden,
        disable_mail: advice.data.disable_mail,
        restart: advice.data.restart,
      };

      resetForm({
        initialValues,
        step: AdviceFormStep.FILL_FORM,
      });
    } else if (mode === 'edit' && initialIds && initialIds.length > 1) {
      // If the form is in edit mode and multiple advices are selected,
      // set the initial values of `field` only to skip the first step (if we don't set it form validation will fail)
      resetForm({
        initialValues: {
          field: initialIds,
        },
        step: AdviceFormStep.FILL_FORM,
      });
    }
  }, [advice.data, initialIds, mode, resetForm]);

  useEffect(() => {
    // If the latest mutation is successful, reset the form
    if (latestMutation?.status === 'success') {
      resetForm({
        initialValues: {
          field: [],
        },
      });
    }
  }, [latestMutation?.status, resetForm]);

  return (
    <Drawer
      classNames={{
        body: clsx({ [classes.drawerBodyHeight]: active === 0 }),
      }}
      closeOnClickOutside={false}
      onClose={prevStep}
      opened={opened}
      position="right"
      scrollAreaComponent={
        active === AdviceFormStep.FILL_FORM ? ScrollArea.Autosize : undefined
      }
      size="auto"
      title={title()}
    >
      <Wheat2AdviceFormProvider form={form}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Stepper
            active={active}
            classNames={{
              root: classes.stepper,
              steps: classes.steps,
              content: classes.stepContent,
            }}
          >
            <Stepper.Step>
              {mode === 'create' ? (
                <FieldsTable
                  rowSelection={fieldsSelection}
                  setRowSelection={handleSetRowSelection}
                />
              ) : null}
            </Stepper.Step>
            <Stepper.Step>
              <Text fw="bold" size="lg">
                Vous avez sélectionné plusieurs parcelles
              </Text>
              <Text>
                Une fois l'enregistrement effectué, les données saisies seront appliquées à l'ensemble des parcelles sélectionnées.
              </Text>
            </Stepper.Step>
            <Stepper.Step>
              <TextInput
                {...form.getInputProps('nitrogen_to_apply')}
                label="Dose d'azote à apporter (u/ha)"
              />
              <Select
                {...form.getInputProps('product')}
                allowDeselect
                data={values.data?.product}
                label="Produit"
              />
              <DateInput
                {...form.getInputProps('date')}
                dateParser={frenchDateParser}
                // defaultDate={dayjs(values.data?.date).toDate()}
                // key is needed to ensure that date input will be re-rendered to set default date
                // key={dayjs(values.data?.date).toDate().toString()}
                label={
                  <InfoLabel
                    info={
                      <p>
                        Date à laquelle vous prévoyez réaliser votre apport. 
                        La carte sera calculée 48h avant avec les dernières données disponibles.
                      </p>
                    }
                    label={t('common.date')}
                  />
                }
                rightSection={<IconCalendar height="60%" width="60%" />}
                valueFormat="DD/MM/YYYY" 
              />
              <Select
                {...form.getInputProps('repartition_method')}
                allowDeselect
                data={values.data?.repartition_method}
                label={
                  <InfoLabel
                    info={
                      <p>
                        La stratégie de répartition consiste à choisir la
                        méthode pour répartir la quantité d'azote que vous avez
                        indiqué. Deux approches sont possibles :
                        <ul>
                          <li>
                            Renforcer les zones faibles : cibler les zones où la
                            biomasse est la plus basse, afin de rééquilibrer et
                            homogénéiser la parcelle.
                          </li>
                          <li>
                            Optimiser les zones fortes : concentrer les apports
                            sur les zones présentant une biomasse élevée, pour
                            maximiser leur potentiel.
                          </li>
                        </ul>
                      </p>
                    }
                    label="Stratégie de répartition"
                  />
                }
              />
              {user?.user_access === ROLES.ADMIN ? (
                <SettingsFieldset />
              ) : null}
              {user?.user_access === ROLES.DISTRI ? (
                <SettingsFieldsetDistri />
              ) : null}
            </Stepper.Step>
          </Stepper>

          <Group className={clsx({ [classes.buttonsGroup]: true })} p="md">
            <Button
              loading={latestMutation?.status === 'pending'}
              type="submit"
            >
              {nextStep()}
            </Button>
            <Button color="red" onClick={prevStep} type="button">
              {active === 0 ? t('common.close') : t('common.previous')}
            </Button>
          </Group>
        </form>
      </Wheat2AdviceFormProvider>
    </Drawer>
  );
}

export default Wheat2AdviceForm;
