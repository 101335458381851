import { setUser } from '@sentry/react';
import * as React from 'react';

import { useLoggedUser } from '../hooks/use-logged-user/logged-user';
import { useLogin } from '../hooks/use-login/login';
import { Profile } from '../types/profile.types';

import { getAccessToken, setAccessToken } from '@data-access';

export interface IAuthContext {
  isAuthenticated: boolean;
  login: ReturnType<typeof useLogin>;
  logout: () => Promise<void>;
  user?: Profile;
}

export const AuthContext = React.createContext<IAuthContext | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = React.useState<string | null>(getAccessToken());
  const isAuthenticated = !!token;
  const loggedUser = useLoggedUser({ enabled: isAuthenticated });

  const login = useLogin({
    onSuccess: (data) => {
      setToken(data.token);
      setAccessToken(data.token);
    },
  });

  const logout = React.useCallback(async () => {
    setAccessToken(null);
    setToken(null);
  }, []);

  React.useEffect(() => {
    setToken(getAccessToken());
  }, []);

  React.useEffect(() => {
    if (loggedUser.data?.id) {
      setUser({
        id: loggedUser.data.id,
      });
    } else {
      setUser(null);
    }
  }, [loggedUser]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, user: loggedUser.data }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
