import { useQuery } from '@tanstack/react-query';

import { fieldsQueries } from '../../queries/fields-queries';

import { useQueriesWithGlobal } from '@data-access';

export function useValues() {
  const queries = useQueriesWithGlobal(fieldsQueries);

  const values = useQuery(queries.values());

  return values;
}

export default useValues;
