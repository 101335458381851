import { Stack, Paper, Overlay, Text, Button } from '@mantine/core';
import { captureException } from '@sentry/react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import {
  ErrorRouteComponent,
  useParams,
  useRouter,
} from '@tanstack/react-router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './page.module.css';

import { PfMap } from '@map';

export const ErrorPage: ErrorRouteComponent = ({ error, reset }) => {
  const { fieldId } = useParams({
    from: '/_app/fields_/$fieldId/maps/$mapType',
  });
  const router = useRouter();
  const { t } = useTranslation();

  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  const handleReset = () => {
    reset();
    router.navigate({
      to: '/fields/$fieldId',
      params: {
        fieldId,
      },
    });
  };

  useEffect(() => {
    // Reset the query error boundary
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  useEffect(() => {
    if (error) {
      captureException(error);
    }
  }, [error]);

  return (
    <Stack h="100%">
      <Paper flex="1 0 auto" h={68}></Paper>
      <Paper h="100%" p={0} pos="relative">
        <Overlay className={classes.overlay}>
          <Paper>
            <Text mb="md" size="lg">
              {t('field.maps.errorTitle')}
            </Text>
            <Button fullWidth onClick={handleReset}>
              {t('common.resetError')}
            </Button>
          </Paper>
        </Overlay>
        <PfMap></PfMap>
      </Paper>
    </Stack>
  );
};

export default ErrorPage;
