import { Stack } from '@mantine/core';
import clsx from 'clsx';

import classes from './full-main-height.module.css';

interface FullMainHeightSidebarProps {
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
}

export const FullMainHeightSidebar = ({
  children,
  size = 'md',
}: FullMainHeightSidebarProps) => {
  return (
    <div
      className={clsx(classes.sidebar, {
        [classes.sidebarSm]: size === 'sm',
        [classes.sidebarMd]: size === 'md',
        [classes.sidebarLg]: size === 'lg',
      })}
    >
      {children}
    </div>
  );
};
