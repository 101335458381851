import { useQuery } from '@tanstack/react-query';

import { advicesQueries } from '../../queries/advices';

import { useQueriesWithGlobal } from '@data-access';

interface Params {
  type: string;
  fieldId?: number | null;
}

export function useValues({ type, fieldId }: Params) {
  const queries = useQueriesWithGlobal(advicesQueries);

  const values = useQuery(
    queries.values({ crop: type, fieldId: fieldId ?? null }),
  );

  return values;
}

export default useValues;
