import { MultiPoint } from 'geojson';
import { Layer, Source } from 'react-map-gl';

export const SAMPLES_LAYER_ID = 'samples-layer';

export type SamplesFeatureCollection = GeoJSON.FeatureCollection<
  MultiPoint,
  Record<string, any>
>;

/* eslint-disable-next-line */
export interface SamplesLayerProps {
  data: SamplesFeatureCollection;
  id?: string;
}

const polygonOutlineLayerStyle = {
  type: 'line' as const,
  paint: {
    'line-color': '#000',
    'line-width': 3,
  },
};

export function SamplesLayers(props: SamplesLayerProps) {
  return (
    <Source data={props.data} id="samples-data" type="geojson">
      <Layer
        id={props.id || SAMPLES_LAYER_ID}
        paint={{
          'circle-color': ['get', 'color'],
          'circle-radius': 5,
          'circle-opacity': 0.8,
        }}
        type="circle"
      />
      {/* <Layer
      id={props.id ? `${props.id}_outline` : undefined}
      {...polygonOutlineLayerStyle}
      /> */}
    </Source>
  );
}

export default SamplesLayers;
