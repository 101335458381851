import {
  Alert,
  Button,
  Fieldset,
  Group,
  NumberInput,
  ScrollArea,
  Select,
  Switch,
  Transition,
} from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { UsePkForm, usePkForm } from '../../hooks/use-pk-form/pk-form';

import classes from './pk-form.module.css';

import { useQueriesWithGlobal } from '@data-access';
import { fieldsQueries } from '@fields/data-access';

interface PkFormProps {
  fieldId: number;
  isPending?: boolean;
  error?: Error | null;
  initialValues: UsePkForm['FormValues'];
  onCancel: () => void;
  onSubmit: (values: UsePkForm['TransformedValues']) => void;
}

export function PkForm({
  fieldId,
  onCancel,
  onSubmit,
  isPending,
  initialValues,
  error,
}: PkFormProps) {
  const { t, i18n } = useTranslation();
  const form = usePkForm({
    initialValues,
  });

  const queries = useQueriesWithGlobal(fieldsQueries);
  const pkValues = useQuery(queries.pkValues(fieldId));

  const selectedCrop = pkValues.data?.res.crop.find(
    (crop) => crop.id.toString() === form.getValues().crop,
  );
  const selectedProduct = pkValues.data?.res.product.find(
    (product) => product.id.toString() === form.getValues().product,
  );

  const handleSubmit = (values: UsePkForm['TransformedValues']) => {
    onSubmit(values);
  };

  form.watch('withResidue', ({ value }) => {
    if (value === false) {
      form.setValues({
        residue: null,
      });
    }
  });

  form.watch('organic', ({ value }) => {
    if (value === false) {
      form.setValues({
        product: null,
        product_qt: null,
      });
    }
  });

  form.watch('is_simplified', ({ value }) => {
    if (value === false) {
      form.setValues({
        P2O5_value: null,
        K2O_value: null,
      });
    } else {
      form.setValues({
        P2O5_value: initialValues.P2O5_value,
        K2O_value: initialValues.K2O_value,
      });
    }
  });

  return (
    <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <ScrollArea className={classes.inputsContainer}>
        <Fieldset legend={t('advices.agronomic_data')} mt="sm" variant="filled">
          <Select
            {...form.getInputProps('soil_type')}
            data={pkValues.data?.soil_type}
            label={t('common.soilType')}
            required
          />
          <Select
            {...form.getInputProps('crop')}
            data={pkValues.data?.crop(i18n.language)}
            label={t('common.properties.crop')}
            mt="sm"
            required
          />
          <NumberInput
            {...form.getInputProps('expected_yield')}
            hideControls
            label={`${t('common.properties.pk_expected_yield')} (${selectedCrop?.unit || '-'}/ha)`}
            mt="sm"
            required
          />

          <Switch
            {...form.getInputProps('withResidue', { type: 'checkbox' })}
            label={t('common.properties.withResidue')}
            mt="sm"
          />
          {form.getValues().withResidue ? (
            <NumberInput
              {...form.getInputProps('residue')}
              hideControls
              label={t('common.properties.residue')}
              mt="sm"
              required
            />
          ) : null}
        </Fieldset>

        <Fieldset
          legend={t('forms.impasse_fieldset_legend')}
          mt="sm"
          variant="filled"
        >
          <Select
            {...form.getInputProps('P2O5_impasse')}
            data={pkValues.data?.P2O5_impasse}
            label="P205"
            required
          />

          <Select
            {...form.getInputProps('K2O_impasse')}
            data={pkValues.data?.K2O_impasse}
            label="K2O"
            mt="sm"
            required
          />
        </Fieldset>

        <Switch
          {...form.getInputProps('organic', { type: 'checkbox' })}
          label={t('advice.organic_fert')}
          mt="xl"
        />
        {form.getValues().organic ? (
          <>
            <Select
              {...form.getInputProps('product')}
              allowDeselect
              clearable
              data={pkValues.data?.product(i18n.language)}
              label={t('advices.properties.pdt_org1')}
              mt="sm"
              required
            />
            <NumberInput
              {...form.getInputProps('product_qt')}
              hideControls
              label={`${t('advices.properties.qte_pdt_ependu')} (${selectedProduct?.unit || '-/ha'})`}
              mt="sm"
              required
            />
          </>
        ) : null}

        <Switch
          label={t('common.properties.pk_is_simplied')}
          mt="lg"
          readOnly={pkValues.data?.res.is_simplified}
          {...form.getInputProps('is_simplified', { type: 'checkbox' })}
        />
        {form.getValues().is_simplified ? (
          <>
            <NumberInput
              hideControls
              label={t('common.properties.P2O5_value')}
              mt="sm"
              required
              {...form.getInputProps('P2O5_value')}
            />
            <NumberInput
              hideControls
              label={t('common.properties.K2O_value')}
              mt="sm"
              required
              {...form.getInputProps('K2O_value')}
            />
          </>
        ) : null}
      </ScrollArea>

      <Group mt="lg">
        <Transition
          duration={400}
          mounted={!!error}
          timingFunction="ease"
          transition="fade-up"
        >
          {(styles) => (
            <Alert
              color="red"
              icon={<IconInfoCircle />}
              style={styles}
              variant="filled"
              w="100%"
            >
              Une erreur est survenue lors de l'enregistrement des données
            </Alert>
          )}
        </Transition>
        <Button loading={isPending} type="submit">
          {t('common.save')}
        </Button>
        <Button color="red" onClick={onCancel} type="button">
          {t('common.cancel')}
        </Button>
      </Group>
    </form>
  );
}

export default PkForm;
