import classes from './full-main-height.module.css';

interface FullMainHeightHeaderProps {
  children: React.ReactNode;
}

export const FullMainHeightHeader = ({
  children,
}: FullMainHeightHeaderProps) => {
  return <div className={classes.header}>{children}</div>;
};
