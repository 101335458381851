import { createFileRoute } from '@tanstack/react-router';

import { AdvicesListPage } from '@advices/feature';

type Search = {
  type?: string;
};

export const Route = createFileRoute('/_app/precifert-n')({
  validateSearch: (search: Record<string, unknown>): Search => {
    // validate and parse the search params into a typed state
    return {
      type: (search.type as string) || '',
    };
  },

  component: AdvicesListPage,
  errorComponent: AdvicesListPage.Error,
});
