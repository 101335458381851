import {
  Button,
  Center,
  Group,
  Loader,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FieldMapsLayout } from '../../../../components/field-maps-layout/field-maps-layout';
import SelectMapType from '../../../../components/select-map-type/select-map-type';
import useSharedFieldMaps from '../../../../hooks/use-shared-field-maps/shared-field-maps';
import { MAP_DISPLAY_TYPES } from '../compare-maps/page';

import { FORM_STATUS } from '@advices/data-access';
import { layersOptions, PRESCRIPTION_FROM_TYPES } from '@data-access';
import { ImageLayer, PfMap, PlotsLayer, SelectImageSlider } from '@map';
import { TsrButtonLink } from '@ui';

interface SatelliteMapProps {
  type: MAP_DISPLAY_TYPES;
}

export function SatelliteMap({ type }: SatelliteMapProps) {
  const { t } = useTranslation();
  const {
    field,
    compare,
    fieldId,
    queries,
    handleMapLoad,
    handleCompareChange,
  } = useSharedFieldMaps({ type });

  const [heatmapType, setHeatmapType] = useState('NDVI');
  const [selectedHeatmap, setSelectedHeatmap] = useState<string | null>(null);

  // Fetch maps data using react-query
  const maps = useQuery({
    ...queries.heatmapsSummary({
      fieldId: Number(fieldId),
      heatmapType: heatmapType,
    }),
    refetchInterval(query) {
      return query.state.data?.status !== FORM_STATUS.FINISHED ? 5000 : false;
    },
  });

  // Find the currently selected map
  const selectedMap = maps.data?.data.find(
    (map) => map.id?.toString() === selectedHeatmap,
  );

  // Automatically set the first heatmap option if none is selected
  useEffect(() => {
    if (maps.data?.options?.length && !selectedHeatmap) {
      setSelectedHeatmap(maps.data.options[0].value);
    }
  }, [maps.data, selectedHeatmap]);

  // Render the map and heatmap slider if status is FINISHED
  const renderMapContent = () => {
    if (maps.data?.status === FORM_STATUS.FINISHED && selectedMap) {
      return (
        <Stack gap={0} h="100%">
          <PfMap onLoad={handleMapLoad}>
            <ImageLayer
              coordinates={maps.data?.corners_coords}
              imageUrl={selectedMap.url}
            />
            <PlotsLayer
              data={{ type: 'FeatureCollection', features: [field.data] }}
              outline
            />
          </PfMap>
          <SelectImageSlider
            key={heatmapType + maps.data?.options?.length}
            onChange={setSelectedHeatmap}
            options={maps.data?.options.map(option => ({ ...option, manual_check: false })) || []}
            value={selectedHeatmap || ''}
          />
        </Stack>
      );
    }
    return null;
  };

  // Render the loading state if status is PENDING
  const renderLoadingState = () => {
    if (!maps.data || maps.data?.status === FORM_STATUS.PENDING) {
      return (
        <Center h="100%" p="xl">
          <Stack align="center">
            <Text fw="bold" size="xl" ta="center">
              {t('Nous sommes en train de préparer vos cartes')}
            </Text>
            <Loader />
          </Stack>
        </Center>
      );
    }
    return null;
  };

  return (
    <FieldMapsLayout
      content={
        <>
          {renderMapContent()}
          {renderLoadingState()}
        </>
      }
      header={
        <Group h="100%" justify="space-between" p="md">
          <Group>
            {type !== 'single' && (
              <SelectMapType
                maps={field.data.properties.maps}
                onChange={handleCompareChange}
                value={type === 'compare-left' ? compare?.[0] : compare?.[1]}
              />
            )}
            <Select
              data={layersOptions(t)}
              disabled={maps.data?.status !== FORM_STATUS.FINISHED}
              onChange={(v) => setHeatmapType(v ?? 'LAI')}
              value={heatmapType}
            />
          </Group>
          <Group>
            <TsrButtonLink
              disabled={heatmapType === 'RGB' || selectedHeatmap === null}
              params={{ fieldId }}
              search={
                selectedMap?.id
                  ? {
                      mapType: PRESCRIPTION_FROM_TYPES.SATELLITE,
                      heatmapId: selectedMap.id,
                      heatmapLayer: heatmapType,
                    }
                  : {}
              }
              to="/fields/$fieldId/maps/prescriptions/create"
            >
              {t('Créer une carte de préconisation')}
            </TsrButtonLink>
          </Group>
        </Group>
      }
    />
  );
}

export default SatelliteMap;
