import { List, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export const SoilTypeInfo = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Text fw="bold" size="md">
        {t('advices.soil_type_info1')}
      </Text>
      <List size="sm">
        <List.Item>{t('advices.soil_type_info2')}</List.Item>
        <List.Item>{t('advices.soil_type_info3')}</List.Item>
        <List.Item>{t('advices.soil_type_info4')}</List.Item>
      </List>

      <Text fw="bold" size="md">
        {t('advices.soil_type_info5')}
      </Text>
      <Text size="sm">{t('advices.soil_type_info6')}</Text>

      <Text fw="bold" size="md">
        {t('advices.soil_type_info7')}
      </Text>
      <Text size="sm">{t('advices.soil_type_info8')}</Text>

      <Text fw="bold" size="md">
        {t('advices.soil_type_info9')}
      </Text>
      <Text size="sm">{t('advices.soil_type_info10')}</Text>
    </div>
  );
};
