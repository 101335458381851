import { constants } from '@mapbox/mapbox-gl-draw/';
import { difference, featureCollection, flatten } from '@turf/turf';

import { highlightPropertyName } from '../utils/constants';

export const modeName = 'cut_polygon';

const { geojsonTypes } = constants;

/// This mode uses the `mapbox-gl-draw-passing-mode` mode to draw the spilitting lineString.
/// here is the name used to add that mode:
export const passingModeName = `passing_draw_polygon`;

export const defaultOptions = {
  highlightColor: '#222',
  lineWidth: 0.001,
  lineWidthUnit: 'kilometers',
};

export const CutPolygonMode: MapboxDraw.DrawCustomMode = {
  toDisplayFeatures: function (state, geojson, display) {
    display(geojson);
  },
};

CutPolygonMode.onSetup = function (opt) {
  const { highlightColor = defaultOptions.highlightColor } = opt || {};

  const main = this.getSelected()
    .filter((f) => f.type === 'Polygon' || f.type === 'MultiPolygon')
    .map((f) => {
      f.setProperty(highlightPropertyName, highlightColor);
      this.addFeature(f);
      return f.toGeoJSON();
    }) as GeoJSON.Feature[];

  if (main.length < 1) {
    throw new Error(
      'Please select a feature/features (Polygon or MultiPolygon) to split!'
    );
  }
  /// `onSetup` job should complete for this mode to work.
  /// so `setTimeout` is used to bupass mode change after `onSetup` is done executing.
  setTimeout(() => {
    this.changeMode(passingModeName, {
      onDraw: (cuttingpolygon: any) => {
        main.forEach((feature) => {
          if (
            feature.geometry.type === geojsonTypes.POLYGON ||
            feature.geometry.type === geojsonTypes.MULTI_POLYGON
          ) {
            const afterCut = difference(
              featureCollection([feature, cuttingpolygon])
            );

            if (afterCut) {
              const newFeatures = flatten(afterCut).features;

              newFeatures.forEach((f) => {
                const newFeature = this.newFeature(structuredClone(f));

                newFeature.setProperty(highlightPropertyName, undefined);
                this.addFeature(newFeature);
              });

              if (feature.id && this.getFeature(feature.id.toString())) {
                this.deleteFeature(feature.id.toString());
              }

              // this.(newF);
            }
          } else {
            console.info('The feature is not Polygon/MultiPolygon!');
          }
        });
      },
    });
  }, 0);

  return {
    main,
  };
};

export default CutPolygonMode;
