import {
  Fieldset,
  Select,
  Paper,
  Skeleton,
  Center,
  Text,
  Group,
  SelectProps,
  ThemeIcon,
} from '@mantine/core';
import { IconCheck, IconMap } from '@tabler/icons-react';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRapeseedAdviceFormContext } from '../../../hooks/use-rapeseed-advice-form/rapeseed-advice-form';
import { BiomassMap } from '../../biomass-map/biomass-map';
import InfoLabel from '../../info-label/info-label';

import { useValues } from '@advices/data-access';
import { CropEnum } from '@data-access';

export const BiomassMapsFieldset = () => {
  const { t } = useTranslation();
  const form = useRapeseedAdviceFormContext();
  const [biomassStartMapId, setBiomassStartMapId] = useState<
    number | undefined
  >();
  const [biomassEndMapId, setBiomassEndMapId] = useState<number | undefined>();
  const selectedFields = form.getValues().field;

  const values = useValues({
    type: CropEnum.RAPESEED,
    fieldId: selectedFields.length === 1 ? selectedFields[0] : null,
  });

  const renderSelectOption: SelectProps['renderOption'] = ({
    option,
    checked,
  }) => {
    const maps = [
      ...(values.data?.biomass_map_start || []),
      ...(values.data?.biomass_map_end || []),
    ];
    const isMap = maps?.find((map) => map.value === option.value) !== undefined;
    return (
      <Group flex="1" gap="xs">
        {checked && <IconCheck opacity={0.6} size={18} stroke={1.5} />}
        {option.label}
        {isMap ? (
          <ThemeIcon size="sm" variant="transparent">
            <IconMap stroke={1.5} />
          </ThemeIcon>
        ) : null}
      </Group>
    );
  };

  form.watch('biomass_start', ({ value }) => {
    if (value) {
      const next = values.data?.biomass_map_start?.find(
        (map) => map.value === value,
      )?.id;
      setBiomassStartMapId(next);
    }
  });

  form.watch('biomass_end', ({ value }) => {
    if (value) {
      const next = values.data?.biomass_map_end?.find(
        (map) => map.value === value,
      )?.id;
      setBiomassEndMapId(next);
    }
  });

  useEffect(() => {
    if (values.data) {
      const startMap = values.data.biomass_map_start?.find(
        (map) => map.value === form.getValues().biomass_start,
      );
      const endMap = values.data.biomass_map_end?.find(
        (map) => map.value === form.getValues().biomass_end,
      );

      if (startMap) {
        setBiomassStartMapId(startMap.id);
      }

      if (endMap) {
        setBiomassEndMapId(endMap.id);
      }
    }
  }, [values.data]);

  return (
    <Fieldset legend={t('advices.properties.biomass_legend')} variant="filled">
      <Select
        {...form.getInputProps('biomass_start')}
        data={values.data?.biomass_merge_start}
        label={
          <InfoLabel
            info={t('advices.properties.biomass_start_info')}
            label={t('advices.properties.biomass_start')}
          />
        }
        renderOption={renderSelectOption}
      />

      <Paper h={500} mt="sm" p={0} shadow="unset" w={650}>
        {biomassStartMapId !== undefined ? (
          <Suspense fallback={<BiomassMap.Pending />}>
            <BiomassMap mapId={biomassStartMapId} />
          </Suspense>
        ) : (
          <BiomassMap.Error />
        )}
      </Paper>
      <Select
        {...form.getInputProps('biomass_end')}
        data={values.data?.biomass_merge_end}
        label={
          <InfoLabel
            info={t('advices.properties.biomass_end_info')}
            label={t('advices.properties.biomass_end')}
          />
        }
        mt="sm"
        renderOption={renderSelectOption}
      />
      <Paper h={500} mt="sm" p={0} shadow="unset" w={650}>
        {biomassEndMapId !== undefined ? (
          <Suspense fallback={<Skeleton h="100%" w="100%" />}>
            <BiomassMap mapId={biomassEndMapId} />
          </Suspense>
        ) : (
          <Center bg="gray.1" h="100%" p="xl">
            <Text fw="bold" size="xl" ta="center">
              {t('advices.biomass_map_unavailable')}
            </Text>
          </Center>
        )}
      </Paper>
    </Fieldset>
  );
};
